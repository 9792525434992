import React, { useEffect, useState, useRef } from "react";
import { DatePicker, Alert, Form, Input, Spin } from "antd";
import SimpleReactValidator from "simple-react-validator";
import moment from "moment";
import { getProfile, updateProfile } from "../APIClient";
import PrimaryButton from "./PrimaryButton";
import { useHistory } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import ReactDOM from "react-dom";
import CustomDatePicker from "./CustomDatePicker";

const AccountProfilePanel = () => {
  const [alert, setAlert] = useState({ text: "", type: "" });
  const [loading, setLoading] = useState(false);
  const [isMounted, setIsMounted] = useState(true);
  const [values, setValues] = useState({
    avatar: "",
    email: "",
    full_name: "",
    date_of_birth: "",
    zip_code: "",
    no_insurance_plan: false,
  });
  const [, forceUpdate] = useState();
  const simpleValidator = useRef(
    new SimpleReactValidator({
      autoForceUpdate: { forceUpdate: forceUpdate },
      element: (message) => (
        <div className="rnm-input-error text-xs">{message}</div>
      ),
      validators: {
        date_older_than: {
          message: "You must be older than 18 years-old",
          rule: function (val, params) {
            return (
              moment(val).diff(moment().subtract(params[0], "years"), "hours") <
              0
            );
          },
        },
      },
    })
  );

  const disabled = () => !simpleValidator.current.allValid();
  useEffect(() => {
    setIsMounted(true);
    loadProfile();
    return () => setIsMounted(false);
  }, []);

  const loadProfile = async () => {
    setLoading(true);
    try {
      const profile = await getProfile();
      const {
        avatar,
        email,
        full_name,
        date_of_birth,
        zip_code,
        no_insurance_plan,
      } = profile.Item;
      if (isMounted) {
        setValues({
          avatar,
          email,
          full_name,
          date_of_birth: new Date(
            date_of_birth.replace(/-/g, "/") + " 00:00:00"
          ),
          zip_code,
          no_insurance_plan,
        });
        console.log(
          "date_of_birth",
          date_of_birth,
          new Date(date_of_birth.replace(/-/g, "/") + " 00:00:00")
        );
      }
    } catch (error) {
      console.log(error);
    } finally {
      if (isMounted) {
        setLoading(false);
      }
    }
  };

  const onSubmit = async () => {
    setLoading(true);
    setAlert({ text: "", type: "" });
    if (!values.full_name.trim() || !values.date_of_birth) {
      setAlert({ text: "All form fields are required.", type: "error" });
      return;
    }

    try {
      const profile = await updateProfile({
        avatar: values.avatar,
        email: values.email.trim(),
        full_name: values.full_name.trim(),
        date_of_birth: moment(values.date_of_birth).format("YYYY-MM-DD"),
        zip_code: values.zip_code,
        no_insurance_plan: values.no_insurance_plan,
      });
      setAlert({
        text: "Your profile has been updated successfully",
        type: "success",
      });
    } catch (e) {
      setAlert({ text: e.message, type: "error" });
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues((v) => ({ ...v, [name]: value }));
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    simpleValidator.current.showMessageFor(name);
    setValues((v) => ({ ...v, [name]: value }));
  };

  const handleDatePickerChange = (value) => {
    simpleValidator.current.showMessageFor("date_of_birth");
    setValues((v) => ({ ...v, date_of_birth: value === null ? "" : value }));
  };

  const handleDatePickerBlur = (value) => {
    simpleValidator.current.showMessageFor("date_of_birth");
    setValues((v) => ({ ...v, date_of_birth: value === null ? "" : value }));
  };

  return (
    <>
      <h3 className="text-lg font-bold text-primary mt-5 mb-0">
        Update Contact Information
      </h3>

      {alert.text && (
        <Alert
          message={alert.text}
          type={alert.type}
          style={{ marginBottom: 24, marginTop: 24 }}
          closable
          onClose={() => setAlert({ text: "", type: "" })}
        />
      )}
      <Form className="mt-6 mb-11 w-96">
        <Form.Item>
          <div className="text-base font-body">Email</div>
          <Input
            name="email"
            size="large"
            onChange={handleChange}
            onBlur={handleBlur}
            className="rnm-input"
            disabled={true}
            value={values.email}
          />
          {simpleValidator.current.message(
            "email",
            values.email,
            "required|email"
          )}
        </Form.Item>
        <Form.Item>
          <div className="text-base font-body">Full Name</div>
          <Input
            name="full_name"
            size="large"
            onChange={handleChange}
            onBlur={handleBlur}
            className="rnm-input"
            value={values.full_name}
          />
          {simpleValidator.current.message(
            "full_name",
            values.full_name,
            "required|min:3|max:100"
          )}
        </Form.Item>
        <Form.Item>
          <div className="text-base font-body">Date of birth (MM/DD/YYYY)</div>
          <CustomDatePicker
            onChange={handleDatePickerChange}
            onClickOutside={handleDatePickerBlur}
            selected={values.date_of_birth}
            maxDate={
              new Date(
                moment().subtract(18, "years").format("MM/DD/YYYY hh:mm:ss")
              )
            }
          />
          {simpleValidator.current.message(
            "date_of_birth",
            values.date_of_birth,
            "required|date_older_than:18"
          )}
        </Form.Item>
        <Form.Item className="mt-6 mb-2">
          <PrimaryButton disabled={disabled() || loading} onClick={onSubmit}>
            {loading ? (
              <Spin
                indicator={<LoadingOutlined style={{ color: "#FFFFFF" }} />}
              />
            ) : (
              "Update"
            )}
          </PrimaryButton>
        </Form.Item>
      </Form>
    </>
  );
};

export default AccountProfilePanel;
