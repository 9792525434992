import React from "react";
import PrimaryButton from "../../components/PrimaryButton";
import Section from "../../components/Section";
import { Collapse } from "antd";
import {
  DownOutlined,
  MinusCircleFilled,
  PlusCircleFilled,
} from "@ant-design/icons";
import { Link, useHistory } from "react-router-dom";
import { goToPrivatePage } from "../../utils";

const { Panel } = Collapse;

const DATA = [
  {
    id: "1",
    title: "General",
    body: [
      {
        title: "Do you accept health insurance?",
        text: "Renuma, nor any of its affiliates, does not submit to health insurance for reimbursement for its services.",
      },
      {
        title: "Will my records be kept private?",
        text: "Yes, it is our priority to make sure your records are private and protected. Your records will not be released without your permission.",
      },
      {
        title: "Do you accept credit card?",
        text: "Yes.",
      },
      {
        title: "Do you accept HSA/FSA?",
        text: "Yes.",
      },
      {
        title: "Do you offer this service to children or teenagers?",
        text: "You must be at least 18 years of age to create a Renuma account.",
      },
      {
        title:
          "If I am enrolled in any Medicare or Medicaid plan (including privately managed Medicare/Medicaid), why am I ineligible for most of Renuma’s services?",
        text: "Some of the services offered via Renuma’s affiliates could be considered a Medicare-covered service. Individuals enrolled in Medicare or Medicaid cannot be offered to pay out-of-pocket for a service that is covered by Medicare. We are therefore only offering Medicare or Medicaid enrollees the ability to take the free Symptoms of OSA survey at this time.",
      },
    ],
  },
  {
    id: "2",
    title: "Online Survey",
    large_title: "Online Sleep Survey",
    body: [
      {
        title:
          "Is there a fee for creating a Renuma account and completing the online survey?",
        text: "No, this service is free.",
      },
      {
        title: "How does the online survey for sleep apnea work?",
        text: "You start by creating an account with Renuma. Then, you complete an interactive online questionnaire created by a doctor who is board-certified in sleep medicine. Using a sophisticated algorithm, you will immediately be given automated guidance as to whether a home sleep study is the right option for you.",
      },
      {
        title: "What happens after I finish the online survey?",
        text: "You may be given the option to order a home sleep study for a fee of $199, arrange a telemedicine consultation with a sleep doctor for a fee of $15, or do nothing further.",
      },
      {
        title:
          "What is the purpose of having a telemedicine consultation with a sleep doctor prior to a home sleep study?",
        text: "To receive guidance and counseling about sleep apnea and the best way to diagnose it.",
      },
      {
        title:
          "Can I skip the online survey for sleep apnea and just order a home sleep study?",
        text: "A home sleep study is not always the right option for someone. That’s why we perform an online survey for sleep apnea first before considering the home sleep study.",
      },
    ],
  },
  {
    id: "3",
    title: "Home Sleep Study",
    body: [
      {
        title:
          "How long will it take for the home sleep testing device to be delivered to me?",
        text: "Your home sleep testing device should be delivered to you in 4-7 days.",
      },
      {
        title:
          "Do I have to send back the home sleep testing device once I’ve finished the test?",
        text: "No, it is disposable. It can be discarded after you’ve completed your home sleep study.",
      },
      {
        title:
          "Do I need a smartphone in order to use the home sleep testing device?",
        text: "Yes, the home sleep testing device will need to communicate its data to your phone via a Bluetooth connection. Your phone will then send this data to the cloud so a sleep doctor can interpret it. Make sure your phone is plugged in and no more than a few feet away during the entirety of your home sleep study.",
      },
      {
        title:
          "Do I have to download any apps on my phone in order to do the home sleep study?",
        text: "Yes, you will need to download the WatchPAT One app. But, you can delete the app once you’ve finished the home sleep study.",
      },
      {
        title:
          "Why is the app requesting access to my phone’s location, photos, and files?",
        text: "This is necessary for the home sleep study’s data to get uploaded to the cloud. The app will not access any files unrelated to your sleep study. You can delete the app once you’ve finished the study. Once you delete the app, neither the app nor any third-party application related to it will ever have access to your location, photos, or files.",
      },
      {
        title: "How can I get more assistance with doing the home sleep study?",
        text: "You can reach out to a Renuma customer service representative during regular business hours. In addition, the documentation that came with your home sleep testing device will have links to resources that can answer your questions.",
      },
      {
        title: "What happens after I finish the home sleep study?",
        text: "A sleep doctor will evaluate the data from the home sleep testing device and create a report. This process will take 2-3 days.",
      },
      {
        title:
          "How will I get notified that my home sleep study has been interpreted by a sleep doctor?",
        text: "You will receive an email notification that your home sleep study report has been uploaded to your Renuma account.",
      },
      {
        title:
          "Medical reports can be confusing, will Renuma really make sure I understand what the report is actually saying?",
        text: "Absolutely. In addition to your official report, you will have an explanatory video uploaded to your account to give you guidance on what the home sleep study findings mean for you. If the home sleep study shows you have sleep apnea, the video will explain your options for treating it. Plus, your home sleep study fee includes a telemedicine consultation with a sleep doctor to review the results.",
      },
      {
        title:
          "I want to review my home sleep study with a sleep doctor, does Renuma help me do that?",
        text: "Yes! The sleep study fee includes a consultation with a sleep doctor to be done after your home sleep study is completed. ",
      },
    ],
  },
  {
    id: "4",
    title: "Sleep Apnea Treatment",
    body: [
      {
        title: "What are my options for treating sleep apnea?",
        text: "There are many treatment options for sleep apnea. A CPAP machine that helps keep your airway open while you sleep remains the most popular option. Renuma can help you with this form of treatment for sleep apnea if you sign up for Renuma+.",
      },
      {
        title: "How will I know what treatment is best for my sleep apnea?",
        text: "After completing your home sleep study, an explanatory video will be uploaded to your account to give you guidance on what the home sleep study findings mean for you. If the home sleep study shows you have sleep apnea, the video will explain your options for treating it. Plus, your home sleep study fee includes a telemedicine consultation with a sleep doctor to review the results and figure out your treatment plan.",
      },
      {
        title:
          "Can Renuma assist with treatments for sleep apnea that don’t require sleeping with a CPAP machine?",
        text: "At this time, we only assist with CPAP therapy for treating sleep apnea.",
      },
      {
        title:
          "Is Renuma able to assist with treating all kinds of sleep apnea?",
        text: "Renuma is designed to assist with the survey and treatment of uncomplicated obstructive sleep apnea. There are more complex types of sleep apnea that require more sophisticated treatments and Renuma’s service is not designed for those individuals.",
      },
      {
        title:
          "Can I get treatment elsewhere after I finish my home sleep study?",
        text: "Of course.",
      },
      {
        title: "Can you send my records to other doctors?",
        text: "Of course. And the records will be sent in a HIPAA-compliant manner.",
      },
    ],
  },
  {
    id: "5",
    title: "Renuma+",
    body: [
      {
        title: "What is Renuma+?",
        text: "Renuma+ is our service that coordinates treatment of your sleep apnea with CPAP therapy. It involves delivery of a CPAP machine and necessary accessories to your home, regular replacement of these accessories, and ongoing monitoring of the effectiveness of your therapy.",
      },
      {
        title:
          "How does Renuma+ save me time over the traditional method of CPAP therapy in a sleep doctor’s office?",
        text: "When your sleep apnea is managed in a sleep doctor’s office, you schedule appointments with your sleep doctor regularly so he/she can see if the therapy is working well. But, if you’re feeling great and the machine is reporting that it’s working well, why spend time meeting with your sleep doctor? Our sleep apnea management program involves regular check-ins via interactive questionnaires that can be completed in 1 or 2 minutes. If you’re feeling well and the machine is reporting that it’s controlling your sleep apnea well, we won’t waste your time with sleep doctor consultations. And for those who need more help, we will coordinate with healthcare professionals to get your treatment working better.",
      },
      {
        title: "How much does Renuma+ cost?",
        text: "It costs $100 a month for the first 10 months, followed by $30 a month after that.",
      },
      {
        title: "Will I be getting a used CPAP machine?",
        text: "No, all CPAP machines will be unused.",
      },
      {
        title:
          "What happens if I don’t like CPAP therapy and decide to cancel my monthly payments?",
        text: "If you’ve started using your CPAP machine, you will be charged the unpaid remainder of its price (a machine generally costs $850). We cannot accept returns when a machine is used.",
      },
      {
        title:
          "Is there any situation in which I can return the CPAP machine or supplies?",
        text: "As long as the CPAP machine has not been turned on, you will be able to return it within 30 days of delivery. If it has been turned on (which then makes the machine “used”), the machine cannot be returned.",
      },
      {
        title:
          "What is the return policy for any of the CPAP supplies, such as masks and hoses, etc?",
        text: "Unopened supplies can be returned within 30 days of delivery. All opened supplies cannot be returned.",
      },
      {
        title:
          "Can I join Renuma+ without having had an online survey or home sleep study through your service?",
        text: "Renuma+ is designed for individuals who are starting the process of survey for sleep apnea with a home sleep study. In the future, we may offer the option to begin engaging with our platform at the treatment stage. But that is not currently available.",
      },
    ],
  },
  {
    id: "6",
    title: "Sleep Doctor Consultations",
    body: [
      {
        title:
          "Will a sleep doctor affiliated with Renuma be able to help me with other sleep problems, such as insomnia or restless legs syndrome?",
        text: "No, a doctor affiliated with Renuma will only be focused on guiding you in the survey of sleep apnea and making sure your sleep apnea treatment with CPAP is working properly.",
      },
      {
        title:
          "Can a sleep doctor affiliated with Renuma prescribe medication for my sleep apnea? Maybe a sleeping pill?",
        text: "No. If you require prescription medication as part of your treatment, that would best be managed by a sleep doctor in a more traditional medical setting.",
      },
    ],
  },
  {
    id: "7",
    title: "COVID-19 and CPAP Machine Recall Considerations",
    body: [
      {
        title:
          "Can a CPAP machine cause COVID-19 to spread more easily to others in my home?",
        text: "As long as you follow the CDC guidelines for isolation when infected with COVID-19, CPAP usage will not put others at risk for contracting COVID-19. Specifically, no one should be in the room with you while you are using your CPAP machine as it may spread viral particles into the air.",
      },
      {
        title:
          "Is a CPAP machine dangerous to use if I’m currently ill with COVID-19?",
        text: "No, if you are able to comfortably use your machine, you should continue doing so.",
      },
      {
        title:
          "Can a CPAP machine reinfect me with COVID-19 after I’ve recovered from it?",
        text: "Once you’ve fully recovered from COVID-19, you should replace your mask, hose, filter, and humidifier chamber. In addition, always make sure you are properly cleaning your CPAP supplies at the recommended intervals. With these precautions in place, you can safely continue to use your CPAP machine after you’re recovered from COVID-19. ",
      },
      {
        title:
          "I have heard that some CPAP machines have been recalled. How is this affecting your services?",
        text: "We will only dispense machines that are not impacted by the recall. So, this recall has no impact on our services.",
      },
      {
        title:
          "Does the global supply chain crisis impact the shipping of CPAP machines?",
        text: "Yes, this has been delaying the time it takes to receive a new CPAP machine. The delay is currently a few weeks.",
      },
    ],
  },
];

const FaqScreen = () => {
  const history = useHistory();

  const goToSurvey = () => {
    goToPrivatePage({
      history,
      url: "/onboarding/survey",
      warningText:
        "You already took the survey, this service can't be done twice.",
    });
  };

  return (
    <>
      <Section>
        <div className="mt-16">
          <h1
            className="text-3xl leading-snug md:text-4xl font-bold text-center mb-13 md:mb-20"
            data-aos="fade-up"
            data-aos-duration="700"
            data-aos-delay="0"
          >
            Frequently Asked Questions
          </h1>
          <div
            className="max-w-4xl -mx-4 md:mx-auto"
            data-aos="fade-up"
            data-aos-duration="700"
            data-aos-delay="100"
          >
            <Collapse
              expandIconPosition="right"
              bordered={false}
              defaultActiveKey={["1"]}
              expandIcon={({ isActive }) => (
                <DownOutlined
                  style={{ fontSize: 24, color: "#9061A7" }}
                  rotate={isActive ? -180 : 0}
                />
              )}
              accordion
              className="bg-white"
              style={{ borderTop: "1px solid #9061A7" }}
              forceRender={true}
            >
              {DATA.map((item) => (
                <Panel
                  header={
                    <div>
                      {item.large_title ? (
                        <>
                          <span className={"inline md:hidden"}>
                            {item.title}
                          </span>
                          <span className="hidden md:inline">
                            {item.large_title}
                          </span>
                        </>
                      ) : (
                        item.title
                      )}
                    </div>
                  }
                  key={item.id}
                  className="font-bold text-lg md:text-xl2"
                  style={{ borderBottom: "1px solid #9061A7" }}
                >
                  {typeof item.body === "string" ? (
                    <div className="font-normal font-body">{item.body}</div>
                  ) : (
                    <Collapse
                      bordered={false}
                      className="bg-white -ml-4 -mr-4 md:mr-0"
                      expandIcon={({ isActive }) =>
                        isActive ? (
                          <MinusCircleFilled
                            className="top-7"
                            style={{ fontSize: 24, color: "#5FCAFD" }}
                          />
                        ) : (
                          <PlusCircleFilled
                            className="top-7"
                            style={{
                              fontSize: 24,
                              color: "#EEE2F6",
                              backgroundColor: "black",
                              borderRadius: 12,
                            }}
                          />
                        )
                      }
                      forceRender={true}
                    >
                      {item.body.map((subitem, key) => (
                        <Panel
                          key={key}
                          header={<div className="ml-10">{subitem.title}</div>}
                          className="font-bold md:font-normal text-lg md:text-xl2 leading-snug font-body"
                          style={{ borderBottom: "none" }}
                        >
                          <div className="font-normal text-base md:text-lg pl-11 pr-0 md:pr-11">
                            {subitem.text}
                          </div>
                        </Panel>
                      ))}
                    </Collapse>
                  )}
                </Panel>
              ))}
            </Collapse>
          </div>
        </div>
      </Section>
      <Section className="bg-renuma-violet-100">
        <div className="max-w-4xl m-auto text-center mt-8 mb-4 md:mb-8">
          <h3
            className="leading-normal md:leading-10 text-3xl font-bold mb-4 md:mb-4 capitalize"
            data-aos="fade-up"
            data-aos-duration="700"
            data-aos-delay="0"
          >
            Get Better Sleep Now
          </h3>
          <p
            className="mb-10 md:mb-10 font-body text-base md:text-xl2 leading-normal md:leading-8"
            data-aos="fade-up"
            data-aos-duration="700"
            data-aos-delay="100"
          >
            Start your journey towards a healthier sleep with our free online
            survey.
          </p>
          <PrimaryButton
            onClick={goToSurvey}
            data-aos="fade-up"
            data-aos-duration="700"
            data-aos-delay="150"
          >
            Get Started
          </PrimaryButton>
        </div>
      </Section>
    </>
  );
};
export default FaqScreen;
