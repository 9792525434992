import React from "react";
import { Link } from "react-router-dom";
import Section from "../../components/Section";

const ConditionsOfUseScreen = () => {
  return (
    <Section>
      <div className="mt-16">
        <h1 className="text-3xl leading-snug md:text-4xl font-bold text-center mb-13 md:mb-20">
          Conditions of use
        </h1>
        <div className="max-w-4xl -mx-4 md:mx-auto">
          <h2 className="text-3xl leading-snug md:text-2xl font-bold text-center mb-13 md:mb-20">
            Our services are not intended to replace your relationship with any
            physician and may not be suitable for everybody. If you have a
            medical emergency, immediately call your doctor or dial 911.
          </h2>
          <p className="text-sm leading-normal font-normal font-body mb-4">
            THIS WEBSITE IS OWNED AND OPERATED BY RENUMA, LLC AND ITS AFFILIATES
            ("RENUMA", "WE", “OUR” AND "US"). ACCESS AND USE OF THIS WEBSITE AND
            ITS RELATED SERVICES AND WEB PAGES (COLLECTIVELY, THE "SITE") AND
            ANY PRODUCTS OR SERVICES AVAILABLE FOR PURCHASE AND/OR DISTRIBUTION
            THROUGH THE SITE (COLLECTIVELY, THE “PRODUCT”) ARE PROVIDED BY
            RENUMA TO YOU ON THE CONDITION THAT YOU ACCEPT THESE TERMS OF USE
            AND TERMS AND CONDITIONS OF SALE (COLLECTIVELY, THE “TERMS”), AND BY
            ACCESSING OR USING THIS SITE, YOU AGREE TO THESE TERMS. IF YOU DO
            NOT AGREE TO ACCEPT AND ABIDE BY THESE TERMS YOU SHOULD NOT ACCESS
            OR USE THIS SITE OR PURCHASE ANY PRODUCT.
          </p>
          <p className="text-sm leading-normal font-normal font-body mb-4">
            The Site is intended to allow us to provide the Services to
            registered users. The “Services” may include (i) providing
            individuals with access to sleep service products and services
            offered by Renuma or by third party health care providers, including
            physicians, durable medical equipment suppliers, and other vendors;
            (ii) providing individuals with access to diagnostic tools; (iii)
            development and gathering of health care records and health care
            information and storage of such records and information; (iv)
            administrative support in connection with scheduling, payment for
            health care provider services, and payment for sleep service
            products and services; and (v) telecommunications support for using
            the Services as a means of direct access to health care providers,
            and treatment by such health care providers
          </p>
          <p className="text-sm leading-normal font-normal font-body mb-4">
            Our Services provide access to products and services from: (i) the
            following durable medical equipment suppliers: (a) M D Respiratory
            Services, Inc. (the “Suppliers”); and (i) the following independent
            physician groups: (a) Intelligent Sleep Medical Services (the
            “Providers”). By accepting these Terms, you agree that any product
            or service you receive from the Providers and Suppliers are subject
            to these terms, and the
          </p>
          <p className="text-sm leading-normal font-normal font-body mb-4">
            These Terms, the <Link to="/privacy-notice">Privacy Policy</Link>{" "}
            and all other documents referenced herein govern the relationship
            between you, the Site visitor ("you") and Renuma with respect to
            your use of this Site and your purchase of Product. You agree that
            the agreement formed by these Terms is like any written negotiated
            agreement signed by you, and you agree to be bound by, and fully
            comply with, its terms. You represent and warrant that you have all
            necessary right, power and authority to enter into this agreement
            and to perform and otherwise discharge all of your obligations
            hereunder.
          </p>
          <p className="text-sm leading-normal font-normal font-body mb-4">
            We reserve the right at any time to change: (i) these Terms; (ii)
            this Site, including terminating, eliminating, supplementing,
            modifying, adding or discontinuing any content or feature or data or
            service on or available through this Site or the hours that they are
            available; and (iii) the equipment, hardware or software required to
            use and access this Site.
          </p>
          <p className="text-sm leading-normal font-normal font-body mb-4">
            Any changes we make to these Terms will be effective immediately
            upon notice, which we may provide by any means including, without
            limitation, posting on this Site. Your continued use of this Site
            after such notice will be deemed acceptance of such changes. Be sure
            to return to this Site periodically to ensure you are familiar with
            the most current version of these Terms.
          </p>
          <p className="text-sm leading-normal font-normal font-body mb-4">
            Any changes we make to these Terms will be effective immediately
            upon notice, which we may provide by any means including, without
            limitation, posting on this Site. Your continued use of this Site
            after such notice will be deemed acceptance of such changes. Be sure
            to return to this Site periodically to ensure you are familiar with
            the most current version of these Terms.You may not interfere with
            the security of, or otherwise abuse this Site or any system
            resources, services or networks connected to or accessible through
            this Site. You may only use this Site for lawful purposes.
          </p>

          <h2 className="text-3xl leading-snug md:text-2xl font-bold text-left mt-12">
            1. JURISDICTION
          </h2>
          <p className="text-sm leading-normal font-normal font-body mb-4">
            If you are residing in a jurisdiction that restricts the ability to
            enter into agreements such as set out in these Terms, you may not
            enter into this agreement or use this Site.
          </p>
          <p className="text-sm leading-normal font-normal font-body mb-4">
            Furthermore, if you are residing in a jurisdiction where it is
            forbidden by law to participate in the activities or services
            offered by this Site, you may not enter into this agreement or use
            this Site. By using this Site you are explicitly stating that you
            have verified in your own jurisdiction if your use of this Site is
            allowed.
          </p>
          <p className="text-sm leading-normal font-normal font-body mb-4">
            We make no representation that materials in this Site are
            appropriate or available for use in all locations. Those who choose
            to access the Site do so on their own initiative and at their own
            risk, and are responsible for compliance with local laws, if and to
            the extent local laws are applicable.
          </p>
          <p className="text-sm leading-normal font-normal font-body mb-4">
            We reserve the right to limit the availability of the Site, the
            Services, or other products described or offered thereon to any
            person, geographic area, or jurisdiction we so desire, at any time
            and in our sole discretion, and to limit the quantities of any such
            Services, materials, or other product provided.
          </p>

          <h2 className="text-3xl leading-snug md:text-2xl font-bold text-left mt-12">
            2. DISCLAIMERS
          </h2>
          <p className="text-sm leading-normal font-normal font-body mb-4">
            Renuma shall have no liability for interruptions or omissions in
            Internet, network or hosting services and does not warrant that the
            Site or the services which make this Site available or electronic
            communications sent by Renuma are free from viruses or any other
            harmful elements.
          </p>
          <p className="text-sm leading-normal font-normal font-body mb-4">
            Renuma is not a health care provider or supplier. However, by
            accessing the Services, you may be entering into a patient
            relationship with the Providers or Suppliers. Renuma does not
            control or interfere with the professional judgment of any Provider
            or Supplier. The Providers and Suppliers and solely responsible for
            the products and services that they each provide through the
            Services.
          </p>
          <p className="text-sm leading-normal font-normal font-body mb-4">
            Any material downloaded or otherwise obtained through the use of
            this Site is done at your own discretion and risk. You will be
            solely responsible for any damage to your computer system or loss of
            data that results from the download of any such material.
          </p>

          <h2 className="text-3xl leading-snug md:text-2xl font-bold text-left mt-12">
            3. PRIVACY AND CONSENT
          </h2>
          <p className="text-sm leading-normal font-normal font-body mb-4">
            Renuma is committed to respecting the privacy of the personal
            information of the individuals with whom we interact. We have
            developed a Privacy Policy to describe our privacy practices and how
            we collect, use and disclose the personal information of those
            individuals who visit this Site. Please see our{" "}
            <Link to="/privacy-notice">Privacy Policy</Link> for further
            information.
          </p>
          <p className="text-sm leading-normal font-normal font-body mb-4">
            <u>Consent to Data Collection and Processing</u>
            <br />
            You hereby agree with and consent to the collection and processing
            of your personal information as described in the Privacy Policy.
          </p>
          <p className="text-sm leading-normal font-normal font-body mb-4">
            <u>Consent to International Data Transfers</u>
            <br />
            Your personal information may be collected, processed and stored by
            Renuma or its services providers in the United States and other
            countries where our servers reside. Please be aware that the privacy
            protections and legal requirements, including the rights of
            authorities to access your personal information, in some of these
            countries may not be equivalent to those in your country. You hereby
            agree with and consent to the transfer of your personal data to the
            United States and other countries where Renuma operates.
          </p>
          <p className="text-sm leading-normal font-normal font-body mb-4">
            <u>Withdrawal of Consent</u>
            <br />
            You have the right, at any time, to withdraw your consent to
            Renuma’s collection and processing of your information, or to the
            transfer of your personal information to the United States and other
            countries where Renuma operates. You may withdraw your consent by
            emailing Renuma at{" "}
            <a href="mailto:privacy@Renumasleep.com">privacy@Renumasleep.com</a>
            .
          </p>
          <h2 className="text-3xl leading-snug md:text-2xl font-bold text-left mt-12">
            SPECIAL CONSENT TO TELEHEALTH SERVICES
          </h2>
          <p className="text-sm leading-normal font-normal font-body mb-4">
            Telemedicine involves the delivery of health care services using
            electronic communications, information technology, or other means
            between a health care provider and a patient who are not in the same
            physical location. Telemedicine may be used for diagnosis,
            treatment, follow-up and/or related patient education, and may
            include, but is not limited to:
          </p>
          <ul className="list-disc pl-4 mb-4">
            <li>
              Electronic transmission of medical records, photo images, personal
              health information, or other data between you and the Providers;
            </li>
            <li>
              Interactions between you and the Providers via audio, video,
              and/or data communications; and
            </li>
            <li>
              Use of output data from medical devices, sound, and video files.
            </li>
          </ul>
          <p className="text-sm leading-normal font-normal font-body mb-4">
            The electronic systems used in the Services will incorporate network
            and software security protocols to protect the privacy and security
            of health information and imaging data, and will include measures to
            safeguard the data to ensure its integrity against intentional or
            unintentional corruption.
          </p>
          <p className="text-sm leading-normal font-normal font-body mb-4">
            The Provider will obtain a separate informed consent from you prior
            to your participation in a telehealth visit.
          </p>

          <h2 className="text-3xl leading-snug md:text-2xl font-bold text-left mt-12">
            4. USER ACCOUNTS
          </h2>
          <p className="text-sm leading-normal font-normal font-body mb-4">
            In order to access and use certain Services available on this Site,
            you may be required to open and maintain an account ("Account") with
            us. During the registration process, we will collect personal
            information that you provide. All of the information you provide to
            us or that we collect from you in connection with your use of the
            Site will be governed by these Terms and our{" "}
            <Link to="privacy-notice">Privacy Policy</Link>. You agree at all
            times to: (i) provide accurate, current and complete information
            about yourself as prompted by our registration form or otherwise;
            and (ii) maintain and update your information (including your e-mail
            address) to keep it accurate, current and complete. You acknowledge
            that, if any information provided by you is untrue, inaccurate, not
            current or incomplete, we may terminate your Account and your
            ability to use this Site and, in our sole discretion, to report you
            to the appropriate authorities.
          </p>
          <p className="text-sm leading-normal font-normal font-body mb-4">
            You understand and agree that, in addition to the foregoing
            restrictions, we reserve the right to change, remove, alter or
            delete any Account at any time and for any reason in our sole
            discretion. You will be responsible for the confidentiality and use
            of your username and password and agree not to transfer your right
            to use or access this Site via your username or password to any
            third person.
          </p>
          <p className="text-sm leading-normal font-normal font-body mb-4">
            YOU ARE ENTIRELY RESPONSIBLE FOR MAINTAINING THE CONFIDENTIALITY OF
            YOUR USERNAME AND PASSWORD AND FOR ANY AND ALL ACTIVITIES THAT ARE
            CONDUCTED THROUGH YOUR ACCOUNT.
          </p>
          <p className="text-sm leading-normal font-normal font-body mb-4">
            You agree to notify us immediately of any unauthorized use, theft or
            misappropriation of your Account, username, or password. We shall
            not be liable for any loss that you incur as a result of someone
            else using your username or password, either with or without your
            knowledge.
          </p>
          <p className="text-sm leading-normal font-normal font-body mb-4">
            Information submitted by users will be transmitted to and shared
            with third parties that may be located in other countries, in order
            to provide services to such users, including but not limited to
            transaction processing and fraud prevention.
          </p>

          <h2 className="text-3xl leading-snug md:text-2xl font-bold text-left mt-12">
            5. USER CONTRIBUTIONS
          </h2>
          <p className="text-sm leading-normal font-normal font-body mb-4">
            The Site may allow you to submit, communicate, upload, or otherwise
            make available ideas, suggestions, messages, documents, proposals,
            photographs, audio, video, and other content (“User Content”), which
            may be accessible and viewable by other users of the Site and used
            by Renuma in providing you services and products. User Content does
            not include any personal information you submit to the Site which is
            maintained in accordance with the Privacy Policy or information
            which may be governed by the Health Insurance Portability and
            Accountability Act of 1996, Public Law 104-191, and its related
            regulations and amendments from time to time (collectively, “HIPAA”)
            or other state-specific privacy laws and regulations. You should not
            include any such information in the User Content. You alone are
            responsible for your User Content, and once published, it cannot
            always be withdrawn. You assume all risks associated with your User
            Content, including anyone’s reliance on its accuracy and your right
            to publish it. You represent that you own, or have the necessary
            permissions to use and authorize the use of your User Content as
            described herein. You may not imply that your User Content is in any
            way sponsored or endorsed by Renuma.
          </p>
          <p className="text-sm leading-normal font-normal font-body mb-4">
            You may not submit or upload User Content that is defamatory,
            harassing, threatening, bigoted, hateful, violent, vulgar, lewd,
            offensive, indecent, obscene, pornographic, or otherwise offensive
            or that harms or can reasonably be expected harm any person or
            entity (such User Content, “Offensive User Content”), whether or not
            such material is protected by law. You agree that you will not use
            your Account or any Site to publish or disseminate, or allow any
            other person to use your Account to publish, republish, or
            disseminate, any Offensive User Content, nor us profanity in any
            posting to any Site.
          </p>
          <p className="text-sm leading-normal font-normal font-body mb-4">
            You may also expose yourself to liability to Renuma or third parties
            if, for example, you hold an Account and User Content contains
            material that is false, intentionally misleading, or defamatory;
            violates any third-party right, including any copyright, trademark,
            patent, trade secret, moral right, privacy right, right of
            publicity, or any other intellectual property or proprietary right;
            contains material that is unlawful, including illegal hate speech or
            pornography; exploits or otherwise harms minors; or violates or
            advocates the violation of any law or regulation. If you comment
            about an experience with Renuma or any third party, or write
            defamatory or inflammatory comments through the Site (or repeat,
            copy, or redistribute the defamatory or inflammatory comments of
            others), you could also expose yourself to liability.
          </p>
          <p className="text-sm leading-normal font-normal font-body mb-4">
            We may use your User Content in a number of different ways,
            including publicly displaying and reformatting it. As such, you
            hereby irrevocably grant us world-wide, non-exclusive, royalty-free,
            transferable rights to use your User Content for any purpose
            associated with the operation of the Site and for our internal
            tracking, reporting, and data analysis purposes, without limit as to
            time, manner, and frequency of use, without further notice to you,
            with or without attribution, and without the requirement of
            permission from or payment to you or any other person or entity. You
            also irrevocably grant the users of the Site the right to access
            your User Content in connection with their use of the Site. Finally,
            you irrevocably waive, and cause to be waived, against Renuma and
            its users, any claims and assertions of moral rights or attribution
            with respect to User Content. By “use” we mean use, copy, publicly
            perform or display, distribute, modify, translate, and create
            derivative works of User Content.
          </p>
          <p className="text-sm leading-normal font-normal font-body mb-4">
            We reserve the right to remove, screen, edit, or reinstate your User
            Content from time to time at our sole discretion and without notice
            to you. By submitting User Content to Renuma through its suggestion
            or feedback webpages, via email, or otherwise, you acknowledge and
            agree that: (a) User Content does not contain confidential or
            proprietary information; (b) Renuma may have something similar to
            the User Content already under consideration or in development; (c)
            We have no obligation to retain or provide you with copies of user
            Content, nor do we guarantee any confidentiality with respect to
            your User Content.
          </p>

          <h2 className="text-3xl leading-snug md:text-2xl font-bold text-left mt-12">
            6. TERMS AND CONDITIONS OF SALE
          </h2>
          <p className="text-sm leading-normal font-normal font-body mb-4">
            <u>Payment</u>
            <br />
            By providing a credit card or other payment method accepted by
            Renuma and/or one of its affiliates, you represent and warrant that
            that you are authorized to use the designated payment method and
            that you authorize us (or our third-party payment processor) to
            charge your payment method for the total amount of your order
            (including any applicable taxes and other charges). If the payment
            method you provide cannot be verified, is invalid or is otherwise
            not acceptable, your order may be suspended or cancelled. You must
            resolve any problem we encounter in order to proceed with your
            order.
          </p>
          <p className="text-sm leading-normal font-normal font-body mb-4">
            <u>Availability, Pricing, Delivery, and Taxes</u>
            <br />
            The Product is subject to availability, and we reserve the right to
            impose quantity limits on any order, to reject all or part of an
            order and to discontinue offering the Product without prior notice.
            The price for the Product is subject to change at any time, but
            changes will not affect any order for the Product you have already
            placed. Renuma calculates and charges sales tax in accordance with
            applicable laws.
            <br />
            The Product will be delivered within a reasonable time after your
            order, subject to the availability of the Product. Renuma shall not
            be liability for any delays, loss, or damage in transit. Renuma
            shall deliver the Product to the address you provide (the “Delivery
            Point”) using Renuma’s standard methods for packaging and shipping
            the Product. You shall be liable for all shipping costs. Title and
            risk of loss passes to you upon delivery of the Product at the
            Delivery Point.
          </p>
          <p className="text-sm leading-normal font-normal font-body mb-4">
            <u>Return for Refund</u>
            <br />
            You may return new, unopened Product to Renuma or the Supplier that
            furnished the Product to you, as applicable, within 30 days
            following your order. To initiate a return, you must inform us of
            your decision within the Cancellation Period by contacting Renuma
            customer support and clearly stating your desire to return the
            Product. Although it will not affect your right to a refund for
            purchased Products, please include details on when you ordered the
            Product and your reason for returning the Product. To receive a
            refund, you must return your Product within the Cancellation Period.
            You will be responsible for all costs associated with returning the
            Product to Renuma or the applicable Supplier (including the cost of
            shipping the Product back to Renuma or the applicable Supplier).
            Renuma or the applicable Supplier will refund the price you paid for
            the Product plus original delivery cost (up to the value of our
            Ground delivery option), less the value of any promotional
            merchandise or discounts you received. We may reduce the amount of
            your refund to reflect any reduction in the value of the Product, as
            determined in our sole discretion, caused by your handling of the
            Product. We will process the refund due to you as soon as possible
            and, in any case, within 30 days from the date of receipt by Renuma
            of the returned Product. The Product is not eligible for a refund
            after the 30-day period.
          </p>
          <p className="text-sm leading-normal font-normal font-body mb-4">
            <u>Return of HSAT Equipment</u>
            <br />
            Unless the HSAT equipment provided to you is intended for single use
            or otherwise requested by Renuma, you must return to Renuma all HSAT
            equipment provided to you to for diagnostic analysis no later than
            14 days following your completion of at-home testing in accordance
            with the provided return instructions. If you fail to return the
            HSAT equipment within the foregoing period, or if the HSAT equipment
            is damaged upon its return, Renuma reserves the right to charge you
            for the value of such equipment.
          </p>

          <h2 className="text-3xl leading-snug md:text-2xl font-bold text-left mt-12 underline">
            SPECIAL NOTICE TO MEDICARE AND MEDICAID BENEFICIARIES
          </h2>
          <p className="text-sm leading-normal font-normal font-body mb-4">
            Renuma is unable to offer the Services to Medicare and Medicaid
            beneficiaries at this time. In accordance with Federal and state
            laws, Renuma is not permitted to provide the Services to Medicare
            and Medicaid beneficiaries even if they would prefer to pay out of
            pocket for the Services.
          </p>
          <p className="text-sm leading-normal font-normal font-body mb-4">
            In connection with your use of the Site, you will be asked to
            confirm that you are not a Medicare or Medicaid beneficiary.
          </p>
          <p className="text-sm leading-normal font-normal font-body mb-4">
            If you are a Medicare or Medicaid beneficiary and would like
            assistance with obtaining items or services similar to the Products
            and Services, please contact a Renuma representative at
            848-272-0794.
          </p>

          <h2 className="text-3xl leading-snug md:text-2xl font-bold text-left mt-12">
            SPECIAL NOTICE TO INDIVIDUALS COVERED BY PRIVATE INSURANCE
          </h2>
          <p className="text-sm leading-normal font-normal font-body mb-4">
            By electing to obtain the Services and Products through the Site,
            you are agreeing to pay for the Services and Products out-of-pocket
            and that you will be financially responsible for all Products and
            Services provided by Renuma, the Providers, and the Suppliers.
            Neither Renuma nor any of the Providers and Suppliers will, under
            any circumstances, bill insurance companies for the Products and
            Services. Neither Renuma nor any of the Providers and Suppliers make
            any representation or guarantee regarding whether your insurance
            will cover the Services or Products if you submit a claim to your
            insurance independent of Renuma, the Providers, or Suppliers. Your
            insurance may cover items or services similar to the Products and
            Services if you obtain such items or services elsewhere.
          </p>

          <h2 className="text-3xl leading-snug md:text-2xl font-bold text-left mt-12">
            7. PERSONAL USE ONLY; RESALE
          </h2>
          <p className="text-sm leading-normal font-normal font-body mb-4">
            This Site is only for personal use. You may not use this Site in any
            way that is unlawful, or harms us or any other person or entity, as
            determined in our sole discretion.
          </p>
          <p className="text-sm leading-normal font-normal font-body mb-4">
            Purchases made on this Site are intended for end users only, and are
            not authorized for resale.
          </p>

          <h2 className="text-3xl leading-snug md:text-2xl font-bold text-left mt-12">
            8. CODE OF CONDUCT
          </h2>
          <p className="text-sm leading-normal font-normal font-body mb-4">
            You agree that you will not attempt to, nor permit any third party
            to, enter restricted areas of Renuma’s computer systems or perform
            functions that you are not authorized to perform pursuant to these
            Terms. While using this Site you agree to comply with all applicable
            laws, rules and regulations (including, but not limited to,
            export/import laws and laws relating to privacy, obscenity,
            copyright, trademark, confidential information, trade secret, libel,
            slander or defamation). You shall not interfere with or disrupt the
            Site, our servers or our networks, or take any action that imposes
            unreasonably or disproportionately large load on our infrastructure.
          </p>

          <h2 className="text-3xl leading-snug md:text-2xl font-bold text-left mt-12">
            9. COPYRIGHT INFRINGEMENT
          </h2>
          <p className="text-sm leading-normal font-normal font-body mb-4">
            If you know or suspect that any of the materials on the Site have
            been used or copied in a way that constitutes copyright
            infringement, please send notice to our designated agent identified
            below. According to the U.S. Digital Millennium Copyright Act, 17
            U.S.C. § 512(c)(3), your notice must comply with the following
            requirements:
          </p>
          <p className="text-sm leading-normal font-normal font-body mb-4">
            A physical or electronic signature of the copyright owner or person
            authorized to act on behalf of the owner of an exclusive right that
            is allegedly infringed;
            <br />
            Identification of the copyrighted work claimed to have been
            infringed, or, if multiple copyrighted works at a single online site
            are covered by a notification, a representative list of such works
            at that site;
            <br />
            Identification of the material that is claimed to be infringing or
            to be the subject of infringing activity and that is to be removed
            or access to which is to be disabled, and information reasonably
            sufficient to permit us to locate the material;
            <br />
            Information reasonably sufficient to permit us to contact you, such
            as address, telephone number, and, if available, an electronic mail
            address at which you may be contacted;
            <br />
            A statement by you that you have a good faith belief that use of the
            material in the manner complained of is not authorized by the
            copyright owners, its agent or the law; and
            <br />
            A statement that the information in the notification is accurate and
            under penalty of perjury, that you are the copyright owner or
            authorized to act on behalf of the owner of an exclusive right that
            is allegedly infringed.
            <br />
            The designated agent for notice of copyright infringement claims may
            be reached as follows:
            <br />
            <a href="mailto:ip@renumasleep.com">ip@renumasleep.com</a>.
          </p>
          <p className="text-sm leading-normal font-normal font-body mb-4">
            The preceding information is provided exclusively for notifying us
            that your copyrighted material may have been infringed. All other
            inquiries, such as product related questions and requests or
            concerns regarding improper postings and/or content, will not
            receive a response through this process.
          </p>

          <h2 className="text-3xl leading-snug md:text-2xl font-bold text-left mt-12">
            10. AVAILABILITY
          </h2>
          <p className="text-sm leading-normal font-normal font-body mb-4">
            While we endeavor to keep downtime to a minimum, we cannot promise
            that this Site will be uninterrupted, secure or error-free. We
            reserve the right to interrupt/suspend this Site, or any part
            thereof, with or without prior notice for any reason and you shall
            not be entitled to any refunds of fees for interruption of this
            Site.
          </p>

          <h2 className="text-3xl leading-snug md:text-2xl font-bold text-left mt-12">
            11. CURRENCY OF WEBSITE
          </h2>
          <p className="text-sm leading-normal font-normal font-body mb-4">
            Renuma updates the information on this Site periodically. However,
            Renuma cannot guarantee or accept any responsibility or liability
            for the accuracy, currency or completeness of the information on
            this Site. Renuma may revise, supplement or delete information,
            services and/or the resources contained in this Site and reserves
            the right to make such changes without prior notification to past,
            current or prospective visitors.
          </p>

          <h2 className="text-3xl leading-snug md:text-2xl font-bold text-left mt-12">
            12. LINKED WEBSITES
          </h2>
          <p className="text-sm leading-normal font-normal font-body mb-4">
            This Site may provide links to third party websites for your
            convenience only. The inclusion of these links does not imply that
            Renuma monitors or endorses these websites or their respective
            practices. Renuma does not accept any responsibility for such
            websites. Renuma shall not be responsible or liable, directly or
            indirectly, for any damage or loss, caused or alleged to be caused
            by or in connection with the use of or the reliance upon any
            information, content, goods or services available on or through any
            third party websites or linked resources. These Terms and our{" "}
            <Link to="/privacy-notice">Privacy Policy</Link> do not apply to
            your use of any third party websites, so be sure to review any
            applicable terms and policies of third party sites.
          </p>

          <h2 className="text-3xl leading-snug md:text-2xl font-bold text-left mt-12">
            13. INTERNET SOFTWARE OR COMPUTER VIRUSES
          </h2>
          <p className="text-sm leading-normal font-normal font-body mb-4">
            Due to technical difficulties with the Internet, Internet software
            or transmission problems could produce inaccurate or incomplete
            copies of information contained on this Site. Due to the ability to
            share certain content and materials, computer viruses or other
            destructive programs may also be inadvertently downloaded from this
            Site.
          </p>
          <p className="text-sm leading-normal font-normal font-body mb-4">
            Renuma shall not be responsible or liable for any software, computer
            viruses or other destructive, harmful or disruptive files or
            programs that may infect or otherwise impact your use of your
            computer equipment or other property on account of your access to,
            use of, or browsing on this Site or your downloading of any user
            materials or other content from this Site. Renuma recommends that
            you install appropriate anti-virus or other protective software.
          </p>

          <h2 className="text-3xl leading-snug md:text-2xl font-bold text-left mt-12">
            14. OUR MATERIALS
          </h2>
          <p className="text-sm leading-normal font-normal font-body mb-4">
            This Site is owned and operated by Renuma in conjunction with others
            pursuant to contractual arrangements.
          </p>
          <p className="text-sm leading-normal font-normal font-body mb-4">
            Unless otherwise specified, all materials appearing on this site,
            including the text, site design, logos, graphics, icons, and images,
            as well as the selection, assembly and arrangement thereof, are the
            sole property of Renuma and its licensors. ALL RIGHTS RESERVED.
            <br />
            Renuma may make certain content, software or other electronic
            materials (including all files, text, URLs, video, audio and images
            contained in or generated by such materials, and accompanying data)
            (collectively " Renuma Materials") available to you from this Site,
            from time to time. If you download or use Renuma Materials, you
            agree that such materials are licensed for your limited personal use
            only and not sold or assigned and: (i) may only be used for your
            personal and non-commercial use, (ii) may not be modified, used to
            create a derivative work, incorporated into any other work or
            otherwise exploited without Renuma 's prior written permission, and
            (iii) shall be only be used in compliance with any additional
            license terms accompanying such materials. Renuma does not transfer
            either the title or the intellectual property rights to the Renuma
            Materials, and retains full and complete title to the Renuma
            Materials as well as all intellectual property rights therein. You
            agree not to sell, share, redistribute, or reproduce the Renuma
            Materials. You further agree not to decompile, reverse-engineer,
            disassemble, or otherwise convert any compiled Renuma Materials into
            a human-perceivable form. Renuma or its licensors own all related
            trademarks and logos, and you agree not to copy or use them in any
            manner.
          </p>
          <p className="text-sm leading-normal font-normal font-body mb-4">
            All rights not expressly granted herein are reserved by Renuma. Any
            unauthorized use of the materials appearing on this Site may violate
            copyright, trademark and other applicable laws and could result in
            criminal or civil penalties.
            <br />
            This Site, (including its organization and presentation and Renuma
            Materials) is the property of Renuma and its licensors and may be
            protected by intellectual property laws including laws relating to
            copyrights, trade-marks, trade-names, internet domain names and
            other similar rights.
          </p>

          <h2 className="text-3xl leading-snug md:text-2xl font-bold text-left mt-12">
            15. DISCLAIMER OF WARRANTIES
          </h2>
          <p className="text-sm leading-normal font-normal font-body mb-4">
            THIS SITE, THE PRODUCT, AND THE RENUMA MATERIALS ARE PROVIDED "AS
            IS" WITHOUT REPRESENTATION, WARRANTY OR CONDITION OF ANY KIND,
            EITHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO THE IMPLIED
            REPRESENTATIONS, WARRANTIES OR CONDITIONS OF MERCHANTABILITY, OR
            FITNESS FOR A PARTICULAR PURPOSE. RENUMA DOES NOT REPRESENT OR
            WARRANT THAT THIS SITE, THE PRODUCT, OR THE RENUMA MATERIALS WILL
            MEET YOUR REQUIREMENTS OR THAT THEIR USE WILL BE SECURE,
            UNINTERRUPTED OR ERROR FREE. YOU WILL BE SOLELY RESPONSIBLE FOR, AND
            RENUMA DISCLAIMS, ANY AND ALL LOSS, LIABILITY OR DAMAGES RESULTING
            FROM YOUR USE OF THE SITE, THE PRODUCT, AND THE RENUMA MATERIALS.
            RENUMA HEREBY DISCLAIMS ANY IMPLIED WARRANTIES OF MERCHANTABILITY,
            FITNESS FOR A PARTICULAR PURPOSE, AND ANY IMPLIED WARRANTIES ARISING
            OUT OF COURSE OF DEALING OR USAGE OF TRADE.
          </p>
          <p className="text-sm leading-normal font-normal font-body mb-4">
            SOME STATES AND JURISDICTIONS DO NOT ALLOW LIMITATIONS ON IMPLIED
            WARRANTIES, SO THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU. WHEN THE
            IMPLIED WARRANTIES ARE NOT ALLOWED TO BE EXCLUDED IN THEIR ENTIRETY,
            THEY WILL BE LIMITED TO THE SHORTEST DURATION PERMITTED BY LAW. YOU
            MAY ALSO HAVE OTHER RIGHTS WHICH VARY FROM STATE-TO-STATE.
          </p>

          <h2 className="text-3xl leading-snug md:text-2xl font-bold text-left mt-12">
            16. LIMITATION OF LIABILITY
          </h2>
          <p className="text-sm leading-normal font-normal font-body mb-4">
            YOU ASSUME ALL RESPONSIBILITY AND RISK FOR USE OF THIS SITE, THE
            PRODUCT AND THE RENUMA MATERIALS INCLUDING WITHOUT LIMITATION ANY OF
            THE INFORMATION CONTAINED THEREIN.
          </p>
          <p className="text-sm leading-normal font-normal font-body mb-4">
            IN NO EVENT SHALL RENUMA OR ANY OF ITS DIRECTORS, OFFICERS,
            EMPLOYEES, SHAREHOLDERS, PARTNERS, OR AGENTS BE LIABLE FOR ANY
            DIRECT, INCIDENTAL, INDIRECT, PUNITIVE, EXEMPLARY, CONSEQUENTIAL OR
            OTHER SIMILAR DAMAGES WHATSOEVER (INCLUDING DAMAGES FOR LOSS OF
            PROFITS, INTERRUPTION, LOSS OF BUSINESS INFORMATION, OR ANY OTHER
            PECUNIARY LOSS) IN CONNECTION WITH ANY CLAIM, LOSS, DAMAGE, ACTION,
            SUIT OR OTHER PROCEEDING ARISING UNDER OR OUT OF THESE TERMS,
            INCLUDING WITHOUT LIMITATION YOUR USE OF, RELIANCE UPON, ACCESS TO,
            OR EXPLOITATION OF THIS SITE, THE PRODUCT, THE RENUMA MATERIALS OR
            ANY PART THEREOF, OR ANY RIGHTS GRANTED TO YOU HEREUNDER, EVEN IF WE
            HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, WHETHER THE
            ACTION IS BASED ON CONTRACT, TORT (INCLUDING NEGLIGENCE),
            INFRINGEMENT OF INTELLECTUAL PROPERTY RIGHTS OR OTHERWISE. SOME
            STATES AND JURISDICTIONS DO NOT ALLOW FOR THE LIMITATION OR
            EXCLUSION OF LIABILITY, SO THE ABOVE LIMITATION OR EXCLUSION MAY NOT
            APPLY TO YOU.
          </p>

          <h2 className="text-3xl leading-snug md:text-2xl font-bold text-left mt-12">
            17. INDEMNIFICATION
          </h2>
          <p className="text-sm leading-normal font-normal font-body mb-4">
            You agree to, upon request, defend, indemnify and hold us harmless
            from any and all liabilities, claims, losses and expenses, including
            attorneys' fees, which arise directly or indirectly from, or relate
            to: (1) any breach of these Terms for which you are responsible; (2)
            your use of this Site or the Product; or (3) your violation,
            infringement, or misappropriation of the intellectual property,
            proprietary or other rights of any third party. Renuma reserves the
            right, at its own expense, to assume the exclusive defense and
            control of any matter otherwise subject to indemnification by you,
            and you shall not in any event settle any such matter without the
            prior written consent of Renuma. This indemnification section will
            survive any termination or expiration of these Terms.
          </p>

          <h2 className="text-3xl leading-snug md:text-2xl font-bold text-left mt-12">
            18. CONTACTING RENUMA
          </h2>
          <p className="text-sm leading-normal font-normal font-body mb-4">
            If you need to contact us regarding this Site or these Terms, please
            e-mail us at{" "}
            <a href="mailto:support@renumasleep.com">support@renumasleep.com</a>
            . You can also contact us via telephone at 848-272-0794.
          </p>

          <h2 className="text-3xl leading-snug md:text-2xl font-bold text-left mt-12">
            19. FORCE MAJEURE
          </h2>
          <p className="text-sm leading-normal font-normal font-body mb-4">
            We will not be liable for any failure to perform, or delay in
            performing of, any of our obligations under a contract that is cause
            by an act or event beyond our reasonable control, including without
            limitation acts of God, strikes, lock-outs or other industrial
            action by third parties, civil commotion, riot, terrorist attack,
            war, fire, explosion, storm, flood, earthquake, pandemics,
            epidemics, other natural disasters, failure of public or private
            telecommunications networks or impossibility of the use of railways,
            shipping, aircraft, motor transport or other means of public or
            private transport
          </p>
          <h2 className="text-3xl leading-snug md:text-2xl font-bold text-left mt-12">
            20. MISCELLANEOUS
          </h2>
          <p className="text-sm leading-normal font-normal font-body mb-4">
            These Terms are governed by and construed in accordance with the
            laws of the State of New York and the United States of America,
            without regards to their respective principles of conflicts of law.
            You agree to the exclusive personal jurisdiction by the federal and
            state courts located in the city of New York, New York, United
            States of America, in any matter arising from or related to these
            Terms and your use of the Site and you waive any jurisdictional,
            venue, or inconvenient forum objections to such courts.
          </p>
          <p className="text-sm leading-normal font-normal font-body mb-4">
            If any provision of these Terms is found to be unlawful, void, or
            for any reason unenforceable, then that provision shall be deemed
            severable from these Terms and shall not affect the validity and
            enforceability of any remaining provisions.
          </p>
          <p className="text-sm leading-normal font-normal font-body mb-4">
            These Terms set out the entire agreement between us relating to the
            use of the Site and the subject matter contained herein and
            supersede any and all prior or contemporaneous written or oral
            agreements between us. These Terms are not assignable, transferable
            or sub-licensable by you except with Renuma’s prior written consent.
            These Terms provide only for the use of this Site. These Terms shall
            not be construed as a teaming, joint venture, or other such
            arrangement, unless the parties expressly and explicitly execute an
            agreement to that effect. No waiver by either party of any breach or
            default hereunder shall be deemed to be a waiver of any preceding or
            subsequent breach or default. Any heading, caption or section title
            contained in these Terms is inserted only as a matter of convenience
            and in no way defines or explains any section or provision hereof.
          </p>
        </div>
      </div>
    </Section>
  );
};

export default ConditionsOfUseScreen;
