import Icon from "@ant-design/icons";

import Grid from "./grid.svg";
import List from "./list.svg";

const GridSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="currentColor"
  >
    <rect width="4" height="4" transform="translate(0 0)" fill="currentColor" />
    <rect width="4" height="4" transform="translate(0 8)" fill="currentColor" />
    <rect
      width="4"
      height="4"
      transform="translate(0 16)"
      fill="currentColor"
    />
    <rect width="4" height="4" transform="translate(8 0)" fill="currentColor" />
    <rect width="4" height="4" transform="translate(8 8)" fill="currentColor" />
    <rect
      width="4"
      height="4"
      transform="translate(8 16)"
      fill="currentColor"
    />
    <rect
      width="4"
      height="4"
      transform="translate(16 0)"
      fill="currentColor"
    />
    <rect
      width="4"
      height="4"
      transform="translate(16 8)"
      fill="currentColor"
    />
    <rect
      width="4"
      height="4"
      transform="translate(16 16)"
      fill="currentColor"
    />
  </svg>
);
const ListSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="16"
    viewBox="0 0 18 16"
    fill="currentColor"
  >
    <g id="icon" transform="translate(-1508.257 -713)">
      <rect
        width="18"
        height="2"
        transform="translate(1508.257 713)"
        fill="currentColor"
      />
      <rect
        width="18"
        height="2"
        transform="translate(1508.257 720)"
        fill="currentColor"
      />
      <rect
        width="18"
        height="2"
        transform="translate(1508.257 727)"
        fill="currentColor"
      />
    </g>
  </svg>
);

export const GridIcon = (props) => <Icon component={GridSvg} {...props} />;
export const ListIcon = (props) => <Icon component={ListSvg} {...props} />;
