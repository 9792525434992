import React, { useState, useCallback, useRef, useMemo } from "react";
import { useEffect } from "react";
import DatePicker from "react-datepicker";
import { CalendarOutlined } from "@ant-design/icons";
import "react-datepicker/dist/react-datepicker.css";

export default function CustomDatePicker({
  onChange,
  onClickOutside,
  selected,
  ...props
}) {
  const [date, setDate] = useState(new Date());

  useEffect(() => {
    if (selected !== null && selected !== undefined) {
      setDate(selected);
    }
  }, [selected]);

  const isLeapYear = useCallback((year) => {
    return !((year % 4 === 0 && year % 100 !== 0) || year % 400 === 0);
  }, []);

  const format = useCallback(
    (val) => {
      if (!val) return "";
      const len = val.length;

      if (len === 1 && !["0", "1"].includes(val[0])) {
        return "";
      } else if (
        (len === 2 && val[0] !== "0" && !["0", "1", "2"].includes(val[1])) ||
        val.substr(0, 2) === "00"
      ) {
        return val.substr(0, 1);
      } else if (len === 3) {
        if (val[2] === "/") {
          return val.substr(0, 2);
        } else if (
          (val.substr(0, 2) === "02" && ["0", "1", "2"].includes(val[2])) ||
          (val.substr(0, 2) !== "02" && ["0", "1", "2", "3"].includes(val[2]))
        ) {
          return val.substr(0, 2) + "/" + val.substr(2);
        } else {
          return val.substr(0, 2);
        }
      } else if (
        len === 5 &&
        (val.substr(3, 5) === "00" ||
          (val[3] === "3" && !["0", "1"].includes(val[4])) ||
          (val[3] === "3" &&
            val[4] === "1" &&
            !["01", "03", "05", "07", "08", "10", "12"].includes(
              val.substr(0, 2)
            )))
      ) {
        return val.substr(0, 4);
      } else if (len === 6) {
        if (val[5] === "/") {
          return val.substr(0, 5);
        } else {
          return val.substr(0, 5) + "/" + val.substr(5);
        }
      } else if (len === 8 && val.substr(0, 6) === "02/29/") {
        let year = +val.substr(6);
        year = year > 1970 ? 1900 + year : 2000 + year;
        if (isLeapYear(year)) {
          return val.substr(0, 7);
        }
      } else if (len === 10 && val.substr(0, 6) === "02/29/") {
        if (isLeapYear(+val.substr(6))) {
          return val.substr(0, 9);
        }
      } else if (len > 10) {
        return val.substr(0, 10);
      }
      return val;
    },
    [isLeapYear]
  );

  const handleChange = (e) => {
    setDate(e);
    if (onChange) {
      onChange(e);
    }
  };

  const onChangeRaw = useCallback(
    (e) => {
      e.target.value = format(e.target.value);
    },
    [format]
  );

  const handleClickOutside = (e) => {
    if (onClickOutside) {
      onClickOutside(date);
    }
  };

  return (
    <div className="relative">
      <DatePicker
        className="ant-input ant-input-lg rnm-input"
        format="DD/MM/YY"
        onChange={handleChange}
        onChangeRaw={onChangeRaw}
        onClickOutside={handleClickOutside}
        selected={date}
        suffixIcon={<CalendarOutlined />}
        placeholder="mm/dd/yy"
        {...props}
      />
      <CalendarOutlined className="text-gray-400 absolute top-4 right-4" />
    </div>
  );
}
