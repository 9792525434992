import React, { useState, useRef, useEffect } from "react";
import {
  Select,
  Alert,
  Button,
  Card,
  Form,
  Input,
  Spin,
  Checkbox,
  Popover,
  Collapse,
} from "antd";
import { Link, useHistory, useLocation } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import Section from "../../components/Section";
import { EyeShowIcon, EyeHideIcon } from "../../components/Icons";
import PrimaryButton from "../../components/PrimaryButton";
import { Auth } from "aws-amplify";
import { LoadingOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { useAuth } from "../../providers/auth.context";
import { signup } from "../../APIClient";
import moment from "moment";
import StateFromZip from "../../hooks/StateFromZip";
import { USER_PROFILE } from "../../utils";
import CustomDatePicker from "../../components/CustomDatePicker";
import DavidImage from "../../assets/images/David.png";
import ReasonWhy from "../../assets/images/3reason-why.png";
import { SendNudgeEmail } from "../../APIClient";

const { Panel } = Collapse;

const NudgeSomeone = () => {
		const [error, setError] = useState("");
		const [loading, setLoading] = useState(false);
		const [sent, setSent] = useState(false);
		const { currentUser } = useAuth();
	  const [values, setValues] = useState({
	    nudger_name: "",
	    nudged_name: "",
	    nudged_email: "",
	  });
		const history = useHistory()		
		const inputEmail = useRef(null);
		//useEffect(() => {
	  //	//console.log('currentUser',currentUser)
	  //	if(!currentUser){
	  //		history.push("/login")
	  //		return
	  //	}
		//}, [])
	  useEffect(() => {
	    if (inputEmail.current) {
	      inputEmail.current.focus();
	    }
	  }, [inputEmail]);
	  const simpleValidator = useRef(
	    new SimpleReactValidator({
	      element: (message) => (
	        <div className="rnm-input-error text-xs">{message}</div>
	      ),
	    })
	  );

	  const disabled = () => !simpleValidator.current.allValid();
	  const handleChange = (e) => {
	    const { name, value } = e.target;
	    setValues((v) => ({ ...v, [name]: value }));
	  };
	  const handleBlur = (e) => {
	    const { name, value } = e.target;
	    simpleValidator.current.showMessageFor(name);
	    setValues((v) => ({ ...v, [name]: value }));
	  };


		//const onSubmit = async () => {
		//	setLoading(true);
		//	setError("");
		//	setSent(true)
		//	const sent = {
    //    email: values.nudged_email.trim(),
    //    full_name: values.nudged_name.trim(),
    //    nudger_name: values.nudger_name.trim(),
    //  }
    //  console.log('sent', sent)
		//	setLoading(false);
		//}

 	  const onSubmit = async () => {
	    setLoading(true);
	    setError("");
	    
	    if (!values.nudged_email.trim()) {
	      setError("Incorrect email");
	      setLoading(false);
	      return;
	    }

	    if (!values.nudged_name.trim()) {
	      setError("Incorrect name");
	      setLoading(false);
	      return;
	    }

	    if (!values.nudger_name.trim()) {
	      setError("Incorrect name");
	      setLoading(false);
	      return;
	    }

	    console.log('data', {
        email: values.nudged_email.trim(),
        full_name: values.nudged_name.trim(),
        nudger_name: values.nudger_name.trim(),
      })
      
	    try {
	      const sent = await SendNudgeEmail({
	        email: values.nudged_email.trim(),
	        full_name: values.nudged_name.trim(),
	        nudger_name: values.nudger_name.trim(),
	      });
	      if (sent.error === false) {
	        setSent(true)
					setLoading(false);
	      }
	    } catch (e) {
	      setError(e.message);
	    } finally {
	      setLoading(false);
	    }
	  };
	  
	  return (
    <Section className="h-full pb-0 flex justify-center bg-nudge bg-no-repeat !bg-left-top">
      {!sent && <div
        className="container w-full flex items-center py-10"
        data-aos="fade-up"
        data-aos-duration="700"
        data-aos-delay="0"
      >
        <div className="flex items-center flex-col md:flex-row w-full">
          <div className="text-container">
            <h3 className="nudge uppercase">
              NUDGE SOMEONE AWAKE TO THE RISKS OF SLEEP APNEA.
            </h3>
             <h3 className="nudge font-light mt-4 mb-6">They will receive an email with a link to a free sleep apnea risk assessment tool.</h3>
             <h3 className="nudge ">No strings attached. </h3>
             <p className="nudge ">Renuma is not a healthcare provider.</p>
          </div>
          <div className="md:max-w-lg w-full">
            <Card className="-mx-6 md:mx-0 bg-renuma-violet-800 rounded-3xl">
              {error && (
                <Alert
                  message={error}
                  type="error"
                  style={{ marginBottom: 24 }}
                  closable
                  onClose={() => setError("")}
                />
              )}
              <Form className="mt-6 ml-7 mr-7 mb-24 md:mb-2">
								<Form.Item>
		              <h3 className="font-bold text-white">ABOUT YOU:</h3>
		              <Input
		                //ref={inputEmail}
		              	placeholder="Your full name"
		                name="nudger_name"
		                size="large"
		                onChange={handleChange}
		                onBlur={handleBlur}
		                className="rnm-input rounded-full py-3 px-5"
		              />
		              {simpleValidator.current.message(
		                "nudged_name",
		                values.nudger_name,
		                "required"
		              )}
		            </Form.Item>
								<Form.Item>
		              <h3 className="font-bold text-white">I WANT TO HELP:</h3>
		              <Input
		                //ref={inputEmail}
		              	placeholder="Their Full Name"
		                name="nudged_name"
		                size="large"
		                onChange={handleChange}
		                onBlur={handleBlur}
		                className="rnm-input rounded-full py-3 px-5"
		              />
		              {simpleValidator.current.message(
		                "nudged_name",
		                values.nudged_name,
		                "required"
		              )}
		            </Form.Item>
		           
								<Form.Item>
		              <Input
		                //ref={inputEmail}
		              	placeholder="Their email address"
		                name="nudged_email"
		                size="large"
		                onChange={handleChange}
		                onBlur={handleBlur}
		                className="rnm-input rounded-full py-3 px-5"
		              />
		              {simpleValidator.current.message(
		                "nudged_email",
		                values.nudged_email,
		                "required|email"
		              )}
		            </Form.Item>
		            <Form.Item className="mt-6 flex justify-center">
		              <PrimaryButton
		                //disabled={disabled() || loading}
		                onClick={onSubmit}
		                className="flex mx-auto bg-white hover:text-white hover:bg-renuma-violet-400"
		                type="send_nudge_white"
		              >
		                {loading ? (
		                  <Spin
		                    indicator={<LoadingOutlined style={{ color: "#FFFFFF" }} />}
		                  />
		                ) : (
		                  "SEND YOUR NUDGE"
		                )}
		              </PrimaryButton>
		            </Form.Item>
              </Form>
            </Card>
          </div>
        </div>
      </div>}

      {sent && <div
        className="container w-full py-10"
        data-aos="fade-up"
        data-aos-duration="700"
        data-aos-delay="0"
      >
      <div class="card bg-renuma-violet-800 text-white py-10 rounded-3xl max-w-[947px] mx-auto">
			  <div class="card-body text-center">
					<h4 className="uppercase text-white font-semibold">
						YOUR NUDGE HAS BEEN SENT! A BETTER SLEEP FOR THEM IS JUST STEPS AWAY. 
					</h4>
          <div className="max-w-[265px] mt-2 text-center mx-auto">
            
             <p>Do you know someone else at risk of sleep apnea?</p>
             <PrimaryButton
              onClick={() => {setSent(false)}}
              className="flex bg-white mt-8"
              type="send_nudge_white"
            >
            NUDGE SOMEONE ELSE
            </PrimaryButton>
          </div>
			  </div>
			</div>
			<div className="flex justify-center mt-[180px] mr-0">
				<img width={947} src={ReasonWhy} alt="" />
			</div>
          
          
      </div>}
    </Section>
  );
};
export default NudgeSomeone;