import React, { useEffect, useState } from "react";
import { Card, Table, Select, Breadcrumb, Input, Button, Space } from "antd";
import { Line } from "@ant-design/charts";
import { SearchOutlined } from "@ant-design/icons";

import { useHistory } from "react-router-dom";

import { EyeFilled } from "@ant-design/icons";

import { getPatientCancelationRequests } from "../../APIClient";
import SurveyPreview, { NoSurveys } from "../../components/SurveyPreview";
import LoadingCard from "../../components/LoadingCard";
import OrderPreview, { NoOrders } from "../../components/OrderPreview";
import moment from "moment";
import moment_tz from "moment-timezone";
import _ from "lodash";
import PrimaryButton from "../../components/PrimaryButton";
import { USER_PROFILE } from "../../utils";

const { Option } = Select;

const AdminCancelationRequestsScreen = () => {
  const [loading, setLoading] = useState({ patients: false, signedUps: false });
  const [isMounted, setIsMounted] = useState(true);
  const [sortedInfo, setSortedInfo] = useState({
    order: null,
    columnKey: null,
  });
  const [data, setData] = useState([]);
  const [filteredInfo, setFilteredInfo] = useState({ survey: [] });
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const history = useHistory();
  let searchInput = null;

  const clearSort = () => {
    setSortedInfo({
      order: null,
      columnKey: null,
    });
  };

  const sortAlphabetically = (a, b, index) => {
    if (a[index] < b[index]) {
      return -1;
    }
    if (a[index] > b[index]) {
      return 1;
    }
    return 0;
  };

  useEffect(() => {
    setIsMounted(true);
    loadPatients();
    return () => {
      setIsMounted(false);
    };
  }, []);

  const loadPatients = async () => {
    setLoading({ patients: true });
    try {
      const res = await getPatientCancelationRequests();
      if (isMounted) {
        setLoading({ patients: false });
        setData(res.patients);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading({ patients: false });
    }
  };

  const handleTableChange = (pagination, filters, sorter, extra) => {
    setSortedInfo(sorter);
    setFilteredInfo(filters);
  };

  const getColumnSearchProps = (dataIndex, dataTitle) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node;
          }}
          placeholder={`Search ${dataTitle}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.select(), 100);
      }
    },
    render: (text) => text.toString(),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const columns = [
    {
      title: "Patient name",
      dataIndex: "full_name",
      key: "full_name",
      sorter: (a, b) => sortAlphabetically(a, b, "full_name"),
      filteredValue: filteredInfo.full_name || null,
      ...getColumnSearchProps("full_name", "by Name"),
    },
    {
      title: "E-mail",
      dataIndex: "email",
      key: "email",
      align: "center",
      render: (item) => (item === true ? "Yes" : "No"),
      sorter: (a, b) => sortAlphabetically(a, b, "email"),
      filteredValue: filteredInfo.email || null,
      ...getColumnSearchProps("email", "by E-mail"),
    },
    {
      title: "Sleep Test",
      dataIndex: "sleep_test",
      key: "sleep_test",
      align: "center",
      render: (item) => (item === true ? "Yes" : "No"),
      sorter: (a, b) => sortAlphabetically(a, b, "sleep_test"),
      filters: [
        { text: "Yes", value: true },
        { text: "No", value: false },
      ],
      filteredValue: filteredInfo.sleep_test || null,
      onFilter: (value, record) => record.sleep_test === value,
    },
    {
      title: "Renuma+",
      dataIndex: "renuma_plus",
      key: "renuma_plus",
      align: "center",
      render: (item) => (item === true ? "Yes" : "No"),
      sorter: (a, b) => sortAlphabetically(a, b, "renuma_plus"),
      filters: [
        { text: "Yes", value: true },
        { text: "No", value: false },
      ],
      filteredValue: filteredInfo.renuma_plus || null,
      onFilter: (value, record) => record.renuma_plus === value,
    },
    {
      title: "Telemedicine",
      dataIndex: "telemedicine_paid",
      key: "telemedicine_paid",
      align: "center",
      render: (item) => (item === true ? "Yes" : "No"),
      sorter: (a, b) => sortAlphabetically(a, b, "telemedicine_paid"),
      filters: [
        { text: "Yes", value: true },
        { text: "No", value: false },
      ],
      filteredValue: filteredInfo.telemedicine || null,
      onFilter: (value, record) => record.telemedicine === value,
    },
    {
      title: "Actions",
      key: "actions",
      align: "center",
      render: (text, record) => (
        <EyeFilled
          onClick={() => {
            history.push(`/dashboard/cancelation-requests/${record.email}`);
          }}
        />
      ),
      filteredValue: filteredInfo.actions || null,
    },
  ];

  const handleGoPage = (page) => {
    history.push(page);
  };

  return (
    <>
      <Breadcrumb className="mb-8">
        <Breadcrumb.Item
          onClick={() => {
            handleGoPage("/dashboard");
          }}
          className="cursor-pointer"
        >
          Home
        </Breadcrumb.Item>
        <Breadcrumb.Item>Cancelation Requests</Breadcrumb.Item>
      </Breadcrumb>
      <div className="flex flex-col md:flex-row mb-10">
        <Card className="flex-1 card-dashboard bg-dashboardHome bg-right-8-top-4 bg-no-repeat rounded-md drop-shadow mb-6 md:mb-0">
          <div className="flex flex-row justify-between items-center mb-6">
            <div className="font-bold">Patients</div>
          </div>
          {loading.patients && <div>Loading...</div>}
          {!loading.patients && (
            <Table
              rowKey="email"
              size="middle"
              columns={columns}
              dataSource={data}
              onChange={handleTableChange}
              pagination={{ pageSize: 10 }}
            />
          )}
        </Card>
      </div>
    </>
  );
};
export default AdminCancelationRequestsScreen;
