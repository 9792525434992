import React, { useState, useRef, useEffect } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Alert, Card, Form, Input, Spin, Checkbox } from "antd";
import { Link } from "react-router-dom";

import { SendEmail } from "../../APIClient";

import SimpleReactValidator from "simple-react-validator";
import PrimaryButton from "../../components/PrimaryButton";
import Section from "../../components/Section";
import phone from "../../assets/svgs/phone.svg";

const { TextArea } = Input;

const ConditionsOfUseScreen = () => {
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [values, setValues] = useState({
    email: "",
    full_name: "",
    message: "",
  });
  const disabled = () => !simpleValidator.current.allValid();
  const [, forceUpdate] = useState();
  const [loading, setLoading] = useState(false);

  const simpleValidator = useRef(
    new SimpleReactValidator({
      autoForceUpdate: { forceUpdate: forceUpdate },
      element: (message) => (
        <div className="rnm-input-error text-xs">{message}</div>
      ),
    })
  );

  const onSubmit = async () => {
    setLoading(true);
    setError("");

    if (!values.email.trim()) {
      setError("Incorrect email");
      return;
    }

    try {
      const sent = await SendEmail({
        email: values.email.trim(),
        full_name: values.full_name.trim(),
        message: values.message,
      });
      if (sent.error === false) {
        setSuccess(
          "Your message has been sent successfully. Thank you for contact us."
        );
        resetForm();
      }
    } catch (e) {
      setError(e.message);
    } finally {
      setLoading(false);
    }
  };

  const resetForm = (e) => {
    setValues({
      email: "",
      full_name: "",
      message: "",
    });
    simpleValidator.current.hideMessages();
    simpleValidator.current.visibleFields = [];
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues((v) => ({ ...v, [name]: value }));
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    simpleValidator.current.showMessageFor(name);
    setValues((v) => ({ ...v, [name]: value }));
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setValues((v) => ({ ...v, [name]: checked }));
  };

  return (
    <Section
      className="bg-login bg-w-180% bg-no-repeat h-full pb-0 flex justify-center"
      style={{ height: "auto", minHeight: "100vh" }}
    >
      <div className="max-w-screen-xl w-full flex items-center">
        <div className="flex justify-around flex-col md:flex-row w-full">
          <div className="md:max-w-md mt-6">
            <h3 className="text-xl md:text-3xl font-bold mb-4 leading-none">
              Contact us
            </h3>
            <div className="text-lg font-body flex flex-row items-center mt-8">
              <img alt="phone" src={phone} className="mr-2" /> 848-272-0794
            </div>
          </div>
          <div className="md:max-w-lg w-full">
            <Card className="-mx-6 md:mx-0">
              {error && (
                <Alert
                  message={error}
                  type="error"
                  style={{ marginBottom: 24 }}
                  closable
                  onClose={() => setError("")}
                />
              )}
              <Form className="mt-6 ml-7 mr-7 mb-24 md:mb-2">
                {success && (
                  <div style={{ minHeight: "350px" }}>
                    <p className="text-sm">{success}</p>
                  </div>
                )}
                {!success && (
                  <>
                    <Form.Item>
                      <div className="text-base font-body">Email</div>
                      <Input
                        name="email"
                        size="large"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="rnm-input"
                      />
                      {simpleValidator.current.message(
                        "email",
                        values.email,
                        "required|email"
                      )}
                    </Form.Item>
                    <Form.Item>
                      <div className="text-base font-body">Full Name</div>
                      <Input
                        name="full_name"
                        size="large"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="rnm-input"
                      />
                      {simpleValidator.current.message(
                        "full_name",
                        values.full_name,
                        "required|min:3|max:100"
                      )}
                    </Form.Item>
                    <Form.Item>
                      <div className="text-base font-body">Message</div>
                      <TextArea
                        name="message"
                        size="large"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="rnm-input"
                        rows={8}
                      />
                      {simpleValidator.current.message(
                        "message",
                        values.message,
                        "required"
                      )}
                    </Form.Item>
                    <Form.Item className="mt-6">
                      <div className="flex ">
                        <Checkbox
                          name="agree"
                          onChange={handleCheckboxChange}
                          className="text-xs font-base leading-none"
                        />
                        <div className="text-xs font-base leading-none ml-4">
                          I agree to accept the{" "}
                          <Link
                            to="/privacy-notice"
                            className="text-renuma-violet-primary"
                          >
                            Privacy Notice
                          </Link>{" "}
                          and the{" "}
                          <Link
                            to="/conditions-of-use"
                            className="text-renuma-violet-primary"
                          >
                            Terms and Conditions of Use
                          </Link>
                        </div>
                      </div>
                      {simpleValidator.current.message(
                        "agree",
                        values.agree ? "si" : "",
                        "required|string"
                      )}
                    </Form.Item>
                    <Form.Item className="mt-6 mb-2">
                      <PrimaryButton
                        disabled={disabled() || loading}
                        onClick={onSubmit}
                        className="w-full"
                      >
                        {loading ? (
                          <Spin
                            indicator={
                              <LoadingOutlined style={{ color: "#FFFFFF" }} />
                            }
                          />
                        ) : (
                          "Send"
                        )}
                      </PrimaryButton>
                    </Form.Item>
                  </>
                )}
              </Form>
            </Card>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default ConditionsOfUseScreen;
