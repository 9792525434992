import React, { useEffect, useState } from "react";
import Section from "../../../components/Section";
import checkIcon from "../../../assets/svgs/check.svg";
import Stethoscope from "../../../assets/svgs/stethoscope.svg";
import {
  USER_APPOINTMENT_DATA,
  USER_ORDER_CHECKOUT_DATA,
  USER_PROFILE,
  USER_COMPLETE_SURVEY,
  USER_COMPLETE_VIDEO,
  getDateRange,
} from "../../../utils";
import PrimaryButton from "../../../components/PrimaryButton";
import { useHistory, useParams } from "react-router-dom";
import moment from "moment";

import { usePaymentInputs } from "react-payment-inputs";
import { CreditCards } from "../../../assets/svgs/credit-cards/";

const AppointmentSummary = () => {
  const [loading, setLoading] = useState(true);
  const { type } = useParams();
  const [userProfile, setUserProfile] = useState({});
  const [appointmentData, setAppointmentData] = useState(null);
  const [orderData, setOrderData] = useState(null);
  const [shippingDetails, setShippingDetails] = useState({});
  const [count, setCount] = useState(0);
  const slotRange = 15;
  const appointmentPrice = 15;
  const history = useHistory();
  const [isFree, setIsFree] = useState(false);

  useEffect(() => {
    const order = JSON.parse(localStorage.getItem("order"));
    const profile = JSON.parse(localStorage.getItem(USER_PROFILE));
    const appointment = JSON.parse(localStorage.getItem(USER_APPOINTMENT_DATA));
    const shipping = JSON.parse(localStorage.getItem(USER_ORDER_CHECKOUT_DATA));

    if (appointment) {
      setAppointmentData(appointment);
      setOrderData(order);
      setUserProfile(profile);
      setShippingDetails(shipping);

      localStorage.removeItem("order");
      localStorage.removeItem(USER_APPOINTMENT_DATA);
      localStorage.removeItem(USER_ORDER_CHECKOUT_DATA);
      localStorage.removeItem(USER_COMPLETE_SURVEY);
      localStorage.removeItem(USER_COMPLETE_VIDEO);

      setTimeout(() => {
        setCount((c) => c + 1);
      }, 500);
      if (type && type === "free") {
        setIsFree(true);
      }
      setLoading(false);
    } else {
      history.push("/dashboard");
    }
  }, []);

  const handleGoToDashboard = () => {
    history.push("/dashboard");
  };

  const getDate = (time = 30) => {
    // Friday, March 31, 2021
    if (appointmentData === null) return null;
    return moment(appointmentData.selected_datetime).format("dddd, LL");
  };

  return (
    <Section className="bg-littleClouds">
      {!loading && (
        <>
          <div className="max-w-3xl mx-auto flex flex-col items-center text-center pt-14 pb-10">
            <img src={checkIcon} alt="Check Icon" className="mb-8" />
            <h2 className="text-3xl font-bold leading-snug text-primary-text mb-4">
              Your Telemedicine Appointment is Confirmed!
            </h2>
            <p className="text-base font-body leading-normal">
              Thank you for making an appointment! We will send you a
              confirmation email with information about your appointment.
            </p>
          </div>
          <div className="max-w-4xl mx-auto bg-renuma-violet-100 pt-6 px-32 pb-24">
            <h3 className="text-xl2 font-bold leading-normal mb-8 text-center">
              Appointment Confirmation
            </h3>
            <div className="bg-white pt-8 px-32 pb-14">
              <div className="flex flex-row">
                <div className="w-20 mr-8 flex justify-start items-center">
                  <img
                    src={Stethoscope}
                    alt="Stethoscope Icon"
                    className="w-14"
                  />
                </div>
                <div className="text-base font-body leading-normal">
                  <div>
                    <h4 className="text-base font-bold mb-2">
                      Appointment Information
                    </h4>
                    <h3 className="font-body text-base leading-snug text-primary-text text-right md:text-left">
                      {appointmentData !== null &&
                        `${appointmentData.professional.first_name} ${appointmentData.professional.last_name}, ${appointmentData.professional.title}`}
                    </h3>
                    <p className="font-body text-base leading-normal text-secondary-text text-right md:text-left mb-0">
                      {slotRange} minute Appointment
                      <br />
                      {getDateRange(appointmentData.utc_datetime, slotRange)}
                      <br />
                      {getDate()}
                    </p>
                  </div>
                </div>
              </div>
              <div className="border border-gray-200 my-8"></div>
              <div>
                <p className="font-body text-base leading-normal text-secondary-text text-right md:text-left mb-0">
                  Telemedicine Consultation
                </p>
              </div>
              {!isFree && (
                <div className="flex flex-col flex-1">
                  <div className="border border-gray-200 my-8"></div>

                  <div className="flex flex-row justify-between">
                    <div>
                      Subtotal ISMS*
                      <br />
                      Tax
                      <br />
                      Shipping
                      <br />
                      <strong>Total</strong>
                    </div>
                    <div className="text-right">
                      ${orderData.description.amount.toFixed(2)}
                      <br />
                      $0.00
                      <br />
                      $0.00
                      <br />
                      <strong>${orderData.total.toFixed(2)}</strong>
                    </div>
                  </div>

                  <div className="border border-gray-200 my-8"></div>

                  <div className="flex flex-row justify-between">
                    <div>
                      Amount
                      <br />
                      Charged
                    </div>
                    <div className="text-right">
                      <strong>${appointmentPrice}.00</strong>
                      <br />

                      {shippingDetails.credit_card_number && (
                        <div className="font-base font-body flex flex-row items-center">
                          <img
                            src={
                              CreditCards[shippingDetails.credit_card_type.type]
                            }
                          />
                          <div className="ml-2">
                            <span className="text-3xl leading-3 relative">
                              <span className="inline-block top-1 relative">
                                &middot;&middot;&middot;&middot;
                              </span>
                            </span>
                            {shippingDetails.credit_card_number}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
              <div className="border border-gray-200 my-8"></div>

              <div>
                <p className="text-sm font-body leading-normal">
                  *Renuma’s web platform integrates and manages all the
                  components involved in sleep apnea care through partnerships
                  with various healthcare companies. Our medical services are
                  provided by Intelligent Sleep Medical Services (ISMS) and our
                  medical equipment is provided by MD Respiratory Services
                  (MDRS). Your order summary reflects how your payment is
                  distributed to our partners
                </p>
              </div>
            </div>
          </div>
          <div className="mt-6 max-w-3xl mx-auto flex flex-col items-center text-center">
            <p className="text-base font-body leading-normal mb-10">
              If you have any further questions, don’t hesitate to contact us at{" "}
              <a
                href="mailto:contact@renumasleep.com"
                className="text-primary-text"
              >
                contact@renumasleep.com
              </a>
              .<br />
              We are happy to help!
            </p>
            <PrimaryButton onClick={handleGoToDashboard} className="">
              Go to Dashboard
            </PrimaryButton>
          </div>
        </>
      )}
    </Section>
  );
};

export default AppointmentSummary;
