import React, { useEffect, useState } from "react";
import { Spin, List, Avatar } from "antd";
import { useHistory } from "react-router-dom";
import PrimaryButton from "./PrimaryButton";
import SecondaryButton from "./SecondaryButton";
import { getProfile, getNextBilling, unsuscribeRenumaPlus } from "../APIClient";
import cpapImage from "../assets/images/CPAP-Image.png";
import checkIcon from "../assets/svgs/check.svg";
import moment from "moment-timezone";

const AccountRenumaPlusSettings = () => {
  const [loading, setLoading] = useState(false);
  const [canceling, setCanceling] = useState(false);
  const [canceled, setCanceled] = useState(false);
  const [hasRenumaPlus, setHasRenumaPlus] = useState(null);
  const [hasSleepApnea, setHasSleepApnea] = useState(null);
  const [madeTelemedicinePaid, setMadeTelemedicinePaid] = useState(null);
  const [nextBilling, setNextBilling] = useState(null);
  const history = useHistory();

  useEffect(() => {
    loadProfile();
  }, []);

  useEffect(() => {
    if (hasRenumaPlus === true) {
      loadNextBilling();
    }
  }, [hasRenumaPlus]);

  const loadProfile = async () => {
    setLoading(true);
    try {
      const profile = await getProfile();

      // se obtiene el userProfile de localStorage
      let locProfile = JSON.parse(localStorage.getItem("userProfile"));
      // se actualizan los datos del user_state
      locProfile.user_state = profile.Item.user_state;
      // se guarda el userProfile con los datos actualizados
      localStorage.setItem("userProfile", JSON.stringify(locProfile));

      setHasRenumaPlus(profile.Item.user_state.Orders.Renuma_Plus);
      setHasSleepApnea(profile.Item.user_state.Health.Have_Sleep_Apnea);
      setMadeTelemedicinePaid(profile.Item.user_state.Telemedicine.Paid);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const loadNextBilling = async () => {
    setLoading(true);
    try {
      const resp = await getNextBilling();
      setNextBilling(resp);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const onClickCancel = async () => {
    setCanceling(true);
    try {
      const res = await unsuscribeRenumaPlus();
      setCanceled(true);
    } catch (error) {
      console.log(error);
    } finally {
      setCanceling(false);
    }
  };

  const onClickGetRenumaPlus = () => {
    history.push("/onboarding/renuma_plus");
  };

  return (
    <div className="mb-11">
      {hasRenumaPlus === false && (
        <div className="pb-11">
          <h3 className="text-lg font-bold text-primary-text">
            Sign up for Renuma+ to receive your CPAP machine
          </h3>
          <div className="flex flex-row space-x-6">
            <div>
              <img src={cpapImage} className="w-40" />
            </div>
            <p className="font-body text-base text-secondary-text leading-normal mt-4 mb-0 w-1/2">
              When you sign up with Renuma+ you'll receive a CPAP machine,
              replacement supplies every 6 months, and on-going monitoring
              supervised by a sleep doctor.
            </p>
          </div>

          <List
            dataSource={[
              { title: "Supplies delivered to your door" },
              { title: "Ongoing monitoring of treatment" },
            ]}
            renderItem={(item) => (
              <List.Item className="pb-0 border-none">
                <List.Item.Meta
                  className="flex-row justify-start items-stretch"
                  avatar={<Avatar src={checkIcon} />}
                  title={
                    <div className="flex flex-column justify-start items-center h-8 font-body text-base">
                      {item.title}
                    </div>
                  }
                />
              </List.Item>
            )}
          />

          <div className="mt-10">
            <PrimaryButton
              onClick={onClickGetRenumaPlus}
              disabled={!hasSleepApnea || !madeTelemedicinePaid}
            >
              Get Started with Renuma+
            </PrimaryButton>
          </div>
        </div>
      )}
      {!canceled && hasRenumaPlus === true && nextBilling && (
        <>
          {/* <h2 className="text-base font-body font-normal">
            Billed on the 1st of every month.
          </h2> */}
          <h1 className="text-base font-body font-bold mb-6">
            Next Billing on {moment(nextBilling.date_time).format("LL")} for $
            {nextBilling.amount}
          </h1>
          <h3 className="text-base font-body font-bold text-renuma-violet-primary">
            Renuma Plus cancelation process
          </h3>
          <p className="text-base font-body font-normal leading-normal w-4/5">
            The Renuma+ fee pays for medical equipment (CPAP and accessories)
            and medical services (a sleep doctor checking on your machine
            regularly). Unperformed medical services costs will be refunded to
            you. Medical equipment removed from its packaging cannot be
            refunded. If you pay for Renuma+ in monthly installments, you will
            be charged any outstanding balance for the cost of the medical
            equipment. You will receive an email once the account cancellation
            has been finalized.
            <br />
            <br />
            See our Terms and Conditions for further details. For any questions
            or if you are eligible for a medical equipment refund, please
            contact us at{" "}
            <a
              href="mailto:info@renumaslepp.com"
              className="text-renuma-violet-primary underline"
            >
              info@renumaslepp.com
            </a>
            .
          </p>
          <div className="mt-10 flex flex-row justify-start">
            {!canceling && (
              <SecondaryButton onClick={onClickCancel}>
                Cancel Renuma+
              </SecondaryButton>
            )}

            {canceling && (
              <Spin className="text-renuma-violet-primary">
                <SecondaryButton>Cancel Renuma+</SecondaryButton>
              </Spin>
            )}
          </div>
        </>
      )}

      {canceled && (
        <h3 className="text-base font-body font-bold">
          Your Renuma+ service was cancelled
        </h3>
      )}
    </div>
  );
};
export default AccountRenumaPlusSettings;
