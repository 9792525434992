import React from "react";
import { useHistory } from "react-router-dom";
import Section from "../../components/Section";

const Error404 = () => {
  const history = useHistory();
  const handleHomePage = () => {
    history.push("/");
  };
  return (
    <Section className="text-center mt-10 md:mt-10">
      <div>
        <h2 className="text-4xl text-semibold">404</h2>
        <h3 className="text-2xl text-semibold">
          Ooops! You weren't supposed to see this
        </h3>
        <p className="text-base">
          The page you're looking for no longer exists.
          <br />
          Return to the{" "}
          <span
            className="underline cursor-pointer text-renuma-violet-primary"
            onClick={handleHomePage}
          >
            home page
          </span>{" "}
          and remember: you haven't seen anything.
        </p>
      </div>
    </Section>
  );
};

export default Error404;
