import React, { useEffect, useState, useRef } from "react";
import { Form, Input, Spin, Select, Alert, Space, Checkbox } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import SimpleReactValidator from "simple-react-validator";
import {
  getShipping,
  updateShipping,
  getBillingAddress,
  updateBillingAddress,
} from "../APIClient";
import { states } from "../utils";
import PrimaryButton from "./PrimaryButton";
import PlacesAutocompleteInput from "./PlacesAutocompleteInput";

const AccountAddressPanel = () => {
  const [alert, setAlert] = useState({ text: "", type: "" });
  const { Option } = Select;

  const [values, setValues] = useState({
    street_address: "",
    apt_suite: "",
    city: "",
    state_province: "",
    zipcode: "",
    billing_street_address: "",
    billing_apt_suite: "",
    billing_city: "",
    billing_state_province: "",
    billing_zipcode: "",
  });
  const [sameAddress, setSameAddress] = useState(true);

  const [, forceUpdate] = useState();
  const simpleValidator = useRef(
    new SimpleReactValidator({
      autoForceUpdate: { forceUpdate: forceUpdate },
      element: (message) => (
        <div className="rnm-input-error text-xs">{message}</div>
      ),
    })
  );
  const [loading, setLoading] = useState(false);

  const disabled = () => !simpleValidator.current.allValid();
  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    setLoading(true);
    try {
      let res = await getShipping();
      if (!res.error && res.street_address === undefined) {
        res = {
          street_address: "",
          apt_suite: "",
          city: "",
          state_province: "",
          zipcode: "",
        };
      }

      const billing_res = await getBillingAddress();

      setValues((v) => ({
        ...v,
        street_address: res.street_address,
        apt_suite: res.apt_suite,
        city: res.city,
        state_province: res.state_province,
        zipcode: res.zipcode,
        billing_street_address: billing_res.street_address,
        billing_apt_suite: billing_res.apt_suite,
        billing_city: billing_res.city,
        billing_state_province: billing_res.state_province,
        billing_zipcode: billing_res.zip_code,
      }));
      if (
        res.street_address === billing_res.street_address &&
        res.apt_suite === billing_res.apt_suite &&
        res.city === billing_res.city &&
        res.state_province === billing_res.state_province
      ) {
        setSameAddress(true);
      } else {
        setSameAddress(false);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues((v) => ({ ...v, [name]: value }));
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    simpleValidator.current.showMessageFor(name);
    setValues((v) => ({ ...v, [name]: value }));
  };

  const handleChangeAddress = (data) => {
    const { name, value } = data;
    setValues((v) => ({ ...v, [name]: value }));
  };

  const handleBlurAddress = (data) => {
    const { name } = data;
    simpleValidator.current.showMessageFor(name);
    setValues((v) => ({ ...v, [name]: values[name] }));
  };

  const onClickUpdate = async () => {
    setLoading(true);
    setAlert({ text: "", type: "" });

    if (disabled()) {
      setAlert({ text: "All form data is required", type: "error" });
      return;
    }

    try {
      const billing = await updateBillingAddress({
        street_address: values.billing_street_address.trim(),
        apt_suite: values.billing_apt_suite.trim(),
        city: values.billing_city.trim(),
        state_province: values.billing_state_province.trim(),
        zip_code: values.billing_zipcode.trim(),
      });

      const shipping = await updateShipping(
        sameAddress
          ? {
              street_address: values.billing_street_address.trim(),
              apt_suite: values.billing_apt_suite.trim(),
              city: values.billing_city.trim(),
              state_province: values.billing_state_province.trim(),
              zipcode: values.billing_zipcode.trim(),
            }
          : {
              street_address: values.street_address.trim(),
              apt_suite: values.apt_suite.trim(),
              city: values.city.trim(),
              state_province: values.state_province.trim(),
              zipcode: values.zipcode.trim(),
            }
      );
      setAlert({
        text: "Your address has been updated successfully.",
        type: "success",
      });
    } catch (e) {
      setAlert({ text: e.message, type: "error" });
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <h3 className="text-lg font-bold text-primary mt-5 mb-0">
        Update Billing Address
      </h3>

      <Form className="mt-2 mb-11 w-1/2">
        {alert.text && (
          <Alert
            message={alert.text}
            type={alert.type}
            style={{ marginBottom: 24, marginTop: 24 }}
            closable
            onClose={() => setAlert({ text: "", type: "" })}
          />
        )}
        <Form.Item>
          <div className="text-base font-body">Street Address</div>
          {/*<PlacesAutocompleteInput
            name="billing_street_address"
            value={values.billing_street_address}
            onChange={handleChangeAddress}
            onBlur={handleBlurAddress}
          />*/}
          <Input
            name="billing_street_address"
            value={values.billing_street_address}
            size="large"
            onChange={handleChange}
            onBlur={handleBlur}
            className="rnm-input"
          />
          {simpleValidator.current.message(
            "billing_street_address",
            values.billing_street_address,
            "required"
          )}
        </Form.Item>
        <Form.Item>
          <div className="text-base font-body">Apt#/Suite</div>
          <Input
            name="billing_apt_suite"
            value={values.billing_apt_suite}
            size="large"
            onChange={handleChange}
            onBlur={handleBlur}
            className="rnm-input"
          />
        </Form.Item>
        <div className="space-x-6 flex flex-row w-full">
          <Form.Item className="w-full">
            <div className="text-base font-body">City</div>
            <Input
              name="billing_city"
              value={values.billing_city}
              size="large"
              onChange={handleChange}
              onBlur={handleBlur}
              className="rnm-input"
            />
            {simpleValidator.current.message(
              "billing_city",
              values.billing_city,
              "required"
            )}
          </Form.Item>
          <Form.Item className="w-full">
            <div className="text-base font-body">State</div>
            <Select
              onChange={(e) =>
                handleChange({
                  target: { name: "billing_state_province", value: e },
                })
              }
              onBlur={(e) =>
                handleBlur({
                  target: {
                    name: "billing_state_province",
                    value: values.billing_state_province,
                  },
                })
              }
              className="rnm-input"
              size="large"
              value={values.billing_state_province}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              filterSort={(optionA, optionB) =>
                optionA.children
                  .toLowerCase()
                  .localeCompare(optionB.children.toLowerCase())
              }
            >
              {states.usa.map((item, k) => (
                <Option key={k} value={item.code}>
                  {item.name}
                </Option>
              ))}
            </Select>
            {simpleValidator.current.message(
              "billing_state_province",
              values.billing_state_province,
              "required"
            )}
          </Form.Item>
        </div>
        <div className="w-1/2 pr-3">
          <Form.Item>
            <div className="text-base font-body">Zipcode</div>
            <Input
              name="billing_zipcode"
              maxLength="5"
              value={values.billing_zipcode}
              size="large"
              onChange={handleChange}
              onBlur={handleBlur}
              className="rnm-input"
            />
            {simpleValidator.current.message(
              "billing_zipcode",
              values.billing_zipcode,
              "required"
            )}
          </Form.Item>
        </div>
        <div className="w-full">
          <Checkbox
            checked={sameAddress}
            onChange={(e) => {
              setSameAddress((v) => !v);
            }}
          >
            My shipping address is the same as my billing address
          </Checkbox>
        </div>
        {/* Shipping Information */}
        {!sameAddress && (
          <div className="mt-20">
            <h3 className="text-lg font-bold text-primary mt-5 mb-0">
              Update Shipping Address
            </h3>

            <Form.Item>
              <div className="text-base font-body">Street Address</div>
              <PlacesAutocompleteInput
                name="street_address"
                value={values.street_address}
                onChange={handleChangeAddress}
                onBlur={handleBlurAddress}
              />
              {simpleValidator.current.message(
                "street_address",
                values.street_address,
                "required"
              )}
            </Form.Item>
            <Form.Item>
              <div className="text-base font-body">Apt#/Suite</div>
              <Input
                name="apt_suite"
                value={values.apt_suite}
                size="large"
                onChange={handleChange}
                onBlur={handleBlur}
                className="rnm-input"
              />
            </Form.Item>
            <div className="space-x-6 flex flex-row w-full">
              <Form.Item className="w-full">
                <div className="text-base font-body">City</div>
                <Input
                  name="city"
                  value={values.city}
                  size="large"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="rnm-input"
                />
                {simpleValidator.current.message(
                  "city",
                  values.city,
                  "required"
                )}
              </Form.Item>
              <Form.Item className="w-full">
                <div className="text-base font-body">State</div>
                <Select
                  onChange={(e) =>
                    handleChange({
                      target: { name: "state_province", value: e },
                    })
                  }
                  onBlur={(e) =>
                    handleBlur({
                      target: {
                        name: "state_province",
                        value: values.state_province,
                      },
                    })
                  }
                  className="rnm-input"
                  size="large"
                  value={values.state_province}
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  filterSort={(optionA, optionB) =>
                    optionA.children
                      .toLowerCase()
                      .localeCompare(optionB.children.toLowerCase())
                  }
                >
                  {states.usa.map((item, k) => (
                    <Option key={k} value={item.code}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
                {simpleValidator.current.message(
                  "state_province",
                  values.state_province,
                  "required"
                )}
              </Form.Item>
            </div>
            <div className="w-1/2 pr-3">
              <Form.Item>
                <div className="text-base font-body">Zipcode</div>
                <Input
                  name="zipcode"
                  maxLength="5"
                  value={values.zipcode}
                  size="large"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="rnm-input"
                />
                {simpleValidator.current.message(
                  "zipcode",
                  values.zipcode,
                  "required"
                )}
              </Form.Item>
            </div>
          </div>
        )}
        <Form.Item className="mt-6 mb-2">
          <PrimaryButton
            disabled={disabled() || loading}
            onClick={onClickUpdate}
          >
            {loading ? (
              <Spin
                indicator={<LoadingOutlined style={{ color: "#FFFFFF" }} />}
              />
            ) : (
              "Update"
            )}
          </PrimaryButton>
        </Form.Item>
      </Form>
    </>
  );
};

export default AccountAddressPanel;
