import React, { useEffect } from "react";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import { Select } from "antd";
const { Option } = Select;

const PlacesAutocompleteInput = ({
  name,
  placeholder,
  style,
  onChange,
  onBlur,
  value: _value,
  size,
}) => {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      /* Define search scope here */
    },
    debounce: 300,
  });

  useEffect(() => {
    setValue(_value);
  }, [_value]);

  const handleSearch = (value) => {
    // Update the keyword of the input element
    setValue(value);
  };

  const handleChange = ({ description }) => {
    // When user selects a place, we can replace the keyword without request data from API
    // by setting the second parameter to "false"
    setValue(description, false);
    clearSuggestions();

    // Get latitude and longitude via utility functions
    getGeocode({ address: description })
      .then((results) => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        if (onChange) {
          onChange({
            name: name,
            value: description,
            lat: -34.6173786,
            lng: -58.3818095,
          });
        }
      })
      .catch((error) => {
        console.log("Error: ", error);
      });
  };

  const handleBlur = () => {
    if (onBlur) {
      onBlur({ name: name });
    }
  };

  const options = () =>
    data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;
      return (
        <Option key={place_id}>
          <span>
            <strong>{main_text}</strong> <small>{secondary_text}</small>
          </span>
        </Option>
      );
    });

  return (
    <Select
      showSearch
      value={value}
      placeholder={placeholder}
      style={style}
      defaultActiveFirstOption={false}
      showArrow={false}
      filterOption={false}
      onSearch={handleSearch}
      onBlur={handleBlur}
      onChange={(place_id) => {
        const suggestion = data.filter((f) => f.place_id === place_id).pop();
        handleChange(suggestion);
      }}
      notFoundContent={null}
      className={`rnm-select ${size !== undefined ? size : ""}`}
    >
      {status === "OK" && options()}
    </Select>
  );
};

export default PlacesAutocompleteInput;
