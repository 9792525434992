import React, { useState, useEffect, Fragment } from 'react';
import { Breadcrumb, Card, Result } from 'antd';
import { Link, useParams, useHistory } from 'react-router-dom';
import { getSurvey } from '../../APIClient';
const SurveyDetailScreen = () => {
    const { surveyId } = useParams();
    const [survey, setSurvey] = useState(null);
    const [error, setError] = useState('');
    const [iframeSrc, setIframeSrc] = useState('');
    const [showSuccess, setShowSuccess] = useState(false);
    const history = useHistory();
    const loadSurvey = async (surveyId) => {
        try {
            const survey = await getSurvey({ surveyId });
            const IFRAME_SRC = `
      <!-- This embed code may be restricted to use on a single domain, see documentation at: https://help.surveygizmo.com/help/iframe-embed#limit-permitted-domains-for-embeds -->
      <iframe 
        src="${survey.survey_gizmodo_survey_link}?surveyId=${survey.id}&__no_style=true" 
        frameborder="0" 
        width="100%" 
        height="600" 
        style="overflow:hidden; margin: 0 auto; display: block; border: 1px rgb(240, 240, 240) 
        solid"
      ></iframe>
      `;
            setIframeSrc(IFRAME_SRC);
            setSurvey(survey);
        }
        catch (e) {
            setError(e.message);
        }
    };
    const checkSurveyStatus = async () => {
        try {
            const survey = await getSurvey({ surveyId });
            if (survey.is_completed) {
                setShowSuccess(true);
                setTimeout(() => {
                    history.push('/surveys');
                }, 2000);
            }
        }
        catch (e) {
            setError(e.message);
        }
    };
    useEffect(() => {
        if (surveyId) {
            loadSurvey(surveyId);
        }
        const interval = setInterval(() => {
            checkSurveyStatus();
        }, 750);
        return () => clearTimeout(interval);
    }, [surveyId]);
    return (<Fragment>
      <Breadcrumb>
        <Breadcrumb.Item key='surveys'>
          <Link to='/surveys'>Surveys</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item key='id'>
          Survey #{surveyId}
        </Breadcrumb.Item>
      </Breadcrumb>
      <Card style={{ margin: '24px 0px' }}>
        {survey && (<Card.Meta title={<><span>{survey.title}</span><span style={{ float: 'right', fontWeight: 300 }}>{new Date(survey.created_on).toLocaleDateString(undefined, { year: 'numeric', month: 'numeric', day: 'numeric' })}</span></>} description={survey.description}/>)}
        {survey && !showSuccess && (<div dangerouslySetInnerHTML={{ __html: iframeSrc }} style={{ marginTop: 24, marginBottom: 24 }}/>)}
        {showSuccess && (<Result status='success' title='Survey completed!' subTitle='Thanks for completing the survey. The responses have been sent to your provider for review.'/>)}
      </Card>
    </Fragment>);
};
export default SurveyDetailScreen;
