import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import Section from "../../../components/Section";
import homeSleepTestImage from "../../../assets/images/home_sleep_test_image.jpg";
import SleeptestImage from "../../../assets/images/SleeptestImage.jpg";
import playCircle from "../../../assets/svgs/play-circle.svg";
import checkIcon from "../../../assets/svgs/check.svg";
import { Modal, List, Avatar, Skeleton } from "antd";
import PrimaryButton from "../../../components/PrimaryButton";
import {
  USER_COMPLETE_SURVEY,
  USER_COMPLETE_VIDEO,
  WATCHPAT_ONE_DEFAULT_ID,
  getWindowDimensions,
  useWindowDimensions,
} from "../../../utils";
import _ from "lodash";
import ReactPlayer from "react-player";
import { getProduct } from "../../../APIClient";

const OrderSleepTest = () => {
  const [loading, setLoading] = useState(false);
  const [showVideo, setShowVideo] = useState(false);
  const [product, setProduct] = useState({});
  const [showVideoControls, setShowVideoControls] = useState(false);
  const { width, height } = useWindowDimensions();
  const [videoWidth, setVideoWidth] = useState(640);
  const [videoHeight, setVideoHeight] = useState(360);
  const [playing, setPlaying] = useState(false);
  const history = useHistory();
  const player = useRef(null);

  useEffect(() => {
    const userVideo = JSON.parse(localStorage.getItem(USER_COMPLETE_VIDEO));
    const userSurvey = JSON.parse(localStorage.getItem(USER_COMPLETE_SURVEY));

    setShowVideo(userVideo === null);
    loadProduct();
  }, []);

  useEffect(() => {
    let newWidth = Math.round(width * 0.8);
    let newHeight = Math.round(newWidth / 1.777778);
    if (newHeight > height * 0.9) {
      newHeight = Math.round(height * 0.8);
      newWidth = Math.round(newHeight * 1.777778);
    }

    setVideoHeight(newHeight);
    setVideoWidth(newWidth);
  }, [width, height]);

  const loadProduct = async () => {
    setLoading(true);
    try {
      const data = await getProduct(WATCHPAT_ONE_DEFAULT_ID);

      if (data == null) {
        setProduct({});
      } else {
        setProduct(data);
      }
    } catch (error) {
      alert(error);
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleOrderNow = () => {
    history.push({
      pathname: "/onboarding/order_checkout",
      state: { product: product },
    });
  };

  const handlePlay = () => {
    setPlaying(true);
  };

  const handleEnd = () => {
    setShowVideo(false);
    localStorage.setItem(USER_COMPLETE_VIDEO, true);
  };

  const handleOpenVideo = () => {
    player.current.seekTo(0);
    setPlaying(true);
    setShowVideo(true);
    setShowVideoControls(true);
  };

  const handleCloseVideo = () => {
    setPlaying(false);
    setTimeout(() => {
      setShowVideo(false);
      setShowVideoControls(false);
    }, 50);
  };
  return (
    <Section>
      <div className="my-0 md:my-9 max-w-7xl mx-auto flex flex-col md:flex-row space-x-0 md:space-x-32 px-0 md:px-8">
        <div>
          <div
            className="relative mb-4"
            onClick={handleOpenVideo}
            style={{ cursor: "pointer" }}
          >
            <img src={homeSleepTestImage} alt="bedroom" />
            <img
              src={playCircle}
              alt="play button"
              className="absolute top-1/2 left-1/2 -mt-8 -ml-8 h-16 w-16"
            />
          </div>
          <p className="font-body text-base text-secondary-text leading-normal mx-2">
            Watch this short video to learn about the next steps for receiving a
            home sleep test.
          </p>
        </div>
        <div className="mt-14 md:mt-0 w-full max-w-lg">
          <h2 className="text-renuma-violet-primary text-lg mb-2">NEXT STEP</h2>
          <h3 className="text-xl2 md:text-3xl font-bold leading-snug pb-4 mb-6 border-b border-renuma-violet-400">
            Order Your Home Sleep Test
          </h3>
          {loading && (
            <Skeleton active loading={loading} paragraph={{ rows: 4 }} />
          )}
          {!loading && product !== null && (
            <>
              <div className="flex flex-row justify-start mb-10 md:mb-2">
                <div className="w-24 md:w-32">
                  <img
                    src={product.images ? product.images[0].src : ""}
                    alt={product.name}
                    className="h-auto w-full"
                  />
                </div>
                <div
                  className="ml-6 md:ml-10 flex-1 font-body text-base leading-normal watchpat-one"
                  dangerouslySetInnerHTML={{ __html: product.description }}
                ></div>
              </div>
              <List
                dataSource={product.tags}
                renderItem={(item) => (
                  <List.Item className="pb-2 border-none">
                    <List.Item.Meta
                      className="flex-row justify-start items-stretch"
                      avatar={<Avatar src={checkIcon} />}
                      title={
                        <div className="flex flex-column justify-start items-center h-8 font-body text-base">
                          {item.name}
                        </div>
                      }
                    />
                  </List.Item>
                )}
              />
              <div className="mt-8 md:mt-6 text-2xl md:text-3xl font-body font-bold text-primary-text">
                ${product.price}
              </div>

              <div className="mt-10">
                <PrimaryButton onClick={handleOrderNow} disabled={showVideo}>
                  Order Now
                </PrimaryButton>
              </div>
            </>
          )}
        </div>
      </div>
      <Modal
        closable={showVideoControls}
        title={false}
        centered
        visible={showVideo}
        onCancel={showVideoControls ? handleCloseVideo : null}
        footer={false}
        width={videoWidth}
        bodyStyle={{ padding: 0, position: "relative" }}
      >
        <ReactPlayer
          ref={player}
          // url='https://www.youtube.com/watch?v=_RnN96EAQIc'
          url="//surveygizmolibrary.s3.amazonaws.com/library/702100/RenumaExplanation.mp4"
          height={videoHeight}
          width={videoWidth}
          playing={playing}
          controls={showVideoControls}
          onEnded={handleEnd}
        />
        {!playing && (
          <div
            className="absolute p-4 bg-white rounded"
            style={{
              left: "50%",
              top: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <div className="text-center font-body text-base text-primary-text">
              <img
                src={playCircle}
                alt="play button"
                className="h-20 w-20 mx-auto mt-14 mb-9"
                onClick={handlePlay}
              />
              Watch this short video to learn about the next steps for receiving
              a home sleep test.
            </div>
          </div>
        )}
      </Modal>
    </Section>
  );
};

export default OrderSleepTest;
