import React, { useEffect, useState, useRef } from "react";
import { Form, Spin, Alert } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import SimpleReactValidator from "simple-react-validator";
import { updatePaymentMethod } from "../APIClient";

import { PaymentInputsWrapper, usePaymentInputs } from "react-payment-inputs";
import PrimaryButton from "./PrimaryButton";
import useWindowDimensions from "../hooks/useWindowDimensions";

import images from "react-payment-inputs/images";
import CreditCardInput from "./CreditCardInput/CreditCardInput";

const AccountPaymentMethodsPanel = () => {
  const [alert, setAlert] = useState({ text: "", type: "" });

  return (
    <>
      <h3 className="text-lg font-bold text-primary mt-5 mb-0">
        Update Payment Details
      </h3>

      {alert.text && (
        <Alert
          message={alert.text}
          type={alert.type}
          style={{ marginBottom: 24, marginTop: 24 }}
          closable
          onClose={() => setAlert({ text: "", type: "" })}
        />
      )}
      <div className="max-w-md">
        <CreditCardInput />
      </div>
    </>
  );
};

export default AccountPaymentMethodsPanel;
