import React from 'react';
import clsx from 'clsx';

const Section = ({ children, className = '', style = {} }) => (
  <div 
    className={clsx('px-6 md:px-12 py-20 md:py-24', className)}
    style={style}
  >
    {children}
  </div>
);

export default Section;
