import React, { Fragment } from "react";
import Section from "../../components/Section";
import RenumaHelpImage from "../../assets/images/doc-transparent-About-us-page.png";
import DavidImage from "../../assets/images/David1.png";
import PostmanImage from "../../assets/images/postman.jpg";
import PrimaryButton from "../../components/PrimaryButton";
import { Link, useHistory } from "react-router-dom";
import { goToPrivatePage } from "../../utils";

const AboutUsScreen = () => {
  const history = useHistory();

  const goToSurvey = () => {
    goToPrivatePage({
      history,
      url: "/onboarding/survey",
      warningText:
        "You already took the survey, this service can't be done twice.",
    });
  };
  return (
    <Fragment>
      <Section className="flex justify-center bg-renuma-violet-800">
        <div className="max-w-2xl m-auto text-center text-white pt-28 pb-4">
          <h2
            className="text-3xl font-bold mb-6 text-white"
            data-aos="fade-up"
            data-aos-duration="700"
            data-aos-delay="0"
          >
            About Us
          </h2>
          <p
            className="mb-0 font-body text-base md:text-xl2 leading-normal"
            data-aos="fade-up"
            data-aos-duration="700"
            data-aos-delay="100"
          >
            Our mission is to deliver safe and convenient sleep apnea care with
            only a minimal time commitment from you.
          </p>
        </div>
      </Section>
      <Section className="flex justify-center bg-renuma-violet-300 section-pb-0">
        <div className="max-w-7xl text-center justify-between flex flex-col-reverse md:flex-row">
          <div
            className="md:w-1/3"
            data-aos="fade-up"
            data-aos-duration="700"
            data-aos-delay="0"
          >
            <img src={DavidImage} style={{ width: "100%" }} />
          </div>
          <div className="md:w-7/12 right-0 flex-col my-4 md:my-14">
            <h3
              className="leading-normal md:leading-10 text-xl2 md:text-3xl font-bold mb-10 md:mb-6 text-left capitalize"
              data-aos="fade-left"
              data-aos-duration="700"
              data-aos-delay="0"
            >
              Streamlining the Process for your sleep apnea needs
            </h3>
            <p
              className="mb-12 font-body text-base md:text-xl text-left leading-normal md:leading-8"
              data-aos="fade-left"
              data-aos-duration="700"
              data-aos-delay="100"
            >
              Sleep apnea care has changed dramatically over the years and
              innovations continue to happen at a rapid pace. However, the
              traditional path for getting evaluated for sleep apnea is still
              confusing and time-consuming. There are many different individuals
              and companies playing a role: the sleep doctor, the sleep lab, the
              dispenser of medical equipment, the manufacturer of medical
              equipment, the sleep test manufacturer. No one’s talking to each
              other, they’re each asking you the same questions you’ve already
              answered for someone else, and you’re left unsure who's really
              running the show.
            </p>
          </div>
        </div>
      </Section>
      <Section className="flex-col justify-center bg-mobileClouds md:bg-clouds bg-no-repeat bg-center">
        <div className="max-w-4xl m-auto text-center pt-16 pb-44">
          <h3
            className="leading-normal md:leading-10 text-xl2 md:text-3xl font-bold mb-6 capitalize"
            data-aos="fade-up"
            data-aos-duration="700"
            data-aos-delay="0"
          >
            Comprehensive sleep apnea care
          </h3>
          <p
            className="mb-10 font-body text-base md:text-xl2 leading-normal md:leading-8"
            data-aos="fade-up"
            data-aos-duration="700"
            data-aos-delay="100"
          >
            Renuma was born out of the idea that sleep apnea care does not have
            to be complicated and time-consuming. We integrate and manage all
            the components involved in sleep apnea care under one virtual roof
            so you have an easy, quick, and understandable journey towards a
            better night’s sleep.
          </p>
          <div data-aos="fade-up" data-aos-duration="700" data-aos-delay="150">
            <PrimaryButton onClick={goToSurvey}>Get Started</PrimaryButton>
          </div>
        </div>
      </Section>
      <Section className="flex justify-center bg-renuma-violet-300">
        <div className="max-w-7xl text-center flex flex-col-reverse md:flex-row justify-between pb-8 md:pb-0">
          <div className="md:w-7/12 right-0 flex-col mt-10 md:mt-5">
            <h3
              className="leading-normal md:leading-10 text-2xl md:text-3xl font-bold mb-10 md:mb-6 text-left capitalize"
              data-aos="fade-left"
              data-aos-duration="700"
              data-aos-delay="0"
            >
              Hassle-Free and proactive treatment
            </h3>
            <p
              className="mb-0 font-body text-base md:text-xl2 text-left leading-normal md:leading-8"
              data-aos="fade-left"
              data-aos-duration="700"
              data-aos-delay="100"
            >
              When you sign up for Renuma+, we’ll help to provide you with a
              CPAP machine and any necessary supplies for as long as you are a
              enrolled. And, we’ll help keep an eye on how well your machine is
              controlling your sleep apnea. If you’re feeling great and the
              machine results look great, you can skip the traditional follow-up
              appointments. But if any issues arise, we will arrange for you a
              telemedicine consultation with a sleep doctor who can adjust your
              treatment plan.
            </p>
          </div>
          <div
            className="md:w-1/3 md:mb-0 mb-0 -mt-20 md:-mt-24"
            data-aos="fade-up"
            data-aos-duration="700"
            data-aos-delay="0"
          >
            <img src={PostmanImage} style={{ width: "100%" }} />
          </div>
        </div>
      </Section>
      <Section className="flex-col justify-center bg-renuma-violet-100">
        <div className="max-w-4xl m-auto text-center mt-8 mb-4 md:mb-8">
          <h3
            className="leading-normal md:leading-10 text-3xl font-bold mb-4 md:mb-6 capitalize"
            data-aos="fade-up"
            data-aos-duration="700"
            data-aos-delay="0"
          >
            Diagnosis and testing from the comfort of your home
          </h3>
          <p
            className="mb-10 md:mb-12 font-body text-base md:text-xl2 leading-normal md:leading-8"
            data-aos="fade-up"
            data-aos-duration="700"
            data-aos-delay="100"
          >
            The free online sleep survey will determine if a home sleep study is
            a good option for you. You will then have the choice of purchasing a
            home sleep study or we can arrange for you a telemedicine
            consultation with a sleep doctor to discuss your sleep problem
            further.
          </p>
          <div data-aos="fade-up" data-aos-duration="700" data-aos-delay="150">
            <PrimaryButton onClick={goToSurvey}>Get Started</PrimaryButton>
          </div>
        </div>
      </Section>
    </Fragment>
  );
};
export default AboutUsScreen;
