import React, { useState, useRef, useEffect } from "react";
import { Upload, message, Button, Form, Input, Modal, Select } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import SimpleReactValidator from "simple-react-validator";
import { getBase64 } from "../utils";

const { Option } = Select;

function useForceUpdate() {
  const [, dispatch] = useState(Object.create(null));

  // Turn dispatch(required_parameter) into dispatch().
  const memoizedDispatch = React.useCallback(() => {
    dispatch(Object.create(null));
  }, [dispatch]);
  return memoizedDispatch;
}

const UploadDoc = ({ onComplete }) => {
  const [fileList, setFileList] = useState([]);
  const forceUpdateComp = useForceUpdate();
  const [showUploader, setShowUploader] = useState(false);
  const [values, setValues] = useState({
    title: "",
    filename: "",
    fileBase64: "",
    reason: "",
  });
  const [form] = Form.useForm();
  const [, forceUpdate] = useState();
  const simpleValidator = useRef(
    new SimpleReactValidator({
      autoForceUpdate: { forceUpdate: forceUpdate },
      element: (message) => (
        <div className="rnm-input-error text-xs">{message}</div>
      ),
    })
  );

  useEffect(() => {
    forceUpdateComp();
  }, []);

  const disabled = () => !simpleValidator.current.allValid();
  const props = {
    onRemove: (file) => {
      setFileList((v) => {
        const index = v.indexOf(file);
        const newFileList = v.slice();
        newFileList.splice(index, 1);
        return newFileList;
      });
    },
    beforeUpload: async (file) => {
      try {
        setFileList((v) => [...v, file]);
        const fileBase64 = await getBase64(file);
        setValues((v) => ({ ...v, filename: file.name, fileBase64 }));
      } catch (error) {
        console.log(error);
      } finally {
        return false;
      }
    },
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    form.setFieldsValue({ [name]: value });
    setValues((v) => ({ ...v, [name]: value }));
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    simpleValidator.current.showMessageFor(name);
    form.setFieldsValue({ [name]: value });
    setValues((v) => ({ ...v, [name]: value }));
  };
  const handleSelectChange = (value) => {
    form.setFieldsValue({ reason: value });
    setValues((v) => ({ ...v, reason: value }));
  };

  const handleSubmit = () => {
    if (!simpleValidator.current.allValid()) {
      message.error(`All form fields are required.`);
      return;
    }

    if (onComplete) {
      onComplete(JSON.parse(JSON.stringify(values)));
      handleCancel();
    }
  };

  const handleShowModal = () => {
    forceUpdateComp();

    setShowUploader(true);
  };

  const handleCancel = () => {
    form.resetFields();
    setValues({ title: "", filename: "" });
    setFileList([]);
    simpleValidator.current.hideMessages();
    simpleValidator.current.visibleFields = [];
    setShowUploader(false);
  };

  return (
    <>
      <Button onClick={handleShowModal}>Add Document</Button>

      <Modal
        title="Add Document"
        onCancel={handleCancel}
        footer={false}
        visible={showUploader}
      >
        <Form onFinish={handleSubmit} form={form} className="flex flex-col">
          <div className="flex flex-1 flex-col flex-no-wrap space-y-2">
            <Form.Item className="flex mb-0">
              <Select
                name="reason"
                onChange={handleSelectChange}
                defaultValue={values.reason}
                className="border border-gray-300"
              >
                <Option value="">Select type</Option>
                <Option value="home_sleep_test">Home Sleep Test Results</Option>
                <Option value="cpap">CPAP Results</Option>
              </Select>
            </Form.Item>
            <Form.Item className="flex">
              <Input
                name="title"
                placeholder="Document Title"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.title}
              />
              {simpleValidator.current.message(
                "title",
                values.title,
                "required|min:2|max:100"
              )}
            </Form.Item>
            <Form.Item className="flex">
              <Upload
                {...props}
                fileList={fileList}
                maxCount={1}
                multiple={false}
              >
                <Button icon={<UploadOutlined />}>Upload Document</Button>
              </Upload>
              <Input name="filename" type="hidden" value={values.filename} />
              {simpleValidator.current.message(
                "filename",
                values.filename,
                "required"
              )}
            </Form.Item>
          </div>
          <div className="flex flex-row justify-end mt-6 space-x-2 w-full">
            <Button onClick={handleCancel}>Cancel</Button>
            <Button type="primary" htmlType="submit" disabled={disabled()}>
              Add
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default UploadDoc;
