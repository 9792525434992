import React, { useEffect, useRef, useState } from "react";
import Section from "../../../components/Section";
import { Alert, Spin, Button } from "antd";
import SimpleReactValidator from "simple-react-validator";
import { LoadingOutlined } from "@ant-design/icons";
import PrimaryButton from "../../../components/PrimaryButton";
import Stethoscope from "../../../assets/svgs/stethoscope.svg";
import { saveOrder } from "../../../APIClient";
import { useHistory } from "react-router";

import {
  USER_APPOINTMENT_DATA,
  USER_COMPLETE_SURVEY,
  USER_COMPLETE_VIDEO,
  USER_ORDER_CHECKOUT_DATA,
  getDateRange,
} from "../../../utils";
import moment from "moment";

import {
  getCardTypeByType,
  getCardTypeByValue,
} from "../../../hooks/getCardType";
import CreditCardInput from "../../../components/CreditCardInput/CreditCardInput";

const AppointmentCheckout = () => {
  const history = useHistory();
  const [appointmentData, setAppointmentData] = useState(null);
  const [error, setError] = useState("");
  const slotRange = 15;
  const appointmentPrice = 15;
  const [values, setValues] = useState({
    credit_card_approved: false,
    credit_card_last_four: "",
    credit_card_type: "",
  });
  const [, forceUpdate] = useState();
  const simpleValidator = useRef(
    new SimpleReactValidator({
      autoForceUpdate: { forceUpdate: forceUpdate },
      element: (message) => (
        <div className="rnm-input-error text-xs">{message}</div>
      ),
    })
  );
  const [loading, setLoading] = useState(true);
  const disabled = () => !simpleValidator.current.allValid();
  useEffect(() => {
    setAppointmentData(JSON.parse(localStorage.getItem(USER_APPOINTMENT_DATA)));
    setLoading(false);
  }, []);

  const handleEditAppointment = () => {
    history.goBack();
  };

  const getDate = (time = 30) => {
    if (appointmentData === null) return null;
    return moment(appointmentData.selected_datetime).format("dddd, LL");
  };

  const handlePay = async () => {
    setLoading(true);
    try {
      const res = await saveOrder({
        order_type: "Telemedicine",
        appointment: {
          professional: appointmentData.professional.email,
          date_time: appointmentData.utc_datetime,
          kareo_id: appointmentData.professional.kareo_id,
          message: localStorage.getItem("telemedicineComment"),
        },
        subtotal: 15,
        ...values,
      });
      if (res.data.error === false) {
        localStorage.setItem("order", JSON.stringify(res.data));
        localStorage.removeItem("telemedicineComment");

        localStorage.setItem(USER_COMPLETE_SURVEY, false);
        localStorage.setItem(USER_COMPLETE_VIDEO, false);
        localStorage.setItem(
          USER_ORDER_CHECKOUT_DATA,
          JSON.stringify({
            credit_card_type: getCardTypeByType(values.credit_card_type),
            credit_card_number: values.credit_card_last_four,
          })
        );
        history.push("/onboarding/appointment_confirmation");
      } else {
        setError(res.data.error_msg);
      }
    } catch (error) {
      console.log("error", error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleCardApproved = (value, params) => {
    setValues((v) => ({ ...v, credit_card_approved: value, ...params }));
  };

  return (
    <Section className="bg-none md:bg-blurredBedroom bg-no-repeat bg-cover">
      <div className="flex flex-row max-w-7xl mx-auto items-stretch">
        <div className="hidden md:block w-1/2 bg-checkout bg-no-repeat bg-cover"></div>
        <div className="flex-1 bg-white py-10 px-0 md:px-12">
          <div className="mt-8">
            <div className="flex flex-col items-stretch">
              <h2 className="font-bold text-xl2">Order Summary</h2>

              {!loading && (
                <div className="mt-6 flex flex-row">
                  <div className="w-24 mr-9 flex  justify-center items-center">
                    <img src={Stethoscope} alt="Home Sleep Test" />
                  </div>
                  <div className="flex flex-1 flex-col md:flex-row">
                    <div className="w-full md:w-60">
                      <h3 className="font-bold font-body text-base leading-snug text-primary-text">
                        Appointment Information
                      </h3>
                      <h3 className="font-body text-base leading-snug text-primary-text text-right md:text-left">
                        {appointmentData !== null &&
                          `${appointmentData.professional.first_name} ${appointmentData.professional.last_name}, ${appointmentData.professional.title}`}
                      </h3>
                      <p className="font-body text-base leading-normal text-secondary-text text-right md:text-left mb-0">
                        {slotRange} minute Appointment
                        <br />
                        {getDateRange(appointmentData.utc_datetime, slotRange)}
                        <br />
                        {getDate()}
                      </p>
                    </div>
                    <div className="text-right flex-1">
                      <Button
                        size="large"
                        type="link"
                        onClick={handleEditAppointment}
                        className="p-0 h-auto font-base text-renuma-violet-primary border-0 border-b border-renuma-violet-primary mt-7"
                      >
                        Edit
                      </Button>
                    </div>
                  </div>
                </div>
              )}

              <div className="border border-gray-200 my-6"></div>

              <div className="mt-6 flex flex-col md:flex-row w-full">
                <div className="w-24 mr-9"></div>
                <div className="flex flex-row flex-1">
                  <div className="w-48">
                    <p className="font-body text-base leading-normal text-secondary-text text-left mb-0">
                      Subtotal
                      <br />
                      Tax
                      <br />
                      Shipping
                      <br />
                      <strong className="text-xl2 text-renuma-violet-primary">
                        Total
                      </strong>
                    </p>
                  </div>
                  <div className="flex-1">
                    <p className="font-body text-base leading-normal text-secondary-text text-right">
                      ${appointmentPrice}.00
                      <br />
                      $0.00
                      <br />
                      $0.00
                      <br />
                      <strong className="text-xl2 text-renuma-violet-primary">
                        ${appointmentPrice}.00
                      </strong>
                    </p>
                  </div>
                </div>
              </div>

              <div className="border border-gray-200 my-6"></div>

              <h2 className="font-bold text-xl2">Payment Information</h2>

              <div className="mt-6 flex flex-col mb-10">
                <CreditCardInput onApproved={handleCardApproved} />
                <div className="hidden">
                  {simpleValidator.current.message(
                    "credit_card_approved",
                    values.credit_card_approved,
                    "accepted"
                  )}
                </div>
              </div>
              {error && (
                <Alert
                  message={error}
                  type="error"
                  className="mb-6"
                  closable
                  onClose={() => setError("")}
                />
              )}

              <div>
                <PrimaryButton
                  disabled={
                    disabled() ||
                    loading ||
                    values.credit_card_approved === false
                  }
                  onClick={handlePay}
                  className="w-full"
                >
                  {loading ? (
                    <Spin
                      indicator={
                        <LoadingOutlined style={{ color: "#FFFFFF" }} />
                      }
                    />
                  ) : (
                    `Pay \$${appointmentPrice}`
                  )}
                </PrimaryButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default AppointmentCheckout;
