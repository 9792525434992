import React, { useState, useRef } from "react";
import { Alert, Button, Card, Form, Input, Spin } from "antd";
import { useHistory } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import Section from "../../components/Section";
import PrimaryButton from "../../components/PrimaryButton";
import { Auth } from "aws-amplify";
import { LoadingOutlined } from "@ant-design/icons";
const ForgotPasswordScreen = () => {
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [values, setValues] = useState({
    email: "",
  });
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const simpleValidator = useRef(
    new SimpleReactValidator({
      element: (message) => (
        <div className="rnm-input-error text-xs">{message}</div>
      ),
    })
  );
  const disabled = () => !simpleValidator.current.allValid();
  const onSubmit = async () => {
    setLoading(true);
    setError("");
    setSuccess("");
    if (!values.email.trim()) {
      setError("Incorrect username");
      return;
    }
    try {
      const res = await Auth.forgotPassword(values.email.trim());
      sessionStorage.setItem("forgot_pass_code", values.email.trim());
      history.push("/reset-password");
      setLoading(false);
    } catch (e) {
      setError(e.message);
      setLoading(false);
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues((v) => ({ ...v, [name]: value }));
  };
  const handleBlur = (e) => {
    const { name, value } = e.target;
    simpleValidator.current.showMessageFor(name);
    setValues((v) => ({ ...v, [name]: value }));
  };
  return (
    <Section className="bg-login bg-cover bg-no-repeat h-screen md:h-full pb-0">
      <div
        className="-mx-6 md:m-auto w-screen md:w-auto max-w-lg h-full flex items-start md:items-center bg-white md:bg-transparent"
        data-aos="fade-up"
        data-aos-duration="700"
        data-aos-delay="0"
      >
        <Card className="w-full">
          <h3 className="text-3xl font-bold text-center mt-5 mb-0">
            Reset your password
          </h3>

          {error && (
            <Alert
              message={error}
              type="error"
              className="my-6"
              closable
              onClose={() => setError("")}
            />
          )}

          {success && (
            <Alert
              message={success}
              type="success"
              className="my-6"
              closable
              onClose={() => setError("")}
            />
          )}
          <Form className="mt-10 mx-0 md:mx-7 mb-14">
            <Form.Item>
              <div className="text-base font-body">Email</div>
              <Input
                name="email"
                size="large"
                onChange={handleChange}
                onBlur={handleBlur}
                className="rnm-input"
              />
              {simpleValidator.current.message(
                "email",
                values.email,
                "required|email"
              )}
            </Form.Item>
            <Form.Item className="mt-6">
              <PrimaryButton
                disabled={disabled() || loading}
                onClick={onSubmit}
                className="w-full"
              >
                {loading ? (
                  <Spin
                    indicator={<LoadingOutlined style={{ color: "#FFFFFF" }} />}
                  />
                ) : (
                  "Send code"
                )}
              </PrimaryButton>
            </Form.Item>

            <div className="mt-4 text-center">
              <Button
                size="large"
                type="link"
                onClick={() => history.push("/login")}
                className="text-sm font-body text-renuma-violet-primary"
              >
                <span className="underline">Back to Log in</span>
              </Button>
            </div>
          </Form>
        </Card>
      </div>
    </Section>
  );
};
export default ForgotPasswordScreen;
