import React, { Fragment, useEffect, useState } from 'react';
import { Button, Breadcrumb, Card, Collapse, Form, Input, Comment, Tooltip } from 'antd';
import { getResult, addComment, renderDownloadUrl } from '../../APIClient';
import { useParams, Link } from 'react-router-dom';
import { DownloadOutlined } from '@ant-design/icons';
import moment from 'moment';
const ResultDetailScreen = () => {
    const { resultId } = useParams();
    const [result, setResult] = useState(null);
    const [comments, setComments] = useState([]);
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [newCommentText, setNewCommentText] = useState('');
    const loadResults = async () => {
        try {
            setIsLoading(true);
            const result = await getResult({ resultId });
            setResult(result);
            setComments(result.comments);
            setIsLoading(false);
        }
        catch (e) {
            setError(e.message);
            setIsLoading(false);
        }
    };
    const submitNewComment = async () => {
        try {
            const res = await addComment({ resultId, text: newCommentText });
            setComments(res.comments);
            setNewCommentText('');
        }
        catch (e) {
            setError(e.message);
            setNewCommentText('');
        }
    };
    useEffect(() => {
        loadResults();
    }, []);
    return (<Fragment>
      <Breadcrumb>
        <Breadcrumb.Item key='results'>
          <Link to='/results'>Results</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item key='id'>Result #{resultId}</Breadcrumb.Item>
      </Breadcrumb>
      {!isLoading && result && (<>
        <Card style={{ margin: '24px 0px' }}>
          <Card.Meta title={<>
                <span>{result.title}</span>
                <span style={{ float: 'right', fontWeight: 300 }}>{new Date(result.created_on).toLocaleDateString(undefined, { year: 'numeric', month: 'numeric', day: 'numeric' })}</span>
              </>} description={result.commentary}/>
          <div style={{ marginTop: 24, marginBottom: 24 }}>
            <div className='ant-card-meta-title' style={{ marginBottom: 8 }}>Attachments</div>
            <Collapse defaultActiveKey={[]}>
                {result.attachments.map(attachment => (<Collapse.Panel header={attachment.file_name} key={attachment.id} extra={<a href={renderDownloadUrl(attachment.uuid)} download><DownloadOutlined onClick={(e) => e.stopPropagation()}/></a>}>
                    <div>
                      <iframe src={attachment.file} style={{ minHeight: 400, width: '100%' }}/>
                    </div>
                  </Collapse.Panel>))}
            </Collapse>
          </div>
        </Card>
        <Card style={{ margin: '24px 0px' }}>
          <div className='ant-card-meta-title' style={{ marginBottom: 8 }}>Comments</div>
          <div>
          {comments.map(comment => (<Comment author={comment.author} content={<div className='ant-card-meta-description'>{comment.text}</div>} datetime={<Tooltip title={moment(new Date(comment.created_on)).format('YYYY-MM-DD HH:mm:ss')}>
                  <span>{moment(new Date(comment.created_on)).fromNow()}</span>
                </Tooltip>} key={comment.id}/>))}
          </div>
          <Form.Item>
            <Input.TextArea rows={2} onChange={(e) => setNewCommentText(e.target.value)} value={newCommentText}/>
          </Form.Item>
          <Form.Item>
            <Button loading={false} onClick={submitNewComment} type='primary' disabled={!newCommentText}>
              Add Comment
              </Button>
          </Form.Item>
        </Card>
        </>)}
    </Fragment>);
};
export default ResultDetailScreen;
