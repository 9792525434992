import React from "react";

const PrimaryButton = (props) => {
  if (props.type === "link") {
    return (
      <button
        {...props}
        className={`text-renuma-violet-primary ${
          props.size === "small" ? "text-sm" : "text-base"
        } font-normal leading-6  disabled:opacity-50 ${props.className}`}
      ></button>
    );
  }
  if (props.type === "link_submit") {
    return (
      <button
        {...props}
        type="submit"
        className={`text-renuma-violet-primary ${
          props.size === "small" ? "text-sm" : "text-base"
        } font-normal leading-6  disabled:opacity-50 ${props.className}`}
      ></button>
    );
  }
  if (props.size === "small") {
    return (
      <button
        {...props}
        className={`bg-renuma-violet-primary text-white px-6 py-3 text-base font-normal shadow-lg rounded-full leading-6  ${
          props.disabled ? "cursor-not-allowed" : "hover:bg-renuma-violet-800"
        } disabled:opacity-50 ${props.className}`}
      ></button>
    );
  }
  if (props.type === "send_nudge") {
    return (
      <button
      {...props}
      className={`text-white bg-renuma-violet-800 px-6 py-4 shadow-lg rounded-full btn  ${
        props.disabled ? "cursor-not-allowed" : "hover:bg-renuma-violet-800"
      } disabled:opacity-50 ${props.className ? props.className : '' }`}
    ></button>
    );
  }
  if (props.type === "send_nudge_white") {
    return (
      <button
      {...props}
      className={`bg-white text-renuma-violet-800 transition px-6 py-4 shadow-lg rounded-full btn hover:bg-renuma-900 hover:text-white  ${
        props.disabled ? "cursor-not-allowed" : "hover:bg-renuma-violet-800"
      } disabled:opacity-50 ${props.className ? props.className : '' }`}
    ></button>
    );
  }
  return (
    <button
      {...props}
      className={`bg-renuma-violet-primary text-white px-12 py-4 text-lg font-bold shadow-lg rounded-full leading-6  ${
        props.disabled ? "cursor-not-allowed" : "hover:bg-renuma-violet-800"
      } disabled:opacity-50 ${props.className}`}
    ></button>
  );
};

export default PrimaryButton;
