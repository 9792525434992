/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.
const awsmobile = {
  region: "us-east-1",
  userPoolId: "us-east-1_teCl7tTKf",
  userPoolWebClientId: "4bifs28hk64mn608j2v94jr0u3",
  mandatorySignIn: false,
  authenticationFlowType: "USER_SRP_AUTH",
  Auth: {
    region: "us-east-1",
    userPoolId: "us-east-1_teCl7tTKf",
    userPoolWebClientId: "4bifs28hk64mn608j2v94jr0u3",
    mandatorySignIn: false,
    authenticationFlowType: "USER_SRP_AUTH",
    oauth: {},
  },
};
export default awsmobile;
