import React, { useEffect, useState } from "react";
import { Card, Select, Breadcrumb } from "antd";
import { Line } from "@ant-design/charts";

import { getPatientGraphic } from "../../APIClient";
import _ from "lodash";
import AdminTablePatients from "./AdminTablePatients";

const { Option } = Select;

const AdminHomeScreen = () => {
  const [loading, setLoading] = useState({ patients: false, signedUps: false });
  const [isMounted, setIsMounted] = useState(true);
  const [dataType, setDataType] = useState("weeks");
  const [graphicData, setGraphicData] = useState([]);

  useEffect(() => {
    setIsMounted(true);
    loadSignedUps();
    return () => {
      setIsMounted(false);
    };
  }, []);

  useEffect(() => {
    loadSignedUps();
  }, [dataType]);

  const loadSignedUps = async () => {
    setLoading({ signedUps: true });
    try {
      const res = await getPatientGraphic(dataType);
      if (isMounted) {
        setLoading({ signedUps: false });
        setGraphicData(res.user_signedup);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading({ signedUps: false });
    }
  };

  const handleSelectChange = (value) => {
    setDataType(value);
  };

  const configChart = {
    data: graphicData,
    xField: "label",
    yField: "value",
    legend: {
      layout: "horizontal",
      position: "right",
    },
    point: {
      size: 5,
      shape: "circle",
      style: {
        fill: "white",
        stroke: "#6c009e",
        lineWidth: 2,
      },
    },
    lineStyle: {
      stroke: "#6c009e",
    },
    tooltip: {
      showMarkers: false,
    },
    state: {
      active: {
        style: {
          shadowBlur: 4,
          stroke: "#000",
          fill: "red",
        },
      },
    },
    interactions: [
      {
        type: "marker-active",
      },
    ],
  };

  return (
    <>
      <Breadcrumb className="mb-8">
        <Breadcrumb.Item>Home</Breadcrumb.Item>
      </Breadcrumb>
      <div className="flex flex-col md:flex-row mb-10">
        <Card className="flex-1 card-dashboard bg-dashboardHome bg-right-8-top-4 bg-no-repeat rounded-md drop-shadow mb-6 md:mb-0">
          <div className="flex flex-row justify-between items-center mb-6">
            <div className="font-bold">Patients</div>
          </div>
          <AdminTablePatients />
        </Card>
      </div>

      <div className="flex flex-row">
        <Card className="flex-1 card-dashboard bg-dashboardHome bg-right-8-top-4 bg-no-repeat rounded-md drop-shadow mb-6 md:mb-0 max-w-full">
          <div className="flex flex-row justify-between items-center mb-6">
            <div className="font-bold">Signed-up users</div>
            {!loading.signedUps && (
              <Select defaultValue={dataType} onChange={handleSelectChange}>
                <Option value="weeks">Week</Option>
                <Option value="months">Month</Option>
                <Option value="year">Year</Option>
              </Select>
            )}
          </div>
          {loading.signedUps && <div>Loading...</div>}
          {!loading.signedUps && <Line {...configChart} />}
        </Card>
      </div>
    </>
  );
};
export default AdminHomeScreen;
