import React, { useEffect, useState, useRef } from "react";
import { Select, Spin } from "antd";

import _ from "lodash";
import imgClose from "../../assets/images/baseline-close-24px.png";
import PrimaryButton from "../PrimaryButton";
import { LoadingOutlined } from "@ant-design/icons";
import ReactImageZoom from "react-image-zoom";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { getTags } from "../../APIClient";

const { Option } = Select;

function preloadImage(src) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = function () {
      resolve(img);
    };
    img.onerror = img.onabort = function () {
      reject(src);
    };
    img.src = src;
  });
}

const ProductDetail = ({ onClose, onSelect, mask, formData, loading }) => {
  const { width: wWidth, height: wHeight } = useWindowDimensions();
  const [imagesPreloaded, setImagesPreloaded] = useState(false);
  const [isPack, setIsPack] = useState(false);
  const [imageSize, setImageSize] = useState({ width: 0, height: 0 });
  const [sizeOptions, setSizeOptions] = useState([]);
  const [tags, setTags] = useState([]);
  const [values, setValues] = useState({
    size: null,
  });
  const [selectedImage, setSelectedImage] = useState(null);
  const imgContainer = useRef(null);
  useEffect(() => {
    const loadTags = async () => {
      try {
        const res = await getTags();
        setTags(res);
      } catch (error) {
        console.log(error);
      }
    };
    loadTags();
  }, []);

  useEffect(() => {
    let isCancelled = false;

    async function effect() {
      if (isCancelled) {
        return;
      }

      const sizes = mask.attributes.filter((v) => v.name === "Size");
      const image = mask.images[0] ? mask.images[0].src : "";
      const imageData = await preloadImage(image);
      const mWin = 48;
      const mCont = 32;
      const width =
        wWidth > 960 ? imgContainer.current.offsetWidth : wWidth - mWin - mCont;
      const height = (imageData.height * width) / imageData.width;
      const isPack = sizes[0].options[0].toLowerCase().indexOf("pack") > -1;

      setImageSize({ width, height });
      setIsPack(isPack);
      setSizeOptions(sizes[0].options ? sizes[0].options : []);
      setValues((v) => ({ ...v, ...formData }));
      setSelectedImage(image);

      if (isPack) {
        handleChangeSize(0);
      } else {
        handleChangeSize(null);
      }

      if (isCancelled) {
        return;
      }

      setImagesPreloaded(true);
    }

    if (formData !== undefined) {
      effect();
    }

    return () => {
      isCancelled = true;
    };
  }, [formData, mask]);

  const handleChangeSize = (e) => {
    setValues((v) => ({ ...v, size: e }));
  };

  const handleSelect = () => {
    if (onSelect) {
      onSelect(values);
    }
  };

  const handleClose = () => {
    if (onClose) {
      onClose();
      setValues((v) => ({ ...v, size: null }));
    }
  };
  return (
    <div className="relative w-full h-full bg-white border border-renuma-violet-primary rounded-lg">
      <div className={`absolute top-4 right-0 px-4`}>
        <div
          onClick={handleClose}
          className="flex justify-center items-center h-6 w-6 cursor-pointer"
        >
          <img src={imgClose} className="w-6 h-6" />
        </div>
      </div>
      <div className="flex flex-1 flex-col">
        <div className="flex flex-col md:flex-row justify-start md:justify-between items-stretch p-4 space-x-0 md:space-x-16">
          <div ref={imgContainer} className="flex flex-1 flex-col">
            {selectedImage !== null && (
              <ReactImageZoom
                style={{ zIndex: 9999 }}
                {...{
                  width: imageSize.width,
                  height: imageSize.height,
                  zoomWidth: 390,
                  zoomHeight: 240,
                  img: selectedImage,
                }}
              />
            )}
            <div className="flex flex-row">
              {mask.images.map((image, k) => (
                <div
                  key={k}
                  className="w-16 h-16 cursor-pointer"
                  onClick={() => setSelectedImage(image.src)}
                >
                  <img src={image.src} alt={mask.name} />
                </div>
              ))}
            </div>
          </div>
          <div className="flex flex-1 flex-col">
            <h3 className="text-xl font-semibold mt-8">{mask.name}</h3>
            <div className="mt-2 mb-4 border-b border-b-gray-200 w-full"></div>
            <p className="text-base leading-none">
              {/* <strong className="font-semibold">Availability:</strong>{" "}
              {mask.stock_status === "instock" ? "In stock" : "Sold out"}
              <br />
              <br /> */}
              <strong className="font-semibold">Category:</strong>{" "}
              {mask.categories.map((i, k) => {
                if (i.id === 15) {
                  return;
                }
                return <span key={k}>{i.name}</span>;
              })}
              <br />
              <br />
              <strong className="font-semibold">Free Shipping</strong>
            </p>
            <div className="mt-2 mb-4 border-b border-b-gray-200 w-full"></div>
            <div>
              <strong className="font-semibold">Size</strong>{" "}
              {sizeOptions.length > 0 && !isPack ? (
                <Select
                  placeholder="Select size"
                  className="ml-2"
                  onChange={handleChangeSize}
                  value={values.size}
                >
                  {sizeOptions.map((i, k) => (
                    <Option key={k} value={k}>
                      {i}
                    </Option>
                  ))}
                </Select>
              ) : (
                sizeOptions[0]
              )}
            </div>
            <div className="mt-2 mb-4 border-b border-b-gray-200 w-full"></div>
            <div>
              <strong className="font-semibold">
                Benefits of this mask over others:
              </strong>
              <ul>
                {tags.length > 0 &&
                  mask.tags.map((i, k) => {
                    const tag = tags
                      .filter((t) => t.id === i.id && t.description !== "")
                      .pop();
                    if (tag === undefined) {
                      return null;
                    }
                    return <li key={k}>{tag.description}</li>;
                  })}
              </ul>
            </div>
            <div className="mt-2 mb-4 border-b border-b-gray-200 w-full"></div>
            <div>
              <PrimaryButton
                disabled={values.size === null || loading}
                onClick={handleSelect}
              >
                {!loading ? (
                  "Select This Mask"
                ) : (
                  <Spin
                    indicator={<LoadingOutlined style={{ color: "#FFFFFF" }} />}
                  />
                )}
              </PrimaryButton>
            </div>
          </div>
        </div>
        <div className="flex flex-1 flex-col p-14">
          <h3 className="text-base font-semibold text-renuma-violet-primary">
            Product Infomation
          </h3>
          <div className="border-b-2 border-gray-200 w-full relative mb-8">
            <div className="border-b-2 border-renuma-violet-primary w-36 absolute top-0 left-0"></div>
          </div>
          <div
            className="mask-description"
            dangerouslySetInnerHTML={{
              __html: mask.description,
            }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetail;
