import React, { useState } from "react";
import { Button, Card, List, Avatar, Collapse } from "antd";
import { Link, useHistory } from "react-router-dom";
import { MinusCircleFilled, PlusCircleFilled } from "@ant-design/icons";
import Stethoscope from "../assets/svgs/stethoscope.svg";
import checkIcon from "../assets/svgs/check.svg";
import PrimaryButton from "./PrimaryButton";
import SecondaryButton from "./SecondaryButton";
import moment from "moment-timezone";
import { useEffect } from "react";

import {
  USER_PROFILE,
  getDateRange,
  getDate,
  MASK_DEFAULT_ID,
  CPAP_MACHINE_DEFAULT_ID,
} from "../utils";
import { getUserState, getProduct } from "../APIClient";
import { CreditCards } from "../assets/svgs/credit-cards/";
import { ModalWarning } from "../utils";
import SleeptestImage from "../assets/images/SleeptestImage.jpg";

const { Panel } = Collapse;

const OrderPreview = ({ orders, open = false }) => {
  const [collapsed, setCollapsed] = useState(!open);
  const [userProfile, setUserProfile] = useState({});
  const slotRange = 30;

  useEffect(() => {
    setUserProfile(JSON.parse(localStorage.getItem(USER_PROFILE)));
  }, []);

  const toggleCard = () => {
    setCollapsed((v) => !v);
  };

  const handleViewReceipt = (receipt_url) => {
    if (receipt_url === undefined) {
      ModalWarning({
        warningText: "Your order has not receipt.",
      });
    } else if (receipt_url === "") {
      ModalWarning({
        warningText: "Your receipt will ready once we confirm your payment.",
      });
    } else {
      window.open(receipt_url);
    }
  };

  return (
    <>
      <Card
        className="card-dashboard  card-body-1 rounded-md drop-shadow w-full mb-6"
        title={
          <div
            className={`flex flex-row justify-between items-center ${
              collapsed ? "" : "border-b border-gray-200 pb-4"
            }`}
          >
            <div>Order History</div>
            <div>
              <div onClick={toggleCard}>
                {!collapsed ? (
                  <MinusCircleFilled className="text-4xl text-renuma-violet-primary cursor-pointer" />
                ) : (
                  <PlusCircleFilled className="text-4xl text-renuma-violet-primary cursor-pointer" />
                )}
              </div>
            </div>
          </div>
        }
      >
        <Collapse
          expandIconPosition="right"
          className="mb-6"
          defaultActiveKey="0"
        >
          {!collapsed &&
            orders.map((order, key) => (
              <Panel
                header={
                  <div className="flex flex-row items-stretch space-x-2 pt-2 pb-2">
                    <div className="flex flex-row items-center w-20 md:w-48">
                      {moment(order.date_ordered).format("MM/DD/YYYY")}
                    </div>
                    <div className="flex flex-row justify-between w-full max-w-2xl">
                      <div className="flex items-center">
                        Order # {order.id}
                      </div>
                      <div className="flex items-center text-red-400">
                        {order.status && order.status === "unsubscribed" && (
                          <>Order Canceled</>
                        )}
                      </div>
                    </div>
                  </div>
                }
                key={key}
              >
                <div key={key} className="flex flex-row space-x-2 pt-4 pb-16">
                  <div className="w-20 md:w-48"></div>
                  <div className="flex-1 flex-col">
                    <div className="flex flex-row justify-between w-full max-w-2xl">
                      <div></div>
                      <div>
                        <Button
                          type="link"
                          className="text-renuma-violet-primary"
                          onClick={() => handleViewReceipt(order.receipt_url)}
                        >
                          View Receipt
                        </Button>
                      </div>
                    </div>
                    <div className="flex flex-row space-x-8 max-w-2xl">
                      <div className="w-20">
                        {order.type === "Renuma+" && (
                          <img
                            src={order.products[0].images[0].src}
                            className=""
                          />
                        )}
                        {order.type === "Home Sleep Test" && (
                          <img
                            src={order.products[0].images[0].src}
                            className=""
                          />
                        )}
                        {order.type === "Telemedicine" && (
                          <img
                            src={Stethoscope}
                            alt="Stethoscope Icon"
                            className="w-14"
                          />
                        )}
                      </div>
                      <div className="flex-1">
                        {order.type === "Home Sleep Test" && (
                          <div>
                            <h3 className="text-lg font-bold text-primary-text">
                              Order Your Home Sleep Test
                            </h3>
                            <p className="font-body text-base text-secondary-text leading-normal mb-0">
                              Includes:
                            </p>
                            <ul className="list-none md:list-disc list-inside mb-0">
                              <li>Home sleep test</li>
                              <li>Results in 3 days</li>
                              <li>
                                One virtual consultation with a sleep doctor
                              </li>
                            </ul>
                            <div className="border border-gray-200 my-8"></div>
                            <p className="font-body text-base text-secondary-text leading-normal mb-0">
                              Sleep Study with Post-Test Telemedicine
                              Consultation
                            </p>

                            <div className="border border-gray-200 my-8"></div>
                          </div>
                        )}

                        {order.type === "Telemedicine" && (
                          <div>
                            <h4 className="text-base font-bold mb-2">
                              Appointment Information
                            </h4>
                            {order.appointment !== undefined && (
                              <>
                                <h3 className="font-body text-base leading-snug text-primary-text text-right md:text-left">
                                  {`${order.appointment.professional.first_name} ${order.appointment.professional.last_name}, ${order.appointment.professional.title}`}
                                </h3>
                                <p className="font-body text-base leading-normal text-secondary-text text-right md:text-left mb-0">
                                  {slotRange} minute Appointment
                                  <br />
                                  {getDateRange(
                                    order.appointment.selected_datetime,
                                    slotRange
                                  )}
                                  <br />
                                  {getDate()}
                                </p>
                              </>
                            )}

                            <div className="border border-gray-200 my-8"></div>
                          </div>
                        )}

                        {order.type === "Renuma+" && (
                          <div>
                            <h4 className="text-base font-bold mb-2">
                              Order summary
                            </h4>
                            <p className="font-body text-base leading-normal text-secondary-text text-right md:text-left mb-4">
                              Renuma+ Treatment Initiation Phase* Includes
                            </p>
                          </div>
                        )}

                        <div className="flex flex-row justify-between">
                          {order.type === "Renuma+" && (
                            <div className="flex flex-col justify-between w-full">
                              {order.description !== undefined &&
                                order.description.map((i, k) => (
                                  <div
                                    key={k}
                                    className="flex flex-row justify-between w-full"
                                  >
                                    <div className="flex-1">
                                      {i.details ===
                                      "Monthly Amount Charged" ? (
                                        <strong>{i.details}</strong>
                                      ) : (
                                        i.details
                                      )}
                                    </div>
                                    <div className="w-32 text-right">
                                      ${parseInt(i.amount).toFixed(2)}
                                    </div>
                                  </div>
                                ))}
                              {/* <div className="mt-6">
                                <p className="font-body text-base leading-normal text-secondary-text text-right md:text-left mb-4">
                                  RenumaPlus Fee Treatment Maintenance Phase
                                  (month 11+)
                                </p>
                              </div>
                              {order.description !== undefined &&
                                order.description[1].map((i, k) => (
                                  <div
                                    key={k}
                                    className="flex flex-row justify-between w-full"
                                  >
                                    <div className="flex-1">
                                      {i.details ===
                                      "Monthly Amount Charged" ? (
                                        <strong>{i.details}</strong>
                                      ) : (
                                        i.details
                                      )}
                                    </div>
                                    <div className="w-32 text-right">
                                      ${parseInt(i.amount).toFixed(2)}
                                    </div>
                                  </div>
                                ))} */}
                            </div>
                          )}
                          {order.type !== "Renuma+" && (
                            <>
                              <div>
                                Subtotal
                                {order.type !== "Renuma+" ? " ISMS*" : ""}
                                <br />
                                Tax
                                <br />
                                Shipping
                                <br />
                                <strong>Total</strong>
                              </div>
                              <div className="text-right">
                                $
                                {order.type === "Renuma+"
                                  ? parseFloat(
                                      parseInt(order.subtotal[0]) +
                                        parseInt(order.subtotal[1])
                                    ).toFixed(2)
                                  : parseFloat(order.subtotal).toFixed(2)}
                                <br />${parseFloat(order.tax).toFixed(2)}
                                <br />$
                                {parseFloat(order.shipping_price).toFixed(2)}
                                <br />
                                <strong>
                                  ${parseFloat(order.total).toFixed(2)}
                                </strong>
                              </div>
                            </>
                          )}
                        </div>

                        {order.type !== "Telemedicine" && (
                          <>
                            <div className="border border-gray-200 my-8"></div>

                            <div className="flex flex-row justify-between">
                              <div>
                                Shipping
                                <br />
                                Details
                              </div>
                              <div className="text-right ml-16">
                                <strong>{userProfile.full_name}</strong>
                                <br />
                                {order.street_address}
                                <br />
                                {order.apt_suite}
                                <br />
                                {order.city}, {order.state} {order.zipcode}
                              </div>
                            </div>
                          </>
                        )}

                        <div className="border border-gray-200 my-8"></div>

                        <div className="flex flex-row justify-between">
                          <div>
                            {order.payment_method === "monthly" ? (
                              <>Total Purchase</>
                            ) : (
                              <>
                                Total Amount
                                <br />
                                Charged
                              </>
                            )}
                          </div>
                          <div className="text-right">
                            <strong>
                              ${parseFloat(order.total).toFixed(2)}
                            </strong>
                            <br />

                            {order.credit_card_number && (
                              <div className="font-base font-body flex flex-row items-center">
                                <img
                                  src={CreditCards[order.credit_card_type.type]}
                                />
                                <div className="ml-2">
                                  <span className="text-3xl leading-3 relative">
                                    <span className="inline-block top-1 relative">
                                      &middot;&middot;&middot;&middot;
                                    </span>
                                  </span>
                                  {order.credit_card_number}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="border border-gray-200 my-8"></div>

                        <div className="">
                          {order.type === "Renuma+" && (
                            <p className="text-sm font-body leading-normal">
                              *This phase lasts 10 months. After 10 months, you
                              will be auto-enrolled in the Treatment Maintenance
                              Phase at a cost of $180 every 6 months. Cancel
                              anytime. See{" "}
                              <Link
                                to="/conditions-of-use"
                                className="text-renuma-violet-primary"
                              >
                                Terms and Conditions
                              </Link>{" "}
                              for cancellation process.
                            </p>
                          )}
                          <p className="text-sm font-body leading-normal">
                            {order.type === "Renuma+" && "*"}*Renuma’s web
                            platform integrates and manages all the components
                            involved in sleep apnea care through partnerships
                            with various healthcare companies. Our medical
                            services are provided by Intelligent Sleep Medical
                            Services (ISMS) and our medical equipment is
                            provided by MD Respiratory Services (MDRS). Your
                            order summary reflects how your payment is
                            distributed to our partners.
                          </p>
                        </div>

                        {order.type === "Renuma+" && (
                          <>
                            {/* <div className="border border-gray-200 my-8"></div>

                            <div className="max-w-sm">
                              <p className="text-sm font-body leading-normal">
                                You will be charged the monthly service and
                                equipment fees until you cancel. You can cancel
                                anytime by visiting your account page on our
                                website. Please note that if you’ve started
                                using your CPAP machine, you will be charged the
                                unpaid remainder of its price (a machine
                                generally costs $850). We cannot accept returns
                                when a machine is used.
                              </p>
                            </div> */}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Panel>
            ))}
        </Collapse>
      </Card>
    </>
  );
};

const PlaceNewOrder = ({ orders, userState }) => {
  const [loading, setLoading] = useState({ userState: false, product: false });
  const [collapsed, setCollapsed] = useState(false);
  const [showRenumaPlus, setShowRenumaPlus] = useState(false);
  const [machine, setMachine] = useState(null);
  const [product, setProduct] = useState(null);

  const history = useHistory();

  useEffect(() => {
    if (userState !== null) {
      setShowRenumaPlus(
        userState.Health.Have_Sleep_Apnea === true &&
          (userState.Telemedicine.Free === true ||
            userState.Telemedicine.Paid === true)
      );
    }
  }, [userState]);

  useEffect(() => {
    loadProduct();
  }, [showRenumaPlus]);

  const loadProduct = async () => {
    setLoading((v) => ({ ...v, product: true }));
    try {
      const resCPAP = await getProduct(CPAP_MACHINE_DEFAULT_ID);
      setMachine(resCPAP);
      const res = await getProduct(MASK_DEFAULT_ID);
      setProduct(res);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading((v) => ({ ...v, product: false }));
    }
  };

  const toggleCard = () => {
    setCollapsed((v) => !v);
  };

  const onClickGetRenumaPlus = () => {
    history.push("/onboarding/renuma_plus");
  };

  const onClickCustomizeOrder = () => {
    history.push("/onboarding/renuma_plus");
  };
  return (
    <Card
      className="card-dashboard  card-body-1 rounded-md drop-shadow w-full"
      title={
        <div
          className={`flex flex-row justify-between items-center ${
            collapsed ? "" : "border-b border-gray-200 pb-4"
          }`}
        >
          <div>Place New Order</div>
          <div>
            <div onClick={toggleCard}>
              {!collapsed ? (
                <MinusCircleFilled className="text-4xl text-renuma-violet-primary cursor-pointer" />
              ) : (
                <PlusCircleFilled className="text-4xl text-renuma-violet-primary cursor-pointer" />
              )}
            </div>
          </div>
        </div>
      }
    >
      {!collapsed && (
        <>
          <div className={!showRenumaPlus ? "pb-11 opacity-40" : "pb-11"}>
            <h3 className="text-lg font-bold text-primary-text">
              Sign up for Renuma+ to receive your CPAP machine
            </h3>
            <div className="flex flex-row space-x-6">
              <div>
                <img
                  src={
                    machine !== null && machine.images[0]
                      ? machine.images[0].src
                      : ""
                  }
                  className="w-40"
                />
              </div>
              <p className="font-body text-base text-secondary-text leading-normal mt-4 mb-0 w-1/2">
                When you sign up with Renuma+ you'll receive a CPAP machine,
                replacement supplies every 6 months, and on-going monitoring
                supervised by a sleep doctor.
              </p>
            </div>

            <List
              dataSource={[
                { title: "Supplies delivered to your door" },
                { title: "Ongoing monitoring of treatment" },
              ]}
              renderItem={(item) => (
                <List.Item className="pb-0 border-none">
                  <List.Item.Meta
                    className="flex-row justify-start items-stretch"
                    avatar={<Avatar src={checkIcon} />}
                    title={
                      <div className="flex flex-column justify-start items-center h-8 font-body text-base">
                        {item.title}
                      </div>
                    }
                  />
                </List.Item>
              )}
            />

            <div className="mt-10">
              <PrimaryButton
                onClick={onClickGetRenumaPlus}
                disabled={!showRenumaPlus}
              >
                Get Started with Renuma+
              </PrimaryButton>
            </div>
          </div>

          {/* <div className="border border-b-gray-200 mb-11"></div>

          <div className="pb-11 opacity-40">
            <h3 className="text-lg font-bold text-primary-text">
              Order your replacement supplies early
            </h3>
            <div className="flex flex-row space-x-6">
              <div>
                <img
                  src={
                    product !== null && product.images[0]
                      ? product.images[0].src
                      : "https://renumasleep.s3.amazonaws.com/ResmedAirFitF30_1.jpg"
                  }
                  className="w-40"
                />
              </div>
              <p className="font-body text-base text-secondary-text leading-normal mt-4 mb-0 w-1/2">
                Replacement supplies such as masks and hoses
              </p>
            </div>

            <div className="mt-10">
              <PrimaryButton onClick={onClickCustomizeOrder}>
                Customize Order
              </PrimaryButton>
            </div>
          </div> */}
        </>
      )}
    </Card>
  );
};

const NoOrders = ({ userState }) => {
  const history = useHistory();
  const [hasSurvey, setHasSurvey] = useState(false);
  const [notRecommended, setNotRecommended] = useState(false);

  useEffect(() => {
    if (userState !== null && userState.Home_Study.Good_Candidate === "Bad") {
      setNotRecommended(true);
    }
    if (userState !== null && userState.Surveys.Completed === true) {
      setHasSurvey(true);
    }
  }, [userState]);

  const onOrderSleepTest = () => {
    if (hasSurvey) {
      history.push("/onboarding/order_checkout");
    } else {
      ModalWarning({
        warningText:
          "You haven't taken the survey yet, please do it first before ordering your test/scheduling an appointment",
      });
    }
  };

  const clickGetStarted = () => {
    if (!hasSurvey) {
      ModalWarning({
        warningText:
          "You haven't taken the survey yet, please do it first before ordering your test/scheduling an appointment",
      });
      return
    }
    if (userState.Telemedicine.Free === true) {
      ModalWarning({
        warningText: "You already scheduled your virtual consultation",
      });
    } else {
      history.push("/onboarding/telemedicine/free");
    }
  };

  return (
    <Card
      className="card-dashboard card-actions-right rounded-md drop-shadow w-full"
      title="You have no orders"
    >
      <div className="flex flex-col h-full">
        <div className="flex flex-row space-x-2">
          <div className="flex-1">
            <p className="font-body text-base text-secondary-text leading-normal mb-0">
              Includes:
            </p>
            <ul className="list-none md:list-disc list-inside mb-0">
              <li>Home sleep test</li>
              <li>Results in 3 days</li>
              <li>One virtual consultation with a sleep doctor</li>
            </ul>
          </div>
          <div className="w-20 md:w-48">
            <img src={SleeptestImage} className="" />
          </div>
        </div>
        <div className="flex flex-1 flex-row items-end space-x-6">
          <PrimaryButton onClick={onOrderSleepTest}>
            Order Sleep Test
          </PrimaryButton>
          <SecondaryButton disabled={notRecommended} onClick={clickGetStarted}>
            Schedule Test Results Appointment
          </SecondaryButton>
        </div>
      </div>
    </Card>
  );
};

export default OrderPreview;
export { PlaceNewOrder, NoOrders };
