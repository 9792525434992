import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { getSurvey, getUserState, saveSurveyAnswers } from "../../../APIClient";
import LoadingCard from "../../../components/LoadingCard";
import Section from "../../../components/Section";
import { USER_PROFILE, USER_COMPLETE_SURVEY } from "../../../utils";
import * as SurveyJs from "survey-react";
import * as widgets from "surveyjs-widgets";
import "survey-react/survey.css";
import "nouislider/dist/nouislider.css";
import $ from "jquery";

import moment from "moment";
import _ from "lodash";

widgets.prettycheckbox(SurveyJs);
widgets.select2(SurveyJs, $);
widgets.inputmask(SurveyJs);
widgets.jquerybarrating(SurveyJs, $);
widgets.jqueryuidatepicker(SurveyJs, $);
widgets.nouislider(SurveyJs);
widgets.select2tagbox(SurveyJs, $);
widgets.sortablejs(SurveyJs);
widgets.ckeditor(SurveyJs);
widgets.autocomplete(SurveyJs, $);
widgets.bootstrapslider(SurveyJs);

const SurveyRenumaPlus = () => {
  const [json, setJson] = useState(null);
  const [model, setModel] = useState(null);
  const [loading, setLoading] = useState(true);
  const history = useHistory();

  useEffect(() => {
    loadSurvey();
  }, []);

  useEffect(() => {
    if (json !== null) {
      const profile = JSON.parse(localStorage.getItem(USER_PROFILE));
      const age = moment().diff(profile.date_of_birth, "years");
      json.pages.map((pg) => {
        pg.elements.map((el) => {
          if (el.name === "p1q1") {
            el.defaultValue = age;
          }
          return el;
        });
        return pg;
      });
      setModel(new SurveyJs.Model(json));
    }
  }, [json]);

  useEffect(() => {
    if (model !== null) {
      const defaultThemeColors = SurveyJs.StylesManager.ThemeColors["default"];
      SurveyJs.settings.executeSkipTriggerOnValueChanged = false;

      defaultThemeColors["$main-color"] = "#9751b7";
      defaultThemeColors["$main-hover-color"] = "#9751b7";
      defaultThemeColors["$text-color"] = "#4a4a4a";
      defaultThemeColors["$header-color"] = "#9751b7";

      defaultThemeColors["$header-background-color"] = "#4a4a4a";
      defaultThemeColors["$body-container-background-color"] = "#f8f8f8";

      SurveyJs.StylesManager.applyTheme();
    }
  }, [model]);

  const loadSurvey = async () => {
    try {
      const res = await getSurvey(10);
      setJson(res.Item);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const onComplete = async (survey, options) => {
    window.scrollTo(0, 0);
    try {
      console.log("onComplete", survey, options);

      history.push({
        pathname:
          "/onboarding/order_checkout/philips-respironics-dreamwear-nasal-mask/resmed-air-touch-n-20",
      });
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Section>
      {loading && <LoadingCard />}
      {!loading && model !== null && (
        <div className="my-9 md:max-w-7xl mx-auto">
          <SurveyJs.Survey
            model={model}
            onComplete={onComplete}
            css={{
              headerText: "text-xl font-bold",
              pageTitle: "text-xl font-bold",
              navigation: {
                complete: "btn sv_complete_btn rounded-full text-lg py-2 px-8",
                prev: "btn sv_prev_btn rounded-full text-lg py-2 px-8",
                next: "btn sv_next_btn rounded-full text-lg py-2 px-8",
                start: "btn sv_start_btn rounded-full text-lg py-2 px-8",
                preview: "btn sv_preview_btn rounded-full text-lg py-2 px-8",
                edit: "btn sv_edit_btn rounded-full text-lg py-2 px-8",
              },
            }}
          />
        </div>
      )}
    </Section>
  );
};

export default SurveyRenumaPlus;
