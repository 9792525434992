import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import Section from "../../../components/Section";
import videoFreeConsultation from "../../../assets/images/schedule_a_consultation.jpg";
import playCircle from "../../../assets/svgs/play-circle.svg";
import { Modal } from "antd";
import PrimaryButton from "../../../components/PrimaryButton";
import {
  USER_COMPLETE_SURVEY,
  USER_COMPLETE_VIDEO,
  useWindowDimensions,
} from "../../../utils";
import _ from "lodash";
import ReactPlayer from "react-player";

const ScheduleFreeConsultation = () => {
  const [showVideo, setShowVideo] = useState(false);
  const [showVideoControls, setShowVideoControls] = useState(false);
  const { width, height } = useWindowDimensions();
  const [videoWidth, setVideoWidth] = useState(640);
  const [videoHeight, setVideoHeight] = useState(360);
  const [playing, setPlaying] = useState(false);
  const history = useHistory();
  const player = useRef(null);

  useEffect(() => {
    const userVideo = JSON.parse(localStorage.getItem(USER_COMPLETE_VIDEO));
    const userSurvey = JSON.parse(localStorage.getItem(USER_COMPLETE_SURVEY));

    console.log("userVideo", userVideo);
    console.log("userSurvey", userSurvey);
    // console.log('userSurvey', userSurvey[1].fields[0].options[0].option === "1")

    setShowVideo(userVideo === null);
  }, []);

  useEffect(() => {
    let newWidth = Math.round(width * 0.8);
    let newHeight = Math.round(newWidth / 1.777778);
    if (newHeight > height * 0.9) {
      newHeight = Math.round(height * 0.8);
      newWidth = Math.round(newHeight * 1.777778);
    }

    setVideoHeight(newHeight);
    setVideoWidth(newWidth);
  }, [width, height]);

  const handleScheduleNow = () => {
    history.push("/onboarding/telemedicine/free");
  };

  const handlePlay = () => {
    setPlaying(true);
  };

  const handleEnd = () => {
    setShowVideo(false);
    localStorage.setItem(USER_COMPLETE_VIDEO, true);
  };

  const handleOpenVideo = () => {
    if (player && player.current) {
      player.current.seekTo(0);
    }
    setPlaying(true);
    setShowVideo(true);
    setShowVideoControls(true);
  };

  const handleCloseVideo = () => {
    setPlaying(false);
    setTimeout(() => {
      setShowVideo(false);
      setShowVideoControls(false);
    }, 50);
  };

  return (
    <Section>
      <div className="my-0 md:my-9 max-w-7xl mx-auto flex flex-col md:flex-row space-x-0 md:space-x-32 px-0 md:px-8">
        <div>
          <div
            className="relative mb-4"
            onClick={handleOpenVideo}
            style={{ cursor: "pointer" }}
          >
            <img src={videoFreeConsultation} alt="bedroom" />
            <img
              src={playCircle}
              alt="play button"
              className="absolute top-1/2 left-1/2 -mt-8 -ml-8 h-16 w-16"
            />
          </div>
          <p className="font-body text-base text-secondary-text leading-normal mx-2">
            Watch this short video to learn about the next steps for receiving a
            home sleep test.
          </p>
        </div>
        <div className="mt-14 md:mt-0 max-w-lg">
          <h2 className="text-renuma-violet-primary text-lg mb-2">NEXT STEP</h2>
          <h3 className="text-xl2 md:text-3xl font-bold leading-snug pb-4 mb-6 border-b border-renuma-violet-400">
            Schedule a Consultation
          </h3>
          <div className="flex flex-row justify-start mb-10 md:mb-2">
            <div className="flex-1 font-body text-base leading-normal">
              Schedule a telemedicine appointment with a sleep doctor to discuss
              the results of your sleep study. During this consultation, a sleep
              doctor will review your results, discuss treatment options with
              you, develop a plan and answer all your questions.
            </div>
          </div>
          <div className="mt-8 md:mt-6 text-2xl md:text-3xl font-body font-bold text-primary-text">
            Free
          </div>

          <div className="mt-10">
            <PrimaryButton onClick={handleScheduleNow} disabled={showVideo}>
              Schedule Now
            </PrimaryButton>
          </div>
        </div>
      </div>
      <Modal
        closable={showVideoControls}
        title={false}
        centered
        visible={showVideo}
        onCancel={showVideoControls ? handleCloseVideo : null}
        footer={false}
        width={videoWidth}
        bodyStyle={{ padding: 0, position: "relative" }}
      >
        <ReactPlayer
          ref={player}
          url="https://www.youtube-nocookie.com/embed/zO2ZejAEjmE?controls=1&autoplay=1&fs=0&iv_load_policy=3&modestbranding=1&rel=0&showinfo=0"
          height={videoHeight}
          width={videoWidth}
          playing={playing}
          controls={showVideoControls}
          onEnded={handleEnd}
        />
        {!playing && (
          <div
            className="absolute p-4 bg-white rounded"
            style={{
              left: "50%",
              top: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <div className="text-center font-body text-base text-primary-text">
              <img
                src={playCircle}
                alt="play button"
                className="h-20 w-20 mx-auto mt-14 mb-9"
                onClick={handlePlay}
              />
              Watch this short video to learn about your sleep apnea results
            </div>
          </div>
        )}
      </Modal>
    </Section>
  );
};

export default ScheduleFreeConsultation;
