import axios from "axios";
import WooCommerceRestApi from "@woocommerce/woocommerce-rest-api";

const WooCommerce = new WooCommerceRestApi({
  url:
    process.env.NODE_ENV === "production"
      ? "https://wprenuma.liricus.com.ar/"
      : "https://wprenuma.liricus.com.ar/",
  consumerKey:
    process.env.NODE_ENV === "production"
      ? "ck_c81ebb88ea3cf404389eb6c14ed691929952b987"
      : "ck_c81ebb88ea3cf404389eb6c14ed691929952b987",
  consumerSecret:
    process.env.NODE_ENV === "production"
      ? "cs_fb9bfb26969c2aa65f6eb19202dc84ffda2a5a4d"
      : "cs_fb9bfb26969c2aa65f6eb19202dc84ffda2a5a4d",
  version: "wc/v3",
  axiosConfig: {
    headers: {},
  },
});

const MEDUSA = "https://medusa.renumasleep.com";

export const HOSTNAME =
  process.env.NODE_ENV === "production"
    ? "https://d404arz0bl.execute-api.us-east-1.amazonaws.com/Prod"
    : "https://d404arz0bl.execute-api.us-east-1.amazonaws.com/Prod";
const toTitleCase = (str) => {
  if (typeof str !== "string") return str;
  return str.replace(
    /\w{1}/g,
    (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  );
};
const parseError = (error) => {
  return Object.entries(error)
    .map(([key, value]) => {
      if (key === "error") {
        return null;
      }
      if (key === "non_field_errors") {
        return value.join(" ");
      } else if (Array.isArray(value)) {
        return `${toTitleCase(key)}: ${value
          .map((txt) => toTitleCase(txt))
          .join(" ")}`;
      } else {
        return value;
      }
    })
    .join(" ");
};
export const SendEmail = async (data) => {
  const response = await axios.post(`${HOSTNAME}/contact-us`, data);
  if (response.status === 200) {
    return response.data;
  } else {
    throw new Error(parseError(response.data));
  }
};
export const SendNudgeEmail = async (data) => {
  const response = await axios.post(`${HOSTNAME}/nudge-someone`, data);
  if (response.status === 200) {
    return response.data;
  } else {
    throw new Error(parseError(response.data));
  }
};
export const signup = async ({
  email,
  full_name,
  date_of_birth,
  zip_code,
  no_insurance_plan,
  nudge
}) => {
  const response = await axios.post(`${HOSTNAME}/register/`, {
    email,
    full_name,
    date_of_birth,
    zip_code,
    no_insurance_plan,
    nudge
  });
  if (response.status === 201 && response.data) {
    // localStorage.setItem('token', response.data.token)
    return response.data;
  } else {
    console.error(response.data);
    throw new Error(parseError(response.data));
  }
};
export const getProfile = async () => {
  const response = await axios.get(`${HOSTNAME}/profile/`);
  if (response.status === 200 && response.data) {
    return response.data;
  } else {
    console.error(response.data);
    throw new Error(parseError(response.data));
  }
};
export const getNextBilling = async () => {
  const response = await axios.get(`${HOSTNAME}/next-billing/`);
  if (response.status === 200 && response.data) {
    return response.data;
  } else {
    throw new Error(parseError(response.data));
  }
};
export const unsuscribeRenumaPlus = async () => {
  const response = await axios.put(`${HOSTNAME}/unsubscribe-renuma-plus/`);
  if (response.status === 200 && response.data) {
    return response.data;
  } else {
    throw new Error(parseError(response.data));
  }
};
export const updateProfile = async ({
  avatar,
  email,
  full_name,
  date_of_birth,
  zip_code,
  no_insurance_plan,
}) => {
  const response = await axios.put(`${HOSTNAME}/profile/`, {
    avatar,
    email,
    full_name,
    date_of_birth,
    zip_code,
    no_insurance_plan,
  });
  if (response.status === 200 && response.data) {
    return response.data;
  } else {
    console.error(response.data);
    throw new Error(parseError(response.data));
  }
};
export const getProfessional = async () => {
  const response = await axios.get(`${HOSTNAME}/professional/`);
  if (response.status === 200 && response.data) {
    return response.data;
  } else {
    console.error(response.data);
    throw new Error(parseError(response.data));
  }
};
export const getSchedule = async (year, month, day, email) => {
  let url = `${HOSTNAME}/schedule?year=${year}&month=${month}&email=${email}`;
  if (day !== null) {
    url += `&today=${day}`;
  }
  const response = await axios.get(url);
  if (response.status === 200 && response.data) {
    return response.data;
  } else {
    console.error(response.data);
    throw new Error(parseError(response.data));
  }
};
export const saveSchedule = async (data) => {
  const response = await axios.post(`${HOSTNAME}/schedule`, data);
  if (response.status === 201) {
    return response.data;
  } else {
    throw new Error(parseError(response.data));
  }
};
export const updateSchedule = async (data) => {
  const response = await axios.put(`${HOSTNAME}/schedule`, data);
  if (response.status === 200) {
    return response.data;
  } else {
    throw new Error(parseError(response.data));
  }
};
export const cancelSchedule = async (data) => {
  const response = await axios.delete(`${HOSTNAME}/schedule?id=${data.id}`, {
    data: data,
  });
  if (response.status === 204) {
    return response.data;
  } else {
    throw new Error(parseError(response.data));
  }
};

export const getCollection = async (id) => {
  const response = await axios.get(
    id == null
      ? `${MEDUSA}/admin/collections/`
      : `${MEDUSA}/admin/collections/${id}`
  );
  if (response.status === 200) {
    return id === null ? response.data.collections : response.data.collection;
  } else {
    throw new Error(parseError(response.data));
  }
};

export const getProductsByCollection = async (id, per_page = 10) => {
  if (id === null) throw new Error("Id parameter is required.");

  const response = await WooCommerce.get(
    `products?category=${id}&per_page=${per_page}`
  );

  if (response.status === 200) {
    return response.data;
  } else {
    throw new Error(parseError(response.data));
  }
};

export const getProduct = async (id) => {
  const response = await WooCommerce.get(
    id == null ? `products/` : `products/${id}`
  );

  if (response.status === 200) {
    return id == null ? response.data : response.data;
  } else {
    throw new Error(parseError(response.data));
  }
};

export const getTags = async (id) => {
  const response = await WooCommerce.get(
    id == null ? `products/tags?per_page=50` : `products/tags/${id}`
  );

  if (response.status === 200) {
    return id == null ? response.data : response.data;
  } else {
    throw new Error(parseError(response.data));
  }
};

export const getOrder = async (id) => {
  const response = await axios.get(`${HOSTNAME}/order?id=${id}`);
  if (response.status === 200) {
    return response.data;
  } else {
    throw new Error(parseError(response.data));
  }
};

export const onboardLegalName = async ({ firstName, lastName }) => {
  const response = await axios.post(
    `${HOSTNAME}/accounts/onboarding/legal_name/`,
    { first_name: firstName, last_name: lastName }
  );
  if (response.status === 200 && response.data) {
    return response.data;
  } else {
    console.error(response.data);
    throw new Error(parseError(response.data));
  }
};
export const onboardBirthday = async ({ dob }) => {
  const response = await axios.post(
    `${HOSTNAME}/accounts/onboarding/birthday/`,
    { birthday: dob }
  );
  if (response.status === 200 && response.data) {
    return response.data;
  } else {
    console.error(response.data);
    throw new Error(parseError(response.data));
  }
};
export const getCurrentAccount = async () => {
  const response = await axios.get(`${HOSTNAME}/accounts/`);
  if (response.status === 200 && response.data) {
    return response.data;
  } else {
    console.error(response.data);
    throw new Error(parseError(response.data));
  }
};
export var TreatmentType;
(function (TreatmentType) {
  TreatmentType["Plan"] = "PLAN";
  TreatmentType["Consult"] = "CONSULT";
})(TreatmentType || (TreatmentType = {}));
export const onboardTreatmentType = async ({ treatmentType }) => {
  const response = await axios.post(
    `${HOSTNAME}/accounts/onboarding/treatment_type/`,
    { initial_treatment_type: treatmentType }
  );
  if (response.status === 200 && response.data) {
    return response.data;
  } else {
    console.error(response.data);
    throw new Error(parseError(response.data));
  }
};
export const onboardAddress = async ({
  streetAddress,
  secondaryAddress,
  city,
  state,
  zipcode,
}) => {
  const response = await axios.post(
    `${HOSTNAME}/accounts/onboarding/address/`,
    {
      street_address: streetAddress,
      secondary_address: secondaryAddress,
      city,
      state,
      zipcode,
    }
  );
  if (response.status === 200 && response.data) {
    return response.data;
  } else {
    console.error(response.data);
    throw new Error(parseError(response.data));
  }
};
export const onboardPayment = async ({ paymentMethodId }) => {
  const response = await axios.post(
    `${HOSTNAME}/accounts/onboarding/payment/`,
    { payment_method_id: paymentMethodId }
  );
  if (response.status === 200 && response.data) {
    return response.data;
  } else {
    console.error(response.data);
    throw new Error(parseError(response.data));
  }
};
export const finishOnboarding = async () => {
  const response = await axios.post(`${HOSTNAME}/accounts/onboarding/finish/`);
  if (response.status === 200 && response.data) {
    return response.data;
  } else {
    console.error(response.data);
    throw new Error(parseError(response.data));
  }
};
var OrderTypeVariant;
(function (OrderTypeVariant) {
  OrderTypeVariant["AHT"] = "At-home test";
  OrderTypeVariant["CPAP"] = "CPAP Machine";
  OrderTypeVariant["PARTS"] = "CPAP Supplies";
})(OrderTypeVariant || (OrderTypeVariant = {}));
export const getOrderTypes = async () => {
  const response = await axios.get(`${HOSTNAME}/orders/order-types/`);
  if (response.status === 200 && response.data) {
    return response.data;
  } else {
    console.error(response.data);
    throw new Error(parseError(response.data));
  }
};
export const getOrders = async () => {
  const response = await axios.get(`${HOSTNAME}/order/`);
  if (response.status === 200 && response.data) {
    return response.data.Items;
  } else {
    console.error(response.data);
    // throw new Error(parseError(response.data));
    return response.data;
  }
};
export const createOrder = async ({ orderTypeId }) => {
  const response = await axios.post(`${HOSTNAME}/order/`, {
    order_type: orderTypeId,
  });
  if (response.status === 201 && response.data) {
    return response.data.Items;
  } else {
    console.error(response.data);
    throw new Error(parseError(response.data));
  }
};
export const saveOrder = async ({
  order_type, // "home_sleep_test"
  street_address,
  apt_suite,
  city,
  state_province,
  zipcode,
  appointment,
  subtotal,
  products,
  payment_method,
  quotes_cpap_machine,
  quotes_mask,
}) => {
  const response = await axios.post(`${HOSTNAME}/order/`, {
    order_type,
    street_address,
    apt_suite,
    city,
    state_province,
    zipcode,
    appointment,
    subtotal,
    products,
    payment_method,
    quotes_cpap_machine,
    quotes_mask,
  });

  return response;
};
export const getSurveysAnswer = async () => {
  const response = await axios.get(`${HOSTNAME}/survey-answers`);
  if (response.status === 200 && response.data) {
    return response.data.Items;
  } else {
    console.error(response.data);
    throw new Error(parseError(response.data));
  }
};
export const getSurveys = async () => {
  const response = await axios.get(`${HOSTNAME}/survey`);
  if (response.status === 200 && response.data) {
    return response.data.Items;
  } else {
    console.error(response.data);
    throw new Error(parseError(response.data));
  }
};
export const getSurvey = async (id) => {
  const response = await axios.get(`${HOSTNAME}/survey?id=${id}`);
  if (response.status === 200 && response.data) {
    return response.data;
  } else {
    console.error(response.data);
    throw new Error(parseError(response.data));
  }
};
export const saveSurvey = async (data) => {
  const response = await axios.post(`${HOSTNAME}/survey/`, data);
  console.log("status", response.status);
  if (response.status === 201 && response.data) {
    return response.data;
  } else {
    console.error(response.data);
    throw new Error(parseError(response.data));
  }
};
export const saveSurveyAnswers = async (data) => {
  const response = await axios.post(`${HOSTNAME}/survey-answers/`, data);
  if (response.status === 201 && response.data) {
    return response.data;
  } else {
    console.error(response.data);
    throw new Error(parseError(response.data));
  }
};
export const renderDownloadUrl = (uuid) =>
  `${HOSTNAME}/results/download/${uuid}`;
export const getResults = async () => {
  const response = await axios.get(`${HOSTNAME}/result/`);
  if (response.status === 200 && response.data) {
    return response.data;
  } else {
    console.error(response.data);
    throw new Error(parseError(response.data));
  }
};
export const getResult = async ({ resultId }) => {
  const response = await axios.get(`${HOSTNAME}/results/${resultId}/`);
  if (response.status === 200 && response.data) {
    return response.data;
  } else {
    console.error(response.data);
    throw new Error(parseError(response.data));
  }
};
export const addComment = async ({ resultId, text }) => {
  const response = await axios.post(
    `${HOSTNAME}/results/${resultId}/comment/`,
    { text }
  );
  if (response.status === 201 && response.data) {
    return response.data;
  } else {
    console.error(response.data);
    throw new Error(parseError(response.data));
  }
};
export const getAppointments = async () => {
  const response = await axios.get(`${HOSTNAME}/appointments/`);
  if (response.status === 200 && response.data) {
    return response.data.Items;
  } else {
    console.error(response.data);
    // throw new Error(parseError(response.data));
    return response.data;
  }
};
export const lookupAppointmentsByUUID = async ({ uuid }) => {
  const response = await axios.get(
    `${HOSTNAME}/appointments/uuid_lookup?uuid=${uuid}`
  );
  if (response.status === 200 && response.data) {
    return response.data;
  } else {
    console.error(response.data);
    throw new Error(parseError(response.data));
  }
};
export const changePassword = async ({ currentPassword, newPassword }) => {
  const response = await axios.post(`${HOSTNAME}/accounts/change-password/`, {
    current_password: currentPassword,
    new_password: newPassword,
  });
  if (response.status === 204) {
    return null;
  } else {
    console.error(response.data);
    throw new Error(parseError(response.data));
  }
};
export const getShipping = async () => {
  const response = await axios.get(`${HOSTNAME}/shipping/`);
  if (response.status === 200 && response.data) {
    return response.data;
  } else {
    console.error(response.data);
    throw new Error(parseError(response.data));
  }
};
export const updateShipping = async ({
  street_address,
  apt_suite,
  city,
  state_province,
  zipcode,
}) => {
  const response = await axios.put(`${HOSTNAME}/shipping/`, {
    street_address,
    apt_suite,
    city,
    state_province,
    zipcode,
  });
  if (response.status === 204) {
    return response.data;
  } else {
    console.error(response.data);
    throw new Error(parseError(response.data));
  }
};
export const getBillingAddress = async () => {
  const response = await axios.get(`${HOSTNAME}/billing-address`);
  if (response.status === 200 && response.data) {
    return response.data;
  } else {
    console.error(response.data);
    throw new Error(parseError(response.data));
  }
};
export const updateBillingAddress = async ({
  street_address,
  apt_suite,
  city,
  state_province,
  zip_code,
}) => {
  const response = await axios.put(`${HOSTNAME}/billing-address`, {
    street_address,
    apt_suite,
    city,
    state_province,
    zip_code,
  });
  if (response.status === 204) {
    return response.data;
  } else {
    console.error(response.data);
    throw new Error(parseError(response.data));
  }
};
export const getPaymentMethods = async () => {
  const response = await axios.get(`${HOSTNAME}/accounts/payment-methods/`);
  if (response.status === 200 && response.data) {
    return response.data;
  } else {
    console.error(response.data);
    throw new Error(parseError(response.data));
  }
};
export const removePaymentMethod = async ({ paymentMethodId }) => {
  const response = await axios.delete(
    `${HOSTNAME}/accounts/payment-methods/${paymentMethodId}/`
  );
  if (response.status === 204) {
    return null;
  } else {
    console.error(response.data);
    throw new Error(parseError(response.data));
  }
};
export const makePaymentMethodDefault = async ({ paymentMethodId }) => {
  const response = await axios.post(
    `${HOSTNAME}/accounts/payment-methods/${paymentMethodId}/make_default/`
  );
  if (response.status === 204) {
    return null;
  } else {
    console.error(response.data);
    throw new Error(parseError(response.data));
  }
};
export const addPaymentMethod = async ({ paymentMethodId }) => {
  const response = await axios.post(`${HOSTNAME}/accounts/payment-methods/`, {
    payment_method_id: paymentMethodId,
  });
  if (response.status === 201) {
    return null;
  } else {
    console.error(response.data);
    throw new Error(parseError(response.data));
  }
};
export const updatePaymentMethod = async ({
  credit_card_number,
  expiration,
  cvc,
}) => {
  const response = await axios.put(`${HOSTNAME}/payment-methods/`, {
    credit_card_number,
    expiration,
    cvc,
  });
  if (response.status === 201) {
    return null;
  } else {
    console.error(response.data);
    throw new Error(parseError(response.data));
  }
};
export const getCharges = async () => {
  const response = await axios.get(`${HOSTNAME}/billing/charges/`);
  if (response.status === 200 && response.data) {
    return response.data;
  } else {
    console.error(response.data);
    throw new Error(parseError(response.data));
  }
};
export const getSubscriptions = async () => {
  const response = await axios.get(`${HOSTNAME}/billing/subscriptions/`);
  if (response.status === 200 && response.data) {
    return response.data;
  } else {
    console.error(response.data);
    throw new Error(parseError(response.data));
  }
};
export const startSubscription = async () => {
  const response = await axios.post(`${HOSTNAME}/billing/subscriptions/`);
  if (response.status === 201 && response.data) {
    return response.data;
  } else {
    console.error(response.data);
    throw new Error(parseError(response.data));
  }
};
export const cancelSubscription = async ({ subscriptionId }) => {
  const response = await axios.delete(
    `${HOSTNAME}/billing/subscriptions/${subscriptionId}/`
  );
  if (response.status === 204) {
    return null;
  } else {
    console.error(response.data);
    throw new Error(parseError(response.data));
  }
};
export const getUserState = async () => {
  const response = await axios.get(`${HOSTNAME}/user-state`);
  if (response.status === 200 && response.data) {
    return response.data;
  } else {
    throw new Error(parseError(response.data));
  }
};
export const getPatientStatus = async (page = 1) => {
  const response = await axios.get(`${HOSTNAME}/patient-status?page=${page}`);
  if (response !== undefined && response.status === 200 && response.data) {
    return response.data;
  } else {
    throw new Error(parseError(response.data));
  }
};
export const getPatientGraphic = async (units) => {
  const response = await axios.get(
    `${HOSTNAME}/patient-graphic?units=${units}`
  );
  if (response.status === 200 && response.data) {
    return response.data;
  } else {
    throw new Error(parseError(response.data));
  }
};
export const getPatientProfile = async (email) => {
  const response = await axios.get(
    `${HOSTNAME}/patient-profile?patient=${email}`
  );
  if (response.status === 200 && response.data) {
    return response.data;
  } else {
    console.error(response.data);
    throw new Error(parseError(response.data));
  }
};
export const cancelAccount = async ({ message }) => {
  const response = await axios.post(`${HOSTNAME}/cancel-account`, {
    cancellation_reason: message,
  });
  if (response.status === 200 && response.data) {
    return response.data;
  } else {
    throw new Error(parseError(response.data));
  }
};
export const getPatientCancelationRequests = async (email) => {
  let params = "";
  if (email !== undefined) {
    params = `?patient=${email}`;
  }
  const response = await axios.get(`${HOSTNAME}/cancelation-requests${params}`);
  if (response.status === 200 && response.data) {
    return response.data;
  } else {
    throw new Error(parseError(response.data));
  }
};
export const deleteAccount = async (email) => {
  const response = await axios.delete(
    `${HOSTNAME}/delete-account?patient=${email}`
  );
  if (response.status === 200 && response.data) {
    return response.data;
  } else {
    throw new Error(parseError(response.data));
  }
};
export const getPatientSurveyData = async (email) => {
  const response = await axios.get(
    `${HOSTNAME}/patient-survey-data?patient=${email}`
  );
  return response;
};
export const getPatientFiles = async (email) => {
  const response = await axios.get(
    `${HOSTNAME}/patient-files?patient=${email}`
  );
  if (response.status === 200 && response.data) {
    return response.data;
  } else {
    console.error(response.data);
    throw new Error(parseError(response.data));
  }
};
export const getPatientOrders = async (email) => {
  const response = await axios.get(
    `${HOSTNAME}/patient-orders?patient=${email}`
  );
  if (response.status === 200 && response.data) {
    return response.data;
  } else {
    console.error(response.data);
    // throw new Error(parseError(response.data));
    return response.data;
  }
};
export const setPatientOsa = async (data) => {
  const response = await axios.post(`${HOSTNAME}/patient-osa`, {
    patient: data.email,
    osa: data.osa,
  });
  if (response.status === 200 && response.data) {
    return response.data;
  } else {
    console.error(response.data);
    throw new Error(parseError(response.data));
  }
};
export const addDocument = async ({
  patient,
  reason,
  title,
  file,
  extension,
}) => {
  let data = {
    patient,
    reason,
    title,
    file,
    extension,
  };
  const response = await axios.post(`${HOSTNAME}/upload-file`, data);
  if (response.status === 201 && response.data) {
    return response.data;
  } else {
    console.error(response.data);
    throw new Error(parseError(response.data));
  }
};

export const getStripeSetting = async () => {
  const response = await axios.get(`${HOSTNAME}/stripe-setting`);

  if (response && response.status === 200 && response.data) {
    return response.data;
  } else {
    console.error(response.data);
    throw new Error(parseError(response.data));
  }
};

export const getStripeCreditCard = async () => {
  const response = await axios.get(`${HOSTNAME}/stripe-service`);
  if (response.status === 200 && response.data) {
    return response.data;
  } else {
    console.error(response.data);
    throw new Error(parseError(response.data));
  }
};

export const saveStripeCreditCard = async ({
  credit_card_number,
  expiration,
  cvc,
}) => {
  const response = await axios.post(`${HOSTNAME}/stripe-service`, {
    credit_card_number,
    expiration,
    cvc,
  });
  if (response.status === 201 && response.data) {
    return response.data;
  } else {
    console.error(response.data);
    throw new Error(response.data.message);
  }
};

export const updateStripeCreditCard = async ({ payment_method }) => {
  const response = await axios.put(`${HOSTNAME}/stripe-service`, {
    payment_method,
  });

  if (response.status === 200 && response.data) {
    return response.data;
  } else {
    throw new Error(
      response.data.message ? response.data.message : response.data.error_msg
    );
  }
};

export const deleteStripeCreditCard = async () => {
  const response = await axios.delete(`${HOSTNAME}/stripe-service`);

  if (response.status === 200 && response.data) {
    return response.data;
  } else {
    throw new Error(response.data.message);
  }
};
