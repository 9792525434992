import React, { useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import {
  updateStripeCreditCard,
  getStripeSetting,
  getStripeCreditCard,
  deleteStripeCreditCard,
} from "../../APIClient";
import { CreditCards } from "../../assets/svgs/credit-cards";
import PrimaryButton from "../PrimaryButton";
import { Alert, Modal } from "antd";

const stripePromise = loadStripe(
  "pk_live_51HPT8jDPHJM074BNKecdxTNd8Uefoo3JEqxwGWbSWTmJkscN9I3QDxl8dzJcICOZukA6b1KFqONK7pJqNz7qIXvQ00QKkCEj8I"
);

const CheckoutForm = ({ onApproved }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [values, setValues] = useState({
    credit_card_approved: false,
    credit_card_status: "add",
    credit_card_last_four: "",
    credit_card_type: "",
  });
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [cardError, setCardError] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [cardStripeComplete, setCardStripeComplete] = useState(false);
  
  useEffect(() => {
    getCreditCard();
  }, []);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }
  }, [stripe]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log(
      "elements.getElement(CardElement)",
      elements.getElement(CardElement)
    );

    if (elements == null) {
      return;
    }

    try {
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardElement),
      });
      console.log(paymentMethod);
      if (error) {
        console.log("stripe error", error);
      } else {
        console.log("payment was successfully");
        saveCreditCard(paymentMethod);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const saveCreditCard = async (paymentMethod) => {
    setSaving(true);
    setCardError("");
    setValues((v) => ({
      ...v,
      credit_card_approved: false,
      credit_card_last_four: "",
      credit_card_type: "",
    }));
    try {
      const data = {
        payment_method: paymentMethod.id,
      };
      const res = await updateStripeCreditCard(data);
      /* const res = await new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve({ data: {}, status: 200 });
        }, 1000);
      }); */
      setValues((v) => ({
        ...v,
        credit_card_approved: true,
        credit_card_status: "added",
        credit_card_last_four: paymentMethod.card.last4,
        credit_card_type: paymentMethod.card.brand.toLowerCase(),
      }));
      if (onApproved) {
        onApproved(true, {
          credit_card_last_four: res.last4,
          credit_card_type: res.brand.toLowerCase(),
        });
      }
    } catch (error) {
      //console.log("Error .....:", error);
      if (error) {
        setCardError(error?.message ? error?.message : "Internal error");
      } else {
        setCardError(error?.message ? error?.message : "Internal error");
      }
      if (onApproved) {
        onApproved(false, {
          credit_card_last_four: values.credit_card_last_four,
          credit_card_type: values.credit_card_type,
        });
      }
    } finally {
      setSaving(false);
    }
  };

  const getCreditCard = async () => {
    setLoading(true);
    try {
      const res = await getStripeCreditCard();

      setValues((v) => ({
        ...v,
        credit_card_status: "saved",
        credit_card_last_four: res.last4,
        credit_card_type: res.brand.toLowerCase(),
      }));
      if (onApproved) {
        onApproved(true, {
          credit_card_last_four: res.last4,
          credit_card_type: res.brand.toLowerCase(),
        });
      }
    } catch (error) {
      console.log(error);
      if (onApproved) {
        onApproved(false, {
          credit_card_last_four: "",
          credit_card_type: "",
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const deleteCreditCard = async () => {
    setSaving(true);
    try {
      const res = await deleteStripeCreditCard();

      setValues((v) => ({
        ...v,
        credit_card_status: "add",
        credit_card_last_four: "",
        credit_card_type: "",
      }));
      if (onApproved) {
        onApproved(false, {
          credit_card_last_four: "",
          credit_card_type: "",
        });
      }
    } catch (error) {
      console.log(error);
      if (onApproved) {
        onApproved(false, {
          credit_card_last_four: "",
          credit_card_type: "",
        });
      }
      setCardError(error.message);
    } finally {
      setSaving(false);
    }
  };

  const handleEditCard = () => {
    setValues((v) => ({
      ...v,
      credit_card_status: "add",
    }));
  };

  const openConfirm = () => {
    setShowDeleteModal(true);
    setCardError("");
  };

  const confirmOk = () => {
    setShowDeleteModal(false);
    deleteCreditCard();
  };

  const confirmCancel = () => {
    setShowDeleteModal(false);
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        {!loading &&
          !saving &&
          values.credit_card_last_four !== "" &&
          (values.credit_card_status === "saved" ||
            values.credit_card_status === "edited" ||
            values.credit_card_status === "added") && (
            <div className="flex flex-row space-x-6">
              <img src={CreditCards[values.credit_card_type]} />{" "}
              <span>*******{values.credit_card_last_four}</span>
              <PrimaryButton
                type="link_submit"
                size="small"
                onClick={handleEditCard}
              >
                Edit
              </PrimaryButton>
              <PrimaryButton
                type="link_submit"
                size="small"
                onClick={() => openConfirm()}
              >
                <span style={{ color: "red" }}>Delete</span>
              </PrimaryButton>
            </div>
          )}
        {values.credit_card_status === "add" && (
          <>
            {!loading && (
              <CardElement
                onChange={(e) => {
                  console.log(e)
                  console.log(e.complete)
                  setCardStripeComplete(e.complete)
                }}
                options={{
                  hidePostalCode: true,
                }}
              />
            )}
            {!loading && !saving && (
              <div className="text-right py-4">
                <PrimaryButton
                  //type="link_submit"
                  size="small"
                  disabled={(!stripe || !elements) || !cardStripeComplete}
                >
                  Save card
                </PrimaryButton>
              </div>
            )}
          </>
        )}
        {(loading || saving) && <div>Loading...</div>}

        {cardError && (
          <div className="my-4">
            <Alert
              message={cardError}
              type="error"
              className="mb-6"
              closable
              onClose={() => setCardError("")}
            />
          </div>
        )}
      </form>
      <Modal
        title="Delete credit card"
        visible={showDeleteModal}
        onOk={confirmOk}
        onCancel={confirmCancel}
      >
        <p className="text-sm">Are you sure to delete credit card?</p>
      </Modal>
    </>
  );
};

const CreditCardInput = ({ onApproved }) => {
  const appearance = {
    theme: "night",
  };

  const options = {
    appearance,
  };

  return (
    <div style={{ marginBottom: 24 }}>
      <Elements stripe={stripePromise} options={options}>
        <CheckoutForm onApproved={onApproved} />
      </Elements>
    </div>
  );
};

export default CreditCardInput;
