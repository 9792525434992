import React, { useState } from "react";
import { Card } from "antd";
import { MinusCircleFilled, PlusCircleFilled } from "@ant-design/icons";

const Panel = ({
  title,
  borderHeader,
  collapsed = true,
  highlighted,
  children,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(collapsed);

  const toggleCard = () => {
    setIsCollapsed((v) => !v);
  };
  return (
    <Card
      className={`card-dashboard  card-body-1 rounded-md drop-shadow w-full mb-6 ${
        highlighted ? "bg-renuma-violet-400" : ""
      }`}
      title={
        <div
          className={`flex flex-row justify-between items-center ${
            isCollapsed || borderHeader === false
              ? ""
              : "border-b border-gray-200 pb-4"
          }`}
          onClick={toggleCard}
        >
          <div>{title}</div>
          <div>
            <div>
              {!isCollapsed ? (
                <MinusCircleFilled className="text-4xl text-renuma-violet-primary cursor-pointer" />
              ) : (
                <PlusCircleFilled className="text-4xl text-renuma-violet-primary cursor-pointer" />
              )}
            </div>
          </div>
        </div>
      }
    >
      {!isCollapsed && <>{children}</>}
    </Card>
  );
};
export default Panel;
