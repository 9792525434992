import axios from "axios";
import { HOSTNAME } from "../APIClient";

const REFRESH_TOKEN_URL = HOSTNAME + "/refresh-token";
const LOCAL_STORAGE_REFRESH_TOKEN = Object.keys(localStorage).filter((i) =>
  i.match(/refreshToken/)
)[0];

export const refreshToken = async (error) => {
  return new Promise((resolve, reject) => {
    try {
      const refresh_token = localStorage.getItem(LOCAL_STORAGE_REFRESH_TOKEN);
      const header = {
        "Content-Type": "application/json",
        Authorization: process.env.AUTHORIZATION,
      };
      const parameters = {
        method: "POST",
        headers: header,
      };
      const body = {
        grant_type: "refresh_token",
        refresh_token,
      };
      axios
        .post(REFRESH_TOKEN_URL, body, parameters)
        .then(async (res) => {
          localStorage.setItem(
            "accessToken",
            res.data.AuthenticationResult.AccessToken
          );

          // window.location.reload();
          return resolve(res);
        })
        .catch((err) => {
          return reject(error);
        });
    } catch (err) {
      return reject(err);
    }
  });
};
