import React, { useEffect, useState } from "react";
import { Breadcrumb } from "antd";
import { getResults } from "../../APIClient";
import LoadingCard from "../../components/LoadingCard";
import PrimaryButton from "../../components/PrimaryButton";
import ResultPreview, { NoResults } from "../../components/ResultPreview";

const ResultsScreen = () => {
  const [results, setResults] = useState([]);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const loadResults = async () => {
    try {
      setIsLoading(true);
      const rs = await getResults();

      const results = rs.files.filter(
        (i) => i.reason === "home_sleep_test" || i.reason === "cpap"
      );

      setResults(results);
      setIsLoading(false);
    } catch (e) {
      setError(e.message);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    loadResults();
  }, []);
  return (
    <>
      <h3 className="text-3xl font-bold text-primary-text mt-4">Results</h3>
      {!isLoading &&
        results.length > 0 &&
        results.map((item, key) => (
          <ResultPreview key={key} result={item} collapsed={key !== 0} />
        ))}
      {!isLoading && results.length === 0 && <NoResults />}
      {isLoading && <LoadingCard />}
    </>
  );
};
export default ResultsScreen;
