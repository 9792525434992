import React from "react";
const SecondaryButton = (props) => {
  if (props.size === "small") {
    return (
      <button
        {...props}
        className={`bg-white border-2 border-renuma-violet-primary text-renuma-violet-primary px-6 py-3 text-base font-normal shadow-lg rounded-full leading-6 disabled:opacity-50 ${
          props.disabled
            ? "cursor-not-allowed"
            : "hover:bg-renuma-violet-800 hover:text-white"
        } ${props.className}`}
      ></button>
    );
  }
  return (
    <button
      {...props}
      className={`bg-white border-2 border-renuma-violet-primary text-renuma-violet-primary px-6 py-4 text-lg font-bold shadow-lg rounded-full leading-6 disabled:opacity-50 ${
        props.disabled
          ? "cursor-not-allowed"
          : "hover:bg-renuma-violet-800 hover:text-white"
      } ${props.className}`}
    ></button>
  );
};
export default SecondaryButton;
