import React, { useEffect, useRef, useState } from "react";
import Section from "../../../components/Section";
import {
  Alert,
  Steps,
  Form,
  Input,
  Spin,
  Button,
  Select,
  List,
  Avatar,
  Checkbox,
} from "antd";

import checkSmallIcon from "../../../assets/images/check_icon_small.png";
import headSeparator from "../../../assets/images/renuma-plus-price-separator.png";
import clsx from "clsx";
import SimpleReactValidator from "simple-react-validator";
import { LoadingOutlined } from "@ant-design/icons";
import PrimaryButton from "../../../components/PrimaryButton";

import { CreditCards } from "../../../assets/svgs/credit-cards";

import {
  getProduct,
  saveOrder,
  getShipping,
  getBillingAddress,
  updateBillingAddress,
} from "../../../APIClient";
import { useHistory, useParams } from "react-router";
import {
  USER_ORDER_CHECKOUT_DATA,
  USER_PROFILE,
  USER_COMPLETE_SURVEY,
  USER_COMPLETE_VIDEO,
  states,
  QUOTES_CPAP_MACHINE,
  QUOTES_MASK,
  CPAP_MACHINE_DEFAULT_ID,
  MASK_DEFAULT_ID,
  WATCHPAT_ONE_DEFAULT_ID,
} from "../../../utils";
import { getCardTypeByType } from "../../../hooks/getCardType";
import CreditCardInput from "../../../components/CreditCardInput/CreditCardInput";
import PlacesAutocompleteInput from "../../../components/PlacesAutocompleteInput";

const OnboardingOrderCheckout = () => {
  const history = useHistory();
  const { prod_1, prod_2 } = useParams();
  const [userProfile, setUserProfile] = useState({});
  const [error, setError] = useState("");
  const [errorSave, setErrorSave] = useState("");
  const [product, setProduct] = useState(null);
  const [product2, setProduct2] = useState(null);

  const [mask, setMask] = useState(null);

  const [prodType, setProdType] = useState("");
  const [values, setValues] = useState({
    billing_street_address: "",
    billing_apt_suite: "",
    billing_city: "",
    billing_state_province: "",
    billing_zipcode: "",
    street_address: "",
    apt_suite: "",
    city: "",
    state_province: "",
    zipcode: "",
    subtotal: 0,

    credit_card_approved: false,
  });
  const [sameAddress, setSameAddress] = useState(true);
  const [, forceUpdate] = useState();
  const simpleValidator = useRef(
    new SimpleReactValidator({
      autoForceUpdate: { forceUpdate: forceUpdate },
      element: (message) => (
        <div className="rnm-input-error text-xs">{message}</div>
      ),
      validators: {
        credit_card_number: {
          // name the rule
          message: "Card number is invalid",
          rule: (val, params, validator) => {
            return validator.helpers.testRegex(
              val,
              /^\d{4} \d{4} \d{4} \d{4}$/i
            );
          },
        },
      },
    })
  );
  const simpleValidator2 = useRef(
    new SimpleReactValidator({
      autoForceUpdate: { forceUpdate: forceUpdate },
      element: (message) => (
        <div className="rnm-input-error text-xs">{message}</div>
      ),
      validators: {
        credit_card_number: {
          // name the rule
          message: "Card number is invalid",
          rule: (val, params, validator) => {
            return validator.helpers.testRegex(
              val,
              /^\d{4} \d{4} \d{4} \d{4}$/i
            );
          },
        },
      },
    })
  );
  const [loading, setLoading] = useState(false);
  const { Step } = Steps;
  const { Option } = Select;
  const [current, setCurrent] = useState(0);
  const disabled = () => !simpleValidator.current.allValid();
  const disabled2 = () => !simpleValidator2.current.allValid();

  useEffect(() => {
    if (prod_1 !== undefined) {
      setProdType("renuma_plus");
    } else {
      setProdType("home_sleep_test");
    }
    loadAddressses();
    setUserProfile(JSON.parse(localStorage.getItem(USER_PROFILE)));
  }, []);

  useEffect(() => {
    loadProduct();
    loadDefaultMask();
  }, [prodType]);

  const loadAddressses = async () => {
    try {
      let res = await getShipping();

      let billing_res = await getBillingAddress();

      if (!res.error && res.street_address === undefined) {
        const order_sess = JSON.parse(
          localStorage.getItem(USER_ORDER_CHECKOUT_DATA)
        );
        if (order_sess) {
          res = {
            street_address: order_sess.street_address,
            apt_suite: order_sess.apt_suite,
            city: order_sess.city,
            state_province: order_sess.state_province,
            zipcode: order_sess.zipcode,
          };
          if (billing_res.street_address === "") {
            billing_res = {
              street_address: order_sess.street_address,
              apt_suite: order_sess.apt_suite,
              city: order_sess.city,
              state_province: order_sess.state_province,
              zipcode: order_sess.zip_code,
            };
          }
        } else {
          res = {
            street_address: "",
            apt_suite: "",
            city: "",
            state_province: "",
            zipcode: "",
          };
        }
      }

      setValues((v) => ({
        ...v,
        street_address: res.street_address,
        apt_suite: res.apt_suite,
        city: res.city,
        state_province: res.state_province,
        zipcode: res.zipcode,
        billing_street_address: billing_res.street_address,
        billing_apt_suite: billing_res.apt_suite,
        billing_city: billing_res.city,
        billing_state_province: billing_res.state_province,
        billing_zipcode: billing_res.zip_code,
      }));

      // zipcode is omitted because it will always have a value, which is filled in the registration form
      if (
        res.street_address === billing_res.street_address &&
        res.apt_suite === billing_res.apt_suite &&
        res.city === billing_res.city &&
        res.state_province === billing_res.state_province
      ) {
        setSameAddress(true);
      } else {
        setSameAddress(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const customDot = (dot, { status, title, index }) => (
    <div
      className={clsx(
        "h-3.5 md:h-5 w-3.5 md:w-5 -ml-0.5 md:-ml-1.5 -mt-0.5 md:-mt-1.5 rounded-full border",
        status === "wait" ? "bg-gray-200 border-gray-200" : "",
        status === "process" ? "bg-blue-200 border-renuma-violet-primary" : "",
        status === "finish"
          ? "bg-renuma-violet-primary border-renuma-violet-primary"
          : ""
      )}
    ></div>
  );

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues((v) => ({ ...v, [name]: value }));
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    simpleValidator.current.showMessageFor(name);
    setValues((v) => ({ ...v, [name]: value }));
  };
  const handleBlur2 = (e) => {
    const { name, value } = e.target;
    simpleValidator2.current.showMessageFor(name);
    setValues((v) => ({ ...v, [name]: value }));
  };

  const handleChangeAddress = (data) => {
    const { name, value } = data;
    setValues((v) => ({ ...v, [name]: value }));
  };

  const handleBlurAddress = (data) => {
    const { name } = data;
    simpleValidator.current.showMessageFor(name);
    setValues((v) => ({ ...v, [name]: values[name] }));
  };

  const handleBlurAddress2 = (data) => {
    const { name } = data;
    simpleValidator2.current.showMessageFor(name);
    setValues((v) => ({ ...v, [name]: values[name] }));
  };

  const nextStep = () => {
    if (current === 0) {
      localStorage.setItem(
        USER_ORDER_CHECKOUT_DATA,
        JSON.stringify({
          street_address: values.billing_street_address,
          apt_suite: values.billing_apt_suite,
          city: values.billing_city,
          state_province: values.billing_state_province,
          zipcode: values.billing_zipcode,
        })
      );
    }
    setCurrent((c) => c + 1);
  };

  const loadProduct = async () => {
    setLoading(true);
    if (!prodType) {
      return;
    }
    try {
      const res = await getProduct(
        prodType === "home_sleep_test"
          ? WATCHPAT_ONE_DEFAULT_ID
          : CPAP_MACHINE_DEFAULT_ID
      );
      setProduct(res);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const loadDefaultMask = async () => {
    try {
      const res = await getProduct(MASK_DEFAULT_ID);
      setMask(res);
    } catch (error) {
      console.log(error);
    }
  };

  const handleEditShipping = () => {
    setCurrent(0);
  };

  const handleEditPayment = () => {
    setCurrent(1);
  };

  const handlePlaceOrder = async () => {
    setLoading(true);
    try {
      const products = [product];
      const shipping = sameAddress
        ? {
            street_address: values.billing_street_address,
            apt_suite: values.billing_apt_suite,
            city: values.billing_city,
            state_province: values.billing_state_province,
            zipcode: values.billing_zipcode,
          }
        : {
            street_address: values.street_address,
            apt_suite: values.apt_suite,
            city: values.city,
            state_province: values.state_province,
            zipcode: values.zipcode,
          };
      const data = {
        order_type:
          prodType === "home_sleep_test" ? "Home Sleep Test" : "Renuma+",
        products,
        // ...values,
        ...shipping,
        credit_card_approved: values.credit_card_approved,
        credit_card_last_four: values.credit_card_last_four,
        credit_card_type: values.credit_card_type,
        subtotal: product.price,
        payment_method: "full",
        quotes_cpap_machine: 1,
        quotes_mask: 1,
      };
      const res = await saveOrder(data);
      const resBilling = await updateBillingAddress({
        street_address: values.billing_street_address,
        apt_suite: values.billing_apt_suite,
        city: values.billing_city,
        state_province: values.billing_state_province,
        zip_code: values.billing_zipcode,
      });
      console.log(resBilling);
      if (res.status == 201) {
        localStorage.setItem(USER_COMPLETE_SURVEY, false);
        localStorage.setItem(USER_COMPLETE_VIDEO, false);
        localStorage.setItem(
          USER_ORDER_CHECKOUT_DATA,
          JSON.stringify({
            street_address: values.billing_street_address,
            apt_suite: values.billing_apt_suite,
            city: values.billing_city,
            state_province: values.billing_state_province,
            zipcode: values.billing_zipcode,
            order_id: res.data.id,

            credit_card_type: getCardTypeByType(values.credit_card_type),
            credit_card_number: values.credit_card_last_four,
          })
        );

        if (prodType === "home_sleep_test") {
          history.push({
            pathname: "/onboarding/order_summary/",
            state: { product: product },
          });
        } else {
          history.push({
            pathname: "/onboarding/order_summary/",
            state: { product: product, product2: product2 },
          });
        }
      } else {
        setErrorSave(res.data.error_msg);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleCardApproved = (value, params) => {
    setValues((v) => ({ ...v, credit_card_approved: value, ...params }));
    console.log({ credit_card_approved: value, ...params });
  };

  const handleSameAddress = (e) => {
    console.log(!sameAddress);
    if (!sameAddress) {
      setValues((v) => ({
        ...v,
        street_address: v.billing_street_address,
        apt_suite: v.billing_apt_suite,
        city: v.billing_city,
        state_province: v.billing_state_province,
        zipcode: v.billing_zipcode,
      }));
    } else {
      setValues((v) => ({
        ...v,
        street_address: "",
        apt_suite: "",
        city: "",
        state_province: "",
        zipcode: "",
      }));
    }
    setSameAddress((v) => !v);
  };
  return (
    <Section className="bg-none md:bg-blurredBedroom bg-no-repeat bg-cover">
      <div className="flex flex-col max-w-7xl mx-auto bg-gray-100 rounded-3xl">
        {prodType === "renuma_plus" && (
          <>
            <div className="pt-12 px-12">
              <h2 className="text-xl font-semibold mb-10">
                Lets review your Renuma+ Care Plan{" "}
              </h2>
              <p className="text-lg">
                With Renuma+, you will receive a CPAP Machine and ongoing
                monitoring to make sure the treatment is working - including $15
                telemedicine consultations with a sleep doctor if needed.
              </p>
            </div>
            <div className="flex flex-row">
              <div className="hidden md:block w-2/3 py-10 px-12">
                {!product && !mask && (
                  <div className="flex flex-row justify-center py-6">
                    <Spin
                      indicator={
                        <LoadingOutlined style={{ color: "#6C009E" }} />
                      }
                    />
                  </div>
                )}
                {product && mask && (
                  <div className="border border-solid border-gray-300 bg-white">
                    <div className="w-full">
                      <div className="w-full flex flex-row header-double-price relative">
                        <div className="w-1/2 bg-secondary flex flex-row justify-center items-center">
                          <h6 className="mx-8 my-0 text-xs md:text-xl text-white font-semibold leading-normal text-center">
                            Foundation Phase:
                            <br />
                            Equipment + Monitoring
                          </h6>
                        </div>
                        <div className="w-1/2 bg-renuma-violet-primary flex flex-row justify-center items-center">
                          <h6 className="mx-8 my-0 text-xs md:text-xl text-white font-semibold leading-normal text-center">
                            Maintenance Phase:
                            <br />
                            At the end of {QUOTES_CPAP_MACHINE} months
                          </h6>
                        </div>
                        <div className="absolute w-full t-0 l-0 flex flex-row justify-center">
                          <img src={headSeparator} />
                        </div>
                      </div>
                      <div className="w-full flex flex-row">
                        <div className="w-1/2 border-r-6 border-white py-4 md:py-6 px-3 bg-secondary-light">
                          <h4 className="text-2xs md:text-base text-renuma-violet-primary font-base text-center mb-2 md:mb-6">
                            You pay
                          </h4>
                          <h5 className="text-2xl md:text-base font-body text-center mb-2 md:mb-6 leading-none">
                            <strong>${product.price}</strong>
                            <br />
                            <br />
                            OR
                            <br />
                            <br />
                            Payable in {QUOTES_CPAP_MACHINE} monthly
                            installments
                            <br />
                            of ${product.price / QUOTES_CPAP_MACHINE}.
                          </h5>

                          <img
                            src={product.images[0].src}
                            alt={product.name}
                            className="h-32 mx-auto mb-2"
                          />

                          <List
                            dataSource={[
                              {
                                title:
                                  "Cost of CPAP Machine, Face Masks, Air tubing, Air filters, humididfier in 10 monthly payments",
                              },
                              {
                                title:
                                  "Replacement supplies such as masks and hoses",
                              },
                              { title: "Supplies delivered to your door" },
                              { title: "Ongoing monitoring of treatment" },
                            ]}
                            renderItem={(item) => (
                              <List.Item className="pt-0 md:pt-3 pb-2 border-none">
                                <List.Item.Meta
                                  className="flex-row justify-start items-stretch list-small-icon"
                                  avatar={<Avatar src={checkSmallIcon} />}
                                  title={
                                    <div className="flex flex-column justify-start items-center font-body text-2xs md:text-sm leading-snug">
                                      {item.title}
                                    </div>
                                  }
                                />
                              </List.Item>
                            )}
                          />
                        </div>
                        <div className="w-1/2 border-l-6 border-white py-4 md:py-6 px-3 bg-renuma-violet-400">
                          <h4 className="text-2xs md:text-base text-renuma-violet-primary font-base text-center mb-2 md:mb-6">
                            After Foundation phase You pay
                          </h4>
                          <h5 className="text-2xl md:text-base font-body text-center mb-2 md:mb-6 leading-none">
                            <strong>${mask.price}</strong>
                            <br />
                            <br />
                            OR
                            <br />
                            <br />
                            Payable in {QUOTES_MASK} monthly installments
                            <br />
                            of ${mask.price / QUOTES_MASK}.
                          </h5>

                          <img
                            src={mask.images[0].src}
                            alt={mask.name}
                            className="h-32 mx-auto mb-2"
                          />

                          <List
                            dataSource={[
                              {
                                title:
                                  "Replacement supplies such as masks and hoses",
                              },
                              { title: "Supplies delivered to your door" },
                              { title: "Ongoing monitoring of treatment" },
                            ]}
                            renderItem={(item) => (
                              <List.Item className="pt-0 md:pt-3 pb-2 border-none">
                                <List.Item.Meta
                                  className="flex-row justify-start items-stretch list-small-icon"
                                  avatar={<Avatar src={checkSmallIcon} />}
                                  title={
                                    <div className="flex flex-column justify-start items-center font-body text-2xs md:text-sm leading-snug">
                                      {item.title}
                                    </div>
                                  }
                                />
                              </List.Item>
                            )}
                          />
                          <p className="text-sm md:text-sm text-renuma-violet-primary font-base text-left leading-tight mb-2 md:mb-3 mt-10">
                            Maintenance Phase payment
                            <br />
                            auto-renews every 6 months until cancelled.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="flex-1 py-10 px-0 md:px-12">
                <h3 className="text-lg md:text-lg text-renuma-violet-primary font-base text-left font-semibold mb-2 md:mb-3">
                  START YOUR CARE PLAN TODAY!
                </h3>
                <ul className="list-decimal pl-4">
                  <li className="my-11 text-lg md:text-lg text-renuma-violet-primary font-base text-left font-semibold">
                    Select CPAP Machine
                  </li>
                  <li className="my-11 text-lg md:text-lg text-renuma-violet-primary font-base text-left font-semibold">
                    Select Mask
                  </li>
                  <li className="my-11 text-lg md:text-lg text-renuma-violet-primary font-base text-left font-semibold">
                    Confirm Payment Option
                    <br />
                    <span className="text-base">
                      (Pay Now or Via Installments)
                    </span>
                  </li>
                  <li className="my-11 text-lg md:text-lg text-renuma-violet-primary font-base text-left font-semibold">
                    Purchase
                  </li>
                </ul>

                <PrimaryButton
                  onClick={() => {
                    history.push("/onboarding/order_renuma_plus_products");
                  }}
                  className="w-full font-semibold"
                  size="small"
                >
                  Get Started with Renuma+
                </PrimaryButton>
              </div>
            </div>
          </>
        )}
        {prodType === "home_sleep_test" && (
          <div className="flex flex-row">
            <div className="hidden md:flex flex-1 w-1/2 bg-checkout bg-cover bg-no-repeat"></div>
            <div className="flex-1 w-1/2 bg-white py-10 px-0 md:px-12">
              <Steps
                className="mt-2 -mx-6 md:mx-0"
                current={current}
                progressDot={customDot}
              >
                <Step className="flex-1" title="Shipping" />
                <Step className="flex-1" title="Payment" />
                <Step className="flex-1" title="Review Order" />
              </Steps>
              {error && (
                <Alert
                  message={error}
                  type="error"
                  className="mb-6"
                  closable
                  onClose={() => setError("")}
                />
              )}
              <div className="mt-8" style={{ minHeight: "500px" }}>
                {current === 0 && (
                  <>
                    <Form className="mt-2">
                      <h2 className="font-bold text-xl2">Billing Details</h2>
                      <div>
                        <Form.Item>
                          <div className="text-base font-body">
                            Street Address
                          </div>
                          {/*<PlacesAutocompleteInput
                            name="billing_street_address"
                            onChange={handleChangeAddress}
                            onBlur={handleBlurAddress}
                            value={values.billing_street_address || ""}
                          />*/}
                          <Input
                              name="billing_street_address"
                              value={values.billing_street_address}
                              size="large"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className="rnm-input"
                            />
                          {simpleValidator.current.message(
                            "billing_street_address",
                            values.billing_street_address,
                            "required"
                          )}
                        </Form.Item>
                        <Form.Item>
                          <div className="text-base font-body">Apt#/Suite</div>
                          <Input
                            name="billing_apt_suite"
                            value={values.billing_apt_suite}
                            size="large"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className="rnm-input"
                          />
                        </Form.Item>
                        <div className="space-x-6 flex flex-row w-full">
                          <Form.Item className="w-full">
                            <div className="text-base font-body">City</div>
                            <Input
                              name="billing_city"
                              value={values.billing_city}
                              size="large"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className="rnm-input"
                            />
                            {simpleValidator.current.message(
                              "billing_city",
                              values.billing_city,
                              "required"
                            )}
                          </Form.Item>
                          <Form.Item className="w-full">
                            <div className="text-base font-body">State</div>
                            <Select
                              onChange={(e) =>
                                handleChange({
                                  target: {
                                    name: "billing_state_province",
                                    value: e,
                                  },
                                })
                              }
                              onBlur={(e) =>
                                handleBlur({
                                  target: {
                                    name: "billing_state_province",
                                    value: values.billing_state_province,
                                  },
                                })
                              }
                              className="rnm-input"
                              size="large"
                              value={values.billing_state_province}
                              showSearch
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                              filterSort={(optionA, optionB) =>
                                optionA.children
                                  .toLowerCase()
                                  .localeCompare(optionB.children.toLowerCase())
                              }
                            >
                              {states.usa.map((item, k) => (
                                <Option key={k} value={item.code}>
                                  {item.name}
                                </Option>
                              ))}
                            </Select>
                            {simpleValidator.current.message(
                              "billing_state_province",
                              values.billing_state_province,
                              "required"
                            )}
                          </Form.Item>
                        </div>
                        <div className="w-1/2 pr-3">
                          <Form.Item>
                            <div className="text-base font-body">Zipcode</div>
                            <Input
                              name="billing_zipcode"
                              maxLength="5"
                              value={values.billing_zipcode}
                              size="large"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className="rnm-input"
                            />
                            {simpleValidator.current.message(
                              "billing_zipcode",
                              values.billing_zipcode,
                              "required"
                            )}
                          </Form.Item>
                        </div>

                        <Checkbox
                          checked={sameAddress}
                          onChange={handleSameAddress}
                          className="mt-2"
                        >
                          My shipping address is the same as my billing address
                        </Checkbox>
                      </div>
                      {!sameAddress && (
                        <div>
                          <h2 className="font-bold text-xl2 mt-8">
                            Shipping Details
                          </h2>
                          <Form.Item>
                            <div className="text-base font-body">
                              Street Address
                            </div>
                            {/*<PlacesAutocompleteInput
                              name="street_address"
                              onChange={handleChangeAddress}
                              onBlur={handleBlurAddress2}
                              value={values.street_address || ""}
                            />*/}
                            <Input
                              name="street_address"
                              value={values.street_address || ""}
                              size="large"
                              onChange={handleChange}
                              onBlur={handleBlur2}
                              className="rnm-input"
                            />
                            {simpleValidator2.current.message(
                              "street_address",
                              values.street_address,
                              "required"
                            )}
                          </Form.Item>
                          <Form.Item>
                            <div className="text-base font-body">
                              Apt#/Suite
                            </div>
                            <Input
                              name="apt_suite"
                              value={values.apt_suite}
                              size="large"
                              onChange={handleChange}
                              onBlur={handleBlur2}
                              className="rnm-input"
                            />
                          </Form.Item>
                          <div className="space-x-6 flex flex-row w-full">
                            <Form.Item className="w-full">
                              <div className="text-base font-body">City</div>
                              <Input
                                name="city"
                                value={values.city}
                                size="large"
                                onChange={handleChange}
                                onBlur={handleBlur2}
                                className="rnm-input"
                              />
                              {simpleValidator2.current.message(
                                "city",
                                values.city,
                                "required"
                              )}
                            </Form.Item>
                            <Form.Item className="w-full">
                              <div className="text-base font-body">State</div>
                              <Select
                                onChange={(e) =>
                                  handleChange({
                                    target: {
                                      name: "state_province",
                                      value: e,
                                    },
                                  })
                                }
                                onBlur={(e) =>
                                  handleBlur2({
                                    target: {
                                      name: "state_province",
                                      value: values.state_province,
                                    },
                                  })
                                }
                                className="rnm-input"
                                size="large"
                                value={values.state_province}
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                                filterSort={(optionA, optionB) =>
                                  optionA.children
                                    .toLowerCase()
                                    .localeCompare(
                                      optionB.children.toLowerCase()
                                    )
                                }
                              >
                                {states.usa.map((item, k) => (
                                  <Option key={k} value={item.code}>
                                    {item.name}
                                  </Option>
                                ))}
                              </Select>
                              {simpleValidator2.current.message(
                                "state_province",
                                values.state_province,
                                "required"
                              )}
                            </Form.Item>
                          </div>
                          <div className="w-1/2 pr-3">
                            <Form.Item>
                              <div className="text-base font-body">Zipcode</div>
                              <Input
                                name="zipcode"
                                maxLength="5"
                                value={values.zipcode}
                                size="large"
                                onChange={handleChange}
                                onBlur={handleBlur2}
                                className="rnm-input"
                              />
                              {simpleValidator2.current.message(
                                "zipcode",
                                values.zipcode,
                                "required"
                              )}
                            </Form.Item>
                          </div>
                        </div>
                      )}
                      <Form.Item className="mt-6 mb-2">
                        <PrimaryButton
                          disabled={
                            (sameAddress
                              ? disabled()
                              : disabled() || disabled2()) || loading
                          }
                          onClick={nextStep}
                          className="w-full"
                        >
                          {loading ? (
                            <Spin
                              indicator={
                                <LoadingOutlined style={{ color: "#FFFFFF" }} />
                              }
                            />
                          ) : (
                            "Next"
                          )}
                        </PrimaryButton>
                      </Form.Item>
                    </Form>
                  </>
                )}

                {current === 1 && (
                  <>
                    <h2 className="font-bold text-xl2">
                      Payment Information 1
                    </h2>
                    <CreditCardInput onApproved={handleCardApproved} />
                    <PrimaryButton
                      disabled={
                        disabled() ||
                        values.credit_card_approved === false ||
                        loading
                      }
                      onClick={nextStep}
                      className="w-full"
                    >
                      {loading ? (
                        <Spin
                          indicator={
                            <LoadingOutlined style={{ color: "#FFFFFF" }} />
                          }
                        />
                      ) : (
                        "Next"
                      )}
                    </PrimaryButton>
                  </>
                )}

                {current === 2 && (
                  <>
                    <h2 className="font-bold text-xl2">Order Summary</h2>

                    <div className="border border-gray-200 my-6"></div>

                    <div className="mt-6 flex flex-col md:flex-row">
                      <div className="w-24 mr-9">
                        <img src={product.images[0].src} alt={product.name} />
                      </div>

                      <div className="flex flex-row w-full">
                        <div className="w-48">
                          <h3 className="font-bold font-body text-base leading-snug text-primary-text">
                            {product.name}
                          </h3>
                          <p className="font-body text-base leading-normal text-secondary-text text-left mb-0">
                            Subtotal
                            <br />
                            Tax
                            <br />
                            Shipping
                            <br />
                            <strong className="text-xl2 text-renuma-violet-primary">
                              Total
                            </strong>
                          </p>
                        </div>
                        <div className="flex-1">
                          <h3 className="font-bold font-body text-base leading-snug text-white">
                            &nbsp;
                          </h3>
                          {product != null && (
                            <p className="font-body text-base leading-normal text-secondary-text text-right">
                              {`$${parseInt(product.price).toFixed(2)}`}
                              <br />
                              $0.00
                              <br />
                              $0.00
                              <br />
                              <strong className="text-xl2 text-renuma-violet-primary">
                                {`$${parseInt(product.price).toFixed(2)}`}
                              </strong>
                            </p>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="border border-gray-200 my-6"></div>

                    <div className="mt-6 flex flex-row">
                      <div className="w-24 mr-9">
                        <p className="font-body text-base leading-normal text-secondary-text text-left mb-0">
                          Shipping Details
                        </p>
                      </div>
                      <div className="flex flex-1 flex-col md:flex-row">
                        <div className="w-full md:w-60">
                          <h3 className="font-bold font-body text-base leading-snug text-primary-text text-right md:text-left">
                            {userProfile.full_name}
                          </h3>
                          <p className="font-body text-base leading-normal text-secondary-text text-right md:text-left mb-0">
                            {values.billing_street_address}
                            <br />
                            {values.billing_city},{" "}
                            {values.billing_state_province}{" "}
                            {values.billing_zipcode}
                          </p>
                        </div>
                        <div className="text-right flex-1">
                          <Button
                            size="large"
                            type="link"
                            onClick={handleEditShipping}
                            className="p-0 h-auto font-base text-renuma-violet-primary border-0 border-b border-renuma-violet-primary"
                          >
                            Edit
                          </Button>
                        </div>
                      </div>
                    </div>

                    <div className="border border-gray-200 my-6"></div>

                    <div className="mt-6 flex flex-row mb-10">
                      <div className="w-24 mr-9">
                        <p className="font-body text-base leading-normal text-secondary-text text-left mb-0">
                          Payment Information
                        </p>
                      </div>
                      <div className="flex flex-1 flex-col md:flex-row">
                        <div className="w-full md:w-60">
                          <div className="font-base font-body flex flex-row items-center flex-1 justify-end md:justify-start">
                            <img src={CreditCards[values.credit_card_type]} />
                            <div className="ml-2">
                              <span className="text-3xl leading-3 relative">
                                <span className="inline-block top-1 relative">
                                  &middot;&middot;&middot;&middot;
                                </span>
                              </span>
                              {values.credit_card_last_four}
                            </div>
                          </div>
                        </div>
                        <div className="text-right flex-1">
                          <Button
                            size="large"
                            type="link"
                            onClick={handleEditPayment}
                            className="p-0 h-auto font-base text-renuma-violet-primary border-0 border-b border-renuma-violet-primary"
                          >
                            Edit
                          </Button>
                        </div>
                      </div>
                    </div>

                    {errorSave !== "" && (
                      <>
                        <div className="border border-gray-200 mt-4 mb-8"></div>
                        <div className="text-red-500 text-center">
                          {errorSave}
                        </div>
                      </>
                    )}

                    <div className="border border-gray-200 mt-4 mb-8"></div>

                    <PrimaryButton
                      disabled={disabled() || loading}
                      onClick={handlePlaceOrder}
                      className="w-full"
                    >
                      {loading ? (
                        <Spin
                          indicator={
                            <LoadingOutlined style={{ color: "#FFFFFF" }} />
                          }
                        />
                      ) : (
                        "Place Order"
                      )}
                    </PrimaryButton>
                  </>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </Section>
  );
};

export default OnboardingOrderCheckout;
