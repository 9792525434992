import React, { useEffect, useState } from "react";
import { Card } from "antd";
import {
  getAppointments,
  getSurveysAnswer,
  getOrders,
  getResults,
  getUserState,
} from "../../APIClient";
import ResultPreview, { NoResults } from "../../components/ResultPreview";
import HomeAppointmentPreview, {
  NoAppointments,
} from "../../components/HomeAppointmentPreview";
import HomeSurveyPreview, {
  NoSurveys,
} from "../../components/HomeSurveyPreview";
import LoadingCard from "../../components/LoadingCard";
import HomeOrderPreview, { NoOrders } from "../../components/HomeOrderPreview";
import moment from "moment";
import moment_tz from "moment-timezone";
import _ from "lodash";
import PrimaryButton from "../../components/PrimaryButton";
import { ModalWarning, USER_PROFILE } from "../../utils";
import { useHistory } from "react-router-dom";

const HomeScreen = () => {
  const history = useHistory();
  const [loading, setLoading] = useState({
    userState: false,
    appointments: false,
    surveys: false,
    orders: false,
  });
  const [userProfile, setUserProfile] = useState(null);
  const [userState, setUserState] = useState(null);
  const [appointments, setAppointments] = useState([]);
  const [isLoadingAppointments, setIsLoadingAppointments] = useState(false);
  const [surveys, setSurveys] = useState([]);
  const [isLoadingSurveys, setIsLoadingSurveys] = useState(false);
  const [isMounted, setIsMounted] = useState(true);

  useEffect(() => {
    setIsMounted(true);
    setUserProfile(JSON.parse(localStorage.getItem(USER_PROFILE)));
    loadUserState();
    loadAppointments();
    loadSurveys();
    return () => {
      setIsMounted(false);
    };
  }, []);

  const loadUserState = async () => {
    setLoading((v) => ({ ...v, userState: true }));
    try {
      let state = await getUserState();
      setUserState(state);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading((v) => ({ ...v, userState: false }));
    }
  };

  const loadAppointments = async () => {
    setIsLoadingAppointments(true);
    try {
      const appts = await getAppointments();
      const dateNY = moment_tz.tz("America/New_York");
      if (isMounted) {
        setAppointments(
          _.sortBy(
            _.filter(appts, (i) =>
              moment(i.date_time).tz("America/New_York").isAfter(dateNY)
            ),
            ["date_time"]
          )
        );
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoadingAppointments(false);
    }
  };

  const loadSurveys = async () => {
    setIsLoadingSurveys(true);
    try {
      const surveys = await getSurveysAnswer();
      if (isMounted) {
        setSurveys(surveys);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoadingSurveys(false);
    }
  };

  const handleBookAppointment = () => {
    if (userState.Surveys.Completed) {
      history.push("/onboarding/telemedicine");
    } else {
      ModalWarning({
        warningText:
          "You haven't taken the survey yet, please do it first before ordering your test/scheduling an appointment",
      });
    }
  };

  return (
    <>
      <div className="flex flex-col md:flex-row space-x-0 md:space-x-6 mb-6">
        <Card className="flex-1 card-dashboard bg-dashboardHome bg-right-8-top-4 bg-no-repeat rounded-md drop-shadow mb-6 md:mb-0">
          <h3 className="text-3xl font-bold text-primary-text mt-4">
            Welcome, {userProfile !== null && userProfile.full_name}!
          </h3>
          <p className="font-body text-base text-secondary-text leading-normal mb-0">
            Welcome to your Renuma dashboard. Your dashboard is available 24/7
            for you to book virtual doctor appointments, order supplies, and
            monitor your sleep health.
          </p>
        </Card>

        <div className="w-96">
          {!isLoadingAppointments && appointments.length === 0 && (
            <NoAppointments />
          )}
          {appointments.length > 0 && (
            <HomeAppointmentPreview appointment={appointments[0]} />
          )}
          {isLoadingAppointments && (
            <LoadingCard cardClass="bg-renuma-violet-400" />
          )}
        </div>
      </div>

      <div className="flex flex-col md:flex-row space-x-0 md:space-x-6 mb-6">
        <div className="flex-1">
          {!isLoadingSurveys && surveys.length === 0 && <NoSurveys />}
          {surveys.length > 0 && (
            <HomeSurveyPreview userState={userState} surveys={surveys} />
          )}
          {isLoadingSurveys && <LoadingCard />}
        </div>

        <div className="w-full md:w-3/5">
          {userState !== null && userState.Orders.Home_Test === false && (
            <NoOrders userState={userState} />
          )}
          {userState !== null && userState.Orders.Home_Test === true && (
            <HomeOrderPreview userState={userState} />
          )}
          {loading.userState && <LoadingCard />}
        </div>
      </div>

      <div className="flex flex-row">
        <Card
          className="w-full flex-1 card-dashboard card-body-1 rounded-md drop-shadow pb-16"
          title="Schedule an appointment"
        >
          <div className="flex  flex-col md:flex-row space-x-0 md:space-x-6">
            <div className="w-full md:w-7/12 mb-6 md:mb-0">
              <h3 className="text-lg font-bold text-primary-text">
                Book a 15-Minute Telemedicine Visit with a Sleep Doctor
              </h3>
              <p className="font-body text-base text-secondary-text leading-normal">
                Once you’ve booked a time to meet with a sleep doctor, check
                your email for your confirmation and details on how to join the
                appointment.
              </p>
              <p className="font-body text-base text-secondary-text leading-normal mb-0">
                Optional Virtual Check-ins are $15 each.
              </p>
            </div>
            <div className="flex-1 flex flex-col justify-end items-center">
              <PrimaryButton onClick={handleBookAppointment} disabled={userState !== null && userState.Orders.Home_Test === true}>
                Book Appointment
              </PrimaryButton>
            </div>
          </div>
        </Card>
      </div>
    </>
  );
};
export default HomeScreen;
