import React, { useEffect, useState } from "react";
import { Spin } from "antd";
import Section from "../../../components/Section";
import checkIcon from "../../../assets/svgs/check.svg";
import SleeptestImage from "../../../assets/images/SleeptestImage.jpg";
import CpapImage from "../../../assets/images/cpap_machine.jpg";
import { USER_ORDER_CHECKOUT_DATA, USER_PROFILE } from "../../../utils";
import PrimaryButton from "../../../components/PrimaryButton";
import { useHistory, useParams } from "react-router-dom";
import moment from "moment";
import { useLocation } from "react-router-dom";

import { getOrder, getProduct } from "../../../APIClient";

import { usePaymentInputs } from "react-payment-inputs";
import { CreditCards } from "../../../assets/svgs/credit-cards/";
import { LoadingOutlined } from "@ant-design/icons";

const OrderSummary = () => {
  const location = useLocation();
  const [product, setProduct] = useState(null);

  const [userProfile, setUserProfile] = useState({});
  const [orderCheckoutDetails, setOrderCheckoutDetails] = useState({});
  const [orderDetails, setOrderDetails] = useState({});
  const [count, setCount] = useState(0);
  const history = useHistory();
  const { product2 } = useParams();
  const [prodType, setProdType] = useState("home_sleep_test");
  const [prodDetails, setProdDetails] = useState({});
  const date = moment().format("L");
  const { wrapperProps, getCardImageProps, getCardNumberProps } =
    usePaymentInputs();

  useEffect(() => {
    setUserProfile(JSON.parse(localStorage.getItem(USER_PROFILE)));
    setOrderCheckoutDetails(
      JSON.parse(localStorage.getItem(USER_ORDER_CHECKOUT_DATA))
    );
    localStorage.removeItem(USER_ORDER_CHECKOUT_DATA);

    if (product2 !== undefined) {
      setProdType("renuma_plus");
    }
    //_getProduct();

    setTimeout(() => {
      setCount((c) => c + 1);
    }, 500);
  }, []);

  useEffect(() => {
    setProduct(location.state.product);
    console.log("getOrder", orderCheckoutDetails);
    if (orderCheckoutDetails && orderCheckoutDetails.order_id) {
      _getOrder();
    }
  }, [orderCheckoutDetails]);

  const _getOrder = async () => {
    const res = await getOrder(orderCheckoutDetails.order_id);
    setOrderDetails(res.Item);
    console.log("order", res.Item);
  };

  const _getProduct = async () => {
    const res = await getProduct(product2);
    setProdDetails(res);
  };

  const handleGoToDashboard = () => {
    localStorage.removeItem(USER_ORDER_CHECKOUT_DATA);
    history.push("/dashboard");
  };

  return (
    <Section className="bg-littleClouds">
      <div className="max-w-3xl mx-auto flex flex-col items-center text-center pt-14 pb-10">
        <img src={checkIcon} alt="Check Icon" className="mb-8" />
        <h2 className="text-3xl font-bold leading-snug text-primary-text mb-4">
          Your order is confirmed!
        </h2>
        <p className="text-base font-body leading-normal">
          Thank you for placing your order! Your shipment will be processed
          soon. We will notify you when your order has shipped.
        </p>
      </div>
      <div className="max-w-4xl mx-auto bg-renuma-violet-100 pt-6 px-32 pb-24">
        <h3 className="text-xl2 font-bold leading-normal mb-8 text-center">
          Order summary
        </h3>
        {!orderDetails.products && (
          <div className="bg-white pt-8 px-12 pb-14 flex-row justify-center items-center">
            <Spin
              indicator={<LoadingOutlined style={{ color: "#6C009E" }} />}
            />
          </div>
        )}
        {orderDetails.products && (
          <div className="bg-white pt-8 px-12 pb-14">
            <div className="flex flex-row justify-between mb-10">
              <div className="text-base leading-normal">
                Order {orderDetails.stripe_code}
              </div>
              <div className="text-base leading-normal">
                Date Ordered:{" "}
                {moment(orderDetails.date_ordered).format("MM/DD/YYYY")}
              </div>
            </div>
            <div className="flex flex-row">
              <div className="mr-8 w-20">
                {orderDetails.products[0].images &&
                  orderDetails.products[0].images[0] && (
                    <img
                      src={orderDetails.products[0].images[0].src}
                      alt={orderDetails.name}
                    />
                  )}
              </div>
              <div className="text-base font-body leading-normal">
                <div>
                  <h4 className="text-base font-bold mb-2">
                    {orderDetails.products[0].name}
                  </h4>
                  <p className="text-base mb-0 leading-snug">Includes:</p>
                  <ul className="list-inside list-disc">
                    {orderDetails.included &&
                      orderDetails.included.map((i, k) => (
                        <li key={k} className="text-base mb-0">
                          {i}
                        </li>
                      ))}
                  </ul>
                  <div className="border border-gray-200 my-8"></div>
                  <p className="text-base mb-0 leading-snug">
                    Sleep Study with Post-Test Telemedicine Consultation
                  </p>
                </div>

                <div className="border border-gray-200 my-8"></div>

                <div className="flex flex-row justify-between">
                  <div>
                    Subtotal ISMS*
                    <br />
                    Tax
                    <br />
                    Shipping
                    <br />
                    <strong>Total</strong>
                  </div>
                  <div className="text-right">
                    ${parseFloat(orderDetails.description.amount).toFixed(2)}
                    <br />${parseFloat(orderDetails.tax).toFixed(2)}
                    <br />${parseFloat(orderDetails.shipping_price).toFixed(2)}
                    <br />
                    <strong>
                      ${parseFloat(orderDetails.total).toFixed(2)}
                    </strong>
                  </div>
                </div>

                <div className="border border-gray-200 my-8"></div>

                <div className="flex flex-row justify-between">
                  <div>
                    Shipping
                    <br />
                    Details
                  </div>
                  <div className="text-right ml-10">
                    <strong>{userProfile.full_name}</strong>
                    <br />
                    {orderDetails.street_address}
                    <br />
                    {orderDetails.apt_suite}
                    <br />
                    {orderDetails.city}, {orderDetails.state}{" "}
                    {orderDetails.zipcode}
                  </div>
                </div>

                <div className="border border-gray-200 my-8"></div>

                <div className="flex flex-row justify-between">
                  <div>
                    Amount
                    <br />
                    Charged
                  </div>
                  <div className="text-right">
                    <strong>
                      ${parseFloat(orderDetails.total).toFixed(2)}
                    </strong>
                    <br />

                    {orderDetails.credit_card.last4 && (
                      <div className="font-base font-body flex flex-row items-center">
                        <img
                          src={CreditCards[orderDetails.credit_card.brand]}
                        />
                        <div className="ml-2">
                          <span className="text-3xl leading-3 relative">
                            <span className="inline-block top-1 relative">
                              &middot;&middot;&middot;&middot;
                            </span>
                          </span>
                          {orderDetails.credit_card.last4}
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                {prodType === "renuma_plus" && (
                  <>
                    <div className="border border-gray-200 my-8"></div>

                    <div className="max-w-sm">
                      <p className="text-sm font-body leading-normal">
                        You will be charged the monthly service and equipment
                        fees until you cancel. You can cancel anytime by
                        visiting your account page on our website. Please note
                        that if you’ve started using your CPAP machine, you will
                        be charged the unpaid remainder of its price (a machine
                        generally costs $850). We cannot accept returns when a
                        machine is used.
                      </p>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="border border-gray-200 my-8"></div>

            <div>
              <p className="text-sm font-body leading-normal">
                *Renuma’s web platform integrates and manages all the components
                involved in sleep apnea care through partnerships with various
                healthcare companies. Our medical services are provided by
                Intelligent Sleep Medical Services (ISMS) and our medical
                equipment is provided by MD Respiratory Services (MDRS). Your
                order summary reflects how your payment is distributed to our
                partners
              </p>
            </div>
          </div>
        )}
      </div>
      <div className="mt-6 max-w-3xl mx-auto flex flex-col items-center text-center">
        <p className="text-base font-body leading-normal mb-10">
          If you have any further questions, don’t hesitate to contact us at{" "}
          <a
            href="mailto:contact@renumasleep.com"
            className="text-primary-text"
          >
            contact@renumasleep.com
          </a>
          .<br />
          We are happy to help!
        </p>
        <PrimaryButton onClick={handleGoToDashboard} className="">
          Go to Dashboard
        </PrimaryButton>
      </div>
    </Section>
  );
};

export default OrderSummary;
