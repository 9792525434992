import React, { Fragment, useState, useEffect } from "react";
import {
  Alert,
  Breadcrumb,
  Card,
  Col,
  Divider,
  Modal,
  Spin,
  Button,
  Row,
} from "antd";
import { getOrders, createOrder, getUserState } from "../../APIClient";
import { centsToFormattedValue, titleCase } from "../../utils";
import LoadingCard from "../../components/LoadingCard";
import OrderPreview, {
  PlaceNewOrder,
  NoOrders,
} from "../../components/OrderPreview";

const NewOrderModal = ({
  isVisible,
  setIsVisible,
  activeSubscription,
  defaultPaymentMethod,
  onSuccess,
}) => {
  var _a, _b;
  const [orderTypes, setOrderTypes] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState("");
  const [selectedOrderType, setSelectedOrderType] = useState(null);
  const [orders, setOrders] = useState(null);
  const loadOrderTypes = async () => {
    try {
      const orders = await getOrders();
      // setOrderTypes(orderTypes);
      setOrders(orders);
    } catch (e) {
      setError(e.message);
    }
  };
  const onOrder = async () => {
    try {
      setIsSubmitting(true);
      await createOrder({ orderTypeId: selectedOrderType.id });
      setIsSubmitting(false);
      onSuccess();
    } catch (e) {
      setError(e.message);
      setIsSubmitting(false);
    }
  };
  useEffect(() => {
    loadOrderTypes();
  }, []);
  const onCancel = () => {
    setSelectedOrderType(null);
    setIsVisible(false);
  };
  return (
    <Modal
      visible={isVisible}
      okText={"Order"}
      okButtonProps={{ disabled: !selectedOrderType || isSubmitting }}
      onCancel={onCancel}
      onOk={onOrder}
    >
      <p style={{ fontWeight: 700 }}>
        {!selectedOrderType ? `Select an offering` : `Confirm your order`}
      </p>
      {error && (
        <Alert
          message={error}
          type="error"
          style={{ marginBottom: 24 }}
          closable
          onClose={() => setError("")}
        />
      )}
      <Row>
        {!selectedOrderType &&
          orderTypes.map((orderType) => (
            <Col span={12}>
              <Card
                hoverable
                cover={
                  <img
                    src={orderType.promo_image}
                    style={{ maxHeight: 120, paddingTop: 10 }}
                  />
                }
                onClick={() => setSelectedOrderType(orderType)}
                style={{ margin: 5 }}
              >
                <Card.Meta
                  title={
                    <>
                      <span>{orderType.name}</span>
                      <span
                        style={{
                          float: "right",
                          textDecoration: activeSubscription
                            ? "line-through"
                            : "inherit",
                        }}
                      >
                        {centsToFormattedValue(orderType.price_in_cents)}
                      </span>
                    </>
                  }
                  description={orderType.description}
                />
              </Card>
            </Col>
          ))}
        {selectedOrderType && (
          <Spin spinning={isSubmitting}>
            <Card
              cover={
                <img
                  src={selectedOrderType.promo_image}
                  style={{ maxHeight: 120, paddingTop: 10 }}
                />
              }
              style={{ margin: 5 }}
            >
              <Card.Meta
                title={selectedOrderType.name}
                description={selectedOrderType.description}
              />
              <Divider />
              <Row>
                <Col span={16}>
                  <b>Price</b>
                </Col>
                <Col span={8}>
                  {centsToFormattedValue(selectedOrderType.price_in_cents)}
                </Col>
              </Row>
              <Row>
                <Col span={16}>
                  <b>Payment method</b>
                </Col>
                <Col span={8}>
                  {activeSubscription
                    ? `Subscription #000${activeSubscription.id}`
                    : `${titleCase(
                        (_a = defaultPaymentMethod) === null || _a === void 0
                          ? void 0
                          : _a.card.brand
                      )} ending in ${
                        (_b = defaultPaymentMethod) === null || _b === void 0
                          ? void 0
                          : _b.card.last4
                      }`}
                </Col>
              </Row>
              <Row>
                <Col span={16}>
                  <b>Shipping est</b>
                </Col>
                <Col span={8}>5-7 business days</Col>
              </Row>
            </Card>
          </Spin>
        )}
      </Row>
    </Modal>
  );
};
const OrdersScreen = ({ user }) => {
  const [loading, setLoading] = useState({
    userState: false,
  });
  const [userState, setUserState] = useState(null);
  const [orders, setOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [showNewOrderModal, setShowNewOrderModal] = useState(false);

  useEffect(() => {
    loadOrders();
    loadUserState();
  }, []);

  const loadOrders = async () => {
    try {
      setIsLoading(true);
      const orders = await getOrders();
      setOrders(orders);
      setIsLoading(false);
    } catch (e) {
      setError(e.message);
      setIsLoading(false);
    }
  };

  const loadUserState = async () => {
    setLoading((v) => ({ ...v, userState: true }));
    try {
      const state = await getUserState();
      setUserState(state);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading((v) => ({ ...v, userState: false }));
    }
  };

  const onSuccess = async () => {
    await loadOrders();
    setShowNewOrderModal(false);
  };
  return (
    <>
      <h3 className="text-3xl font-bold text-primary-text mt-4">Orders</h3>
      {isLoading && (
        <>
          <LoadingCard />
          <div className="mb-6"></div>
        </>
      )}

      {!isLoading && orders.length > 0 && (
        <>
          <OrderPreview orders={orders} open={true} userState={userState} />
          <PlaceNewOrder orders={orders} userState={userState} />
        </>
      )}

      {!isLoading && orders.length === 0 && <NoOrders userState={userState} />}

      <NewOrderModal
        isVisible={showNewOrderModal}
        setIsVisible={setShowNewOrderModal}
        defaultPaymentMethod={user.default_payment_method}
        activeSubscription={user.active_subscription}
        onSuccess={onSuccess}
      />
    </>
  );
};
export default OrdersScreen;
