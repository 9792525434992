import React from 'react';
const EyeShow = () => (<svg width="62" height="12" viewBox="0 0 62 12" fill="currentColor" xmlns="http://www.w3.org/2000/svg"> <path d="M5.8891 6C5.8891 6.5467 6.10628 7.07102 6.49286
  7.45759C6.87944 7.84417 7.40375 8.06135 7.95045 8.06135C8.49716 8.06135 9.02147 7.84417 9.40805 7.45759C9.79462 7.07102 10.0118 6.5467 10.0118 6C10.0118 5.4533
  9.79462 4.92898 9.40805 4.54241C9.02147 4.15583 8.49716 3.93865 7.95045 3.93865C7.40375 3.93865 6.87944 4.15583 6.49286 4.54241C6.10628 4.92898 5.8891 5.4533 5.8891
  6V6ZM15.9419 5.52515C14.1971 1.84969 11.5597 0 8.02407 0C4.48665 0 1.85107 1.84969 0.10628 5.52699C0.0362957 5.67518 0 5.83704 0 6.00092C0 6.16481 0.0362957 6.32666
  0.10628 6.47485C1.85107 10.1503 4.48849 12 8.02407 12C11.5615 12 14.1971 10.1503 15.9419 6.47301C16.0836 6.17485 16.0836 5.82883 15.9419 5.52515V5.52515ZM7.95045
  9.23926C6.16149 9.23926 4.71119 7.78896 4.71119 6C4.71119 4.21104 6.16149 2.76074 7.95045 2.76074C9.73941 2.76074 11.1897 4.21104 11.1897 6C11.1897 7.78896 9.73941
  9.23926 7.95045 9.23926Z"/> <path d="M28.1719 11.098C27.5279 11.098 26.9492 10.986 26.4359 10.762C25.9319 10.5287 25.5352 10.2113 25.2459
  9.81001C24.9565 9.39935 24.8072 8.92801 24.7979 8.39601H26.1559C26.2025 8.85335 26.3892 9.24068 26.7159 9.55801C27.0519 9.86601 27.5372 10.02 28.1719 10.02C28.7785
  10.02 29.2545 9.87068 29.5999 9.57201C29.9545 9.26401 30.1319 8.87201 30.1319 8.39601C30.1319 8.02268 30.0292 7.71935 29.8239 7.48601C29.6185 7.25268 29.3619
  7.07535 29.0539 6.95401C28.7459 6.83268 28.3305 6.70201 27.8079 6.56202C27.1639 6.39401 26.6459 6.22601 26.2539 6.05801C25.8712 5.89001 25.5399 5.62868 25.2599
  5.27401C24.9892 4.91001 24.8539 4.42468 24.8539 3.81801C24.8539 3.28601 24.9892 2.81468 25.2599 2.40401C25.5305 1.99335 25.9085 1.67601 26.3939 1.45201C26.8885
  1.22801 27.4532 1.11601 28.0879 1.11601C29.0025 1.11601 29.7492 1.34468 30.3279 1.80201C30.9159 2.25935 31.2472 2.86601 31.3219 3.62201H29.9219C29.8752 3.24868
  29.6792 2.92201 29.3339 2.64201C28.9885 2.35268 28.5312 2.20801 27.9619 2.20801C27.4299 2.20801 26.9959 2.34801 26.6599 2.62801C26.3239 2.89868 26.1559 3.28135
  26.1559 3.77601C26.1559 4.13068 26.2539 4.42001 26.4499 4.64401C26.6552 4.86802 26.9025 5.04068 27.1919 5.16201C27.4905 5.27401 27.9059 5.40468 28.4379
  5.55401C29.0819 5.73135 29.5999 5.90868 29.9919 6.08601C30.3839 6.25401 30.7199 6.52001 30.9999 6.88401C31.2799 7.23868 31.4199 7.72401 31.4199 8.34001C31.4199
  8.81601 31.2939 9.26402 31.0419 9.68402C30.7899 10.104 30.4165 10.4447 29.9219 10.706C29.4272 10.9673 28.8439 11.098 28.1719 11.098Z"/> <path d="M37.1026 3.18801C37.6813 3.18801 38.204 3.31401 38.6706 3.56601C39.1373 3.80868 39.5013 4.17735 39.7626 4.67201C40.0333 5.16668 40.1686 5.76868 40.1686
  6.47801V11H38.9086V6.66001C38.9086 5.89468 38.7173 5.31135 38.3346 4.91001C37.952 4.49935 37.4293 4.29401 36.7666 4.29401C36.0946 4.29401 35.558 4.50401 35.1566
  4.92401C34.7646 5.34401 34.5686 5.95535 34.5686 6.75802V11H33.2946V0.640015H34.5686V4.42001C34.8206 4.02801 35.166 3.72468 35.6046 3.51001C36.0526 3.29535 36.552
  3.18801 37.1026 3.18801Z"/> <path d="M45.5957 11.126C44.8771 11.126 44.2237 10.9627 43.6357 10.636C43.0571 10.3093 42.5997 9.84735 42.2637
  9.25002C41.9371 8.64335 41.7737 7.94335 41.7737 7.15001C41.7737 6.36601 41.9417 5.67535 42.2777 5.07801C42.6231 4.47135 43.0897 4.00935 43.6777 3.69201C44.2657
  3.36535 44.9237 3.20201 45.6517 3.20201C46.3797 3.20201 47.0377 3.36535 47.6257 3.69201C48.2137 4.00935 48.6757 4.46668 49.0117 5.06401C49.3571 5.66135 49.5297
  6.35668 49.5297 7.15001C49.5297 7.94335 49.3524 8.64335 48.9977 9.25002C48.6524 9.84735 48.1811 10.3093 47.5837 10.636C46.9864 10.9627 46.3237 11.126 45.5957
  11.126ZM45.5957 10.006C46.0531 10.006 46.4824 9.89868 46.8837 9.68402C47.2851 9.46935 47.6071 9.14735 47.8497 8.71802C48.1017 8.28868 48.2277 7.76601 48.2277
  7.15001C48.2277 6.53401 48.1064 6.01135 47.8637 5.58201C47.6211 5.15268 47.3037 4.83535 46.9117 4.63001C46.5197 4.41535 46.0951 4.30801 45.6377 4.30801C45.1711
  4.30801 44.7417 4.41535 44.3497 4.63001C43.9671 4.83535 43.6591 5.15268 43.4257 5.58201C43.1924 6.01135 43.0757 6.53401 43.0757 7.15001C43.0757 7.77535 43.1877
  8.30268 43.4117 8.73202C43.6451 9.16135 43.9531 9.48335 44.3357 9.69802C44.7184 9.90335 45.1384 10.006 45.5957 10.006Z"/> <path d="M61.4248
  3.32801L59.0308 11H57.7148L55.8668 4.91001L54.0188 11H52.7028L50.2948 3.32801H51.5968L53.3608 9.76801L55.2648 3.32801H56.5668L58.4288 9.78201L60.1648
  3.32801H61.4248Z"/> </svg>);
export default EyeShow;
