import moment from "moment-timezone";
import React from "react";
import Section from "../../../components/Section";
import checkIcon from "../../../assets/svgs/check.svg";
import PrimaryButton from "../../../components/PrimaryButton";
import { Link, useHistory } from "react-router-dom";

const OrderRenumaPlusSummary = (props) => {
  const { data, payment_method, quotes_cpap_machine } = props.location.state;
  const next_payment = false;
  const history = useHistory();
  const titles = ["Monthly Amount Charged"];

  const handleGoToDashboard = () => {
    history.push("/dashboard");
  };

  return (
    <Section>
      <div className="flex flex-col max-w-7xl mx-auto">
        <img src={checkIcon} alt="Check Icon" className="mb-8 h-12" />
        <h2 className="text-center text-2xl font-semibold mb-12">
          YOUR RENUMA+ TREATMENT INITIATION PHASE ENROLLMENT IS CONFIRMED
        </h2>
        <div className="bg-gray-100 p-8" style={{ borderRadius: "38px" }}>
          <h3 className="text-center text-lg font-semibold">
            Your order is being processed and the equipment will ship soon
          </h3>
          <div className="flex flex-row mt-20 space-x-20 w-full mr-6">
            <div className="flex-1">
              <h3 className="text-left text-xl font-semibold">
                Renuma+ Treatment Initiation Phase* Includes:
              </h3>
              <ul>
                <li>- CPAP machine, accessories, 2 masks</li>
                <li>- Monitoring of treatment effectiveness</li>
                <li>- Replacement supplies after 6 months</li>
                <li>- Cancel anytime</li>
              </ul>
              <div className="flex flex-row mt-12 w-full">
                <div className="flex-1">
                  <h3 className="text-left text-xl font-semibold">
                    Order summary
                  </h3>
                </div>
              </div>
              <p className="text-sm mb-0">
                RenumaPlus Fee Treatment Initiation Phase (for the first 10
                months)
              </p>

              {data.description.map((i, k) => {
                if (
                  payment_method !== "monthly" &&
                  i.details === "Monthly Amount Charged"
                ) {
                  return null;
                }
                return (
                  <div key={k} className="flex flex-row w-full">
                    <div className="flex-1">
                      {titles.includes(i.details) ? (
                        <h3 className="text-left text-xl font-semibold">
                          Monthly Amount Charged
                        </h3>
                      ) : (
                        <p className="text-sm mb-0">{i.details}</p>
                      )}
                    </div>
                    <div className="w-2/6 text-right">
                      ${i.amount.toFixed(2)}
                    </div>
                  </div>
                );
              })}

              {/* <p className="text-sm mb-0 mt-12">
                RenumaPlus Fee Treatment Maintenance Phase (month 11+)
              </p>

              {data.description[1].map((i, k) => {
                if (
                  payment_method !== "monthly" &&
                  i.details === "Monthly Amount Charged"
                ) {
                  return null;
                }
                return (
                  <div key={k} className="flex flex-row w-full">
                    <div className="flex-1">
                      {titles.includes(i.details) ? (
                        <h3 className="text-left text-xl font-semibold">
                          Monthly Amount Charged
                        </h3>
                      ) : (
                        <p className="text-sm mb-0">{i.details}</p>
                      )}
                    </div>
                    <div className="w-2/6 text-right">
                      ${i.amount.toFixed(2)}
                    </div>
                  </div>
                );
              })} */}
              <div className="flex flex-row w-full mt-12">
                <div className="flex-1">
                  <h3 className="text-left text-xl font-semibold">
                    {payment_method === "monthly"
                      ? "Total Purchase"
                      : "Total Amount Charged"}
                  </h3>
                </div>
                <div className="w-2/6 text-right">${data.total.toFixed(2)}</div>
              </div>

              <p className="text-xs leading-normal mb-0 mt-10">
                *This phase lasts 10 months. After 10 months, you will be
                auto-enrolled in the Treatment Maintenance Phase at a cost of
                $180 every 6 months. Cancel anytime. See{" "}
                <Link
                  to="/conditions-of-use"
                  className="text-renuma-violet-primary"
                  target={"_blank"}
                >
                  Terms and Conditions
                </Link>{" "}
                for cancellation process.
                <br />
                <br />
                **Renuma’s web platform integrates and manages all the
                components involved in sleep apnea care through partnerships
                with various healthcare companies. Our medical services are
                provided by Intelligent Sleep Medical Services (ISMS) and our
                medical equipment is provided by MD Respiratory Services (MDRS).
                Your order summary reflects how your payment is distributed to
                our partners.
              </p>
            </div>
            <div className="flex-1">
              <h3 className="text-left text-xl font-semibold">
                Products heading your way:
              </h3>
              {data.products.map((prod, k) => (
                <div
                  key={k}
                  className="flex flex-row items-stretch space-x-6 mb-4"
                >
                  <div>
                    <img
                      src={prod.images[0].src}
                      alt={prod.name}
                      className="h-20 rounded-lg"
                    />
                  </div>
                  <div className="flex flex-row items-center">
                    <p className="text-sm">{prod.name}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="mt-12 max-w-3xl mx-auto flex flex-col items-center text-center">
          <p className="text-base font-body leading-normal mb-10">
            If you have any further questions, don’t hesitate to contact us at{" "}
            <a
              href="mailto:contact@renumasleep.com"
              className="text-primary-text"
            >
              contact@renumasleep.com
            </a>
            .<br />
            We are happy to help!
          </p>
          <PrimaryButton onClick={handleGoToDashboard}>
            Go to Dashboard
          </PrimaryButton>
        </div>
      </div>
    </Section>
  );
};

export default OrderRenumaPlusSummary;
