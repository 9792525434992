import React, { useState } from "react";
import { Card } from "antd";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import PrimaryButton from "./PrimaryButton";
import { useEffect } from "react";

const HomeSurveyPreview = ({ surveys, userState }) => {
  const history = useHistory();
  const [isGoodCandidate, setIsGoodCandidate] = useState(false);
  const [lastSurvey, setLastSurvey] = useState(null);

  useEffect(() => {
    if (userState !== null && userState.Home_Study.Good_Candidate === "Good") {
      setIsGoodCandidate(true);
    }
    if (surveys !== null && surveys.length > 0) {
      console.log("surveys", surveys);
      setLastSurvey(surveys[0]);
    }
  }, [surveys, userState]);
  return (
    <Card
      className="card-dashboard card-actions-right rounded-md drop-shadow mb-6 md:mb-0 w-full h-full"
      title="Surveys"
      extra={<Link to="/dashboard/surveys">View</Link>}
    >
      <h3 className="text-lg font-bold text-primary-text">
        Symptoms of OSA Survey
      </h3>
      <div className="max-h-40 overflow-y-scroll">
        {isGoodCandidate && (
          <p
            className="font-body text-base text-secondary-text leading-normal mb-0"
            dangerouslySetInnerHTML={{
              __html: lastSurvey !== null ? lastSurvey.description : "",
            }}
          ></p>
        )}
        {!isGoodCandidate && (
          <p
            className="font-body text-base text-secondary-text leading-normal mb-0"
            dangerouslySetInnerHTML={{
              __html: lastSurvey !== null ? lastSurvey.description : "",
            }}
          ></p>
        )}
      </div>
    </Card>
  );
};
const NoSurveys = () => {
  const history = useHistory();
  return (
    <Card
      className="card-dashboard card-actions-right rounded-md drop-shadow mb-6 md:mb-0 w-full h-full"
      title="You have not completed any surveys"
    >
      <div className="flex flex-col h-full">
        <p className="font-body text-base text-secondary-text leading-normal">
          A provider will upload a survey as part of your care plan when
          relevant.
        </p>
        <div className="flex flex-1 flex-row justify-center items-end">
          <PrimaryButton
            onClick={() => {
              history.push("/onboarding/survey");
            }}
          >
            Take Symptoms of OSA Survey
          </PrimaryButton>
        </div>
      </div>
    </Card>
  );
};

export default HomeSurveyPreview;
export { NoSurveys };
