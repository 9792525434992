import React from 'react';
import { Layout, Menu } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import { FacebookFilled, InstagramFilled, TwitterSquareFilled } from '@ant-design/icons';
const { Content, Header, Footer } = Layout;
const OnboardingNavigator = ({ children, onLogout }) => {
    const history = useHistory();
    const logout = () => {
        localStorage.removeItem('token');
        onLogout();
        history.push('/');
    };
    return (<Layout className='layout' style={{ backgroundColor: 'white' }}>
      <Header style={{ backgroundColor: 'white', padding: '0px 1%' }}>
        <Menu mode={'horizontal'} selectedKeys={['']}>
          <Menu.Item>
            <Link to='/'>Renuma</Link>
          </Menu.Item>
          <Menu mode={'horizontal'} style={{ borderBottom: 'none', display: 'inline-block', float: 'right' }} className='right-menu' selectedKeys={['']}>
            <Menu.Item onClick={logout}>
              Logout
            </Menu.Item>
          </Menu>
        </Menu>
      </Header>
      <Content style={{ padding: '50px 1%' }}>
        {children}
      </Content>
      <Footer style={{ textAlign: 'center' }}>
        <p style={{ marginBottom: 2 }}>
          <a href='https://www.facebook.com' target='_blank' rel='noopener noreferrer' style={{ textDecoration: 'inherit', color: 'inherit' }}><FacebookFilled style={{ fontSize: 24 }}/></a>&nbsp;
          <a href='https://www.instagram.com' target='_blank' rel='noopener noreferrer' style={{ textDecoration: 'inherit', color: 'inherit' }}><InstagramFilled style={{ fontSize: 24 }}/></a>&nbsp;
          <a href='https://www.twitter.com' target='_blank' rel='noopener noreferrer' style={{ textDecoration: 'inherit', color: 'inherit' }}><TwitterSquareFilled style={{ fontSize: 24 }}/></a>
        </p>
        <p style={{ marginBottom: 2 }}>
          <Link to='/privacy' style={{ color: 'rgba(0, 0, 0, 0.65)' }}>Privacy Policy</Link>&nbsp;|&nbsp;
          <Link to='/terms' style={{ color: 'rgba(0, 0, 0, 0.65)' }}>Terms of Use</Link>&nbsp;|&nbsp;
          <a style={{ color: 'rgba(0, 0, 0, 0.65)' }} href='mailto:remunasleep@gmail.com'>Contact Us</a>
        </p>
        <p style={{ marginBottom: 2 }}>© Renuma Health 2020</p>
      </Footer>
    </Layout>);
};
export default OnboardingNavigator;
