import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { getSurvey, getUserState, saveSurveyAnswers } from "../../../APIClient";
import LoadingCard from "../../../components/LoadingCard";
import Section from "../../../components/Section";
import { USER_PROFILE, USER_COMPLETE_SURVEY } from "../../../utils";
import * as SurveyJs from "survey-react";
import * as widgets from "surveyjs-widgets";
import "survey-react/survey.css";
import "nouislider/dist/nouislider.css";
import { Alert, Modal } from "antd";

import $ from "jquery";

import moment from "moment";
import _ from "lodash";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

//widgets.icheck(SurveyJs, $);
widgets.prettycheckbox(SurveyJs);
widgets.select2(SurveyJs, $);
widgets.inputmask(SurveyJs);
widgets.jquerybarrating(SurveyJs, $);
widgets.jqueryuidatepicker(SurveyJs, $);
widgets.nouislider(SurveyJs);
widgets.select2tagbox(SurveyJs, $);
//widgets.signaturepad(SurveyJs);
widgets.sortablejs(SurveyJs);
widgets.ckeditor(SurveyJs);
widgets.autocomplete(SurveyJs, $);
widgets.bootstrapslider(SurveyJs);

const Survey = () => {
  const [json, setJson] = useState(null);
  const [model, setModel] = useState(null);
  const [loading, setLoading] = useState({
    general: true,
    surveyComplete: false,
  });
  const [userProfile, setUserProfile] = useState(null);
  const [userState, setUserState] = useState(null);
  const [disclaimerModalOpen, setDisclaimerModalOpen] = useState(false)
  const history = useHistory();

  useEffect(() => {
    // loadUserState();
    loadSurvey();
  }, []);

  const loadUserState = async () => {
    try {
      const res = await getUserState();
      setUserState(res);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading((v) => ({ ...v, general: false }));
    }
  };

  useEffect(() => {
    if(userProfile){
        let cod = btoa(`${userProfile.email}${"_disclaimeraccepted"}`)
        const openDisclaimer = localStorage.getItem(cod);
    
        if(openDisclaimer){
          setDisclaimerModalOpen(false)
        } else {
          setDisclaimerModalOpen(true)
        }
      }
  }, [userProfile])

  useEffect(() => {
    if (json !== null) {
      const profile = JSON.parse(localStorage.getItem(USER_PROFILE));
      setUserProfile(profile);
      const age = moment().diff(profile.date_of_birth, "years");
      json.pages.map((pg) => {
        pg.elements.map((el) => {
          if (el.name === "p1q1") {
            el.defaultValue = age;
          }
          return el;
        });
        return pg;
      });
      setModel(new SurveyJs.Model(json));
    }
  }, [json]);

  useEffect(() => {
    if (model !== null) {
      const defaultThemeColors = SurveyJs.StylesManager.ThemeColors["default"];
      SurveyJs.settings.executeSkipTriggerOnValueChanged = false;

      defaultThemeColors["$main-color"] = "#9751b7";
      defaultThemeColors["$main-hover-color"] = "#9751b7";
      defaultThemeColors["$text-color"] = "#4a4a4a";
      defaultThemeColors["$header-color"] = "#9751b7";

      defaultThemeColors["$header-background-color"] = "#4a4a4a";
      defaultThemeColors["$body-container-background-color"] = "#f8f8f8";

      SurveyJs.StylesManager.applyTheme();
      const container = document.querySelector('div[name="p1q4_range1"] input');
      container.addEventListener("keypress", (event) => {
        if (event.keyCode >= 48 && event.keyCode <= 57) {
          return false;
        } else {
          event.preventDefault();
        }
      });
    }
  }, [model]);

  const loadSurvey = async () => {
    try {
      const res = await getSurvey(0);
      setJson(res.Item);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading((v) => ({ ...v, general: false }));
    }
  };

  const onComplete = async (survey, options) => {
    setLoading((v) => ({ ...v, surveyComplete: true }));
    window.scrollTo(0, 0);
    try {
      const res = await saveSurveyAnswers({
        survey_id: 0,
        data: survey.data,
        triggers: survey.triggerValues,
      });

      // se actualiza el user_state en el userProfile de localStorage
      // se obtiene el user state actual
      const userState = await getUserState();
      // se obtiene el userProfile de localStorage
      let profile = JSON.parse(localStorage.getItem("userProfile"));
      // se actualizan los datos del user_state
      profile.user_state = userState;
      // se guarda el userProfile con los datos actualizados
      localStorage.setItem("userProfile", JSON.stringify(profile));

      if (survey.data.p17q1 !== undefined && survey.data.p17q1 !== "") {
        localStorage.setItem("telemedicineComment", survey.data.p17q1);
      }

      if (survey.triggerValues.result_HomeSleepTest) {
        history.push("/onboarding/order_sleep_test");
      } else if (survey.triggerValues.result_Telemedicine) {
        let param = "";
        const telemedicineDate = Object.keys(survey.data).filter(
          (k) => k.indexOf("_telemedicine_date") > -1
        );
        if (telemedicineDate.length > 0) {
          param = survey.data[telemedicineDate.pop()];
        }

        history.push("/onboarding/telemedicine/" + param);
      } else {
        history.push("/dashboard");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading((v) => ({ ...v, surveyComplete: false }));
    }
  };

  return (
    <Section>
      {loading.general && <LoadingCard />}
      {!loading.general && model !== null && (
        <div className="relative my-9 md:max-w-7xl mx-auto">
          {loading.surveyComplete && (
            <div className="flex flex-col justify-center items-center py-10">
              <Spin
                indicator={<LoadingOutlined style={{ color: "#6c009e" }} />}
              />
              <div className="mt-6">processing survey's results</div>
            </div>
          )}
          <Modal
            title="Disclaimer"
            visible={disclaimerModalOpen}
            closable={false}
            onOk={() => {
              let cod = btoa(`${userProfile.email}${"_disclaimeraccepted"}`)
              localStorage.setItem(cod, true)
              setDisclaimerModalOpen(false)
            }}
            onCancel={() => {

            }}
            okButtonProps={{
              children: "Custom OK"
            }}
            cancelButtonProps={{
              children: "Custom cancel",
              style: { display: 'none' }
            }}
            okText="I accept the above"
            cancelText=""
          >
            <p className="text-sm">This survey is not a substitute for an encounter with a healthcare provider. Any information provided in the survey or the concluding automated report is for educational purposes only. Any decision to purchase a medical service and/or medical equipment during or at the conclusion of this survey is to be considered a self-referral. See our <a href="https://www.renumasleep.com/conditions-of-use" target="_blank">Terms of Use</a> for further information.</p>
          </Modal>
          <SurveyJs.Survey
            model={model}
            onComplete={onComplete}
            css={{
              headerText: "text-xl font-bold",
              pageTitle: "text-xl font-bold",
              navigation: {
                complete: "btn sv_complete_btn rounded-full text-lg py-2 px-8",
                prev: "btn sv_prev_btn rounded-full text-lg py-2 px-8",
                next: "btn sv_next_btn rounded-full text-lg py-2 px-8",
                start: "btn sv_start_btn rounded-full text-lg py-2 px-8",
                preview: "btn sv_preview_btn rounded-full text-lg py-2 px-8",
                edit: "btn sv_edit_btn rounded-full text-lg py-2 px-8",
              },
            }}
          />
        </div>
      )}
    </Section>
  );
};

export default Survey;
