import React from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import "./index.css";
import App from "./App";
// import * as serviceWorker from './serviceWorker';
import { Auth } from "aws-amplify";
import awsconfig from "./aws-exports";
import { AuthProvider } from "./providers/auth.context";
import { hotjar } from "react-hotjar";
import AOS from "aos";
import "aos/dist/aos.css";
import { refreshToken } from "./hooks/TokenStorage";

AOS.init({ once: true });

const hjSettings = { hjid: 2730464, hjsv: 6 };
hotjar.initialize(hjSettings.hjid, hjSettings.hjsv);

axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFToken";
axios.interceptors.request.use((config) => {
  if (localStorage.getItem("accessToken")) {
    config["headers"] = {
      Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    };
  }
  config["validateStatus"] = (status) => true;
  return config;
});
axios.interceptors.response.use(
  async (response) => {
    if (
      response.status === 401 &&
      response.config &&
      !response.config.__isRetryRequest
    ) {
      const rs = await refreshToken(response.data);
      if (rs.data.AuthenticationResult.AccessToken) {
        response.config.__isRetryRequest = true;
        response.config.headers.token = localStorage.getItem("accessToken");

        return axios(response.config);
      }
      return rs;
    }
    return response;
  },
  async function (error) {
    const access_token = localStorage.getItem("accessToken");
    if (error.response && error.response.status === 401 && access_token) {
      const response = await refreshToken(error);

      return response;
    }
    return Promise.reject(error);
  }
);
Auth.configure(awsconfig);

ReactDOM.render(
  <AuthProvider user={null}>
    <App />
  </AuthProvider>,
  document.getElementById("root")
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
