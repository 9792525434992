import React, { useState, useEffect } from "react";
import { Card } from "antd";
import { useHistory, Link } from "react-router-dom";
import SleeptestImage from "../assets/images/SleeptestImage.jpg";
import PrimaryButton from "./PrimaryButton";
import SecondaryButton from "./SecondaryButton";
import { ModalWarning } from "../utils";

const HomeOrderPreview = ({ userState }) => {
  const history = useHistory();
  const [completed, setCompleted] = useState(false);

  useEffect(() => {
    if (userState !== null && userState.Home_Study.Completed === true) {
      setCompleted(true);
    }
  }, [userState]);

  const onScheduleAnAppointment = () => {
    if (userState.Telemedicine.Free === true) {
      ModalWarning({
        warningText: "You already scheduled your virtual consultation",
      });
    } else {
      history.push("/onboarding/telemedicine/free");
    }
  };

  return (
    <Card
      className="card-dashboard card-actions-right rounded-md drop-shadow h-full w-full"
      title="Home Sleep Test"
      extra={<Link to="/dashboard/orders">View your orders</Link>}
    >
      <div className="flex flex-row space-x-2">
        <div className="flex-1">
          <p className="font-body text-base text-secondary-text leading-normal mb-0">
            Includes:
          </p>
          <ul className="list-none md:list-disc list-inside mb-6">
            <li>Home sleep test</li>
            <li>Results in 3 days</li>
            <li>One virtual consultation with a sleep doctor</li>
          </ul>
          <SecondaryButton
            disabled={!completed}
            onClick={onScheduleAnAppointment}
          >
            Schedule your virtual consultation
          </SecondaryButton>
        </div>
        <div className="w-20 md:w-48">
          <img
            onClick={() => {
              history.push("/onboarding/order_sleep_test");
            }}
            src={SleeptestImage}
            className=""
          />
        </div>
      </div>
    </Card>
  );
};
const NoOrders = ({ userState }) => {
  const history = useHistory();
  const [notRecommended, setNotRecommended] = useState(false);
  const [hasSurvey, setHasSurvey] = useState(false);

  useEffect(() => {
    if (userState !== null && userState.Home_Study.Good_Candidate === "Bad") {
      setNotRecommended(true);
    }
    if (userState !== null && userState.Surveys.Completed === true) {
      setHasSurvey(true);
    }
  }, [userState]);

  const onOrderSleepTest = () => {
    if (hasSurvey) {
      history.push("/onboarding/order_checkout");
    } else {
      ModalWarning({
        warningText:
          "You haven't taken the survey yet, please do it first before ordering your test/scheduling an appointment",
      });
    }
  };

  const clickGetStarted = () => {
    if (!hasSurvey) {
      ModalWarning({
        warningText:
          "You haven't taken the survey yet, please do it first before ordering your test/scheduling an appointment",
      });
      return
    }
    if (userState.Telemedicine.Free === true) {
      ModalWarning({
        warningText: "You already scheduled your virtual consultation",
      });
    } else {
      history.push("/onboarding/telemedicine/free");
    }
  };

  return (
    <Card
      className="card-dashboard card-actions-right rounded-md drop-shadow h-full w-full"
      title="Order Your Home Sleep Test"
    >
      <div className="flex flex-col h-full">
        <div className="flex flex-row space-x-2">
          <div className="flex-1">
            <p className="font-body text-base text-secondary-text leading-normal mb-0">
              Includes:
            </p>
            <ul className="list-none md:list-disc list-inside mb-0">
              <li>Home sleep test</li>
              <li>Results in 3 days</li>
              <li>One virtual consultation with a sleep doctor</li>
            </ul>
          </div>
          <div className="w-20 md:w-48">
            <img src={SleeptestImage} className="" />
          </div>
        </div>
        <div className="flex flex-1 flex-row items-end space-x-6">
          <PrimaryButton disabled={notRecommended} onClick={onOrderSleepTest}>
            Order Sleep Test
          </PrimaryButton> 
          <SecondaryButton disabled={notRecommended || (userState !== null && userState.Orders.Home_Test === false)} onClick={clickGetStarted}>
            Schedule Test Results Appointment
          </SecondaryButton>
        </div>
      </div>
    </Card>
  );
};
export default HomeOrderPreview;
export { NoOrders };
