import React, { useEffect, useState, useRef } from "react";
import { Form, Alert, Input, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { EyeShowIcon, EyeHideIcon } from "./Icons";
import SimpleReactValidator from "simple-react-validator";
import PrimaryButton from "./PrimaryButton";

import { Auth } from "aws-amplify";

const AccountPasswordPanel = () => {
  const [alert, setAlert] = useState({ message: "", type: "success" });
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    current_password: "",
    password: "",
    confirm: "",
  });

  const [, forceUpdate] = useState();
  const simpleValidator = useRef(
    new SimpleReactValidator({
      autoForceUpdate: { forceUpdate: forceUpdate },
      messages: {
        in: "New Password does not match the Confirm Password",
      },
      element: (message) => (
        <div className="rnm-input-error text-xs">{message}</div>
      ),
    })
  );

  const disabled = () => !simpleValidator.current.allValid();
  const onSubmit = async () => {
    setLoading(true);
    try {
      const user = await Auth.currentAuthenticatedUser();

      const change = await Auth.changePassword(
        user,
        values.current_password,
        values.password
      );
      if (change === "SUCCESS") {
        setAlert({
          message: "Your password has been changed successfully.",
          type: "success",
        });
        simpleValidator.current.hideMessageFor("current_password");
        simpleValidator.current.hideMessageFor("password");
        simpleValidator.current.hideMessageFor("confirm");
        setValues({
          current_password: "",
          password: "",
          confirm: "",
        });
      } else {
        setAlert({
          message: "Your password wasn't changed. Please try again.",
          type: "error",
        });
      }
    } catch (error) {
      console.log(error);
      setAlert({
        message: "Your password was not changed. Please try again.",
        type: "error",
      });
    } finally {
      setLoading(false);
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues((v) => ({ ...v, [name]: value }));
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    simpleValidator.current.showMessageFor(name);
    setValues((v) => ({ ...v, [name]: value }));
  };
  return (
    <>
      <h3 className="text-lg font-bold text-primary mt-5 mb-0">
        Update Password
      </h3>
      <Form className="mt-6 mb-11 w-96">
        {alert.message && (
          <Alert
            message={alert.message}
            type={alert.type}
            style={{ marginBottom: 24 }}
            closable
            onClose={() => setAlert({ message: "", type: "" })}
          />
        )}
        <Form.Item>
          <div className="text-base font-body">
            Current Password (8 or more characters)
          </div>
          <Input.Password
            name="current_password"
            size="large"
            onChange={handleChange}
            onBlur={handleBlur}
            className="rnm-input"
            value={values.current_password}
            iconRender={(visible) =>
              visible ? (
                <EyeHideIcon style={{ color: "#6C009E" }} />
              ) : (
                <EyeShowIcon style={{ color: "#6C009E" }} />
              )
            }
          />
          {simpleValidator.current.message(
            "current_password",
            values.current_password,
            "required|min:8|max:20"
          )}
        </Form.Item>
        <Form.Item>
          <div className="text-base font-body">
            New Password (8 or more characters)
          </div>
          <Input.Password
            name="password"
            size="large"
            onChange={handleChange}
            onBlur={handleBlur}
            className="rnm-input"
            value={values.password}
            iconRender={(visible) =>
              visible ? (
                <EyeHideIcon style={{ color: "#6C009E" }} />
              ) : (
                <EyeShowIcon style={{ color: "#6C009E" }} />
              )
            }
          />
          {simpleValidator.current.message(
            "password",
            values.password,
            "required|min:8|max:20"
          )}
        </Form.Item>
        <Form.Item>
          <div className="text-base font-body">
            Confirm Password (8 or more characters)
          </div>
          <Input.Password
            name="confirm"
            size="large"
            onChange={handleChange}
            onBlur={handleBlur}
            className="rnm-input"
            value={values.confirm}
            iconRender={(visible) =>
              visible ? (
                <EyeHideIcon style={{ color: "#6C009E" }} />
              ) : (
                <EyeShowIcon style={{ color: "#6C009E" }} />
              )
            }
          />
          {simpleValidator.current.message(
            "confirm",
            values.confirm,
            `required|min:8|max:20|in:${values.password}`
          )}
        </Form.Item>
        <Form.Item className="mt-6 mb-2">
          <PrimaryButton disabled={disabled() || loading} onClick={onSubmit}>
            {loading ? (
              <Spin
                indicator={<LoadingOutlined style={{ color: "#FFFFFF" }} />}
              />
            ) : (
              "Update"
            )}
          </PrimaryButton>
        </Form.Item>
      </Form>
    </>
  );
};

export default AccountPasswordPanel;
