import React, { useState, useRef, useEffect } from "react";
import { Alert, Button, Card, Form, Input, Spin } from "antd";
import { Link, useHistory, useLocation } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import Section from "../../components/Section";
import { EyeShowIcon, EyeHideIcon } from "../../components/Icons";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import PrimaryButton from "../../components/PrimaryButton";
import { Auth } from "aws-amplify";
// App constants
import { AUTH_USER_TOKEN_KEY, USER_PROFILE, PATH_NEXT_URL } from "../../utils";
import { LoadingOutlined } from "@ant-design/icons";
import { useAuth } from "../../providers/auth.context";
import { getProfile } from "../../APIClient";

const LoginScreen = () => {
  const [error, setError] = useState("");
  const [values, setValues] = useState({
    email: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const { state } = useLocation();
  const { updateCurrentUser } = useAuth();
  const { width } = useWindowDimensions();
  const cutOff = 960;
  const inputEmail = useRef(null);
  const simpleValidator = useRef(
    new SimpleReactValidator({
      element: (message) => (
        <div className="rnm-input-error text-xs">{message}</div>
      ),
    })
  );
  useEffect(() => {
    if (inputEmail) {
      inputEmail.current.focus();
    }
  }, [inputEmail]);

  const disabled = () => !simpleValidator.current.allValid();

  const handleClickHere = () => {
    sessionStorage.setItem("email_ccode", values.email.trim());
    history.push("/confirmation-code");
  };

  const handleClickSignUp = () => {
    history.push("/get-an-account");
  };

  const onSubmit = async () => {
    setLoading(true);
    setError("");
    if (!values.email.trim() && !values.password.trim()) {
      setError(
        <span>
          If your email exists on our database you will receive a verification
          code. If you didn't do your account yet please click{" "}
          <span
            className="text-renuma-violet-primary underline cursor-pointer"
            onClick={handleClickSignUp}
          >
            here
          </span>{" "}
          to make one
        </span>
      );
      return;
    }
    try {
      const user = await Auth.signIn(
        values.email.trim(),
        values.password.trim()
      );
      const { from } = state || {
        from: {
          pathname: "/dashboard",
        },
      };
      if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
        console.log("NEW_PASSWORD_REQUIRED", user);
      } else if (user) {
        localStorage.setItem(
          AUTH_USER_TOKEN_KEY,
          user.signInUserSession.accessToken.jwtToken
        );

        const res = await getProfile();

        localStorage.setItem(USER_PROFILE, JSON.stringify(res.Item));

        updateCurrentUser({
          email: res.Item.username,
          username: user.username,
          token: user.storage.accessToken,
          is_onboarded: false,
          role: res.Item.role,
        });

        setLoading(false);
        const nextUrl = sessionStorage.getItem(PATH_NEXT_URL);
        if (res.Item.role === "admin") {
          history.push("/dashboard");
        } else if (nextUrl) {
          sessionStorage.removeItem(PATH_NEXT_URL);
          history.push(nextUrl);
        } else {
          history.push(from);
        }
      } else {
        setError("Log in error");
      }
    } catch (e) {
      let msg = "";
      if (e.message === "User is not confirmed.") {
        msg = (
          <span>
            This email address is already registered, please enter your
            verification code to activate your account{" "}
            <span
              className="text-renuma-violet-primary underline cursor-pointer"
              onClick={handleClickHere}
            >
              here
            </span>
          </span>
        );
      } else if (e.message === "Incorrect username or password.") {
        msg = (
          <span>
            If your email exists on our database you will receive a verification
            code. If you didn't do your account yet please click{" "}
            <span
              className="text-renuma-violet-primary underline cursor-pointer"
              onClick={handleClickSignUp}
            >
              here
            </span>{" "}
            to make one
          </span>
        );
      } else {
        msg = e.message;
      }
      setError(msg);
      setLoading(false);
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues((v) => ({ ...v, [name]: value }));
  };
  const handleBlur = (e) => {
    const { name, value } = e.target;
    simpleValidator.current.showMessageFor(name);
    setValues((v) => ({ ...v, [name]: value }));
  };
  return (
    <Section className="bg-login bg-cover bg-no-repeat h-screen md:h-full pb-0">
      <div
        className="-mx-6 md:m-auto w-screen md:w-auto max-w-lg h-full flex items-start md:items-center bg-white md:bg-transparent"
        data-aos="fade-up"
        data-aos-duration="700"
        data-aos-delay="0"
      >
        <Card className="w-full">
          <div
            className={
              width > cutOff
                ? ""
                : "bg-renuma-violet-100 -mx-6 -mt-11 pb-14 px-6"
            }
          >
            <h3 className="text-3xl font-bold text-left md:text-center mt-5 mb-0">
              Log in
            </h3>
            <div className="text-left md:text-center text-sm md:text-base font-body">
              Don't have an account?
              <Button
                size="large"
                type="link"
                onClick={() => history.push("/get-an-account")}
                className="font-body p-0 ml-2 text-renuma-violet-primary"
              >
                Get an account
              </Button>
            </div>
          </div>

          {error && (
            <Alert
              message={error}
              type="error"
              style={{ marginBottom: 24 }}
              closable
              onClose={() => setError("")}
            />
          )}
          <Form className="mt-10 mx-0 md:mx-7 mb-14">
            <Form.Item>
              <div className="text-base font-body">Email</div>
              <Input
                ref={inputEmail}
                name="email"
                size="large"
                onChange={handleChange}
                onBlur={handleBlur}
                className="rnm-input"
              />
              {simpleValidator.current.message(
                "email",
                values.email,
                "required|email"
              )}
            </Form.Item>
            <Form.Item>
              <div className="text-base font-body">Password</div>
              <Input.Password
                name="password"
                size="large"
                onChange={handleChange}
                onBlur={handleBlur}
                className="rnm-input"
                iconRender={(visible) =>
                  visible ? (
                    <EyeHideIcon style={{ color: "#6C009E" }} />
                  ) : (
                    <EyeShowIcon style={{ color: "#6C009E" }} />
                  )
                }
              />
              {simpleValidator.current.message(
                "password",
                values.password,
                "required|min:8|max:20"
              )}
            </Form.Item>
            <Form.Item className="mt-6">
              <PrimaryButton
                disabled={disabled() || loading}
                onClick={onSubmit}
                className="w-full"
              >
                {loading ? (
                  <Spin
                    indicator={<LoadingOutlined style={{ color: "#FFFFFF" }} />}
                  />
                ) : (
                  "Log in"
                )}
              </PrimaryButton>
            </Form.Item>

            <div className="mt-4 text-center">
              <Button
                size="large"
                type="link"
                onClick={() => history.push("/forgot-password")}
                className="text-sm font-body text-renuma-violet-primary"
              >
                <span className="underline">Forgot password?</span>
              </Button>
            </div>
          </Form>
        </Card>
      </div>
    </Section>
  );
};
export default LoginScreen;
