import React, { useState, useEffect } from "react";
import { Alert, Button, Card } from "antd";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import PrimaryButton from "./PrimaryButton";
import viewMore from "../assets/svgs/view_more.svg";
import Panel from "./Panel";
import moment from "moment";
import SecondaryButton from "./SecondaryButton";
import { getSurvey, getUserState } from "../APIClient";
import { SurveyPDF } from "survey-pdf";
import { ModalWarning } from "../utils";

const SurveyPreview = ({ survey, index, collapsed = true }) => {
  const history = useHistory();
  const [isNew, setIsNew] = useState(false);
  const [loading, setLoading] = useState({
    userState: false,
  });

  const [userState, setUserState] = useState(null);

  useEffect(() => {
    loadUserState();
    setIsNew(
      index === 0 && moment(survey.date).isAfter(moment().subtract(30, "days"))
    );
  }, []);

  const loadUserState = async () => {
    setLoading((v) => ({ ...v, userState: true }));
    try {
      let state = await getUserState();
      setUserState(state);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading((v) => ({ ...v, userState: false }));
    }
  };

  const title = () => {
    if (survey.survey_id === "0") {
      return (
        <>
          {survey.title} {moment(survey.date).format("MM/DD/YYYY")}{" "}
          {isNew ? (
            <span className="text-renuma-violet-primary"> (New)</span>
          ) : (
            ""
          )}
        </>
      );
    } else if (survey.survey_id === "1") {
      return (
        <>
          {survey.title}
          {isNew ? (
            <span className="text-renuma-violet-primary"> (New)</span>
          ) : (
            ""
          )}
        </>
      );
    }
  };

  const handleClickButton = (item) => {
    switch (item) {
      case "Order Sleep Test":
        history.push("/onboarding/order_sleep_test");
        break;
      case "Schedule an Appointment Free":
        if (userState.Home_Study.Completed === false) {
          ModalWarning({
            warningText:
              "You can make an appointment when you receive the result of the Home Sleep Test",
          });
        } else if (userState.Telemedicine.Free === true) {
          ModalWarning({
            warningText: "You already scheduled your virtual consultation",
          });
        } else {
          history.push("/onboarding/telemedicine/free");
        }
        break;
      case "Schedule an Appointment by $15":
        history.push("/onboarding/telemedicine");
        break;
    }
  };

  const handleRetakeEvaluation = () => {
    history.push("/onboarding/survey");
  };

  const actionButtons = () => {
    if (survey.survey_id === "0") {
      return (
        <div className="mt-10">
          <div className="flex flex-row space-x-6 mb-8">
            {survey.buttons.map((item, key) => {
              return (
                <PrimaryButton
                  key={key}
                  onClick={() => handleClickButton(item)}
                >
                  {item}
                </PrimaryButton>
              );
            })}
          </div>
          {survey.buttons.retake_sleep_apnea_evaluation && (
            <Button
              type="link"
              className="text-renuma-violet-primary text-underline"
              onClick={handleRetakeEvaluation}
            >
              Retake Sleep Apnea Survey
            </Button>
          )}
        </div>
      );
    }
  };

  const parseHtml = (text) => {
    const splitted = text.split("<br/>");
    let array = [];
    let number = 1;
    splitted.forEach((e, k) => {
      if (e === "") return;
      array.push(
        <div
          key={k}
          className="flex flex-row justify-start items-center bg-white min-h-14 rounded-full my-6 py-4 px-6"
        >
          <div className="h-16 w-16 flex flex-row justify-center items-center border-2 border-renuma-violet-primary rounded-full mr-4 text-4xl text-renuma-violet-primary font-bold">
            {number}
          </div>
          <div className="flex flex-1 pr-6">{e}</div>
        </div>
      );
      number++;
    });
    return array;
  };

  const handleClickDownloadAnswers = () => {
    if (survey.answers_triggers) {
      downloadAnswersPdf();
    } else {
      ModalWarning({
        warningText: "Error getting patient survey answers",
      });
    }
  };

  const downloadAnswersPdf = async () => {
    try {
      const res = await getSurvey(0);
      const surveyJson = { pages: res.Item.pages };

      const exportToPdfOptions = { fontSize: 12 };
      const surveyPdf = new SurveyPDF(surveyJson, exportToPdfOptions);
      surveyPdf.data = survey.answers_triggers;
      surveyPdf.save();
    } catch (error) {}
  };

  return (
    <Panel
      title={title()}
      extra={<Link to="/dashboard/surveys">View all</Link>}
      actions={[<img src={viewMore} />]}
      borderHeader={survey.survey_id === "0"}
      highlighted={isNew}
      collapsed={collapsed}
    >
      {survey.description && (
        <div className="mt-6 mb-11 bg-dashboardHome bg-right-8-top-4 bg-no-repeat text-base text-secondary-text text-justify leading-normal">
          {parseHtml(survey.description)}
          {actionButtons()}

          {/* {survey.answers_triggers !== undefined && (
            <SecondaryButton onClick={handleClickDownloadAnswers}>
              Download Answers (PDF)
            </SecondaryButton>
          )} */}
        </div>
      )}
    </Panel>
  );
};
const NoSurveys = () => {
  const history = useHistory();
  return (
    <Card
      className="card-dashboard card-actions-right rounded-md drop-shadow mb-6 md:mb-0 w-full"
      title="You have no surveys"
    >
      <div className="flex flex-col h-full">
        <p className="font-body text-base text-secondary-text leading-normal">
          A provider will upload a survey as part of your care plan when
          relevant.
        </p>
        <div className="flex flex-1 flex-row justify-center items-end">
          <PrimaryButton
            onClick={() => {
              history.push("/onboarding/survey");
            }}
          >
            Take a survey
          </PrimaryButton>
        </div>
      </div>
    </Card>
  );
};
const ErrorSurveys = ({ message, description = "" }) => {
  const history = useHistory();
  return (
    <Card
      className="card-dashboard card-actions-right rounded-md drop-shadow mb-6 md:mb-0 w-full"
      title="Error"
    >
      <div className="flex flex-col h-full">
        <Alert
          message={message}
          description={description}
          type="error"
          showIcon
        />
      </div>
    </Card>
  );
};

export default SurveyPreview;
export { NoSurveys, ErrorSurveys };
