import React, { Fragment, useEffect, useState } from 'react';
import { Alert, Breadcrumb, Button, Card, Modal, Tag } from 'antd';
import { cancelSubscription, getCharges, getSubscriptions, startSubscription } from '../../APIClient';
import LoadingCard from '../../components/LoadingCard';
import { centsToFormattedValue } from '../../utils';
const { confirm } = Modal;
const NoCharges = () => (<Card style={{ margin: '24px 0px' }}>
    <Card.Meta title='You have no charges' description='Charges will appear here as your make orders or subscribe to a treatment plan.'/>
  </Card>);
const ChargePreview = ({ charge }) => (<Card style={{ margin: '24px 0px' }}>
    <Card.Meta title={<>
          <span>Charge on {new Date(charge.created * 1000).toLocaleDateString()}</span>
          <Tag style={{ float: 'right' }} color={charge.paid ? 'green' : 'yellow'}>{charge.paid ? `Paid` : `Needs Action`}</Tag>
        </>} description={<>
          <span>Amount: </span><span>{centsToFormattedValue(charge.amount)}</span><br />
          <span>Description: </span><span>{charge.description}</span>
        </>}/>
  </Card>);
const NoSubscription = ({ onSubscriptionAdded }) => {
    const [errorMsg, setErrorMsg] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const onOk = async () => {
        try {
            setIsLoading(true);
            const subscription = await startSubscription();
            setIsLoading(false);
            onSubscriptionAdded();
        }
        catch (e) {
            setErrorMsg(e.message);
        }
    };
    const onClick = () => {
        confirm({
            title: `Please confirm you'd like to enroll in a Renuma treatment subscription.`,
            content: `Our treatment plan costs $99/mo but covers all of your sleep needs.`,
            okText: 'Confirm',
            onOk,
        });
    };
    return (<Card style={{ margin: '24px 0px' }} loading={isLoading}>
      <Card.Meta title='Start a subscription ($99/mo)' description='You can set up a monthly subscription to handle all of your sleep needs, from consults to CPAP supplies.'/>
      <Button type='primary' style={{ marginTop: 24 }} onClick={onClick}>Sign me up</Button>
    </Card>);
};
const ActiveSubscription = ({ subscription, onSubscriptionCancelled }) => {
    const [errorMsg, setErrorMsg] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const onOk = async () => {
        try {
            setIsLoading(true);
            await cancelSubscription({ subscriptionId: subscription.id });
            setIsLoading(false);
            onSubscriptionCancelled();
        }
        catch (e) {
            setErrorMsg(e.message);
        }
    };
    const onClick = () => {
        confirm({
            title: `Please confirm you'd like to cancel your Renuma treatment subscription`,
            content: `You will be responsible for this months bill but will receive no future ones`,
            okText: 'Confirm',
            onOk,
            okButtonProps: { danger: true }
        });
    };
    return (<Card style={{ margin: '24px 0px' }} loading={isLoading}>
      {errorMsg && <Alert message={errorMsg} type='error' style={{ marginBottom: 24 }} closable onClose={() => setErrorMsg('')}/>}
      <Card.Meta title='Active subscription' description={<>
            <span>Created on:</span> <span>{new Date(subscription.details.created * 1000).toLocaleDateString()}</span><br />
            <span>Amount:</span> <span>{centsToFormattedValue(subscription.details.plan.amount)}</span><br />
            <span>Current period:</span> <span>{new Date(subscription.details.current_period_start * 1000).toLocaleDateString()} - {new Date(subscription.details.current_period_end * 1000).toLocaleDateString()}</span><br />
          </>}/>
      <Button type='primary' style={{ marginTop: 24 }} danger onClick={onClick}>Cancel subscription</Button>
    </Card>);
};
const BillingScreen = () => {
    const [charges, setCharges] = useState([]);
    const [isLoadingCharges, setIsLoadingCharges] = useState(false);
    const [subscription, setSubscription] = useState(null);
    const [isLoadingSubscription, setIsLoadingSubscription] = useState(false);
    const loadCharges = async () => {
        setIsLoadingCharges(true);
        const charges = await getCharges();
        setCharges(charges);
        setIsLoadingCharges(false);
    };
    const loadSubscription = async () => {
        setIsLoadingSubscription(true);
        const _subscriptions = await getSubscriptions();
        const _activeSubscriptions = _subscriptions.filter((sub) => sub.is_active);
        if (_activeSubscriptions.length > 0) {
            setSubscription(_activeSubscriptions[0]);
        }
        setIsLoadingSubscription(false);
    };
    useEffect(() => {
        loadCharges();
        loadSubscription();
    }, []);
    return (<Fragment>
      <Breadcrumb>
        <Breadcrumb.Item key='account'>Account</Breadcrumb.Item>
        <Breadcrumb.Item key='settongs'>Billing</Breadcrumb.Item>
      </Breadcrumb>
      <div style={{ margin: '24px 0px' }}>
        <p>Charges</p>
        {isLoadingCharges && (<LoadingCard />)}
        {!isLoadingCharges && charges.length === 0 && <NoCharges />}
        {!isLoadingCharges && charges.length > 0 && (charges.map(charge => <ChargePreview key={charge.id} charge={charge}/>))}
      </div>
      <div style={{ margin: '24px 0px' }}>
        <p>Subscription</p>
        {isLoadingSubscription && (<LoadingCard />)}
        {!isLoadingSubscription && !subscription && <NoSubscription onSubscriptionAdded={loadSubscription}/>}
        {!isLoadingSubscription && subscription && (<ActiveSubscription subscription={subscription} onSubscriptionCancelled={loadSubscription}/>)}
      </div>
    </Fragment>);
};
export default BillingScreen;
