import React, { useState, useEffect } from "react";
import { Card } from "antd";
import { Link, useHistory } from "react-router-dom";
import { MinusCircleFilled, PlusCircleFilled } from "@ant-design/icons";
import { ModalWarning, getDateRange, getDate, isPastDate } from "../utils";
import stethoscope from "../assets/svgs/stethoscope.svg";
import PrimaryButton from "./PrimaryButton";
import moment from "moment";
import moment_tz from "moment-timezone";

const AppointmentPreview = ({ appointments, index, open }) => {
  const [upcoming, setUpcoming] = useState(null);
  const [past, setPast] = useState(null);

  useEffect(() => {
    if (appointments !== undefined) {
      const items = [...appointments];
      setUpcoming(items.filter((i) => isUpcoming(i)));
      setPast(items.filter((i) => !isUpcoming(i)));
    }
  }, [appointments]);

  const isUpcoming = (appointment) => {
    const dateNY = moment_tz.tz("America/New_York");
    return moment(appointment.date_time).tz("America/New_York").isAfter(dateNY);
  };

  return (
    <>
      {upcoming !== null && upcoming.length > 0 && (
        <UpcomingAppointments appointments={upcoming} />
      )}
      {past !== null && past.length > 0 && (
        <PastAppointments appointments={past} />
      )}
    </>
  );
};

const UpcomingAppointments = ({ appointments }) => {
  const [collapsed, setCollapsed] = useState(false);

  const toggleCard = () => {
    setCollapsed((v) => !v);
  };
  return (
    <Card
      className={`card-dashboard card-body-1 rounded-md drop-shadow w-full mb-6 bg-renuma-violet-400`}
      title={
        <div
          className={`flex flex-row justify-between items-center ${
            collapsed ? "" : "border-b border-gray-200 pb-4"
          }`}
        >
          <div>Upcoming Appointment</div>
          <div>
            <div onClick={toggleCard}>
              {collapsed ? (
                <PlusCircleFilled className="text-4xl text-renuma-violet-primary cursor-pointer" />
              ) : (
                <MinusCircleFilled className="text-4xl text-renuma-violet-primary cursor-pointer" />
              )}
            </div>
          </div>
        </div>
      }
    >
      {!collapsed &&
        appointments.map((appointment) => (
          <div className="flex flex-row space-x-6 pt-4 pb-16">
            <div className="">
              <img src={stethoscope} className="h-8 w-8 mt-2" />
            </div>
            <div>
              <p className="text-base font-semibold text-primary-text leading-normal mb-0">
                {appointment !== null && (
                  <>
                    {appointment.professional.full_name}
                    <br />
                    {getDate(appointment.date_time)}
                    <br />
                    {getDateRange(appointment.date_time, 15)}
                    <br />
                    <span className="font-normal">
                      click{" "}
                      <button
                        className="text-renuma-violet-primary font-semibold"
                        type="link"
                        onClick={() => {
                          window.open(
                            appointment.telehealth !== undefined
                              ? appointment.telehealth
                              : "https://telehealth.kareo.com/davidrosenmd"
                          );
                        }}
                      >
                        here
                      </button>{" "}
                      when it’s time to start your scheduled appointment
                    </span>
                    <br />
                    <br />
                    <Link
                      to={`/onboarding/telemedicine/${appointment.date_time}_${appointment.id}`}
                      className="text-renuma-violet-primary font-normal underline"
                    >
                      Edit
                    </Link>
                  </>
                )}
              </p>
            </div>
          </div>
        ))}
    </Card>
  );
};

const PastAppointments = ({ appointments }) => {
  const [collapsed, setCollapsed] = useState(false);

  const toggleCard = () => {
    setCollapsed((v) => !v);
  };
  return (
    <Card
      className={`card-dashboard card-body-1 rounded-md drop-shadow w-full mb-6 `}
      title={
        <div
          className={`flex flex-row justify-between items-center ${
            collapsed ? "" : "border-b border-gray-200 pb-4"
          }`}
        >
          <div>Past Appointment</div>
          <div>
            <div onClick={toggleCard}>
              {collapsed ? (
                <PlusCircleFilled className="text-4xl text-renuma-violet-primary cursor-pointer" />
              ) : (
                <MinusCircleFilled className="text-4xl text-renuma-violet-primary cursor-pointer" />
              )}
            </div>
          </div>
        </div>
      }
    >
      {!collapsed &&
        appointments.map((appointment, k) => (
          <div key={k} className="flex flex-row space-x-6 pt-2 pb-8">
            <div className="">
              <img src={stethoscope} className="h-8 w-8 mt-2" />
            </div>
            <div className="border-b border-gray-200 pb-8 w-1/2">
              <p className="text-base font-semibold text-primary-text leading-normal mb-0">
                {appointment !== null && (
                  <>
                    {appointment.professional.full_name}
                    <br />
                    {getDate(appointment.date_time)}
                    <br />
                    {getDateRange(appointment.date_time, 15)}
                  </>
                )}
              </p>
            </div>
          </div>
        ))}
    </Card>
  );
};

const NewAppointment = ({ onClick }) => {
  const [collapsed, setCollapsed] = useState(false);
  const history = useHistory();

  const toggleCard = () => {
    setCollapsed((v) => !v);
  };

  const handleBookAppointment = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <Card
      className={`card-dashboard card-body-1 rounded-md drop-shadow w-full mb-6 bg-white`}
      title={
        <div
          className={`flex flex-row justify-between items-center ${
            collapsed ? "" : "border-b border-gray-200 pb-4"
          }`}
        >
          <div>Schedule an Appointment</div>
          <div>
            <div onClick={toggleCard}>
              {collapsed ? (
                <PlusCircleFilled className="text-4xl text-renuma-violet-primary cursor-pointer" />
              ) : (
                <MinusCircleFilled className="text-4xl text-renuma-violet-primary cursor-pointer" />
              )}
            </div>
          </div>
        </div>
      }
    >
      {!collapsed && (
        <div className="flex flex-col pt-4 pb-16">
          <h3 className="text-base font-semibold text-lg text-primary-text leading-normal">
            Book a 15-Minute Virtual Check-in with a Sleep Doctor
          </h3>
          <p className="text-base font-normal text-primary-text leading-normal">
            Once you’ve booked a time to meet with a sleep doctor, check your
            email for your confirmation and details on how to join the
            appointment.
          </p>
          <p className="text-base font-normal text-primary-text leading-normal mb-0">
            Optional Virtual Check-ins are $15 each.
          </p>
          <div className="flex flex-start mt-8">
            <PrimaryButton onClick={handleBookAppointment}>
              Book Appointment
            </PrimaryButton>
          </div>
        </div>
      )}
    </Card>
  );
};
const NoAppointments = ({ userState }) => {
  const history = useHistory();
  const [hasSurvey, setHasSurvey] = useState(false);

  useEffect(() => {
    if (userState !== null && userState.Surveys.Completed === true) {
      setHasSurvey(true);
    }
  }, [userState]);

  const onScheduleAnAppointment = () => {
    if (hasSurvey) {
      history.push("/onboarding/telemedicine");
    } else {
      ModalWarning({
        warningText:
          "You haven't taken the survey yet, please do it first before ordering your test/scheduling an appointment",
      });
    }
  };

  return (
    <Card
      className="card-dashboard card-body-1 bg-renuma-violet-400 rounded-md drop-shadow w-full pb-16 mb-6"
      title="You have no appointments scheduled"
    >
      <h3 className="text-base font-semibold text-lg text-primary-text leading-normal">
        Book a 15-Minute Virtual Check-in with a Sleep Doctor
      </h3>
      <p className="text-base font-normal text-primary-text leading-normal">
        Once you’ve booked a time to meet with a sleep doctor, check your email
        for your confirmation and details on how to join the appointment.
      </p>
      <p className="text-base font-normal text-primary-text leading-normal mb-6">
        Optional Virtual Check-ins are $15 each.
      </p>
      <PrimaryButton onClick={onScheduleAnAppointment}>
        Book Appointment
      </PrimaryButton>
    </Card>
  );
};
export default AppointmentPreview;
export { NoAppointments, NewAppointment };
