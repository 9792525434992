import React from 'react';
const EyeHide = () => (<svg width="55" height="17" viewBox="0 0 55 17" fill="currentColor" xmlns="http://www.w3.org/2000/svg"> <path d="M32.5961
3.24201V13H31.3221V8.57601H26.3521V13H25.0781V3.24201H26.3521V7.52601H31.3221V3.24201H32.5961Z"/> <path d="M35.4295 4.08201C35.1868 4.08201 34.9815
3.99801 34.8135 3.83001C34.6455 3.66201 34.5615 3.45667 34.5615 3.21401C34.5615 2.97134 34.6455 2.76601 34.8135 2.59801C34.9815 2.43001 35.1868 2.34601 35.4295
2.34601C35.6628 2.34601 35.8588 2.43001 36.0175 2.59801C36.1855 2.76601 36.2695 2.97134 36.2695 3.21401C36.2695 3.45667 36.1855 3.66201 36.0175 3.83001C35.8588
3.99801 35.6628 4.08201 35.4295 4.08201ZM36.0455 5.32801V13H34.7715V5.32801H36.0455Z"/> <path d="M37.7408 9.13601C37.7408 8.35201 37.8995 7.66601
38.2168 7.07801C38.5341 6.48068 38.9681 6.01867 39.5188 5.69201C40.0788 5.36534 40.7041 5.20201 41.3948 5.20201C41.9921 5.20201 42.5475 5.34201 43.0608
5.62201C43.5741 5.89267 43.9661 6.25201 44.2368 6.70001V2.64001H45.5248V13H44.2368V11.558C43.9848 12.0153 43.6115 12.3933 43.1168 12.692C42.6221 12.9813 42.0435
13.126 41.3808 13.126C40.6995 13.126 40.0788 12.958 39.5188 12.622C38.9681 12.286 38.5341 11.8147 38.2168 11.208C37.8995 10.6013 37.7408 9.91068 37.7408
9.13601ZM44.2368 9.15001C44.2368 8.57134 44.1201 8.06734 43.8868 7.63801C43.6535 7.20867 43.3361 6.88201 42.9348 6.65801C42.5428 6.42468 42.1088 6.30801 41.6328
6.30801C41.1568 6.30801 40.7228 6.42001 40.3308 6.64401C39.9388 6.86801 39.6261 7.19467 39.3928 7.62401C39.1595 8.05334 39.0428 8.55734 39.0428 9.13601C39.0428
9.72401 39.1595 10.2373 39.3928 10.676C39.6261 11.1053 39.9388 11.4367 40.3308 11.67C40.7228 11.894 41.1568 12.006 41.6328 12.006C42.1088 12.006 42.5428 11.894
42.9348 11.67C43.3361 11.4367 43.6535 11.1053 43.8868 10.676C44.1201 10.2373 44.2368 9.72867 44.2368 9.15001Z"/> <path d="M54.6777 8.87001C54.6777
9.11268 54.6637 9.36934 54.6357 9.64001H48.5037C48.5504 10.396 48.8071 10.9887 49.2737 11.418C49.7497 11.838 50.3237 12.048 50.9957 12.048C51.5464 12.048 52.0037
11.922 52.3677 11.67C52.7411 11.4087 53.0024 11.0633 53.1517 10.634H54.5237C54.3184 11.3713 53.9077 11.9733 53.2917 12.44C52.6757 12.8973 51.9104 13.126 50.9957
13.126C50.2677 13.126 49.6144 12.9627 49.0357 12.636C48.4664 12.3093 48.0184 11.8473 47.6917 11.25C47.3651 10.6433 47.2017 9.94334 47.2017 9.15001C47.2017 8.35667
47.3604 7.66134 47.6777 7.06401C47.9951 6.46667 48.4384 6.00934 49.0077 5.69201C49.5864 5.36534 50.2491 5.20201 50.9957 5.20201C51.7237 5.20201 52.3677 5.36067
52.9277 5.67801C53.4877 5.99534 53.9171 6.43401 54.2157 6.99401C54.5237 7.54467 54.6777 8.17001 54.6777 8.87001ZM53.3617 8.60401C53.3617 8.11868 53.2544 7.70334
53.0397 7.35801C52.8251 7.00334 52.5311 6.73734 52.1577 6.56001C51.7937 6.37334 51.3877 6.28001 50.9397 6.28001C50.2957 6.28001 49.7451 6.48534 49.2877
6.89601C48.8397 7.30668 48.5831 7.87601 48.5177 8.60401H53.3617Z"/> <path d="M7.95327 10.0313C8.48536 10.0313 8.99566 9.81989 9.3719 9.44364C9.74815
9.0674 9.95952 8.5571 9.95952 8.02501C9.95952 7.96626 9.95683 7.90804 9.95182 7.85054L7.7788 10.0236C7.8363 10.0286 7.89433 10.0313 7.95327 10.0313ZM14.594
1.81925L13.8287 1.05473C13.8018 1.02787 13.7654 1.01279 13.7274 1.01279C13.6894 1.01279 13.653 1.02787 13.6261 1.05473L11.6679 3.01351C10.5876 2.46143 9.37329
2.18539 8.02492 2.18539C4.58205 2.18539 2.01334 3.97848 0.318771 7.56465C0.250658 7.70888 0.215332 7.8664 0.215332 8.02591C0.215332 8.18541 0.250658 8.34294
0.318771 8.48717C0.99588 9.91339 1.81122 11.0565 2.76478 11.9166L0.869773 13.8109C0.842919 13.8378 0.827833 13.8742 0.827833 13.9122C0.827833 13.9502 0.842919
13.9866 0.869773 14.0135L1.63448 14.7782C1.66135 14.805 1.69778 14.8201 1.73577 14.8201C1.77376 14.8201 1.8102 14.805 1.83707 14.7782L14.594 2.02203C14.6073 2.00872
14.6178 1.99291 14.6251 1.97551C14.6323 1.95812 14.636 1.93947 14.636 1.92064C14.636 1.90181 14.6323 1.88316 14.6251 1.86576C14.6178 1.84836 14.6073 1.83256 14.594
1.81925ZM4.80059 8.02501C4.80054 7.48047 4.94154 6.9452 5.20984 6.47134C5.47815 5.99749 5.86462 5.60121 6.3316 5.3211C6.79858 5.041 7.33015 4.88663 7.87452
4.87303C8.41889 4.85943 8.95751 4.98706 9.43789 5.24349L8.56697 6.11442C8.21573 6.00195 7.8403 5.98841 7.48187 6.07527C7.12344 6.16213 6.79587 6.34604 6.53508
6.60683C6.2743 6.86761 6.09039 7.19519 6.00353 7.55361C5.91666 7.91204 5.93021 8.28747 6.04267 8.63871L5.17175 9.50964C4.92734 9.053 4.79983 8.54294 4.80059
8.02501V8.02501Z"/> <path d="M15.7306 7.56283C15.1001 6.23489 14.3496 5.15206 13.4792 4.31433L10.8972 6.89647C11.1148 7.46541 11.1631 8.0852 11.0361
8.68096C10.9091 9.27673 10.6123 9.82297 10.1816 10.2537C9.75083 10.6844 9.20458 10.9812 8.60882 11.1082C8.01305 11.2352 7.39326 11.187 6.82431 10.9693L4.63428
13.1593C5.6503 13.6295 6.78037 13.8646 8.02448 13.8646C11.4674 13.8646 14.0361 12.0715 15.7306 8.48534C15.7988 8.34112 15.8341 8.18359 15.8341 8.02409C15.8341
7.86458 15.7988 7.70706 15.7306 7.56283V7.56283Z"/> </svg>);
export default EyeHide;
