import React from 'react'

const InitialLoader = () => {
    return (
        <div 
            className="bg-clouds flex flex-row justify-center items-center" 
            style={{height: '100vh', width: '100vw'}}
        >
            <div className="text-base text-renuma-violet-primary">Loading...</div>
        </div>
    )
}

export default InitialLoader
