import React from "react";
import { Button, Card, Tag } from "antd";
import { Link } from "react-router-dom";
import { getDateRange, getDate } from "../utils";
import stethoscope from "../assets/svgs/stethoscope.svg";
import { useHistory } from "react-router-dom";
import PrimaryButton from "./PrimaryButton";

const HomeAppointmentPreview = ({ appointment }) => {
  return (
    <Card
      className="card-dashboard card-body-1 bg-renuma-violet-400 rounded-md drop-shadow h-full w-full"
      title={"Upcoming Appointment"}
    >
      <div className="flex flex-row space-x-6">
        <div className="">
          <img src={stethoscope} className="h-8 w-8 mt-2" />
        </div>
        <div>
          <p className="text-base font-semibold text-primary-text leading-normal mb-4">
            {appointment !== null && appointment.professional && (
              <>
                {appointment.professional.full_name}
                <br />
                {getDate(appointment.date_time)}
                <br />
                {getDateRange(appointment.date_time, 15)}
                <br />
                <Link
                  to={`/onboarding/telemedicine/${appointment.date_time}_${appointment.id}`}
                  className="text-renuma-violet-primary font-normal underline"
                >
                  Edit
                </Link>
              </>
            )}
          </p>
        </div>
      </div>
    </Card>
  );
};
const NoAppointments = () => {
  const history = useHistory();

  return (
    <Card
      className="card-dashboard card-body-1 bg-renuma-violet-400 rounded-md drop-shadow h-full w-full pb-6"
      title="You have no appointments scheduled"
    ></Card>
  );
};
export default HomeAppointmentPreview;
export { NoAppointments };
