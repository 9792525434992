import React, { useEffect, useState } from "react";
import { getAppointments, getUserState } from "../../APIClient";
import LoadingCard from "../../components/LoadingCard";
import AppointmentPreview, {
  NewAppointment,
  NoAppointments,
} from "../../components/AppointmentPreview";
import { ModalWarning } from "../../utils";
import { useHistory } from "react-router-dom";

const AppointmentsScreen = ({ user }) => {
  const history = useHistory();
  const [loading, setLoading] = useState({
    userState: false,
    appointments: false,
    surveys: false,
    orders: false,
  });

  const [userState, setUserState] = useState(null);
  const [appointments, setAppointments] = useState([]);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [hasSurvey, setHasSurvey] = useState(false);
  const [showNewAppointmentModal, setShowNewAppointmentModal] = useState(false);
  useEffect(() => {
    loadAppointments();
    loadUserState();
    console.log(appointments);
  }, []);
  const loadAppointments = async () => {
    try {
      setIsLoading(true);
      const appointments = await getAppointments();
      setAppointments(appointments);
      setIsLoading(false);
    } catch (e) {
      setError(e.message);
      setIsLoading(false);
    }
  };

  const loadUserState = async () => {
    setLoading((v) => ({ ...v, userState: true }));
    try {
      const state = await getUserState();

      setUserState(state);
      setHasSurvey(state.Surveys.Completed);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading((v) => ({ ...v, userState: false }));
    }
  };

  const handleBookAppointment = () => {
    if (userState.Surveys.Completed) {
      history.push("/onboarding/telemedicine");
    } else {
      ModalWarning({
        warningText:
          "You haven't taken the survey yet, please do it first before ordering your test/scheduling an appointment",
      });
    }
  };

  return (
    <>
      <h3 className="text-3xl font-bold text-primary-text mt-4">
        Appointments
      </h3>
      {isLoading && (
        <>
          <LoadingCard />
          <div className="mb-6"></div>
        </>
      )}
      {!isLoading && appointments.length === 0 && (
        <NoAppointments userState={userState} />
      )}
      {!isLoading && appointments.length > 0 && (
        <AppointmentPreview appointments={appointments} />
      )}
      {hasSurvey === true && <NewAppointment onClick={handleBookAppointment} />}
    </>
  );
};
export default AppointmentsScreen;
