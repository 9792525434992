import React, { Fragment, useState, useEffect } from "react";
import { Breadcrumb, Spin } from "antd";
import { getSurveysAnswer } from "../../APIClient";
import SurveyPreview, {
  NoSurveys,
  ErrorSurveys,
} from "../../components/SurveyPreview";
import LoadingCard from "../../components/LoadingCard";
const SurveysScreen = () => {
  var _a;
  const [surveys, setSurveys] = useState([]);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    loadSurveys();
  }, []);

  const loadSurveys = async () => {
    try {
      setIsLoading(true);
      const surveys = await getSurveysAnswer();
      if (surveys) {
        setSurveys(surveys);
      }
    } catch (e) {
      let msg_error = e.message;
      if (e.message == " '0'") {
        msg_error = "Error fetching data";
      }
      setError(msg_error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <h3 className="text-3xl font-bold text-primary-text mt-4">Surveys</h3>

      {!isLoading &&
        surveys.length > 0 &&
        surveys.map((survey, key) => (
          <SurveyPreview
            survey={survey}
            key={key}
            index={key}
            collapsed={key !== 0}
          />
        ))}
      {!isLoading && !error && surveys.length === 0 && <NoSurveys />}
      {!isLoading && error && surveys.length === 0 && (
        <ErrorSurveys message={error} />
      )}
      {isLoading && <LoadingCard />}
    </>
  );
};
export default SurveysScreen;
