import React, { useState, useRef, useEffect } from "react";
import { Alert, Button, Card, Form, Input, Spin, Modal } from "antd";
import { useHistory, useLocation } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import Section from "../../components/Section";
import PrimaryButton from "../../components/PrimaryButton";
import { Auth } from "aws-amplify";
import { useAuth } from "../../providers/auth.context";
import { LoadingOutlined } from "@ant-design/icons";
import CheckIcon from "../../assets/svgs/check.svg";
// App constants
import { AUTH_USER_TOKEN_KEY, USER_PROFILE } from "../../utils";
import { getProfile } from "../../APIClient";



const ConfirmationCodeScreen = () => {
  const [error, setError] = useState("");
  const [sucess, setSuccess] = useState("");
  const [values, setValues] = useState({
    code: "",
    email: sessionStorage.getItem("email_ccode") || "",
  });
  const [loading, setLoading] = useState(false);
  const [loadingStartEvaluation, setLoadingStartEvaluation] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const history = useHistory();
  const { state: locationState } = useLocation();
  const simpleValidator = useRef(
    new SimpleReactValidator({
      element: (message) => (
        <div className="rnm-input-error text-xs">{message}</div>
      ),
    })
  );

  const { registeringUser, updateRegisteringUser, updateCurrentUser } =
    useAuth();

  useEffect(() => {
      if(locationState?.alreadyVerify){
        sessionStorage.removeItem("email_ccode");
        showModal();
      }
  }, [locationState]);
  

  const disabled = () => !simpleValidator.current.allValid();
  const onSubmit = async () => {
    setSuccess("");
    setError("");
    setLoading(true);
    if (!values.code.trim() && values.code.trim().length !== 6) {
      setError("Invalid confirmation code");
      return;
    }
    try {
      await Auth.confirmSignUp(values.email, values.code.trim());
      sessionStorage.removeItem("email_ccode");
      showModal();
    } catch (e) {
      setError(e.message);
      setLoading(false);
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;

    setValues((v) => ({ ...v, [name]: value.replace(/[^\d]/gi, "") }));
  };
  const handleBlur = (e) => {
    const { name, value } = e.target;
    simpleValidator.current.showMessageFor(name);
    setValues((v) => ({ ...v, [name]: value }));
  };
  const handleResendCodeClick = async () => {
    setSuccess("");
    setError("");
    try {
      await Auth.resendSignUp(values.email);
      setSuccess("Code resent successfully");
    } catch (e) {
      setError(e.message);
    }
  };
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleModalStart = async (path = "/onboarding/survey") => {
    setLoadingStartEvaluation(true);
    try {
      const user = await Auth.signIn(
        registeringUser.username,
        registeringUser.password
      );

      if (user) {
        localStorage.setItem(
          AUTH_USER_TOKEN_KEY,
          user.signInUserSession.accessToken.jwtToken
        );

        const profile = await getProfile();
        localStorage.setItem(USER_PROFILE, JSON.stringify(profile.Item));
        updateRegisteringUser({});
        updateCurrentUser({
          email: user.username,
          username: user.username,
          token: user.storage.accessToken,
          is_onboarded: false,
        });
      }

      history.push(path);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Section className="bg-login bg-cover bg-no-repeat h-screen md:h-full pb-0">
      {loadingStartEvaluation && (
        <div
          className="flex flex-row justify-center items-center w-full"
          style={{ minHeight: "100vh" }}
        >
          <Spin indicator={<LoadingOutlined style={{ color: "#6C009E" }} />} />
        </div>
      )}
      {!loadingStartEvaluation && (
        <>
          <div
            className="-mx-6 md:m-auto w-screen md:w-auto max-w-lg h-full flex items-start md:items-center bg-white md:bg-transparent"
            data-aos="fade-up"
            data-aos-duration="700"
            data-aos-delay="0"
          >
            <Card className="w-full">
              <h3 className="text-3xl font-bold text-center mt-5 mb-0">
                Confirmation Code
              </h3>
              <p className="text-xs leading-normal mt-6 mx-8">
                Please enter your verification code. If you didn't see the email
                with it, please check your “junk mail” folder or “spam” folder
                or click the button below to re-send it.
              </p>

              {error && (
                <Alert
                  message={error}
                  type="error"
                  style={{ marginBottom: 24 }}
                  closable
                  onClose={() => setError("")}
                />
              )}

              {sucess && (
                <Alert
                  message={sucess}
                  type="success"
                  style={{ marginBottom: 24 }}
                  closable
                  onClose={() => setSuccess("")}
                />
              )}
              <Form className="mt-6 mx-0 md:mx-7 mb-14">
                <Form.Item>
                  <div className="text-base font-body">Email</div>
                  <Input
                    name="email"
                    size="large"
                    className="rnm-input"
                    value={values.email}
                    disabled
                  />
                </Form.Item>
                <Form.Item>
                  <div className="text-base font-body">Code</div>
                  <Input
                    name="code"
                    size="large"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="rnm-input"
                    value={values.code}
                  />
                  {simpleValidator.current.message(
                    "code",
                    values.code,
                    "required|numeric|size:6"
                  )}
                </Form.Item>
                <Form.Item className="mt-6">
                  <PrimaryButton
                    disabled={disabled() || loading}
                    onClick={onSubmit}
                    className="w-full"
                  >
                    {loading ? (
                      <Spin
                        indicator={
                          <LoadingOutlined style={{ color: "#FFFFFF" }} />
                        }
                      />
                    ) : (
                      "Submit Code"
                    )}
                  </PrimaryButton>
                </Form.Item>

                <div className="text-center text-base font-body">
                  Forgot your code?
                  <Button
                    size="large"
                    type="link"
                    onClick={handleResendCodeClick}
                    className="font-body p-0 ml-2 text-renuma-violet-primary"
                  >
                    Resend code
                  </Button>
                </div>
              </Form>
            </Card>
          </div>
          <Modal
            visible={isModalVisible}
            footer={null}
            width="70%"
            centered={true}
            style={{ paddingBottom: 0 }}
            closable={false}
            maskStyle={{ backgroundColor: "rgba(0,0,0,.9)" }}
          >
            <div className="flex justify-center h-16 mt-10 mb-10">
              <img src={CheckIcon} className="h-full w-auto" />
            </div>
            <h3 className="text-xl2 font-bold text-center mb-4">
              Your account has been created.
            </h3>
            <p className="text-base text-center mb-8 leading-snug font-body max-w-lg mx-auto">
              Fill out the sleep survey so you can learn about sleep apnea and
              receive a free sleep apnea risk report in your email inbox..
            </p>
            <div className="mb-6 text-center">
              <PrimaryButton
                size="large"
                type="link"
                onClick={() => handleModalStart("/onboarding/survey")}
              >
                Start Symptoms of OSA Survey
              </PrimaryButton>
            </div>
            <div className="text-center mb-8">
              <Button
                size="large"
                type="link"
                onClick={() => handleModalStart("/dashboard")}
                className="p-0"
              >
                Skip
              </Button>
            </div>
          </Modal>
        </>
      )}
    </Section>
  );
};
export default ConfirmationCodeScreen;
