import React, { useEffect, useState } from "react";
import { Card, Image, Button } from "antd";
import { useHistory } from "react-router-dom";
import pdfIcon from "../assets/svgs/pdf_icon.svg";
import Panel from "./Panel";
import PrimaryButton from "./PrimaryButton";
import { getUserState } from "../APIClient";
import { ModalWarning } from "../utils";

const NoResults = () => (
  <Card style={{ margin: "24px 0px" }}>
    <Card.Meta
      title="You have no results"
      description="A provider will upload results from at-home tests and CPAP machines when relevant."
    />
  </Card>
);
const ResultPreview = ({ result, collapsed = true }) => {
  const history = useHistory();
  const [userState, setUserState] = useState(null);
  const date = new Date(result.uploaded).toLocaleDateString(undefined, {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  });

  useEffect(() => {
    loadUserState();
  }, []);

  const loadUserState = async () => {
    try {
      let state = await getUserState();
      setUserState(state);
    } catch (error) {
      console.log(error);
    }
  };

  const onOpenFile = () => {
    window.open(result.link);
  };

  const clickGetStarted = () => {
    if (userState.Telemedicine.Free === true) {
      ModalWarning({
        warningText: "You already scheduled your virtual consultation",
      });
    } else {
      history.push("/onboarding/telemedicine/free");
    }
  };

  return (
    <Panel title={`${result.title} ${date}`} collapsed={collapsed}>
      <div className="mb-11">
        {result.link && (
          <div className="flex flex-row justify-start items-center mb-6">
            <Image src={pdfIcon} preview={false} />
            <Button type="text" onClick={onOpenFile}>
              Open Document
            </Button>
          </div>
        )}
        {result.reason === "home_sleep_test" && (
          <>
            <div className="mb-6">
              <PrimaryButton onClick={clickGetStarted}>
                Schedule Test Results Appointment
              </PrimaryButton>
            </div>
            <p className="text-sm font-body text-primary leading-normal">
              Questions? Email:{" "}
              <a
                href="mailto:sleepdoctor@renuma.com"
                className="text-renuma-violet-primary"
              >
                sleepdoctor@renuma.com
              </a>
            </p>
          </>
        )}
      </div>
    </Panel>
  );
};
export default ResultPreview;
export { NoResults };
