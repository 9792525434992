import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import _ from "lodash";
import moment from "moment";
import { getAge, ModalWarning } from "../../utils";
import {
  Card,
  message,
  Skeleton,
  Table,
  Spin,
  Button,
  Modal,
  Breadcrumb,
  Popover,
} from "antd";
import UploadDoc from "../../components/UploadDoc";
import {
  getPatientProfile,
  getPatientSurveyData,
  getPatientOrders,
  getPatientFiles,
  addDocument,
  setPatientOsa,
  getSurvey,
} from "../../APIClient";
import { SurveyPDF } from "survey-pdf";
import { PaperClipOutlined } from "@ant-design/icons";

const AdminPatientDetails = () => {
  const [loadingProfile, setLoadingProfile] = useState(false);
  const [loadingSurvey, setLoadingSurvey] = useState(false);
  const [loadingDocs, setLoadingDocs] = useState(false);
  const [loadingOSA, setLoadingOSA] = useState(false);
  const [loadingOrders, setLoadingOrders] = useState(false);
  const [patient, setPatient] = useState(null);
  const [surveyData, setSurveyData] = useState(null);
  const [surveyDataError, setSurveyDataError] = useState("");
  const [orders, setOrders] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [showConfirmOSA, setShowConfirmOSA] = useState(false);
  const params = useParams();

  useEffect(() => {
    getProfile();
    getSurveyData();
    getOrders();
    getFiles();
  }, []);

  const getProfile = async () => {
    setLoadingProfile(true);
    try {
      const res = await getPatientProfile(params.email);
      setPatient(res.Item);
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingProfile(false);
    }
  };

  const getSurveyData = async () => {
    setLoadingSurvey(true);
    try {
      const res = await getPatientSurveyData(params.email);

      if (res.status === 200 && res.data) {
        setSurveyData(res.data);
      } else if (res.data.error && res.data.error_msg) {
        setSurveyDataError(res.data.error_msg);
      }
    } catch (error) {
      console.log(JSON.stringify(error));
      // setSurveyDataError(error);
    } finally {
      setLoadingSurvey(false);
    }
  };

  const columnsTablePayments = [
    { title: "Product", dataIndex: "type" },
    {
      title: "Price",
      dataIndex: "price",
      render: (item) => `$${item}`,
    },
    {
      title: "",
      dataIndex: "id",
      width: 40,
      render: (text, record, index) =>
        record.type === "Renuma+" && (
          <Popover
            content={record.products.map((i, k) => (
              <div key={k}>{i.name}</div>
            ))}
          >
            <Button icon={<PaperClipOutlined />} shape="circle" />
          </Popover>
        ),
    },
  ];
  const getOrders = async () => {
    setLoadingOrders(true);
    try {
      const res = await getPatientOrders(params.email);
      let data = res.orders ? res.orders : [];
      let total = 0;
      data.forEach((i) => {
        total += parseInt(i.total);
      });
      data.push({ type: "Total", total: total });
      setOrders(
        data.map((i, k) => ({
          key: k,
          type: i.type,
          price: i.total,
          products: i.products,
        }))
      );
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingOrders(false);
    }
  };

  const columnsTableDocs = [
    { title: "Analysis Type", dataIndex: "title" },
    {
      title: "Document",
      dataIndex: "link",
      render: (item) => (
        <a href={item} target="_blank" className="whitespace-nowrap">
          Open file
        </a>
      ),
    },
  ];

  const getFiles = async () => {
    setLoadingDocs(true);
    try {
      const res = await getPatientFiles(params.email);
      const data = res.files.map((i, k) => ({
        key: k,
        title: i.title,
        link: i.link,
      }));
      setDocuments(data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingDocs(false);
    }
  };

  const handleUploadComplete = async (doc) => {
    // setLoading(true);
    try {
      setLoadingDocs(true);
      const extension = doc.filename.split(".").pop();
      const res = await addDocument({
        patient: patient.email,
        reason: doc.reason ? doc.reason : "",
        title: doc.title,
        file: doc.fileBase64,
        extension,
      });
      getFiles();
      message.success(`The document has been uploaded successfully.`);
    } catch (error) {
      console.log(error);
      message.error(`The document was not uploaded.`);
    } finally {
      // setLoading(false);
    }
  };

  const savePatientOSA = async () => {
    try {
      setLoadingOSA(true);
      const res = await setPatientOsa({
        email: patient.email,
        osa: true,
      });
      message.success(`The OSA status has been changed successfully.`);
      getProfile();
    } catch (error) {
      console.log(error);
      message.error(`The OSA status was not changed.`);
    } finally {
      setLoadingOSA(false);
    }
  };

  const handleConfirmOk = () => {
    setShowConfirmOSA(false);
    savePatientOSA();
  };

  const handleConfirmCancel = () => {
    setShowConfirmOSA(false);
  };

  const handleClickDownloadAnswers = () => {
    if (surveyData.answers_triggers) {
      downloadAnswersPdf();
    } else {
      ModalWarning({
        warningText: "Error getting patient survey answers",
      });
    }
  };

  const downloadAnswersPdf = async () => {
    try {
      const res = await getSurvey(0);

      let surveyJson = { pages: res.Item.pages };

      surveyJson.pages[0].elements[0].description +=
        " " + moment(patient.date_of_birth).format("MM/DD/YYYY");

      const exportToPdfOptions = { fontSize: 12 };
      const surveyPdf = new SurveyPDF(surveyJson, exportToPdfOptions);
      const answers = {};
      const regex = new RegExp(/\//g);
      // convert multiple choice from string to array
      Object.keys(surveyData.answers_data).forEach((i) => {
        if (
          surveyData.answers_data[i].match(/\[/) &&
          surveyData.answers_data[i] !== "['none']"
        ) {
          const ad = surveyData.answers_data[i]
            .substring(1, surveyData.answers_data[i].length - 1)
            .split(",");
          const res = [];
          ad.forEach((i) => {
            let x = i.trim();
            let r = "";
            if (x[0] === "'") {
              r = x.replaceAll("'", "");
            } else if (x[0] === '"') {
              r = x.replaceAll('"', "");
            }
            res.push(r);
          });

          answers[i] = res;
        } else {
          answers[i] = surveyData.answers_data[i].replaceAll(regex, "'");
        }
      });
      surveyPdf.data = answers;
      surveyPdf.save();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Breadcrumb className="mb-8">
        <Breadcrumb.Item>
          <Link to="/dashboard">Home</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Patient Details</Breadcrumb.Item>
      </Breadcrumb>
      <div className="flex flex-col mb-10">
        <Card className="flex-1 card-dashboard bg-dashboardHome bg-right-8-top-4 bg-no-repeat rounded-md drop-shadow mb-6">
          <div className="flex flex-row space-x-6">
            <div className="flex flex-1 flex-col">
              <h3 className="font-bold">Personal Data:</h3>
              <Skeleton active loading={loadingProfile} />
              {!loadingProfile && patient !== null && (
                <p className="text-sm mb-0">
                  <strong>Full Name:</strong> {patient.full_name}
                  <br />
                  <strong>Email:</strong> {patient.email}
                  <br />
                  <strong>Zip Code:</strong> {patient.zip_code}
                  <br />
                  <strong>Age:</strong> {getAge(patient.date_of_birth)}
                </p>
              )}
            </div>
            <div className="flex flex-1 flex-col">
              <h3 className="font-bold">Survey Data:</h3>
              <Skeleton active loading={loadingSurvey} />
              {!loadingSurvey && surveyData !== null && (
                <p className="text-sm mb-0">
                  <strong>Answers:</strong>
                  <Button
                    type="text"
                    onClick={handleClickDownloadAnswers}
                    className="px-5 _edit text-renuma-violet-primary"
                    htmlType=""
                  >
                    Download Answers (PDF)
                  </Button>
                  <br />
                  <strong>Preferred Name:</strong>{" "}
                  {surveyData.peferred_name || "-"}
                  <br />
                  <strong>Gender:</strong> {surveyData.gender || "-"}
                  <br />
                  <strong>Height:</strong> {surveyData.height.inches || "-"}
                  <br />
                  <strong>Weight:</strong> {surveyData.weight || "-"}
                  <br />
                  <strong>BMI:</strong>{" "}
                  {parseFloat(surveyData.bmi).toFixed(2) || "-"}
                  {surveyData.comment !== undefined &&
                    surveyData.comment !== "" && (
                      <>
                        <br />
                        <strong>Sleep Apnea survey's motive:</strong>
                        <br />
                        {surveyData.comment}
                      </>
                    )}
                  {surveyData.message !== undefined &&
                    surveyData.message !== "" && (
                      <>
                        <br />
                        <strong>Comment:</strong>
                        <br />
                        {surveyData.message}
                        <br />
                        <small className="text-gray-400">
                          (Telemedicine appointment additional comment for the
                          sleep doctor)
                        </small>
                      </>
                    )}
                </p>
              )}
              {!loadingSurvey && surveyDataError != "" && (
                <p className="text-sm text-red-text">{surveyDataError}</p>
              )}
            </div>
          </div>
        </Card>
        <Card className="flex-1 card-dashboard bg-dashboardHome bg-right-8-top-4 bg-no-repeat rounded-md drop-shadow mb-6">
          <div className="flex flex-row space-x-6">
            <div className="flex flex-1 flex-col">
              <h3 className="font-bold">Billing Data:</h3>
              <Skeleton active loading={loadingProfile} />
              {!loadingProfile && patient !== null && (
                <p className="text-sm mb-0">
                  <strong>Street Address:</strong> {patient.street_address}
                  <br />
                  <strong>Apt suite:</strong> {patient.apt_suite}
                  <br />
                  <strong>City:</strong> {patient.city}
                  <br />
                  <strong>State:</strong> {patient.state_province}
                  <br />
                  <strong>Zip Code:</strong> {patient.zip_code}
                </p>
              )}
            </div>
            <div className="flex flex-1 flex-col">
              <h3 className="font-bold">Shipping Data:</h3>
              <Skeleton active loading={loadingProfile} />
              {!loadingProfile &&
                patient !== null &&
                patient.shipping_address !== undefined &&
                patient.shipping_address !== null && (
                  <p className="text-sm mb-0">
                    <strong>Street Address:</strong>{" "}
                    {patient.shipping_address.street_address}
                    <br />
                    <strong>Apt suite:</strong>{" "}
                    {patient.shipping_address.apt_suite}
                    <br />
                    <strong>City:</strong> {patient.shipping_address.city}
                    <br />
                    <strong>State:</strong>{" "}
                    {patient.shipping_address.state_province}
                    <br />
                    <strong>Zip Code:</strong>{" "}
                    {patient.shipping_address.zipcode}
                  </p>
                )}
            </div>
          </div>
        </Card>
        <Card className="flex-1 card-dashboard bg-dashboardHome bg-right-8-top-4 bg-no-repeat rounded-md drop-shadow mb-6">
          <div className="flex flex-row space-x-6">
            <div className="flex flex-1 flex-col">
              <h3 className="font-bold">Patient Info:</h3>
              <Skeleton active loading={loadingProfile} />

              {!loadingProfile && patient !== null && (
                <p className="text-sm mb-0">
                  <strong>Home Sleep Test:</strong>{" "}
                  {patient.user_state.Orders.Home_Test ? "Yes" : "No"}
                  <br />
                  <strong>Home Sleep Test Results:</strong>{" "}
                  {patient.user_state.Home_Study.Completed ? "Completed" : "No"}
                  <br />
                  <strong>Sleep Survey:</strong>{" "}
                  {patient.user_state.Surveys.Completed ? "Completed" : "-"}
                  <br />
                  <strong>Telemedicine Free:</strong>{" "}
                  {patient.user_state.Telemedicine.Free ? "Yes" : "-"}
                  <br />
                  <strong>Telemedicine Paid:</strong>{" "}
                  {patient.user_state.Telemedicine.Paid ? "Yes" : "-"}
                </p>
              )}
            </div>
            <div className="flex flex-1 flex-col pt-8">
              <Skeleton active loading={loadingProfile} />
              {!loadingProfile && patient !== null && (
                <p className="text-sm mb-0">
                  <strong>Has Sleep Apnea:</strong>{" "}
                  {patient.user_state.Health.Have_Sleep_Apnea ? "Yes" : "No"}{" "}
                  {(patient.user_state.Telemedicine.Free ||
                    patient.user_state.Telemedicine.Paid) &&
                    !patient.user_state.Health.Have_Sleep_Apnea && (
                      <Button
                        disabled={loadingOSA}
                        onClick={() => setShowConfirmOSA(true)}
                      >
                        {loadingOSA ? <Spin /> : "Has Sleep Apnea"}
                      </Button>
                    )}
                  <br />
                  <strong>Risk Sleep Apnea:</strong>{" "}
                  {patient.user_state.Health.Risk_Sleep_Apnea ? "Yes" : "No"}
                  <br />
                  <strong>Symptoms OSA:</strong>{" "}
                  {patient.user_state.Health.Symptoms_OSA ? "Yes" : "No"}
                </p>
              )}
            </div>
          </div>
        </Card>
        <div className="flex flex-row space-x-6">
          <Card className="flex-1 card-dashboard bg-dashboardHome bg-right-8-top-4 bg-no-repeat rounded-md drop-shadow mb-6">
            <div className="flex flex-1 flex-col">
              <h3 className="font-bold">Payments:</h3>
              <Skeleton active loading={loadingOrders} />
              {!loadingOrders && orders !== [] && (
                <Table
                  pagination={false}
                  dataSource={orders}
                  columns={columnsTablePayments}
                />
              )}
            </div>
          </Card>
          <Card className="flex-1 card-dashboard bg-dashboardHome bg-right-8-top-4 bg-no-repeat rounded-md drop-shadow mb-6">
            <div className="flex flex-1 flex-col">
              <div className="flex justify-between items-start">
                <div className="flex">
                  <h3 className="font-bold">Other Documents:</h3>
                </div>
                <div className="flex flex-1 justify-end">
                  <UploadDoc onComplete={handleUploadComplete} />
                </div>
              </div>
              <Skeleton active loading={loadingDocs} />
              {!loadingDocs && documents !== [] && (
                <Table
                  showHeader={false}
                  pagination={false}
                  dataSource={documents}
                  columns={columnsTableDocs}
                />
              )}
            </div>
          </Card>
        </div>
      </div>
      <Modal
        title="Confirm change OSA status"
        visible={showConfirmOSA}
        onOk={handleConfirmOk}
        onCancel={handleConfirmCancel}
      >
        <p className="text-sm">Are you sure to change OSA status?</p>
      </Modal>
    </>
  );
};

export default AdminPatientDetails;
