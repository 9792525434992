import React, { useState, useEffect } from "react";
import { Dropdown, Layout, Menu } from "antd";
import { Link, useHistory, useLocation } from "react-router-dom";
import {
  FormOutlined,
  CalendarOutlined,
  FileDoneOutlined,
  SettingOutlined,
  TagsOutlined,
  HomeOutlined,
  UsergroupDeleteOutlined,
} from "@ant-design/icons";
import clsx from "clsx";
import useWindowDimensions from "../hooks/useWindowDimensions";
import { UserIcon } from "./AnonymousNavigator/UserIcon";
import { ChevronDownIcon } from "./Icons";
import Auth from "@aws-amplify/auth";
import { useAuth } from "../providers/auth.context";

// App constants
import {
  PATH_NEXT_URL,
  USER_COMPLETE_SURVEY,
  USER_ORDER_CHECKOUT_DATA,
} from "../utils";
import { Logo } from "./AnonymousNavigator/Logo";

const { Content, Footer, Sider } = Layout;

const AuthenticatedNavigator = ({ children, onLogout }) => {
  const { height, width } = useWindowDimensions();
  const cutOff = 960;
  const location = useLocation();
  const history = useHistory();
  const { currentUser } = useAuth();
  const [isAdmin, setIsAdmin] = useState(false);
  const [itemSelected, setItemSelected] = useState([
    location.pathname.substring(1),
  ]);

  useEffect(() => {
    setIsAdmin(currentUser.role === "admin");
  }, []);

  useEffect(() => {
    let path = location.pathname.substring(1);
    const rootRoutes = [
      { root: "dashboard/patient-details", alias: "dashboard" },
      {
        root: "dashboard/cancelation-requests",
        alias: "dashboard/cancelation-requests",
      },
    ];
    rootRoutes.forEach((el) => {
      if (location.pathname.includes(el.root)) {
        path = el.alias;
      }
    });
    setItemSelected(path);
  }, [location, isAdmin]);

  const currentYear = () => {
    return new Date().getFullYear();
  };
  const handleLogout = async () => {
    try {
      await Auth.signOut();
      localStorage.clear();
      localStorage.removeItem(USER_ORDER_CHECKOUT_DATA);
      localStorage.removeItem(USER_COMPLETE_SURVEY);

      sessionStorage.removeItem(PATH_NEXT_URL);
      window.location.href = "/";
    } catch (error) {
      console.log("error signing out: ", error);
    }
  };

  const MenuItems = (
    <Menu>
      <Menu.Item key="dd_dashboard">
        <Link to="/dashboard">My Dashboard</Link>
      </Menu.Item>
      <Menu.Item key="dd_logout">
        <a onClick={handleLogout}>Log out</a>
      </Menu.Item>
    </Menu>
  );
  return (
    <>
      <Layout className="layout min-h-100vh">
        <Sider
          width={200}
          collapsible={width < cutOff ? true : false}
          style={
            width < cutOff
              ? { position: "absolute", zIndex: 9999, height: "100%" }
              : null
          }
          className="rnm-sider"
          breakpoint="md"
          collapsedWidth="0"
        >
          <div className="block flex flex-row justify-center pt-8 pb-20">
            <Link to="/">
              <Logo color="#FFFFFF" />
            </Link>
          </div>
          <Menu
            theme="dark"
            mode="inline"
            selectedKeys={itemSelected}
            style={{ borderRight: 0 }}
            onClick={(e) => {
              if (e.key === "logout") {
                localStorage.removeItem("token");
                onLogout();
                history.push("/");
              } else if (e.key === "home") {
                history.push("/");
              } else if (e.key !== "account") {
                history.push(`/${e.key}`);
              }
            }}
          >
            <Menu.Item key="dashboard" icon={<HomeOutlined />}>
              Home
            </Menu.Item>
            {isAdmin && (
              <Menu.Item
                key="dashboard/cancelation-requests"
                icon={<UsergroupDeleteOutlined />}
              >
                Requests
              </Menu.Item>
            )}
            {!isAdmin && (
              <>
                <Menu.Item key="dashboard/results" icon={<FileDoneOutlined />}>
                  Results
                </Menu.Item>
                <Menu.Item
                  key="dashboard/appointments"
                  icon={<CalendarOutlined />}
                >
                  Appointments
                </Menu.Item>
                <Menu.Item key="dashboard/surveys" icon={<FormOutlined />}>
                  Surveys
                </Menu.Item>
                <Menu.Item key="dashboard/orders" icon={<TagsOutlined />}>
                  Orders
                </Menu.Item>
                <Menu.Item key="dashboard/account" icon={<SettingOutlined />}>
                  Account
                </Menu.Item>
              </>
            )}
          </Menu>
        </Sider>

        <Layout>
          <header className={clsx("w-full flex z-10")}>
            <div className="flex items-center justify-end py-6 px-6 md:px-8 2xl:px-0 w-full 2xl:w-2/3 max-w-screen-xl m-auto ">
              <Dropdown overlay={MenuItems}>
                <a
                  className="space-x-2 flex items-center relative ant-dropdown-link"
                  onClick={(e) => e.preventDefault()}
                >
                  <UserIcon color="#6c009e" />
                  <span
                    className={clsx(
                      "hidden sm:inline-block",
                      "text-renuma-violet-primary"
                    )}
                  >
                    My Account
                  </span>
                  <ChevronDownIcon color="#6c009e" />
                </a>
              </Dropdown>
            </div>
          </header>
          <Content style={{ padding: 24 }}>{children}</Content>

          <Footer style={{ textAlign: "center" }}>
            {!isAdmin && (
              <div style={{ marginBottom: 2 }}>
                <Link to="/contact-us" className="text-base text-black">
                  Contact Us
                </Link>
                <div className="inline-block text-base text-black mx-5">|</div>
                <Link to="/privacy-notice" className="text-base text-black">
                  Privacy
                </Link>
                <div className="inline-block text-base text-black mx-5">|</div>
                <Link to="/conditions-of-use" className="text-base text-black">
                  Terms of Use
                </Link>
              </div>
            )}
            <p className="text-sm text-black mb-1 leading-snug">
              © Renuma Health {currentYear()}
            </p>
          </Footer>
        </Layout>
      </Layout>
    </>
  );
};
export default AuthenticatedNavigator;
