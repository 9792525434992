import React from "react";
import Section from "../../components/Section";

const PrivacyNoticeScreen = () => {
  return (
    <Section>
      <div className="mt-16">
        <h1 className="text-3xl leading-snug md:text-4xl font-bold text-center mb-13 md:mb-20">
          Privacy notice
        </h1>
        <div className="max-w-4xl -mx-4 md:mx-auto">
          <p className="text-sm leading-normal font-normal font-body mb-4">
            Renuma, LLC and its affiliates (“Renuma”, “we”, “our”, and “us”)
            respect your concerns about privacy and personal data protection and
            value our relationship with you. This Privacy Policy (this “Policy”)
            applies solely to personal information collected through Renuma’s
            website and any other online services, applications, and web pages
            that post, specifically reference, and incorporate this Policy (all
            of the foregoing, collectively, the “Site”), whether accessed via
            computer, mobile device or other device (collectively, “Device”).
          </p>
          <p className="text-sm leading-normal font-normal font-body mb-4">
            This Policy describes the types of personal information we collect
            through the Site and how that personal information may be used
            and/or with whom it may be shared. This Policy also describes how
            you can reach us to update your personal information, access and
            control the use of the personal information, or get answers to
            questions you may have about our privacy practices at this Site.
            Please read this Policy carefully, because by accessing and using
            this Site you are acknowledging that you understand and agree to the
            terms of this Policy.
          </p>
          <h2 className="text-3xl leading-snug md:text-2xl font-bold text-left mt-12">
            1. COLLECTION AND USE OF PERSONAL INFORMATION
          </h2>
          <p className="text-sm leading-normal font-normal font-body mb-4">
            As you interact with Renuma, there may be opportunities for you to
            provide us with your information. Additionally, we may collect
            certain information about your as further described below.
          </p>
          <p className="text-sm leading-normal font-normal font-body mb-4">
            You may provide us with information about you through a number of
            sources: Renuma websites, applications, product and related events,
            surveys, social media platforms, sweepstakes entries, and through
            our customer contact centers.
          </p>
          <p className="text-sm leading-normal font-normal font-body mb-4">
            The categories of information that Renuma collects about you may
            include, but are not limited to:
          </p>
          <ul className="list-disc pl-4 mb-4">
            <li>
              Identifiers/Contact information (such as name, address, city,
              state and ZIP code, email address, telephone number, unique
              personal identifier, online identifier, IP address, Social
              Security number, driver’s license number, passport number, or
              other similar identifiers)
            </li>
            <li>
              Demographic information (such as gender, date of birth, marital
              status, and household composition)
            </li>
            <li>
              Information about your medical conditions, treatment options,
              referrals, prescriptions, test results and other related health
              information
            </li>
            <li>
              Payment information (such as your credit card number, CVV code,
              and expiration date)
            </li>
            <li>
              Information about your connected devices (such as mobile phone,
              computer, or tablet) and how you interact with our products,
              services, apps, and websites (such as IP address, browser type,
              unique device identifier, cookie data, and associated identifying
              and usage information)
            </li>
            <li>Marketing profile information</li>
            <li>Photographs and videos you submit</li>
            <li>Relationships you have with Renuma</li>
            <li>
              Inferences drawn from other personal information (such as personal
              preferences, characteristics, psychological trends,
              predispositions, behavior, attitudes, intelligence, abilities, and
              aptitudes)
            </li>
            <li>
              Any other information you provide to us as part of the services
              you receive from Renuma
            </li>
          </ul>
          <p className="text-sm leading-normal font-normal font-body mb-4">
            We obtain the categories of personal information listed above from
            the following categories of sources:
          </p>
          <ul className="list-disc pl-4 mb-4">
            <li>Directly from you as described herein;</li>
            <li>Directly and indirectly from activity on this Site;</li>
            <li>
              From third-parties that interact with us in connection with the
              services and products we provide.
            </li>
          </ul>
          <p className="text-sm leading-normal font-normal font-body mb-4">
            The information Renuma collects about you and your Device may be
            used:
          </p>
          <ul className="list-disc pl-4 mb-4">
            <li>
              To provide products and services and maintain customer
              relationships
            </li>
            <li>
              To improve the quality, safety, and security of our products and
              services{" "}
            </li>
            <li>
              To administer your account(s) and process your payments for
              products and services{" "}
            </li>
            <li>
              To operate our websites and applications, including online
              registration processes{" "}
            </li>
            <li>
              To autofill data fields on our websites or applications to improve
              your online experience{" "}
            </li>
            <li>To develop new products and services </li>
            <li>
              To provide customer support and service (such as recall
              information){" "}
            </li>
            <li>For warranty administration and validation </li>
            <li>To provide information and product updates</li>
            <li>
              For research, evaluation of use, and troubleshooting purposes{" "}
            </li>
            <li>For marking and analytics purposes </li>
            <li>To customize and improve communication content </li>
            <li>
              To comply with legal, regulatory, or contractual requirements
            </li>
          </ul>
          <p className="text-sm leading-normal font-normal font-body mb-4">
            Communications with you in connection with these uses may be via
            mail, telephone, e-mail, text message, social medial, or other
            electronic messages, or via our websites and applications.
          </p>
          <p className="text-sm leading-normal font-normal font-body mb-4">
            <u>Personal Information You Provide</u>
            <br />
            <br />
            The privacy and security of the personal information that Renuma
            collects from you is a priority for Renuma. When you visit this Site
            you may be asked or choose to provide personal information that
            could reasonably be used to contact you or to identify you
            personally including, but not limited to: your name, address,
            telephone number, birth date, and email address. If you choose to
            purchase any products or use services offered on the Site, Renuma
            may ask you to provide your name, mailing address, telephone number
            and email address, as well as certain financial information (e.g.,
            credit card number) for the purposes of credit checks, payment and
            processing of any order.
          </p>
          <ul className="list-disc pl-4 mb-4">
            <li>
              If you purchase products from the Site, we request that you
              provide your name, email address, billing and shipping address,
              credit card information, and any other information necessary to
              complete the transaction. Renuma may utilize its third party
              service providers, including those located outside your country of
              residency, to process collected financial information to fulfill
              purchases.{" "}
            </li>
            <li>
              You may choose to receive certain communications from Renuma via
              your email address upon interaction with the Site. From time to
              time Renuma may include surveys, information requests, or other
              features on the Site which ask you to provide personal information
              such as your name, telephone number, email address or mailing
              address. Any information you may provide will be used by Renuma to
              allow you to participate in the particular feature being offered.
              Renuma may also retain any information you provide in order to
              send you information about Renuma’s products and services that may
              be of interest to you. Renuma will provide you with the
              opportunity to opt-out of receiving such communications. We may
              use one or more service providers to administer these messages,
              but we do not share your email address with spammers or any other
              third parties without your permission.
            </li>
          </ul>
          <p className="text-sm leading-normal font-normal font-body mb-4">
            You will be informed what personal information is required and what
            information is optional when asked to submit personal information to
            us. By submitting personal information to Renuma at this Site, you
            hereby consent to Renuma’s collection, use and disclosure of such
            personal information.{" "}
          </p>
          <p className="text-sm leading-normal font-normal font-body mb-4">
            We may combine the personal information you submit directly through
            this Site with other personal information we have collected from
            you, whether on- or offline. We may also combine it with information
            we receive about you from other sources, such as other companies,
            publicly available information sources (including information from
            your publicly available social media profiles), and other third
            parties.
          </p>
          <p className="text-sm leading-normal font-normal font-body mb-4">
            <u>Passive Information Collection and Use</u>
            <br />
            <br />
            As you navigate around this Site, certain information can be
            passively collected (that is, gathered without you actively
            providing the information), using various technologies. We passively
            collect and use information in a variety of ways, including:
          </p>
          <ul className="list-disc pl-4 mb-4">
            <li>
              Through your browser and Device: Certain information is collected
              by most browsers, such as your Media Access Control (MAC) address,
              computer type (Windows or Macintosh), screen resolution, operating
              system version, and Internet browser type and version. We collect
              Device-specific information (such as your hardware model,
              operating system version, unique device identifiers, and mobile
              network information including phone number). We may associate your
              device identifiers or phone number with your Renuma Account.
            </li>
            <li>
              Cookies: Cookies are pieces of information stored directly on the
              computer you are using. Cookies allow us to collect information
              such as browser type, time spent on this Site, pages visited, and
              language preferences. We and our service providers use the
              information for security purposes, to facilitate navigation, and
              display information more effectively. In addition, we use cookies
              to gather statistical information about Site usage in order to
              continually improve its design and functionality, understand how
              individuals use it, and to assist us with resolving questions
              regarding it.
              <br />
              <br />
              You can choose to accept or decline cookies. Most web browsers
              automatically accept cookies, but you can usually modify your
              browser settings to decline cookies if you prefer. If you would
              prefer not to accept cookies, most browsers will allow you to: (i)
              change your browser settings to notify you when you receive a
              cookie, which lets you choose whether or not to accept it; (ii) to
              disable existing cookies; or (iii) to set your browser to
              automatically reject any cookies. If you set your browser to
              reject cookies, part of the Site may not work for you.
            </li>
            <li>
              Pixel tags, web beacons, clear GIFs, or other similar
              technologies: These may be used in connection with some Site pages
              to, among other things, track the actions of Site users and
              compile statistics about Site usage and response rates.
            </li>
            <li>
              IP Address: Your IP Address is a number that is automatically
              assigned to the computer that you are using by your Internet
              Service Provider. An IP Address is identified and logged
              automatically in our server log files whenever a user visits this
              Site, along with the time of the visit and the page(s) that were
              visited. Collecting IP Addresses is standard practice on the
              Internet and is done automatically by many web sites. We use IP
              Addresses for purposes such as calculating Site usage levels,
              helping diagnose server problems, and administering this Site.
            </li>
            <li>
              Do Not Track Requests: Your selection of the “Do Not Track” option
              provided by your browser may not have any effect on our collection
              of cookie information for analytic and internal purposes. The only
              way to completely opt out of the collection of any information
              through cookies or other tracking technology is to actively
              management the settings on your browser or Device to delete and
              disable cookies and other tracking/recording tools. Please note,
              depending on your type of Device or browser, it may not be
              possible to delete or disable all tracking mechanisms on your
              Device.
            </li>
          </ul>
          <p className="text-sm leading-normal font-normal font-body mb-4">
            We permit third-party advertising partners to use the foregoing
            tracking technologies to collect information about your browsing
            activities over time and across different websites when you use the
            Site. For example, we use advertising services provided by
            third-party ad partners, such as Google, to market our service to
            you on other websites and online services. Through a process called
            “retargeting,” each service places a cookie on your browser when you
            visit the Site so that they can identify you and serve you ads on
            other sites around the web based on your browsing activity. To avoid
            use of this information for advertising by these third-party ad
            partners, you can change your browser settings to reject cookies or
            to notify you when cookies are set and you could select the Do Not
            Track option on your browser, though we have no control over and
            cannot confirm whether these third-party ad partners honor the Do
            Not Track browser signal. Additionally, many advertising companies
            are members of the NAI or DAA and/or provide opt-outs on those
            industry pages at networkadvertising.org/choices or
            aboutads.info/choices.
          </p>
          <p className="text-sm leading-normal font-normal font-body mb-4">
            We utilize Google Analytics on the Site. Google Analytics is a
            website analysis service provided by Google, Inc. (“Google”). Google
            utilizes the data collected to track and examine the use of this
            Site, to prepare reports on its activities and share them with other
            Google services. Google may use the data collected to contextualize
            and personalize the ads of its own advertising network. For more
            information on Google’s Privacy Policy go to
            https://www.google.com/intl/en/policies/privacy/. Users can opt-out
            of Google Analytics tracking at
            https://tools/google.com/dlpage/gaoptout/. Some additional Google
            Analytics features that Renuma uses are:
          </p>
          <ul className="list-disc pl-4 mb-4">
            <li>
              Google Analytics Demographics and Interest Reporting – allows
              Renuma to see the age, gender and interests of website visitors to
              better understand them and personalize advertising.
            </li>
            <li>
              Google Display Network Impression Reporting – for banner ads.
            </li>
            <li>
              Remarketing through Google Analytics for Display Advertising –
              allows personalization of ads on our ad networks.
            </li>
            <li>
              User ID Extension for Google Analytics – allows Renuma to connect
              multiple devices, sessions and engagement data.
            </li>
          </ul>
          <p className="text-sm leading-normal font-normal font-body mb-4">
            We, along with third-party vendors such as Google use first-party
            cookies (such as the Google Analytics cookies) and third-party
            cookies (such as the DoubleClick cookie) or other third-party
            identifiers together to compile data regarding user interactions
            with ad impressions and other ad service functions as they relate to
            our website. Users can set preferences for how Google advertises to
            you using the Google Ad Settings page. Alternatively, you can opt
            out by visiting the Network Advertising Initiative Opt Out page or
            by using the Google Analytics Opt Out Browser add on.
          </p>
          <p className="text-sm leading-normal font-normal font-body mb-4">
            <u>Integrated Accounts and Social Media Information</u>
            <br />
            We may implement applications or "widgets" from third party
            providers (e.g., Facebook, Twitter, Instagram, LinkedIn, etc.) that
            allow interaction, integration or content sharing with third party
            services. These widgets or applications may be visible on a page you
            visit on our Site or may require log-in through the third party
            provider’s service. The third party providing the widget or
            integration may offer controls for you to manage your interactions.
            Any interaction with a third party widget or application typically
            allows the third party to collect some information about you
            (whether through cookies, registration information or otherwise).
            These third parties may even track you when you do not interact with
            the widget. If you have engaged any of these third party service
            providers, their respective privacy policies will govern their
            tracking and collection of information. You should review these
            third party policies to understand these practices.
          </p>
          <p className="text-sm leading-normal font-normal font-body mb-4">
            <u>How We Use and Disclose Information</u>
            <br />
            We may disclose personal information that we collect or you provide
            as described in this Policy. The information may be sold to third
            parties or disclosed for a business purpose to the following
            categories of third parties:
          </p>
          <ul className="list-disc pl-4 mb-4">
            <li>
              To contractors, service providers and other third parties we use
              to support our business and who are bound by contractual
              obligations to keep personal information confidential and use it
              only for the purposes for which we disclose it to them.
            </li>
            <li>
              To third parties to market their products or services to you if
              you have consented to these disclosures. We contractually require
              these third parties to keep personal information confidential and
              use it only for the purposes for which we disclose it to them.
            </li>
            <li>To our affiliates for related business purposes.</li>
          </ul>
          <p className="text-sm leading-normal font-normal font-body mb-4">
            We use and disclose personal information you provide to us as
            described to you at the point of collection, such as when you (1)
            register for a user account, (2) submit payment or credit card
            information, (3) submit your name and e-mail address to receive
            product updates and marketing information, and (4) send e-mail
            messages or feedback, or transmit other information by e-mail. When
            you elect to engage in such activities, we may ask that you provide
            us personal information, such as your first and last name, mailing
            address (including zip code), e-mail address, mobile telephone
            numbers, and payment information.
          </p>
          <p className="text-sm leading-normal font-normal font-body mb-4">
            We also use and transfer personal information from or about you:
          </p>
          <ul className="list-disc pl-4 mb-4">
            <li>
              to respond to your inquiries and fulfill your requests, such as to
              send you documents you request or e-mail;
            </li>
            <li>
              to send you important information regarding our relationship with
              you or regarding this Site, changes to our terms, conditions, and
              policies and/or other administrative information; and
            </li>
            <li>
              for our business purposes, such as marketing new products and
              services, data analysis, audits, developing new products or
              services, enhancing our Site, improving our products and services,
              identifying Site usage trends, and determining the effectiveness
              of our Site.
            </li>
            <li>
              o our third party service providers who provide services such as
              website hosting and moderating, mobile application hosting, data
              analysis, infrastructure provision, credit card processing, IT
              services, e-mail services, marketing services, auditing services,
              and other services, in order to enable them to provide services;
            </li>
            <li>
              to verify your information, approve your registration as a user of
              the Site, authenticate payments and process orders, Renuma may
              disclose certain financial and other information you provide to
              Renuma to third parties offering products and services for sale on
              the Site, as well as to third parties that provide credit
              reporting, payment or order fulfillment services;
            </li>
            <li>
              to a third party in the event of any reorganization, merger, sale,
              joint venture, assignment, transfer, or other disposition of all
              or any portion of our business, assets, or stock (including in
              connection with any bankruptcy or similar proceedings); and
            </li>
            <li>
              when we otherwise have your consent. For example, if you sign up
              for programs offered by our partners, we may share certain
              information with the partner. This could include, but is not
              limited to, enrollment information and the activation status of
              your device. Your consent is required to allow these exchanges on
              your behalf and you can change your mind at any time.
            </li>
          </ul>
          <p className="text-sm leading-normal font-normal font-body mb-4">
            You may submit information about new clubs related to Renuma
            products or stories and photographs for inclusion in Renuma’s online
            scrap book or Renuma’s social media platforms. Any information you
            submit to Renuma for posting on the Site may be available to the
            public and may be collected and used by persons other than Renuma.
            You should not submit to Renuma any information that you do not want
            others to know. Renuma does not routinely monitor these areas of the
            Site, and you submit such information to Renuma for posting only at
            your own risk.
          </p>
          <p className="text-sm leading-normal font-normal font-body mb-4">
            In addition, we use and disclose personal information collected
            through this Site as we believe to be necessary or appropriate: (a)
            as permitted by applicable law, including laws outside your country
            of residence; (b) to comply with any legal process; (c) to respond
            to requests from public and government authorities, including public
            and government authorities outside your country of residence; (d) to
            enforce our Terms of Use; (e) to protect our operations or those of
            any of our affiliates; (f) to protect our rights, privacy, safety,
            or property, and/or that of our affiliates, you, or others; and (g)
            to allow us to pursue available remedies or limit the damages that
            we may sustain. We may also use and disclose personal information
            collected through this Site in other ways, with your prior consent.
          </p>
          <p className="text-sm leading-normal font-normal font-body mb-4">
            We also use and disclose information we collect passively as
            described above, under "Passive Information Collection and Use," and
            for any other purpose, except where we are required to do otherwise
            under applicable law (for example, if we are required to treat such
            information as personal information). In addition, we may use and
            disclose information that is not in personally identifiable form
            (such as anonymized, masked or de-identified information) for any
            purpose. If we combine information that is not in personally
            identifiable form with information that is (such as combining your
            name with your geographical location), we will treat the combined
            information as personal information as long as it is combined.
          </p>
          <p className="text-sm leading-normal font-normal font-body mb-4">
            We do not sell any personal information collected from you to any
            third parties.
          </p>
          <p className="text-sm leading-normal font-normal font-body mb-4">
            <u>Use of Information for Marketing Purposes</u>
            <br />
            Renuma and our affiliates may send you regular promotional
            communications about our products, services, events and promotions.
            Such promotional materials may use the “Renuma” brand and may be
            sent to you via different channels, such as: email, phone, SMS text
            messages, postal mailings, and third party social networks.
          </p>
          <p className="text-sm leading-normal font-normal font-body mb-4">
            In order to provide you with the best personalized experience,
            sometimes these communications may be tailored to your preferences
            (for example, as you indicate these to us, as we may have inferred
            from your Site visits, or based on the links you click in our
            emails). When required by applicable law, we will obtain your
            consent before starting these activities. In addition, you may
            withdraw your consent (opt-out) at any time.
          </p>
          <p className="text-sm leading-normal font-normal font-body mb-4">
            In particular, you can opt-out from receiving marketing-related
            emails by following the unsubscribe instructions provided in each
            email. If you can sign-in to your Renuma account, you may be able to
            change your communication preferences under the relevant section of
            the Site. You can also contact us using the contact details provided
            below to opt-out from receiving marketing-related communications.
          </p>
          <p className="text-sm leading-normal font-normal font-body mb-4">
            Please note that even if you opt-out from receiving marketing
            communications, you might still receive administrative
            communications from us, such as technical updates of our products,
            order confirmations, notifications about your account activities,
            and other important notices.
          </p>

          <h2 className="text-3xl leading-snug md:text-2xl font-bold text-left mt-12">
            2. GENERAL PRINCIPLES FOR THE PROCESSING OF PERSONAL INFORMATION
          </h2>
          <p className="text-sm leading-normal font-normal font-body mb-4">
            Personal information will be collected, stored, processed and
            transmitted in accordance with Renuma’s established policies and
            applicable federal, state, local and foreign laws, rules and
            regulations.
          </p>
          <p className="text-sm leading-normal font-normal font-body mb-4">
            The principles of Renuma with respect to the processing of personal
            information are as follows: (1) personal information will be
            processed fairly and lawfully, (2) personal information will be
            collected for specified, explicit, and legitimate purposes and not
            further processed for incompatible purposes, (3) personal
            information collected by Renuma will be adequate, relevant, and
            limited to what is necessary in relation to the purposes for which
            they are collected and processed, (4) personal information collected
            by Renuma will be accurate and, where necessary, kept up to date to
            the best of our ability, (5) personal information collected by
            Renuma will be retained as identifiable data for no longer than
            necessary to serve the purposes for which the personal information
            was collected, and (6) personal data will be processed in a manner
            that ensures appropriate security of the personal data, including
            protection against unauthorized or unlawful processing and against
            accidental loss, destruction or damage, using appropriate technical
            or organizational measures.
          </p>
          <p className="text-sm leading-normal font-normal font-body mb-4">
            If Renuma engages in the processing of personal information for
            purposes other than those specified in this Policy, Renuma will
            provide notice of these changes, the purposes for which the personal
            information will be used, and the recipients of personal
            information.
          </p>

          <h2 className="text-3xl leading-snug md:text-2xl font-bold text-left mt-12">
            3. THIRD PARTY SITES AND SERVICES
          </h2>
          <p className="text-sm leading-normal font-normal font-body mb-4">
            This Policy does not address, and we are not responsible for, the
            privacy, information, or other practices of any third parties,
            including any third party operating any site or web property
            (including, without limitation, any application) that is available
            through this Site or to which this Site contains a link. The
            availability of, or inclusion of a link to, any such site or
            property on this Site does not imply endorsement of it by us or by
            our affiliates.
          </p>
          <p className="text-sm leading-normal font-normal font-body mb-4">
            Third parties may offer products and services on the Sites and
            collect information about you by requesting certain information
            necessary to provide a particular product or service (including, for
            example, insurance coverage for your Renuma products or reports of
            insurance claims). Renuma may also obtain certain information about
            its users from third party customer research groups (e.g.,
            demographic information) which Renuma uses to learn more about its
            customers. Renuma does not control the policies and practices of
            third parties in relation to the use and disclosure of any
            information about users collected by third parties on the Site or in
            market research programs conducted by such third parties and any use
            or disclosure of such information by third parties is not governed
            by this Policy.
          </p>

          <h2 className="text-3xl leading-snug md:text-2xl font-bold text-left mt-12">
            4. CHOICES AND ACCESS
          </h2>
          <p className="text-sm leading-normal font-normal font-body mb-4">
            We give you choices regarding our use and disclosure of your
            personal information. Renuma provides you with the opportunity to
            receive information by email, regular mail or telephone calls from
            Renuma, or third parties which may be of interest to you, including
            information relating to new products and services offered on the
            Site by Renuma, or other third parties. If you do not want to
            receive any information by email, regular mail or telephone calls,
            you may opt-out of further communications provided by Renuma at any
            time by: (i) sending Renuma an email at{" "}
            <a href="mailto:privacy@renumasleep.com">privacy@renumasleep.com</a>
            ; or (ii) writing to Renuma at 200 E 78th St, Suite 1A, New York NY
            10075, Attention: Webmaster. Please note that Renuma can only comply
            with requests relating to email, regular mail or telephone
            communications provided by Renuma. If you no longer wish to receive
            further information by email, regular mail or telephone from any
            third party, you will need to contact such third party directly to
            opt-out of further communications being provided to you. We will
            seek to comply with your request(s) as soon as reasonably
            practicable.
          </p>
          <p className="text-sm leading-normal font-normal font-body mb-4">
            If applicable, and you would like to review, correct, update, or
            delete the personal information that you have provided via this
            Site, please contact us. We will try to comply with your request as
            soon as reasonably practicable.
          </p>

          <h2 className="text-3xl leading-snug md:text-2xl font-bold text-left mt-12">
            5. ACCURACY
          </h2>
          <p className="text-sm leading-normal font-normal font-body mb-4">
            It is your responsibility to provide Renuma with accurate personal
            information. Except as otherwise set forth in this Policy, Renuma
            shall only use personal information in ways that are compatible with
            the purposes for which it was collected or subsequently authorized
            by you. To the extent necessary for these purposes, Renuma shall
            take reasonable steps to ensure that personal information is
            accurate, complete, current and relevant to its intended use.
          </p>

          <h2 className="text-3xl leading-snug md:text-2xl font-bold text-left mt-12">
            6. YOUR RIGHTS OVER YOUR INFORMATION
          </h2>
          <p className="text-sm leading-normal font-normal font-body mb-4">
            By law, you have a number of important rights. In summary, those
            include the following:
          </p>
          <ul className="list-disc pl-4 mb-4">
            <li>
              fair processing of your personal information and transparency over
              how we use your personal information;
            </li>
            <li>
              access to your personal information and to certain other
              supplementary information that this Policy is already designed to
              address;
            </li>
            <li>
              require us to correct any mistakes in your information which we
              hold;{" "}
            </li>
            <li>
              require the erasure of personal information concerning you, in
              certain situations;
            </li>
            <li>
              receive the personal information concerning you that you have
              provided to us, in a structured, commonly used and machine
              readable format, and have the right to transmit that information
              to a third party, in certain situations;
            </li>
            <li>
              object at any time to processing of personal information
              concerning you for direct marketing;
            </li>
            <li>
              object to decisions being taken by automated means that produce
              legal effects concerning you or similarly significantly affect
              you; and
            </li>
            <li>
              object in certain other situations to our continued processing of
              your personal information.
            </li>
          </ul>
          <p className="text-sm leading-normal font-normal font-body mb-4">
            If you would like to exercise any of these rights, please email,
            call, or write to us with the following: (1) enough information to
            identify you; (2) proof of your identity and address; and (3) the
            information to which your request relates.
          </p>
          <p className="text-sm leading-normal font-normal font-body mb-4">
            <u>California Privacy Rights</u>
            <br />
            <br />
            The California Consumer Privacy Act (the “CCPA”) grants users of our
            Site that are California residents certain rights in regards to our
            collection of personal information. These rights include:
          </p>
          <ul className="list-disc pl-4 mb-4">
            <li>
              The right to request the disclosures of (1) what personal
              information has been collected; (2) the categories of sources from
              which the personal information was collected; (3) the business or
              commercial purpose for collection or selling personal information;
              (4) the categories of third parties with whom the business shares
              personal information; and (5) the specific pieces of personal
              information Renuma has collected;
            </li>
            <li>
              The right to request the deletion of their personal information
              that has been collected; and
            </li>
            <li>
              The right to opt-out of the sale of their personal information.
            </li>
          </ul>
          <p className="text-sm leading-normal font-normal font-body mb-4">
            Under the CCPA, a data subject may only make a personal information
            request twice in a 12-month period. Renuma will respond to such
            personal information request within 45 days of receiving the
            personal information request.
          </p>
          <p className="text-sm leading-normal font-normal font-body mb-4">
            Additionally, if you are a California resident, you have the right
            to request information from Renuma regarding the manner in which
            Renuma shares certain categories of your personal information with
            third parties, for the third parties’ direct marketing purposes.
            California law provides that you have the right to submit a request
            to Renuma at its designated address and receive the following
            information:
          </p>
          <ul className="list-disc pl-4 mb-4">
            <li>
              The categories of information Renuma disclosed to third parties
              for the third parties’ direct marketing purposes during the
              preceding calendar year; and
            </li>
            <li>
              The names and address of third parties that received such
              information, or if the nature of their business cannot be
              determined from the name, then examples of the products or
              services marketed.
            </li>
          </ul>
          <p className="text-sm leading-normal font-normal font-body mb-4">
            You are entitled to receive a copy of this information in a
            standardized format and the information will not be specific to you
            individually. You may make such a request by calling 848-272-0794.
          </p>
          <p className="text-sm leading-normal font-normal font-body mb-4">
            <u>Exercising Your Rights</u>
            <br />
            <br />
            To exercise your rights as described herein, you may submit a
            verifiable consumer request to us (as described above) by either
            contacting us via the contact methods provided in the CONTACTING US
            section below. Renuma will not discriminate in any way against any
            users of the Site that exercise their rights provided by law.
          </p>

          <h2 className="text-3xl leading-snug md:text-2xl font-bold text-left mt-12">
            7. RETENTION PERIOD
          </h2>
          <p className="text-sm leading-normal font-normal font-body mb-4">
            We retain your personal information for the period necessary to
            fulfill the purposes outlined in this Policy, unless a longer
            retention period is required or allowed by law or to otherwise
            fulfill a legal obligation.
          </p>

          <h2 className="text-3xl leading-snug md:text-2xl font-bold text-left mt-12">
            8. SECURITY
          </h2>
          <p className="text-sm leading-normal font-normal font-body mb-4">
            Renuma has implemented security measures designed to protect
            personal information provided by you against unauthorized access and
            use. Renuma also uses commercially reasonable efforts to establish
            secure connections with your web browser for any transactions
            conducted online using a credit card or other financial information
            you may provide to Renuma. Please be aware, however, that no data
            transmission over the Internet is 100% secure and any information
            disclosed online can potentially be collected and used by parties
            other than the intended recipient. If you have reason to believe
            that your interaction with us is no longer secure, please
            immediately notify us of the problem by contacting us in accordance
            with the CONTACTING US section below.
          </p>

          <h2 className="text-3xl leading-snug md:text-2xl font-bold text-left mt-12">
            9. USE OF SITE BY MINORS
          </h2>
          <p className="text-sm leading-normal font-normal font-body mb-4">
            We do not intentionally collect information from individuals under
            the age of 18. The Site is not directed to individuals under the age
            of 18 and we request that these individuals not provide personal
            information through this Site.
          </p>

          <h2 className="text-3xl leading-snug md:text-2xl font-bold text-left mt-12">
            10. COMPLIANCE WITH LOCAL LAWS
          </h2>
          <p className="text-sm leading-normal font-normal font-body mb-4">
            This Policy is meant to guide Renuma with respect to personal
            information collected from or about you at this Site. While this
            Policy applies to personal information generally, the local laws,
            rules and regulations of jurisdictions that are applicable to Renuma
            (“Local Laws”) may require standards which are stricter than this
            Policy and, in such event, Renuma will comply with applicable Local
            Laws. Specific privacy policies may be adopted to address the
            specific privacy requirements of particular jurisdictions. All the
            information you provide may be transferred or accessed by entities
            around the world as described in this Policy. Your personal
            information may be stored and processed in any country where we have
            facilities or service providers, and by using our Site or by
            providing consent to us (where required by law), you specifically
            acknowledge and consent to the transfer of information to countries
            outside of your country of residence, including to the United
            States, which may provide for different data protection rules, and a
            lower standard of required protection, than in your country.
            Irrespective of which country your personal data is transferred, we
            would only share your personal data under a ‘need to know’ basis. In
            these circumstances we will, as required by applicable law, ensure
            that your privacy rights are adequately protected by organizational,
            technical, contractual and/or other lawful means.
          </p>

          <h2 className="text-3xl leading-snug md:text-2xl font-bold text-left mt-12">
            11. UPDATES TO THIS PRIVACY POLICY
          </h2>
          <p className="text-sm leading-normal font-normal font-body mb-4">
            We may change this Policy. Please review the LAST UPDATE legend at
            the top of this page to see when this Policy was last revised. Any
            changes to this Policy will become effective when we post the
            revised Policy on this Site. Your use of this Site following these
            changes means that you accept the revised Policy.
          </p>

          <h2 className="text-3xl leading-snug md:text-2xl font-bold text-left mt-12">
            12. COMPLIANCE
          </h2>
          <p className="text-sm leading-normal font-normal font-body mb-4">
            Renuma will use a self-assessment approach to verify compliance with
            this Policy and periodically verify that the Policy is accurate,
            comprehensive for the information intended to be covered,
            prominently displayed, implemented and accessible.
          </p>
          <p className="text-sm leading-normal font-normal font-body mb-4">
            If you believe that your personal information has been processed or
            disclosed in violation of this Policy, Renuma encourages you to
            raise any concerns using the contact information provided in this
            Policy. Renuma will investigate and attempt to resolve any
            complaints and disputes regarding use and disclosure of personal
            information.
          </p>

          <h2 className="text-3xl leading-snug md:text-2xl font-bold text-left mt-12">
            13. YOUR RIGHT TO COMPLAIN
          </h2>
          <p className="text-sm leading-normal font-normal font-body mb-4">
            If you have a complaint as to our use of your information, you may
            file a complaint with us, the California Attorney General, any other
            governmental agency, and/or a judicial body.
          </p>
          <p className="text-sm leading-normal font-normal font-body mb-4">
            A complaint may be submitted directly to Renuma:
          </p>
          <ul className="list-disc pl-4 mb-4">
            <li>
              In writing by sending an email to{" "}
              <a href="mailto:privacy@renumasleep.com">
                privacy@renumasleep.com
              </a>
              ;
            </li>
            <li>
              In writing by sending a letter to 200 E 78th St, Suite 1A, New
              York NY 10075, Attention: Customer Service; or
            </li>
            <li>Orally by directly contacting 848-272-0794; </li>
          </ul>

          <h2 className="text-3xl leading-snug md:text-2xl font-bold text-left mt-12">
            14. CONTACTING US
          </h2>
          <p className="text-sm leading-normal font-normal font-body mb-4">
            If you have any questions about this Policy, please contact Renuma
            directly
          </p>
          <p className="text-sm leading-normal font-normal font-body mb-4">
            Our contact information is as follows:
          </p>
          <p className="text-sm leading-normal font-normal font-body mb-4">
            Renuma, LLC
            <br />
            200 E 78th St
            <br />
            Suite 1A
            <br />
            New York NY 10075
            <br />
            Attention: Customer Service
            <br />
            848-272-0794 (Monday to Friday, 8am – 5pm EST)
            <br />
            <a href="mailto:privacy@renumasleep.com">privacy@renumasleep.com</a>
          </p>
        </div>
      </div>
    </Section>
  );
};

export default PrivacyNoticeScreen;
