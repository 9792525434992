import React, { useEffect, useState } from "react";

import AccountProfilePanel from "../../components/AccountProfilePanel";
import AccountPasswordPanel from "../../components/AccountPasswordPanel";
import AccountAddressPanel from "../../components/AccountAddressPanel";
import AccountPaymentMethodsPanel from "../../components/AccountPaymentMethodsPanel";
import AccountRenumaPlusSettings from "../../components/AccountRenumaPlusSettings";
import Panel from "../../components/Panel";
import { Button, Input, Modal, Spin } from "antd";
import { cancelAccount } from "../../APIClient";
import { LoadingOutlined } from "@ant-design/icons";

const { TextArea } = Input;

const AccountScreen = () => {
  const [showConfirmCancel, setShowConfirmCancel] = useState(false);
  const [requestSent, setRequestSent] = useState(false);
  const [cancelationReason, setCancelationReason] = useState("");
  const [loadingRequest, setLoadingRequest] = useState(false);

  useEffect(() => {
    const profile = JSON.parse(localStorage.getItem("userProfile"));
    if (
      localStorage.getItem("cancelation_request") === "true" ||
      profile.cancel_status === "required"
    ) {
      setRequestSent(true);
    }
  }, []);

  const sendRequest = async () => {
    setShowConfirmCancel(false);
    setLoadingRequest(true);
    try {
      const res = await cancelAccount({
        message: cancelationReason,
      });
      setShowConfirmCancel(true);
      setRequestSent(true);
      localStorage.setItem("cancelation_request", "true");
      // se obtiene el userProfile de localStorage
      let profile = JSON.parse(localStorage.getItem("userProfile"));
      // se actualiza cancel_status
      profile.cancel_status = "required";
      // se guarda el userProfile con los datos actualizados
      localStorage.setItem("userProfile", JSON.stringify(profile));
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingRequest(false);
    }
  };

  const handleCancelAccount = () => {
    setShowConfirmCancel(true);
  };

  const handleConfirmOk = () => {
    if (!requestSent) {
      sendRequest();
      setRequestSent(true);
    }
  };
  const handleConfirmCancel = () => {
    setShowConfirmCancel(false);
  };
  return (
    <>
      <h3 className="text-3xl font-bold text-primary-text mt-4">Account</h3>

      <Panel title="Profile Settings" collapsed={false}>
        <AccountProfilePanel />
        <div className="border border-b-gray-200 mb-11"></div>
        <AccountPasswordPanel />
      </Panel>

      <Panel title="Shipping Details" collapsed={false}>
        <AccountAddressPanel />
      </Panel>

      <Panel title="Payment Methods" collapsed={false}>
        <AccountPaymentMethodsPanel />
      </Panel>

      <Panel title="Delete Account" collapsed={false}>
        <div className="pb-10">
          <Button onClick={handleCancelAccount}>Send Request</Button>
        </div>
      </Panel>

      <Panel title="Renuma+ Settings" collapsed={false}>
        <AccountRenumaPlusSettings />
      </Panel>

      <Modal
        title={"Account deletion request"}
        visible={showConfirmCancel}
        onOk={handleConfirmOk}
        onCancel={handleConfirmCancel}
        cancelText={null}
        footer={
          !requestSent
            ? [
                <Button key="back" onClick={handleConfirmCancel}>
                  Cancel
                </Button>,
                <Button key="ok" type="primary" onClick={handleConfirmOk}>
                  OK
                </Button>,
              ]
            : [
                <Button key="ok" type="primary" onClick={handleConfirmCancel}>
                  OK
                </Button>,
              ]
        }
      >
        <p className="text-sm leading-normal">
          {!requestSent ? (
            <>
              Please let us know the reason you are deleting your account.
              <br />
              <TextArea
                onChange={(e) => setCancelationReason(e.target.value)}
                rows={4}
              />
            </>
          ) : (
            <>
              You will receive a confirmation email when this is completed.
              We're sorry to see you go.
            </>
          )}
        </p>
      </Modal>

      {loadingRequest && (
        <>
          <div
            className="flex flex-row justify-center items-center w-full fixed top-0"
            style={{
              minHeight: "100vh",
              background: "rgba(255, 255, 255, .5)",
              zIndex: 9999,
            }}
          >
            <Spin
              indicator={<LoadingOutlined style={{ color: "#6C009E" }} />}
            />
          </div>
        </>
      )}
    </>
  );
};
export default AccountScreen;
