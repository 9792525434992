import React, { Fragment } from "react";
import { Link, useHistory } from "react-router-dom";
import Section from "../../components/Section";
import Sleepiness from "../../assets/svgs/Sleepiness.svg";
import Heart from "../../assets/svgs/Heart.svg";
import Partner from "../../assets/svgs/Partner.svg";
import check from "../../assets/svgs/check.svg";
import PrimaryButton from "../../components/PrimaryButton";
import { goToPrivatePage } from "../../utils";

const Sleep101Screen = () => {
  const history = useHistory();

  const goToSurvey = () => {
    goToPrivatePage({
      history,
      url: "/onboarding/survey",
      warningText:
        "You already took the survey, this service can't be done twice.",
    });
  };
  return (
    <Fragment>
      <Section className="bg-renuma-violet-100 app-sleep-hero">
        <div className="bg-apnea101 bg-w-180% md:bg-cover bg-no-repeat bg-right-bottom md:bg-right pt-10 md:pt-28 pb-450b md:pb-56 px-6 md:px-0">
          <div className="flex max-w-7xl m-auto">
            <div className="md:w-2/5 ml-0 md:ml-12">
              <h1
                className="text-4xl font-bold text-left md:text-6xl mb-4"
                data-aos="fade-left"
                data-aos-duration="700"
                data-aos-delay="0"
              >
                Snoring and Sleep Apnea
              </h1>
              <h2
                className="text-xl font-semibold text-left md:text-justify mb-6"
                data-aos="fade-left"
                data-aos-duration="700"
                data-aos-delay="100"
              >
                Snoring is a symptom of sleep apnea.
              </h2>
              <p
                className="text-base md:text-xl leading-normal text-secondary-text font-body"
                data-aos="fade-left"
                data-aos-duration="700"
                data-aos-delay="150"
              >
                Treating sleep apnea can protect your heart and improve your quality of life. 
                It affects millions of people and most don’t even know they have the condition.
              </p>
            </div>
          </div>
        </div>
      </Section>
      <Section className="flex flex-col">
        <div className="w-full max-w-5xl m-auto py-4 md:py-12">
          <div className="md:my-10">
            <h2
              className="text-3xl mb-6 md:mb-10 font-bold text-center tracking-tight md:tracking-normal"
              data-aos="fade-up"
              data-aos-duration="700"
              data-aos-delay="0"
            >
              What is Sleep Apnea?
            </h2>
            <p
              className="text-base md:text-xl2 leading-normal font-body"
              data-aos="fade-up"
              data-aos-duration="700"
              data-aos-delay="100"
            >
              Obstructive sleep apnea means you stop breathing in your sleep for
              about 10 or 20 seconds, then breathe normally for a few minutes,
              then stop breathing again. The cycle repeats itself throughout the
              night and you end up with poor quality sleep. Treating sleep apnea
              can help you feel more refreshed and also prevent heart disease.
              The most popular treatment for sleep apnea uses what's called a
              CPAP machine. This machine helps keep your airway open during
              sleep so you no longer stop breathing.
            </p>
          </div>
        </div>
      </Section>
      <Section className="flex flex-col bg-renuma-violet-800 text-white">
        <div className="w-full max-w-5xl m-auto pt-3 md:pt-7 md:pb-20">
          <h3
            className="text-3xl font-bold mb-20 text-white text-center"
            data-aos="fade-up"
            data-aos-duration="700"
            data-aos-delay="0"
          >
            How Sleep Apnea May Be Affecting You
          </h3>

          <div className="flex flex-col text-center md:flex-row">
            <div
              className="flex flex-col justify-center mb-10 flex-1"
              data-aos="fade-up"
              data-aos-duration="700"
              data-aos-delay="100"
            >
              <div className="rounded-full border-4 w-32 h-32 m-auto my-7 flex flex-col justify-center">
                <img src={Sleepiness} className="m-auto" />
              </div>
              <h3 className="text-white text-center">EXCESSIVE SLEEPINESS</h3>
            </div>
            <div
              className="flex flex-col justify-center mb-10 flex-1"
              data-aos="fade-up"
              data-aos-duration="700"
              data-aos-delay="150"
            >
              <div className="border-4 rounded-full w-32 h-32 m-auto my-7 flex flex-col justify-center">
                <img src={Heart} className="m-auto" />
              </div>
              <h3 className="text-white text-center">HEART PROBLEMS</h3>
            </div>
            <div
              className="flex flex-col justify-center mb-10 flex-1"
              data-aos="fade-up"
              data-aos-duration="700"
              data-aos-delay="200"
            >
              <div className="border-4 rounded-full w-32 h-32 m-auto my-7 flex flex-col justify-center">
                <img src={Partner} className="m-auto" />
              </div>
              <h3 className="text-white text-center">
                BED PARTNER CAN’T SLEEP
              </h3>
            </div>
          </div>
        </div>
      </Section>
      <Section>
        <div className="w-full max-w-5xl m-auto pt-6 pb-8">
          <h2
            className="text-3xl font-sans font-bold text-center mb-8"
            data-aos="fade-up"
            data-aos-duration="700"
            data-aos-delay="0"
          >
            Should You Get Tested?
          </h2>
          <p
            className="text-base md:text-xl2 leading-normal font-body"
            data-aos="fade-up"
            data-aos-duration="700"
            data-aos-delay="100"
          >
            You may be at risk for sleep apnea if you have one or more of the
            symptoms listed below. Our online survey can help determine if you
            should get tested.
          </p>
          <div className="font-body space-y-6 mb-14 mt-14">
            <div
              className="flex items-center space-x-8"
              data-aos="fade-left"
              data-aos-duration="700"
              data-aos-delay="0"
            >
              <img alt="list-check" src={check} />
              <p className="text-base md:text-xl2 leading-normal mb-0">
                Are you tired during the day?
              </p>
            </div>
            <div
              className="flex items-center space-x-8"
              data-aos="fade-left"
              data-aos-duration="700"
              data-aos-delay="100"
            >
              <img alt="list-check" src={check} />
              <p className="text-base md:text-xl2 leading-normal mb-0">
                Do you snore?
              </p>
            </div>
            <div
              className="flex items-center space-x-8"
              data-aos="fade-left"
              data-aos-duration="700"
              data-aos-delay="150"
            >
              <img alt="list-check" src={check} />
              <p className="text-base md:text-xl2 leading-normal mb-0">
                Have you been told you gasp for air or stop breathing during
                sleep?
              </p>
            </div>
            <div
              className="flex items-center space-x-8"
              data-aos="fade-left"
              data-aos-duration="700"
              data-aos-delay="200"
            >
              <img alt="list-check" src={check} />
              <p className="text-base md:text-xl2 leading-normal mb-0">
                Do you have high blood pressure?
              </p>
            </div>
            <div
              className="flex items-center space-x-8"
              data-aos="fade-left"
              data-aos-duration="700"
              data-aos-delay="250"
            >
              <img alt="list-check" src={check} />
              <p className="text-base md:text-xl2 leading-normal mb-0">
                Is your neck size greater than 16 inches (for females) or 17
                inches (for males)?
              </p>
            </div>
          </div>
        </div>
      </Section>
      <Section className="bg-renuma-violet-100">
        <div className="w-full max-w-5xl m-auto pt-8 pb-6">
          <h2
            className="text-3xl font-sans font-bold text-center mb-4"
            data-aos="fade-up"
            data-aos-duration="700"
            data-aos-delay="0"
          >
            How Renuma Can Help
          </h2>
          <p
            className="text-base md:text-xl2 leading-normal text-center font-body mb-10"
            data-aos="fade-up"
            data-aos-duration="700"
            data-aos-delay="100"
          >
            Start by creating a free account and receiving a complimentary automated risk report in minutes. 
            The service is free with no obligations. If you decide to purchase a sleep study, expect it the next business day. 
            A sleep doctor will review the results with you. If treatment is needed, we offer assistance in obtaining a CPAP machine and provide regular replacement supplies. 
            A sleep doctor will guide you through the entire process.
          </p>
          <div
            className="flex justify-center"
            data-aos="fade-up"
            data-aos-duration="700"
            data-aos-delay="150"
          >
            <PrimaryButton onClick={goToSurvey}>
              Begin Sleep Evaluation
            </PrimaryButton>
          </div>
        </div>
      </Section>
    </Fragment>
  );
};
export default Sleep101Screen;
