import React from 'react';
import { useState } from 'react';
export const AuthContext = React.createContext({
    currentUser: null,
    updateCurrentUser: (obj) => { },
    registeringUser: null,
    updateRegisteringUser: (obj) => { }
});
export const AuthProvider = ({ user, children }) => {
    const [currentUser, setCurrentUser] = useState(user);
    const [registeringUser, setRegisteringUser] = useState({});

    const update = (args) => {
        setCurrentUser(args);
    };
    const updateRegistering = (args) => {
        setRegisteringUser(args);
    };
    const contextValues = {
        currentUser,
        updateCurrentUser: React.useCallback((args) => update(args), []),
        registeringUser,
        updateRegisteringUser: React.useCallback((args) => updateRegistering(args), [])
    };
    return (<AuthContext.Provider value={contextValues}>
            {children}
        </AuthContext.Provider>);
};
export const useAuth = () => React.useContext(AuthContext);
