import React, { useEffect, useRef, useState } from "react";
import { Button } from "antd";

import SecondaryButton from "../SecondaryButton";

const SelectedProductItem = ({
  number,
  product,
  onEdit,
  buttonText,
  labelText,
}) => {
  const [openFull, setOpenFull] = useState(false);
  const [expandable, setExpandable] = useState(false);
  const descCont = useRef(null);

  useEffect(() => {
    if (descCont.current.clientHeight >= 80) {
      setExpandable(true);
    }
  }, []);

  const editProduct = () => {
    if (onEdit) {
      onEdit();
    }
  };

  const handleViewMore = () => {
    setOpenFull((v) => !v);
  };

  return (
    <>
      <div className="w-8 flex items-center py-6">{number}.</div>
      <div className="flex flex-row flex-1 border-b border-renuma-violet-400 py-6">
        <div className="w-24 flex items-center">
          <img src={product.images[0].src} />
        </div>
        <div className="flex-1 flex flex-col items-start justify-center mx-6">
          <h4
            className="text-base font-semibold"
            dangerouslySetInnerHTML={{
              __html: product.name,
            }}
          ></h4>
          <div
            className={`mask-description-container overflow-hidden ${
              openFull ? "" : "h-20"
            }`}
          >
            <div
              ref={descCont}
              className="mask-description-sm mask-list"
              dangerouslySetInnerHTML={{
                __html: product.description,
              }}
            ></div>
          </div>
          {expandable === true && (
            <div className="w-full text-right">
              <Button
                type="text"
                size="small"
                className="text-xs text-renuma-violet-primary"
                onClick={handleViewMore}
              >
                {openFull ? "View less" : "View more"}
              </Button>
            </div>
          )}
        </div>
        <div className="w-48 flex items-center">
          {buttonText !== undefined && (
            <SecondaryButton
              size="small"
              className="w-full font-semibold"
              onClick={editProduct}
            >
              {buttonText}
            </SecondaryButton>
          )}
          {labelText !== undefined && (
            <div className="bg-white border-2 border-black text-black px-6 py-3 text-base font-normal shadow-lg rounded-full leading-6 text-center w-full font-semibold">
              {labelText}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default SelectedProductItem;
