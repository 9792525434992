import React, { useEffect, useState } from "react";
import { Table, Input, Button, Space } from "antd";
import { SearchOutlined, EyeFilled } from "@ant-design/icons";

import { useHistory } from "react-router-dom";
import { getPatientStatus } from "../../APIClient";

const AdminTablePatients = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [filteredInfo, setFilteredInfo] = useState({ survey: [] });
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [isMounted, setIsMounted] = useState(true);
  const [sortedInfo, setSortedInfo] = useState({
    order: null,
    columnKey: null,
  });
  let searchInput = null;
  const history = useHistory();

  const getColumnSearchProps = (dataIndex, dataTitle) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node;
          }}
          placeholder={`Search ${dataTitle}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.select(), 100);
      }
    },
    render: (text) => text.toString(),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const sortAlphabetically = (a, b, index) => {
    if (a[index] < b[index]) {
      return -1;
    }
    if (a[index] > b[index]) {
      return 1;
    }
    return 0;
  };

  const columns = [
    {
      title: "Patient name",
      dataIndex: "full_name",
      key: "full_name",
      sorter: (a, b) => sortAlphabetically(a, b, "full_name"),
      filteredValue: filteredInfo.full_name || null,
      ...getColumnSearchProps("full_name", "by Name"),
    },
    {
      title: "Survey",
      dataIndex: "survey",
      key: "survey",
      align: "center",
      render: (item) => (item === true ? "Yes" : "No"),
      sorter: (a, b) => sortAlphabetically(a, b, "survey"),
      filters: [
        { text: "Yes", value: true },
        { text: "No", value: false },
      ],
      filteredValue: filteredInfo.survey || null,
      onFilter: (value, record) => record.survey === value,
    },
    {
      title: "Sleep Test",
      dataIndex: "sleep_test",
      key: "sleep_test",
      align: "center",
      render: (item) => (item === true ? "Yes" : "No"),
      sorter: (a, b) => sortAlphabetically(a, b, "sleep_test"),
      filters: [
        { text: "Yes", value: true },
        { text: "No", value: false },
      ],
      filteredValue: filteredInfo.sleep_test || null,
      onFilter: (value, record) => record.sleep_test === value,
    },
    {
      title: "Renuma+",
      dataIndex: "renuma_plus",
      key: "renuma_plus",
      align: "center",
      render: (item) => (item === true ? "Yes" : "No"),
      sorter: (a, b) => sortAlphabetically(a, b, "renuma_plus"),
      filters: [
        { text: "Yes", value: true },
        { text: "No", value: false },
      ],
      filteredValue: filteredInfo.renuma_plus || null,
      onFilter: (value, record) => record.renuma_plus === value,
    },
    {
      title: "Other Docs",
      dataIndex: "files",
      key: "files",
      align: "center",
      render: (item) => (item === true ? "Yes" : "No"),
      sorter: (a, b) => sortAlphabetically(a, b, "files"),
      filteredValue: filteredInfo.files || null,
    },
    {
      title: "Payments",
      dataIndex: "total_payments",
      key: "total_payments",
      align: "right",
      render: (item) => `$${item}`,
      sorter: (a, b) => a.total_payments - b.total_payments,
      filteredValue: filteredInfo.total_payments || null,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (item) => (item === true ? "Active" : "Inactive"),
      sorter: (a, b) => sortAlphabetically(a, b, "status"),
      filters: [
        { text: "Active", value: true },
        { text: "Inactive", value: false },
      ],
      filteredValue: filteredInfo.status || null,
      onFilter: (value, record) => record.status === value,
    },
    {
      title: "Actions",
      key: "actions",
      align: "center",
      render: (text, record) => (
        <EyeFilled
          onClick={() => {
            history.push(`/dashboard/patient-details/${record.email}`);
          }}
        />
      ),
      filteredValue: filteredInfo.actions || null,
    },
  ];

  useEffect(() => {
    setIsMounted(true);
    return () => {
      setIsMounted(false);
    };
  }, []);

  useEffect(() => {
    if (currentPage !== null) {
      loadPatients();
    }
  }, [currentPage]);

  const loadPatients = async () => {
    setLoading(true);
    try {
      const res = await getPatientStatus(currentPage);
      if (isMounted) {
        setLoading(false);
        setData(res.patients);
        setMaxPage(res.max_page);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleTableChange = (pagination, filters, sorter, extra) => {
    setSortedInfo(sorter);
    setFilteredInfo(filters);
  };

  const handleClickPage = (dir) => {
    if (dir === "prev") {
      setCurrentPage((v) => v - 1);
    } else {
      setCurrentPage((v) => v + 1);
    }
  };
  return (
    <>
      {loading && <div>Loading...</div>}
      {!loading && (
        <>
          <Table
            rowKey="email"
            size="middle"
            columns={columns}
            dataSource={data}
            onChange={handleTableChange}
            pagination={false}
          />
          <div className="text-right mt-6">
            <Button
              disabled={currentPage <= 1}
              className="mx-1"
              onClick={() => handleClickPage("prev")}
            >
              &lt;
            </Button>
            <span className="mx-1">
              Page {currentPage}/{maxPage}
            </span>
            <Button
              disabled={maxPage === currentPage}
              className="mx-1"
              onClick={() => handleClickPage("next")}
            >
              &gt;
            </Button>
            {/*  {Array.apply(null, Array(maxPage)).map((i, k) => (
              <Button className="mx-1">{k + 1}</Button>
            ))} */}
          </div>
        </>
      )}
    </>
  );
};

export default AdminTablePatients;
