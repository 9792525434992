import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import moment from "moment";
import moment_timezone from "moment-timezone";
import { Link, useHistory, useLocation } from "react-router-dom";

// lleva a la pagina de survey si aun no la hizo
export const ModalWarning = ({ warningText }) => {
  Modal.warning({
    closable: true,
    centered: true,
    content: warningText,
    okButtonProps: {
      className:
        "bg-renuma-violet-primary text-white px-6 py-2 h-auto border-none text-base font-normal shadow-lg rounded-full leading-6 hover:bg-renuma-violet-800 disabled:opacity-50",
    },
  });
};

// lleva a la pagina de survey si aun no la hizo
export const goToPrivatePage = ({ history, url, warningText }) => {
  const profile = JSON.parse(localStorage.getItem("userProfile"));

  // se obtiene del profile si hizo o no la survey
  if (profile !== null && profile.user_state.Surveys.Completed) {
    Modal.warning({
      closable: true,
      centered: true,
      content: warningText,
      okButtonProps: {
        className:
          "bg-renuma-violet-primary text-white px-6 py-2 h-auto border-none text-base font-normal shadow-lg rounded-full leading-6 hover:bg-renuma-violet-800 disabled:opacity-50",
      },
    });
  } else {
    history.push(url);
  }
};

export const validateEmail = (email) => {
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};
export const centsToFormattedValue = (cents) => {
  const dollarAmount = cents / 100.0;
  return `$${dollarAmount.toLocaleString(undefined, {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  })}`;
};
export const titleCase = (word) => {
  if (word.length >= 2) {
    return `${word[0].toUpperCase()}${word.slice(1)}`;
  } else {
    return word[0].toUpperCase();
  }
};
export const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};
export const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
};

export const getSlot = (date_time = null, taken = false) => {
  if (date_time === null) return null;
  const data = {
    date_time,
    hour: moment_timezone.tz(date_time, "America/New_York").format("HH:mm:ss"),
    taken,
  };
  return data;
};

export const getDateRange = (datetime = null, time = 15) => {
  if (datetime === null) return null;
  const from = moment
    .tz(datetime, "America/New_York")
    .format("LT")
    .toLowerCase();
  const to = moment
    .tz(datetime, "America/New_York")
    .add(time, "minute")
    .format("LT")
    .toLowerCase();
  return `${from} - ${to}`;
};

export const getDate = (datetime = null) => {
  if (datetime === null) return null;
  return moment(datetime).format("dddd, LL");
};

export const isPastDate = (datetime = null) => {
  if (datetime === null) return null;
  const diff = moment(datetime).zone(moment().zone()).diff(moment(), "seconds");
  return diff < 0;
};

export const getAge = (dob) => {
  return moment().diff(moment(dob), "years");
};

export const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      resolve(reader.result);
    };
    reader.onerror = function (error) {
      reject(error);
    };
  });
};

export const states = {
  usa: [
    { code: "AL", name: "Alabama" },
    { code: "AK", name: "Alaska" },
    { code: "AZ", name: "Arizona" },
    { code: "AR", name: "Arkansas" },
    { code: "CA", name: "California" },
    { code: "CO", name: "Colorado" },
    { code: "CT", name: "Connecticut" },
    { code: "DE", name: "Delaware" },
    { code: "DC", name: "District Of Columbia" },
    { code: "FL", name: "Florida" },
    { code: "GA", name: "Georgia" },
    { code: "HI", name: "Hawaii" },
    { code: "ID", name: "Idaho" },
    { code: "IL", name: "Illinois" },
    { code: "IN", name: "Indiana" },
    { code: "IA", name: "Iowa" },
    { code: "KS", name: "Kansas" },
    { code: "KY", name: "Kentucky" },
    { code: "LA", name: "Louisiana" },
    { code: "ME", name: "Maine" },
    { code: "MD", name: "Maryland" },
    { code: "MA", name: "Massachusetts" },
    { code: "MI", name: "Michigan" },
    { code: "MN", name: "Minnesota" },
    { code: "MS", name: "Mississippi" },
    { code: "MO", name: "Missouri" },
    { code: "MT", name: "Montana" },
    { code: "NE", name: "Nebraska" },
    { code: "NV", name: "Nevada" },
    { code: "NH", name: "New Hampshire" },
    { code: "NJ", name: "New Jersey" },
    { code: "NM", name: "New Mexico" },
    { code: "NY", name: "New York" },
    { code: "NC", name: "North Carolina" },
    { code: "ND", name: "North Dakota" },
    { code: "OH", name: "Ohio" },
    { code: "OK", name: "Oklahoma" },
    { code: "OR", name: "Oregon" },
    { code: "PA", name: "Pennsylvania" },
    { code: "RI", name: "Rhode Island" },
    { code: "SC", name: "South Carolina" },
    { code: "SD", name: "South Dakota" },
    { code: "TN", name: "Tennessee" },
    { code: "TX", name: "Texas" },
    { code: "UT", name: "Utah" },
    { code: "VT", name: "Vermont" },
    { code: "VA", name: "Virginia" },
    { code: "WA", name: "Washington" },
    { code: "WV", name: "West Virginia" },
    { code: "WI", name: "Wisconsin" },
    { code: "WY", name: "Wyoming" },
  ],
};

export const STRIPE_PUBLISHABLE_KEY =
  "pk_live_51HPT8jDPHJM074BNKecdxTNd8Uefoo3JEqxwGWbSWTmJkscN9I3QDxl8dzJcICOZukA6b1KFqONK7pJqNz7qIXvQ00QKkCEj8I";
export const AUTH_USER_TOKEN_KEY = "accessToken";
export const USER_PROFILE = "userProfile";
export const USER_COMPLETE_SURVEY = "userSurvey";
export const USER_COMPLETE_VIDEO = "userVideo";
export const USER_ORDER_CHECKOUT_DATA = "orderCheckout";
export const USER_APPOINTMENT_DATA = "appointmentData";
export const PATH_NEXT_URL = "nextUrl";
export const QUOTES_CPAP_MACHINE = 10;
export const QUOTES_MASK = 6;
export const WATCHPAT_ONE_DEFAULT_ID = 63; // WatchPAT ONE
export const CPAP_MACHINE_DEFAULT_ID = 21; // Resmed AirSense 11
export const MASK_DEFAULT_ID = 34; // Resmed Airfit F20 - Size M
export const CPAP_MACHINE_CATEGORY_DEFAULT_ID = 18; // CPAP machines
export const MASK_CATEGORY_DEFAULT_ID = 15; // Masks
