import amex from './amex.svg'
import dinersclub from './dinersclub.svg'
import discover from './discover.svg'
import jcb from './jcb.svg'
import mastercard from './mastercard.svg'
import maestro from './maestro.svg'
import unionpay from './unionpay.svg'
import visa from './visa.svg'

export const CreditCards = {
    amex,
    dinersclub,
    discover,
    jcb,
    mastercard,
    maestro,
    unionpay,
    visa
}