import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import moment from "moment";
import _ from "lodash";
import { getAge } from "../../utils";
import {
  Card,
  Skeleton,
  Breadcrumb,
  Button,
  Modal,
  Form,
  Input,
  Spin,
  message,
  Table,
} from "antd";
import {
  deleteAccount,
  getPatientCancelationRequests,
  getPatientProfile,
  getPatientSurveyData,
} from "../../APIClient";
import { ConsoleSqlOutlined, LoadingOutlined } from "@ant-design/icons";

const AdminCancelationRequestsDetails = () => {
  const [loadingProfile, setLoadingProfile] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [errorDelete, setErrorDelete] = useState("");
  const [data, setData] = useState(null);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [value, setValue] = useState("");
  const [patient, setPatient] = useState(null);
  const params = useParams();
  const history = useHistory();

  const renumaPlusCol = [
    {
      title: "Order ID",
      dataIndex: "order_id",
      key: "order_id",
    },
    {
      title: "Payment method",
      dataIndex: "payment_method",
      key: "payment_method",
      align: "center",
    },
    {
      title: "Next Payment",
      dataIndex: "next_payment",
      key: "next_payment",
      align: "center",
      render: (item) => (
        <>
          {moment(item.date_time).format("DD/MM/YYYY hh:mm a")}
          <br />
          <strong>Amount:</strong> ${item.amount}
          <br />
          <strong>Quotes:</strong> {item.quotes}
        </>
      ),
    },
    {
      title: "Subscription",
      dataIndex: "subscription",
      key: "subscription",
      align: "center",
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      align: "right",
      render: (item) => `$${item}`,
    },
  ];

  const appointmentsCol = [
    {
      title: "Order ID",
      dataIndex: "order_id",
      key: "order_id",
    },
    {
      title: "Appointment",
      dataIndex: "appointment",
      key: "appointment",
      align: "center",
      render: (item) => (
        <>
          {moment(item.date_time).format("DD/MM/YYYY hh:mm a")}
          <br />
          <strong>Kareo ID:</strong> ${item.kareo_id}
        </>
      ),
    },
  ];

  useEffect(() => {
    getProfile();
    getData();
  }, []);

  const getProfile = async () => {
    setLoadingProfile(true);
    try {
      const res = await getPatientProfile(params.email);
      setPatient(res.Item);
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingProfile(false);
    }
  };

  const getData = async () => {
    setLoadingData(true);
    try {
      const res = await getPatientCancelationRequests(params.email);
      setData(res);
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingData(false);
    }
  };

  const handleDeleteAccount = async () => {
    setLoadingDelete(true);
    try {
      const res = await deleteAccount(params.email);
      message.success("The user account was deleted successfully");
      history.push("/dashboard/cancelation-requests", { deleted: true });
    } catch (error) {
      if (error.response === undefined) {
        setErrorDelete("Error deleting user account");
      }
    } finally {
      setLoadingDelete(false);
    }
  };

  const handleClickDelete = () => {
    setErrorDelete("");
    setValue("");
    setShowConfirmDelete(true);
  };

  const handleClickOk = () => {
    console.log("value", value);
    if (value === "DELETE") {
      handleDeleteAccount();
    }
  };

  const handleClickCancel = () => {
    setShowConfirmDelete(false);
  };

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  return (
    <>
      <Breadcrumb className="mb-8">
        <Breadcrumb.Item>
          <Link to="/dashboard">Home</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Cancelation Request Details</Breadcrumb.Item>
      </Breadcrumb>
      <div className="flex flex-col mb-10">
        <Card className="flex-1 card-dashboard bg-dashboardHome bg-right-8-top-4 bg-no-repeat rounded-md drop-shadow mb-6">
          <div className="flex flex-row">
            <div className="flex flex-1 flex-col">
              <h3 className="font-bold">Personal Data:</h3>
              <Skeleton active loading={loadingProfile} />
              {!loadingProfile && patient !== null && (
                <p className="text-sm mb-0">
                  <strong>Full Name:</strong> {patient.full_name}
                  <br />
                  <strong>Email:</strong>{" "}
                  <a
                    href={`mailto:${patient.email}?subject=Cancelation Request Response&body=Dear ${patient.full_name}`}
                  >
                    {patient.email}
                  </a>
                  <br />
                  <strong>Zip Code:</strong> {patient.zip_code}
                  <br />
                  <strong>Age:</strong> {getAge(patient.date_of_birth)}
                </p>
              )}
              <br />
              <br />
              <h3 className="font-bold">Products and services:</h3>
              <Skeleton active loading={loadingData} />
              {!loadingData && data !== null && (
                <>
                  <p className="text-sm mb-0">
                    <strong>Cancellation Reason:</strong> {data.reason}
                    <br />
                    <strong>Cancelation Date:</strong>{" "}
                    {moment(data.datetime).format("MM/DD/YYYY hh:mm:ss")}
                    <br />
                    <strong>Home Sleep Tests:</strong>{" "}
                    {data.sleep_test === true ? "Yes" : "No"}
                  </p>
                  <p className="text-sm mb-0">
                    <strong>Renuma Plus:</strong>
                    {data.renuma_plus.length <= 0 && " No"}
                  </p>
                  {data.renuma_plus.length > 0 && (
                    <Table
                      className="w-full"
                      rowKey="order_id"
                      size="middle"
                      columns={renumaPlusCol}
                      dataSource={data.renuma_plus}
                      pagination={{ pageSize: 10 }}
                    />
                  )}
                  <p className="text-sm mb-0">
                    <strong>Telemedicines:</strong>{" "}
                    {data.telemedicine_paid.length <= 0 && " No"}
                  </p>
                  {data.telemedicine_paid.length > 0 && (
                    <Table
                      className="w-full"
                      rowKey="order_id"
                      size="middle"
                      columns={appointmentsCol}
                      dataSource={data.telemedicine_paid}
                      pagination={{ pageSize: 10 }}
                    />
                  )}
                </>
              )}
            </div>
          </div>
        </Card>
      </div>
      <div className="flex flex-col mb-10">
        <Card className="flex-1 card-dashboard bg-dashboardHome bg-right-8-top-4 bg-no-repeat rounded-md drop-shadow mb-6">
          <div className="flex flex-row justify-end">
            <Button type="primary" onClick={handleClickDelete}>
              Delete account
            </Button>
          </div>
        </Card>
      </div>
      <Modal
        title="Delete account"
        visible={showConfirmDelete}
        onOk={handleClickOk}
        onCancel={handleClickCancel}
        footer={[
          <Button key="cancel" onClick={handleClickCancel}>
            Cancel
          </Button>,
          <Button
            key="ok"
            type="primary"
            onClick={handleClickOk}
            disabled={value !== "DELETE" || loadingDelete}
          >
            {!loadingDelete ? (
              "OK"
            ) : (
              <Spin
                indicator={<LoadingOutlined style={{ color: "#6C009E" }} />}
              />
            )}
          </Button>,
        ]}
      >
        <p className="text-sm leading-normal">
          Do you really want to delete this account?
          <br />
          To continue deletion of this user, write DELETE below.
        </p>
        <Form>
          <Form.Item>
            <Input
              value={value}
              onChange={handleChange}
              placeholder="DELETE"
              onPaste={(e) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e) => {
                e.preventDefault();
                return false;
              }}
              onCut={(e) => {
                e.preventDefault();
                return false;
              }}
            />
            {errorDelete !== "" && (
              <p className="text-xs text-red-700 mb-0">{errorDelete}</p>
            )}
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default AdminCancelationRequestsDetails;
