import React, { useEffect, useState, useRef } from "react";
import Section from "../../../components/Section";
import {
  getBillingAddress,
  getProduct,
  getProductsByCollection,
  getShipping,
  saveOrder,
  updateBillingAddress,
} from "../../../APIClient";
import { List, Spin, Checkbox, Select, Form, Input, Button } from "antd";

import SecondaryButton from "../../../components/SecondaryButton";
import _ from "lodash";
import {
  CaretDownFilled,
  CaretUpFilled,
  LoadingOutlined,
} from "@ant-design/icons";
import imgClose from "../../../assets/images/baseline-close-24px.png";
import PrimaryButton from "../../../components/PrimaryButton";
import {
  QUOTES_CPAP_MACHINE,
  QUOTES_MASK,
  CPAP_MACHINE_CATEGORY_DEFAULT_ID,
  MASK_CATEGORY_DEFAULT_ID,
  states,
} from "../../../utils";
import SimpleReactValidator from "simple-react-validator";
import { Link, useHistory } from "react-router-dom";
import ProductDetail from "../../../components/RenumaPlus/ProductDetail";
import CreditCardInput from "../../../components/CreditCardInput/CreditCardInput";

import { GridIcon, ListIcon } from "../../../assets/svgs";
import SelectedProductItem from "../../../components/RenumaPlus/SelectedProductItem";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import PlacesAutocompleteInput from "../../../components/PlacesAutocompleteInput";

const { Option } = Select;

const OrderRenumaPlusProducts = () => {
  const { width } = useWindowDimensions();
  const cutOff = 960;
  const isMobile = width <= cutOff;
  const [loading, setLoading] = useState({
    cpapMachines: false,
    masks: false,
    confirmMask: false,
    purchase: false,
  });
  const [cpapMachines, setCpapMachines] = useState([]);
  const [masks, setMasks] = useState([]);
  const [maskResults, setMaskResults] = useState([]);
  const [showMachine, setShowMachine] = useState(null);
  const [selectedMachine, setSelectedMachine] = useState(null);
  const [selectedMask, setSelectedMask] = useState(null);
  const [selectedBonusMask, setSelectedBonusMask] = useState(null);
  const [selectedMaskVariation, setSelectedMaskVariation] = useState(null);
  const [selectedBonusMaskVariation, setSelectedBonusMaskVariation] =
    useState(null);
  const [confirmMask, setConfirmMask] = useState(null);
  const [shipping, setShipping] = useState(null);
  const [showGrid, setShowGrid] = useState(true);
  const [sortBy, setSortBy] = useState(null);
  const [sizeOptions, setSizeOptions] = useState([]);
  const [editShippingForm, setEditShippingForm] = useState(false);
  const [errorPurchase, setErrorPurchase] = useState(false);
  const [filters, setFilters] = useState([
    {
      id: 32,
      name: "Nose breather only",
      slug: "nose-breather-only",
      label: "I have trouble breathing through my nose",
      action: "remove",
      checked: false,
    },
    {
      id: 24,
      name: "Real teeth only",
      slug: "real-teeth-only",
      label: "I remove dentures at bedtime",
      action: "remove",
      checked: false,
    },
    {
      id: 25,
      name: "Smooth only",
      slug: "smooth-only",
      label: "I have facial hair",
      action: "remove",
      checked: false,
    },
    {
      id: 26,
      name: "Unobstructed only",
      slug: "unobstructed-only",
      label: "I want to watch TV or screens while using a CPAP",
      action: "add",
      checked: false,
    },
    {
      id: 22,
      name: "Minimal only",
      slug: "minimal-only",
      label: "I want little mask contact with my face",
      action: "add",
      checked: false,
    },
    {
      id: 28,
      name: "Secure only",
      slug: "secure-only",
      label: "I want to be sure the mask stays in place while I sleep",
      action: "add",
      checked: false,
    },
  ]);
  const [values, setValues] = useState({
    size: null,
    payment_method: "",
    accept: false,

    street_address: "",
    apt_suite: "",
    city: "",
    state_province: "",
    zipcode: "",

    credit_card_approved: null,
  });
  const [sameAddress, setSameAddress] = useState(true);
  const [, forceUpdate] = useState();
  const simpleValidator = useRef(
    new SimpleReactValidator({
      autoForceUpdate: { forceUpdate: forceUpdate },
      element: (message) => (
        <div className="rnm-input-error text-xs">{message}</div>
      ),
      validators: {
        zipcode: {
          // name the rule
          message: "ZIP code is invalid",
          rule: (val, params, validator) => {
            return validator.helpers.testRegex(
              val,
              /(^\d{5}$)|(^\d{5}-\d{4}$)/i
            );
          },
        },
      },
    })
  );
  const simpleValidator2 = useRef(
    new SimpleReactValidator({
      autoForceUpdate: { forceUpdate: forceUpdate },
      element: (message) => (
        <div className="rnm-input-error text-xs">{message}</div>
      ),
      validators: {
        zipcode: {
          // name the rule
          message: "ZIP code is invalid",
          rule: (val, params, validator) => {
            return validator.helpers.testRegex(
              val,
              /(^\d{5}$)|(^\d{5}-\d{4}$)/i
            );
          },
        },
      },
    })
  );
  const history = useHistory();
  const disabled = () => !simpleValidator.current.allValid();
  const disabled2 = () => !simpleValidator2.current.allValid();

  useEffect(() => {
    loadMachines();
    loadMasks();
    loadAddresses();
  }, []);

  const loadMachines = async () => {
    setLoading((v) => ({ ...v, cpapMachines: true }));
    try {
      const res = await getProductsByCollection(
        CPAP_MACHINE_CATEGORY_DEFAULT_ID
      );

      setCpapMachines(res);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading((v) => ({ ...v, cpapMachines: false }));
    }
  };

  const loadMasks = async () => {
    setLoading((v) => ({ ...v, masks: true }));
    try {
      const res = await getProductsByCollection(MASK_CATEGORY_DEFAULT_ID, 50);
      const sort = res.sort((a, b) => {
        if (a.menu_order < b.menu_order) {
          return -1;
        }
        if (a.menu_order > b.menu_order) {
          return 1;
        }
        return 0;
      });
      setMasks(sort);
      setMaskResults(sort);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading((v) => ({ ...v, masks: false }));
    }
  };

  const loadAddresses = async () => {
    setLoading((v) => ({ ...v, shipping: true }));
    try {
      const billing_res = await getBillingAddress();
      billing_res.street_address = billing_res.street_address;
      const billing_values = {
        billing_street_address: billing_res.street_address,
        billing_apt_suite: billing_res.apt_suite,
        billing_city: billing_res.city,
        billing_state_province: billing_res.state_province,
        billing_zipcode: billing_res.zip_code,
      };

      let res = await getShipping();
      if (!res.error && res.street_address === undefined) {
        res = {
          street_address: "",
          apt_suite: "",
          city: "",
          state_province: "",
          zipcode: "",
        };
      }
      setShipping(res);
      if (
        res.street_address === billing_res.street_address &&
        res.apt_suite === billing_res.apt_suite &&
        res.city === billing_res.city &&
        res.state_province === billing_res.state_province
      ) {
        setSameAddress(true);
      } else {
        setSameAddress(false);
      }

      setValues((v) => ({ ...v, ...res, ...billing_values }));
      setEditShippingForm(false);
    } catch (error) {
      console.log(error);
    } finally {
      setEditShippingForm(true);
      setLoading((v) => ({ ...v, shipping: false }));
    }
  };

  const isActive = (id) => {
    return showMachine !== null && showMachine.id === id;
  };

  const handleShowMachine = (item) => {
    setShowMachine(item);
  };

  const handleSelectMachine = (item) => {
    console.log("handleSelectedMachine");
    setSelectedMachine(item);
  };

  const handleEditMachine = (item) => {
    setSelectedMachine(null);
    setShowMachine(null);
  };

  useEffect(() => {
    let _masks = [...masks];
    if (filters[0].checked) {
      _masks = _masks.filter((mask) => {
        const tags = _.map(mask.tags, "id");
        if (!tags.includes(filters[0].id)) {
          return true;
        }
        return false;
      });
    }
    if (filters[1].checked) {
      _masks = _masks.filter((mask) => {
        const tags = _.map(mask.tags, "id");
        if (!tags.includes(filters[1].id)) {
          return true;
        }
        return false;
      });
    }
    if (filters[2].checked) {
      _masks = _masks.filter((mask) => {
        const tags = _.map(mask.tags, "id");
        if (!tags.includes(filters[2].id)) {
          return true;
        }
        return false;
      });
    }
    if (filters[3].checked) {
      _masks = _masks.filter((mask) => {
        const tags = _.map(mask.tags, "id");
        if (tags.includes(filters[3].id)) {
          return true;
        }
        return false;
      });
    }
    if (filters[4].checked) {
      _masks = _masks.filter((mask) => {
        const tags = _.map(mask.tags, "id");
        if (tags.includes(filters[4].id)) {
          return true;
        }
        return false;
      });
    }
    if (filters[5].checked) {
      _masks = _masks.filter((mask) => {
        const tags = _.map(mask.tags, "id");
        if (tags.includes(filters[5].id)) {
          return true;
        }
        return false;
      });
    }
    setMaskResults(_masks);
  }, [filters]);

  const handleFilterChange = (value) => {
    const { id } = value;
    setFilters((f) => {
      return f.map((i) => {
        if (i.id === id) {
          return { ...i, checked: !i.checked };
        }
        return i;
      });
    });
  };

  const showMaskDetails = (item) => {
    setSelectedMask(item);
    const sizes = item.attributes.filter((v) => v.name === "Size");
    setSizeOptions(sizes[0].options ? sizes[0].options : []);
  };

  const handleClickPaymentMethod = (method) => {
    setValues((v) => ({ ...v, payment_method: method }));
  };

  const onCheckAccept = (e) => {
    const { value, name } = e.target;
    setValues((v) => ({ ...v, [name]: value }));
  };

  const validateOrder = () => {
    let maskPrice =
      selectedMaskVariation.price !== ""
        ? selectedMaskVariation.price
        : selectedMaskVariation.regular_price;
    if (maskPrice === "") {
      maskPrice =
        selectedBonusMaskVariation.price !== ""
          ? selectedBonusMaskVariation.price
          : selectedBonusMaskVariation.regular_price;
    }

    if (selectedMachine.price === "") {
      setErrorPurchase("The selected product has no price. Please contact us.");
      return false;
    }

    if (maskPrice === "") {
      setErrorPurchase("The selected product has no price. Please contact us.");
      return false;
    }

    handleClickPurchase(maskPrice);
  };

  const handleClickPurchase = async (maskPrice) => {
    setErrorPurchase("");
    setLoading((v) => ({ ...v, purchase: true }));
    const shipping = sameAddress
      ? {
          street_address: values.billing_street_address,
          apt_suite: values.billing_apt_suite,
          city: values.billing_city,
          state_province: values.billing_state_province,
          zipcode: values.billing_zipcode,
        }
      : {
          street_address: values.street_address,
          apt_suite: values.apt_suite,
          city: values.city,
          state_province: values.state_province,
          zipcode: values.zipcode,
        };

    const data = {
      order_type: "Renuma+",
      ...shipping,
      subtotal: [selectedMachine.price, maskPrice],
      products: [
        selectedMachine,
        { ...selectedMaskVariation, selected_size: values.size },
        { ...selectedBonusMaskVariation, selected_size: values.size },
      ],
      payment_method: values.payment_method,
      quotes_cpap_machine:
        values.payment_method === "full" ? 1 : QUOTES_CPAP_MACHINE,
      quotes_mask: QUOTES_MASK,
    };

    try {
      const res = await saveOrder(data);
      const resBilling = await updateBillingAddress({
        street_address: values.billing_street_address,
        apt_suite: values.billing_apt_suite,
        city: values.billing_city,
        state_province: values.billing_state_province,
        zip_code: values.billing_zipcode,
      });
      if (res.status === 201) {
        history.push("/onboarding/order_renuma_plus_summary", {
          data: res.data,
          payment_method: values.payment_method,
          quotes_cpap_machine:
            values.payment_method === "full" ? 1 : QUOTES_CPAP_MACHINE,
          quotes_mask: QUOTES_MASK,
        });
      } else if (res.status === 400) {
        setErrorPurchase(res.data.error_msg);
      } else {
        setErrorPurchase("Your order was not processed. Please try again.");
      }
    } catch (error) {
      console.log(error);
      setErrorPurchase("Your order was not processed. Please try again.");
    } finally {
      setLoading((v) => ({ ...v, purchase: false }));
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues((v) => ({ ...v, [name]: value }));
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    simpleValidator.current.showMessageFor(name);
    setValues((v) => ({ ...v, [name]: value }));
  };

  const handleBlur2 = (e) => {
    const { name, value } = e.target;
    simpleValidator2.current.showMessageFor(name);
    setValues((v) => ({ ...v, [name]: value }));
  };

  const handleDetailsClose = () => {
    setSelectedMask(false);
  };

  const handleDetailSelect = async (values) => {
    setLoading((v) => ({ ...v, confirmMask: true }));
    setValues((v) => ({ ...v, ...values }));
    // search variation mask
    const id = selectedMask.variations[values.size];
    const m1 = await getProduct(id);
    setSelectedMaskVariation(m1);
    // search mask
    const mask = masks.filter((i) => i.id === selectedMask.cross_sell_ids[0]);
    if (mask.length > 0) {
      setSelectedBonusMask(mask[0]);
      const idBonus = mask[0].variations[values.size]
        ? mask[0].variations[values.size]
        : mask[0].variations[0];
      const m2 = await getProduct(idBonus);
      setSelectedBonusMaskVariation(m2);
    }
    setLoading((v) => ({ ...v, confirmMask: false }));
    setConfirmMask(true);
  };

  const handleChangeAddress = (data) => {
    const { name, value } = data;
    setValues((v) => ({ ...v, [name]: value }));
  };

  const handleBlurAddress = (data) => {
    const { name } = data;
    simpleValidator.current.showMessageFor(name);
    setValues((v) => ({ ...v, [name]: values[name] }));
  };

  const handleBlurAddress2 = (data) => {
    const { name } = data;
    simpleValidator2.current.showMessageFor(name);
    setValues((v) => ({ ...v, [name]: values[name] }));
  };

  const resetSelectedMachine = () => {
    setConfirmMask(false);
    setSelectedMask(null);
    setSelectedMachine(null);
    setShowMachine(null);
    setValues((v) => ({ ...v, size: null }));
  };

  const resetSelectedMask = () => {
    setConfirmMask(false);
  };

  const handleEditShipping = () => {
    setEditShippingForm(true);
  };

  const handleCardApproved = (value, params) => {
    console.log({ credit_card_approved: value, ...params });
    setValues((v) => ({ ...v, credit_card_approved: value }));
  };

  const handleListType = (is_grid) => {
    setShowGrid(is_grid);
  };

  const handleSortBy = () => {
    setSortBy((v) => {
      switch (v) {
        case null:
          setMaskResults((v) =>
            v.sort((a, b) => -1 * a.name.localeCompare(b.name))
          );
          return "desc";
          break;
        case "desc":
          setMaskResults((v) => v.sort((a, b) => a.name.localeCompare(b.name)));
          return "asc";
          break;
        case "asc":
          setMaskResults((v) =>
            v.sort((a, b) => {
              if (a.menu_order < b.menu_order) {
                return -1;
              }
              if (a.menu_order > b.menu_order) {
                return 1;
              }
              return 0;
            })
          );
          return null;
          break;
      }
    });
  };

  return (
    <Section className="bg-none">
      {!confirmMask && (
        <div className="flex flex-col max-w-7xl mx-auto">
          <div className="flex flex-col">
            <h2 className="text-2xl font-semibold mb-10">
              1. Select the CPAP machine
            </h2>
            <div className="flex flex-row w-full -mx-4">
              {loading.cpapMachines && (
                <div
                  className="flex flex-row justify-center items-center w-full"
                  style={{ minHeight: "248px" }}
                >
                  <Spin
                    indicator={<LoadingOutlined style={{ color: "#6c009e" }} />}
                  />
                </div>
              )}
              {!loading.cpapMachines &&
                cpapMachines.map((i, k) => {
                  if (selectedMachine !== null && !isActive(i.id)) {
                    return null;
                  }
                  return (
                    <div
                      className={`flex flex-auto p-4 justify-center cpap-machine ${
                        k > 0 ? "border-l border-gray-150" : ""
                      } ${isActive(i.id) ? "active" : ""}`}
                      key={k}
                      style={{
                        maxWidth: isActive(i.id)
                          ? cpapMachines.length > 1 || selectedMachine !== null
                            ? "100%"
                            : "50%"
                          : "33.333333%",
                      }}
                    >
                      <div
                        className={`p-4 pb-10 rounded-lg flex-row flex relative ${
                          isActive(i.id) ? " bg-gray-100 w-full" : "bg-white"
                        }`}
                      >
                        <div
                          className="flex flex-col justify-between h-40 w-40 bg-white p-4 rounded-lg h-full relative"
                          style={{ minHeight: "10rem", minWidth: "10rem" }}
                        >
                          <div className="flex flex-row justify-center">
                            <img src={i.images[0].src} className="w-full" />
                          </div>
                          <div className="flex flex-row">
                            <div className="flex-1 pr-4 text-xs leading-none">
                              {i.name}
                            </div>
                            {!isActive(i.id) && (
                              <div className="">
                                <div
                                  onClick={() => handleShowMachine(i)}
                                  className="flex justify-center items-center h-6 w-6 bg-renuma-violet-primary rounded-full text-white cursor-pointer"
                                >
                                  +
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        <div
                          className={`pt-10 px-4 ${
                            isActive(i.id) ? "block" : "hidden"
                          }`}
                        >
                          <h3 className="font-semibold text-base">{i.name}</h3>
                          <div
                            className="text-sm max-w-md max-h-24 overflow-y-scroll leading-normal cpap-machine-description"
                            dangerouslySetInnerHTML={{ __html: i.description }}
                          ></div>
                        </div>
                        <div
                          className={`absolute top-4 right-0 px-4 ${
                            isActive(i.id) ? "block" : "hidden"
                          }`}
                        >
                          <div
                            onClick={() => handleEditMachine(i)}
                            className="flex justify-center items-center h-6 w-6 cursor-pointer"
                          >
                            <img src={imgClose} className="w-6 h-6" />
                          </div>
                        </div>
                        <div
                          className={`absolute bottom-0 right-0 ${
                            isActive(i.id) ? "block" : "hidden"
                          }`}
                        >
                          {!selectedMachine && (
                            <PrimaryButton
                              className="px-14"
                              style={{
                                transform: "scale(0.7)",
                              }}
                              onClick={() => handleSelectMachine(i)}
                            >
                              Select
                            </PrimaryButton>
                          )}

                          {selectedMachine && (
                            <SecondaryButton
                              className="px-24"
                              style={{
                                transform: "scale(0.7)",
                              }}
                              onClick={() => handleEditMachine(i)}
                            >
                              Edit
                            </SecondaryButton>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>

          <div
            className={`mt-28 ${
              selectedMachine === null ? "disable-layer" : ""
            }`}
          >
            <h2 className="text-2xl font-semibold mb-12">
              2. Select a face mask that best suits your facial features
            </h2>
            <div className="flex-col md:flex-row flex items-start md:items-stretch w-full">
              <div className="w-full md:w-5/12">
                <h3 className="text-lg font-semibold">
                  CHECK ALL FEATURES THAT APPLY:
                </h3>
                <ul>
                  {filters.map((item, key) => (
                    <li key={key}>
                      <Checkbox onChange={() => handleFilterChange(item)}>
                        {item.label}
                      </Checkbox>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="flex-1 flex flex-col w-full">
                <div className="flex flex-row w-full justify-between items-stretch py-2 px-4 bg-gray-100 mb-6">
                  <div className="flex flex-row w-full items-center">
                    Sort By{" "}
                    <div
                      type="default"
                      onClick={handleSortBy}
                      className={`btn-sort bg-gray-100 ml-2 border border-gray-200 py-1 px-2 cursor-pointer hover:text-renuma-violet-700 hover:border-renuma-violet-700 ${
                        sortBy !== null
                          ? "text-renuma-violet-primary border-renuma-violet-primary"
                          : ""
                      }`}
                    >
                      Name{" "}
                      <div
                        className="inline-block align-middle"
                        style={{ transform: "scale(.75)" }}
                      >
                        <CaretUpFilled
                          className={`block -mb-1 ${
                            sortBy === "asc"
                              ? "text-renuma-violet-primary"
                              : "text-gray-400"
                          }`}
                        />
                        <CaretDownFilled
                          className={`block ${
                            sortBy === "desc"
                              ? "text-renuma-violet-primary"
                              : "text-gray-400"
                          }`}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row">
                    <div
                      className={`p-2 cursor-pointer ${
                        showGrid === true ? "bg-gray-200" : ""
                      }`}
                      onClick={() => handleListType(true)}
                    >
                      <GridIcon
                        style={{ color: showGrid ? "#6C009E" : "#C1C8CE" }}
                      />
                    </div>
                    <div
                      className={`p-2 cursor-pointer ${
                        showGrid === false ? "bg-gray-200" : ""
                      }`}
                      onClick={() => handleListType(false)}
                    >
                      <ListIcon
                        style={{ color: !showGrid ? "#6C009E" : "#C1C8CE" }}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-1">
                  {!selectedMask && maskResults.length > 0 && (
                    <List
                      grid={showGrid ? { gutter: 16, column: 3 } : null}
                      dataSource={maskResults}
                      renderItem={(item) => (
                        <List.Item>
                          <div
                            className={`${
                              !showGrid
                                ? "flex flex-row w-full space-x-6"
                                : "w-full h-auto md:w-48 md:h-48 border-gray-100 border p-2 md:p-4"
                            }`}
                          >
                            <div
                              className={`flex flex-row justify-center ${
                                !showGrid ? "w-24 text-center" : "mb-6"
                              }`}
                            >
                              <img
                                src={
                                  item.images.length ? item.images[0].src : ""
                                }
                                className={`${
                                  !showGrid ? "h-20 w-auto" : "w-20 h-20"
                                }`}
                              />
                            </div>
                            <div
                              className={`flex flex-col md:flex-row ${
                                !showGrid ? "w-full justify-between" : ""
                              }`}
                            >
                              <div className="flex-1 pr-4 text-xs leading-none">
                                {item.name}
                              </div>
                              <div className="mt-2 md:mt-0 flex justify-center md:justify-start">
                                <div
                                  onClick={() => showMaskDetails(item)}
                                  className="flex justify-center items-center h-6 w-6 bg-renuma-violet-primary rounded-full text-white cursor-pointer"
                                >
                                  +
                                </div>
                              </div>
                            </div>
                          </div>
                        </List.Item>
                      )}
                    />
                  )}

                  {!selectedMask && maskResults.length === 0 && (
                    <div className="flex flex-row justify-center text-center">
                      You have chosen too many filters.
                      <br />
                      Please uncheck one or more boxes to display your preferred
                      masks
                    </div>
                  )}

                  {selectedMask && (
                    <ProductDetail
                      onClose={handleDetailsClose}
                      onSelect={handleDetailSelect}
                      formData={values}
                      mask={selectedMask}
                      loading={loading.confirmMask}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {confirmMask && (
        <div className="flex flex-row max-w-7xl mx-auto space-x-8">
          <div className="flex flex-col flex-1">
            <h2 className="text-2xl font-semibold mb-10">
              3. Your Renuma+ Care Plan Products
            </h2>
            <ul className="flex flex-col flex-wrap">
              <li className="flex flex-row">
                <SelectedProductItem
                  number="1"
                  product={selectedMachine}
                  onEdit={resetSelectedMachine}
                  buttonText="Edit"
                />
              </li>
              <li className="flex flex-row">
                <SelectedProductItem
                  number="2"
                  product={selectedMask}
                  onEdit={resetSelectedMask}
                  buttonText="Edit"
                />
              </li>
              <li className="flex flex-row">
                <SelectedProductItem
                  number="3"
                  product={{
                    ...selectedBonusMask,
                    name: `Bonus Mask <small class="text-xs">(${selectedBonusMask.name})</small>`,
                    description: `<p class="p1">Thank you for choosing your desired mask.
                        <br />
                        As a bonus, we have included an alternative mask so you
                        can try something different.
                        <br />
                        Sometimes, this can help a customer find what works for
                        them best</p>`,
                  }}
                  labelText="Included"
                />
              </li>
            </ul>
          </div>
          <div className="flex flex-col w-5/12 border border-renuma-violet-primary bg-gray-100 rounded-lg py-5 px-12">
            <h2 className="text-2xl font-semibold text-center mb-10">
              Your Payment Plan Options
            </h2>
            <div className="mb-8">
              <p className="text-base leading-normal mb-5">
                Our Monthly payment option is available to you today.
              </p>
              <div className="text-right">
                {values.payment_method === "monthly" ? (
                  <PrimaryButton onClick={() => handleClickPaymentMethod("")}>
                    Pay Monthly
                  </PrimaryButton>
                ) : (
                  <SecondaryButton
                    onClick={() => handleClickPaymentMethod("monthly")}
                  >
                    Pay Monthly
                  </SecondaryButton>
                )}
              </div>
            </div>
            <div className="mb-8">
              <p className="text-base leading-normal mb-5">
                You can also opt to pay in full for the equipment and
                professional care access.
              </p>
              <div className="text-right">
                {values.payment_method === "full" ? (
                  <PrimaryButton onClick={() => handleClickPaymentMethod("")}>
                    Pay in Full
                  </PrimaryButton>
                ) : (
                  <SecondaryButton
                    onClick={() => handleClickPaymentMethod("full")}
                  >
                    Pay in Full
                  </SecondaryButton>
                )}
              </div>
            </div>

            <div className="mb-4">
              <table className="w-full">
                <tbody>
                  <tr>
                    <td className="w-2/6">
                      <strong className="font-semibold">
                        {values.payment_method !== ""
                          ? values.payment_method === "monthly"
                            ? "INSTALLMENTS:"
                            : "DUE TODAY:"
                          : ""}
                      </strong>
                    </td>
                    <td className="w-1/6">
                      {values.payment_method !== "" && (
                        <>
                          $
                          {(
                            parseInt(selectedMachine.price) /
                            (values.payment_method === "monthly"
                              ? QUOTES_CPAP_MACHINE
                              : 1)
                          ).toFixed(2)}
                        </>
                      )}
                    </td>
                    <td className="w-3/6">
                      {values.payment_method !== "" &&
                        (values.payment_method === "monthly"
                          ? "Monthly recurring payment."
                          : "Single payment")}
                    </td>
                  </tr>
                  <tr>
                    <td className="w-2/6">
                      <strong className="font-semibold">TAX:</strong>
                    </td>
                    <td className="w-1/6">$0.00</td>
                    <td className="w-3/6">Equipment only</td>
                  </tr>
                  <tr>
                    <td>
                      <strong className="font-semibold">SHIPPING:</strong>
                    </td>
                    <td>INCLUDED</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>
                      <strong className="font-semibold">TOTAL:</strong>
                    </td>
                    <td>
                      {values.payment_method !== "" && (
                        <>
                          $
                          {(
                            selectedMachine.price /
                            (values.payment_method === "monthly"
                              ? QUOTES_CPAP_MACHINE
                              : 1)
                          ).toFixed(2)}
                        </>
                      )}
                    </td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="mb-4">
              <h4 className="text-sm font-semibold text-renuma-violet-primary">
                BILLING INFORMATION
              </h4>
              <table className="w-full">
                <tbody>
                  <tr>
                    <td>
                      <strong className="font-semibold">ADDRESS:</strong>
                    </td>
                    <td style={{ maxWidth: "269px" }}>
                      <Form.Item style={{ marginBottom: 0 }}>
                        {editShippingForm == false ? (
                          values.billing_street_address
                        ) : (
                          <PlacesAutocompleteInput
                            name="billing_street_address"
                            value={values.billing_street_address}
                            onChange={handleChangeAddress}
                            onBlur={handleBlurAddress}
                            size="small"
                          />
                        )}
                        {simpleValidator.current.message(
                          "billing_street_address",
                          values.billing_street_address,
                          "required"
                        )}
                      </Form.Item>
                    </td>
                    <td className="text-right">
                      {editShippingForm == false && (
                        <PrimaryButton
                          type="link"
                          size="small"
                          onClick={handleEditShipping}
                        >
                          Edit
                        </PrimaryButton>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong className="font-semibold">APT#/SUITE:</strong>
                    </td>
                    <td>
                      <Form.Item style={{ marginBottom: 0 }}>
                        {editShippingForm == false ? (
                          values.billing_apt_suite
                        ) : (
                          <Input
                            name="billing_apt_suite"
                            value={values.billing_apt_suite}
                            size="large"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className="rnm-input leading-none text-xs"
                            style={{}}
                          />
                        )}
                      </Form.Item>
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>
                      <strong className="font-semibold">CITY:</strong>
                    </td>
                    <td>
                      <Form.Item style={{ marginBottom: 0 }}>
                        {editShippingForm == false ? (
                          values.billing_city
                        ) : (
                          <Input
                            name="billing_city"
                            value={values.billing_city}
                            size="large"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className="rnm-input leading-none text-xs"
                            style={{}}
                          />
                        )}
                        {simpleValidator.current.message(
                          "billing_city",
                          values.billing_city,
                          "required"
                        )}
                      </Form.Item>
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>
                      <strong className="font-semibold">STATE:</strong>
                    </td>
                    <td>
                      <Form.Item style={{ marginBottom: 0 }}>
                        {editShippingForm == false ? (
                          values.billing_state_province
                        ) : (
                          <Select
                            onChange={(e) =>
                              handleChange({
                                target: {
                                  name: "billing_state_province",
                                  value: e,
                                },
                              })
                            }
                            onBlur={(e) =>
                              handleBlur({
                                target: {
                                  name: "billing_state_province",
                                  value: values.billing_state_province,
                                },
                              })
                            }
                            className="rnm-input text-xs renumaplus-select"
                            size="large"
                            value={values.billing_state_province}
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            filterSort={(optionA, optionB) =>
                              optionA.children
                                .toLowerCase()
                                .localeCompare(optionB.children.toLowerCase())
                            }
                          >
                            {states.usa.map((item, k) => (
                              <Option key={k} value={item.code}>
                                {item.name}
                              </Option>
                            ))}
                          </Select>
                        )}
                        {simpleValidator.current.message(
                          "billing_state_province",
                          values.billing_state_province,
                          "required"
                        )}
                      </Form.Item>
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>
                      <strong className="font-semibold">ZIP CODE:</strong>
                    </td>
                    <td>
                      <Form.Item style={{ marginBottom: 0 }}>
                        {editShippingForm == false ? (
                          values.billing_zipcode
                        ) : (
                          <Input
                            name="billing_zipcode"
                            value={values.billing_zipcode}
                            size="large"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className="rnm-input leading-none text-xs"
                            style={{}}
                          />
                        )}
                        {simpleValidator.current.message(
                          "billing_zipcode",
                          values.billing_zipcode,
                          "required|zipcode"
                        )}
                      </Form.Item>
                    </td>
                    <td className="text-right"></td>
                  </tr>
                </tbody>
              </table>
              <Checkbox
                checked={sameAddress}
                onChange={(e) => {
                  setSameAddress((v) => !v);
                }}
                className="mt-2"
              >
                My shipping address is the same as my billing address
              </Checkbox>
            </div>
            {!sameAddress && (
              <div className="mb-4">
                <h4 className="text-sm font-semibold text-renuma-violet-primary">
                  SHIPPING INFORMATION
                </h4>
                <table className="w-full">
                  <tbody>
                    <tr>
                      <td>
                        <strong className="font-semibold">ADDRESS:</strong>
                      </td>
                      <td style={{ maxWidth: "269px" }}>
                        <Form.Item style={{ marginBottom: 0 }}>
                          {editShippingForm == false ? (
                            values.street_address
                          ) : (
                            <PlacesAutocompleteInput
                              name="street_address"
                              value={values.street_address}
                              onChange={handleChangeAddress}
                              onBlur={handleBlurAddress2}
                              size="small"
                            />
                          )}
                          {simpleValidator2.current.message(
                            "street_address",
                            values.street_address,
                            "required"
                          )}
                        </Form.Item>
                      </td>
                      <td className="text-right">
                        {editShippingForm == false && (
                          <PrimaryButton
                            type="link"
                            size="small"
                            onClick={handleEditShipping}
                          >
                            Edit
                          </PrimaryButton>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong className="font-semibold">APT#/SUITE:</strong>
                      </td>
                      <td>
                        <Form.Item style={{ marginBottom: 0 }}>
                          {editShippingForm == false ? (
                            values.apt_suite
                          ) : (
                            <Input
                              name="apt_suite"
                              value={values.apt_suite}
                              size="large"
                              onChange={handleChange}
                              onBlur={handleBlur2}
                              className="rnm-input leading-none text-xs"
                              style={{}}
                            />
                          )}
                        </Form.Item>
                      </td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>
                        <strong className="font-semibold">CITY:</strong>
                      </td>
                      <td>
                        <Form.Item style={{ marginBottom: 0 }}>
                          {editShippingForm == false ? (
                            values.city
                          ) : (
                            <Input
                              name="city"
                              value={values.city}
                              size="large"
                              onChange={handleChange}
                              onBlur={handleBlur2}
                              className="rnm-input leading-none text-xs"
                              style={{}}
                            />
                          )}
                          {simpleValidator2.current.message(
                            "city",
                            values.city,
                            "required"
                          )}
                        </Form.Item>
                      </td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>
                        <strong className="font-semibold">STATE:</strong>
                      </td>
                      <td>
                        <Form.Item style={{ marginBottom: 0 }}>
                          {editShippingForm == false ? (
                            values.state_province
                          ) : (
                            <Select
                              onChange={(e) =>
                                handleChange({
                                  target: { name: "state_province", value: e },
                                })
                              }
                              onBlur={(e) =>
                                handleBlur2({
                                  target: {
                                    name: "state_province",
                                    value: values.state_province,
                                  },
                                })
                              }
                              className="rnm-input text-xs renumaplus-select"
                              size="large"
                              value={values.state_province}
                              showSearch
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                              filterSort={(optionA, optionB) =>
                                optionA.children
                                  .toLowerCase()
                                  .localeCompare(optionB.children.toLowerCase())
                              }
                            >
                              {states.usa.map((item, k) => (
                                <Option key={k} value={item.code}>
                                  {item.name}
                                </Option>
                              ))}
                            </Select>
                          )}
                          {simpleValidator2.current.message(
                            "state_province",
                            values.state_province,
                            "required"
                          )}
                        </Form.Item>
                      </td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>
                        <strong className="font-semibold">ZIP CODE:</strong>
                      </td>
                      <td>
                        <Form.Item style={{ marginBottom: 0 }}>
                          {editShippingForm == false ? (
                            values.zipcode
                          ) : (
                            <Input
                              name="zipcode"
                              value={values.zipcode}
                              size="large"
                              onChange={handleChange}
                              onBlur={handleBlur2}
                              className="rnm-input leading-none text-xs"
                              style={{}}
                            />
                          )}
                          {simpleValidator2.current.message(
                            "zipcode",
                            values.zipcode,
                            "required|zipcode"
                          )}
                        </Form.Item>
                      </td>
                      <td className="text-right"></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
            <div className="mb-4">
              <h4 className="text-sm font-semibold text-renuma-violet-primary">
                PAYMENT INFORMATION
              </h4>
              <div className="my-4">
                <CreditCardInput onApproved={handleCardApproved} />
              </div>
              <div className="hidden">
                {simpleValidator.current.message(
                  "credit_card_approved",
                  values.credit_card_approved,
                  "accepted"
                )}
              </div>
            </div>
            <div className="text-center mb-4 text-red-500">
              {errorPurchase && <div className="text-red">{errorPurchase}</div>}
            </div>
            <div className="flex flex-row justify-end align-stretch space-x-4">
              <div className="flex flex-row justify-start items-center flex-1 text-xs">
                <Checkbox
                  name="accept"
                  value={!values.accept}
                  onChange={onCheckAccept}
                  checked={values.accept}
                  className="mr-2"
                />
                <div>
                  Accept
                  <Link
                    to="/conditions-of-use"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="ml-1 text-xs text-black hover:text-renuma-violet-primary hover:underline"
                  >
                    Terms & Conditions
                  </Link>
                </div>
              </div>
              <div>
                <PrimaryButton
                  disabled={
                    (sameAddress ? disabled() : disabled() || disabled2()) ||
                    values.size === null ||
                    values.payment_method === "" ||
                    values.accept === false ||
                    loading.purchase
                  }
                  onClick={validateOrder}
                >
                  {!loading.purchase ? (
                    "Purchase"
                  ) : (
                    <Spin
                      indicator={
                        <LoadingOutlined style={{ color: "#FFFFFF" }} />
                      }
                    />
                  )}
                </PrimaryButton>
              </div>
            </div>
            <div className="text-center pt-8">
              <PrimaryButton type="link" onClick={() => setConfirmMask(false)}>
                Cancel
              </PrimaryButton>
            </div>
          </div>
        </div>
      )}
    </Section>
  );
};

export default OrderRenumaPlusProducts;
