import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import Section from "../../../components/Section";
import checkIcon from "../../../assets/svgs/check.svg";
import checkSmallIcon from "../../../assets/images/check_icon_small.png";
import headSeparator from "../../../assets/images/renuma-plus-price-separator.png";
import { List, Avatar, Select, Skeleton } from "antd";
import PrimaryButton from "../../../components/PrimaryButton";
import { getProduct } from "../../../APIClient";

import {
  QUOTES_CPAP_MACHINE,
  QUOTES_MASK,
  CPAP_MACHINE_DEFAULT_ID,
  MASK_DEFAULT_ID,
} from "../../../utils";

import _ from "lodash";

const { Option } = Select;

const OrderRenumaPlus = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [cpapMachine, setCpapMachine] = useState({});
  const [mask, setMask] = useState({});

  useEffect(() => {
    loadProduct();
  }, []);

  const handleGetStarted = () => {
    history.push("/onboarding/order_checkout/renuma_plus");
  };

  const loadProduct = async () => {
    setLoading(true);
    try {
      const cpap = await getProduct(CPAP_MACHINE_DEFAULT_ID);
      const rMask = await getProduct(MASK_DEFAULT_ID);
      setCpapMachine(cpap);
      setMask(rMask);
    } catch (error) {
      console.log(error);
      setErrorMessage(
        "Error: Could not stablish server connection, try again later."
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Section>
      <div className="my-0 md:my-9 max-w-7xl mx-auto flex flex-col md:flex-row space-x-0 md:space-x-12 px-0 md:px-8">
        <div className="mt-14 md:mt-0 mb-10 md:mb-0 max-w-xl">
          <h2 className="text-renuma-violet-primary text-lg mb-2">NEXT STEP</h2>
          <h3 className="text-xl2 md:text-3xl font-bold leading-snug pb-4 mb-6 border-b border-renuma-violet-400">
            Sign up for Ongoing Care with Renuma+
          </h3>

          <div className="flex flex-col justify-start mb-10 md:mb-2">
            {loading && (
              <div className="flex-col w-full px-12">
                <Skeleton.Image
                  className="w-full"
                  style={{ height: "340px" }}
                />
                <Skeleton />
              </div>
            )}
            {!loading && (
              <>
                <div className="w-full px-12">
                  <img
                    src={
                      cpapMachine.images !== undefined &&
                      cpapMachine.images.length > 0
                        ? cpapMachine.images[0].src
                        : ""
                    }
                    alt={cpapMachine.name}
                    className="h-auto w-full"
                  />
                </div>
                <div
                  className="px-6 md:px-12 flex-1 font-body text-base leading-normal cpap-machine-description"
                  dangerouslySetInnerHTML={{
                    __html: cpapMachine.short_description,
                  }}
                ></div>
              </>
            )}
          </div>

          {!loading && (
            <List
              dataSource={cpapMachine.tags}
              renderItem={(item) => (
                <List.Item className="pb-2 border-none">
                  <List.Item.Meta
                    className="flex-row justify-start items-stretch"
                    avatar={<Avatar src={checkIcon} />}
                    title={
                      <div className="flex flex-column justify-start items-center h-8 font-body text-base">
                        {item.name}
                      </div>
                    }
                  />
                </List.Item>
              )}
            />
          )}

          {!loading && errorMessage === "" && (
            <div className="mt-10 hidden md:block">
              <PrimaryButton onClick={handleGetStarted}>
                Get Started with Renuma+
              </PrimaryButton>
            </div>
          )}
          {!loading && errorMessage !== "" && (
            <div className="border border-dashed border-gray-300 p-2">
              {errorMessage}
            </div>
          )}
        </div>
        <div className="max-w-lg w-full">
          {loading && <Skeleton paragraph={{ rows: 15 }} />}
          {!loading && errorMessage === "" && (
            <>
              <div className="border border-dashed border-gray-300 p-2">
                <div className="w-full">
                  <div className="w-full flex flex-row header-double-price relative">
                    <div className="w-1/2 bg-secondary flex flex-row justify-center items-center">
                      <h6 className="mx-8 my-0 text-xs md:text-xl text-white font-semibold leading-tight text-center">
                        First {QUOTES_CPAP_MACHINE} months
                      </h6>
                    </div>
                    <div className="w-1/2 bg-renuma-violet-primary flex flex-row justify-center items-center">
                      <h6 className="mx-8 my-0 text-xs md:text-xl text-white font-semibold leading-tight text-center">
                        At the end of {QUOTES_CPAP_MACHINE} months
                      </h6>
                    </div>
                    <div className="absolute w-full t-0 l-0 flex flex-row justify-center">
                      <img src={headSeparator} />
                    </div>
                  </div>
                  <div className="w-full flex flex-row">
                    <div className="w-1/2 bg-blue-100 border-r-6 border-white py-4 md:py-6 px-3">
                      <h4 className="text-2xs md:text-sm text-renuma-violet-primary font-base text-center mb-2 md:mb-3">
                        You pay
                      </h4>
                      <h5 className="text-2xl md:text-3xl font-body font-bold text-center mb-2 md:mb-6">
                        ${cpapMachine.price / 10}
                        <small className="text-2xs md:text-base">/mo.</small>
                      </h5>
                      <List
                        dataSource={[
                          {
                            title:
                              "Cost of CPAP Machine, Face Masks, Air tubing, Air filters, humididfier in 10 monthly payments",
                          },
                          {
                            title:
                              "Replacement supplies such as masks and hoses",
                          },
                          { title: "Supplies delivered to your door" },
                          { title: "Ongoing monitoring of treatment" },
                        ]}
                        renderItem={(item) => (
                          <List.Item className="pt-0 md:pt-3 pb-2 border-none">
                            <List.Item.Meta
                              className="flex-row justify-start items-stretch list-small-icon"
                              avatar={<Avatar src={checkSmallIcon} />}
                              title={
                                <div className="flex flex-column justify-start items-center font-body text-2xs md:text-sm leading-snug">
                                  {item.title}
                                </div>
                              }
                            />
                          </List.Item>
                        )}
                      />
                    </div>
                    <div className="w-1/2 bg-violet-400 bg-opacity-50 border-l-6 border-white py-4 md:py-6 px-3">
                      <h4 className="text-2xs md:text-sm text-renuma-violet-primary font-base text-center mb-2 md:mb-3">
                        You pay
                      </h4>
                      <h5 className="text-2xl md:text-3xl font-body font-bold text-center mb-2 md:mb-6">
                        ${mask.price / QUOTES_MASK}
                        <small className="text-2xs md:text-base">/mo.</small>
                      </h5>
                      <List
                        dataSource={[
                          {
                            title:
                              "Replacement supplies such as masks and hoses",
                          },
                          { title: "Supplies delivered to your door" },
                          { title: "Ongoing monitoring of treatment" },
                        ]}
                        renderItem={(item) => (
                          <List.Item className="pt-0 md:pt-3 pb-2 border-none">
                            <List.Item.Meta
                              className="flex-row justify-start items-stretch list-small-icon"
                              avatar={<Avatar src={checkSmallIcon} />}
                              title={
                                <div className="flex flex-column justify-start items-center font-body text-2xs md:text-sm leading-snug">
                                  {item.title}
                                </div>
                              }
                            />
                          </List.Item>
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-4 hidden md:block">
                <p className="text-sm text-renuma-violet-800 leading-normal">
                  Optional virtual check-ins with a doctor are $15 each and are
                  not included in the monthly fee.
                </p>
              </div>

              <div className="mt-10 block md:hidden">
                <PrimaryButton onClick={handleGetStarted}>
                  Get Started with Renuma+
                </PrimaryButton>
              </div>
            </>
          )}
        </div>
      </div>
    </Section>
  );
};

export default OrderRenumaPlus;
